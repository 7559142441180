import "./CreLoader.css";

const CreLoader = () => {
  return (
    <div className="mask">
      <div className="loader">
        <div className="loader__bounce first"></div>
        <div className="loader__bounce second"></div>
        <div className="loader__bounce third"></div>
      </div>
    </div>
  );
};

export default CreLoader;
