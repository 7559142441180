import React, { useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "../ExtractionTable/ExtractionTable.css";
import Table from "../Table";
import TabTitleContext from "../../../context/TabTitleContext";

const RecognizerTable = (props) => {
  const { currentTab, setCurrentTab } = useContext(TabTitleContext);
  return (
    <div className="output-table">
      <Tabs
        defaultActiveKey={`${currentTab || props.all_tabs[0]}`}
        id="justify-tab-example"
        className="mb-3"
        justify
        onSelect={(tab) => setCurrentTab(tab)}
      >
        {props.all_tabs.map((tab, index) => {
          return (
            <Tab key={index} eventKey={`${tab}`} title={tab}>
              <div className="Extraction">
                <div className="Extraction-tables">
                  <Table
                    kvpTdData={props.kvpTdData}
                    tableThData={props.tableThData}
                    tableTrData={props.tableTrData}
                  />
                </div>
              </div>
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default RecognizerTable;
