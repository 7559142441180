import Accordion from "react-bootstrap/Accordion";
import FaqsList from "./utils/faqs";

const Faqs = () => {
  return (
    <div style={{ padding: "1%" }}>
      {FaqsList.map(({ id, question, content }, index) => {
        return (
          <Accordion
            defaultActiveKey={id[index]}
            key={id}
            style={{ marginBottom: "1%" }}
          >
            <Accordion.Item eventKey={id}>
              <Accordion.Header>
                <span className="heading">
                  {id}. {question}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <span className="para">{content}</span>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
    </div>
  );
};

export default Faqs;
