

const InferSideImage = (props) => {
  return (
    <div className="carousel_conatiner">
      <img src={props.image} alt="img" draggable="false" />
      <div style={{ padding: "8px" }}>
        <h6 className="heading carousel_conatiner_title">{props.title}</h6>
        <span className="para">{props.children}</span>
      </div>
    </div>
  );
};

export default InferSideImage;
