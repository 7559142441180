import {useContext} from "react";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import Papa from "papaparse";
import html2pdf from "html2pdf.js";
import ParentContext from "../context/context";

const useDownload = (currentTab, response) => {
  const { state } = useContext(ParentContext);
  const { backendResponse, responseText } = state;
  let kvpTab, TableTab;
  if (Array.isArray(response?.Data)) {
    kvpTab = response?.Data?.filter((element) => {
      return element?.BlockType === "kvp";
    });
  }
  let FindKvp = kvpTab?.find((element) => element?.BlockType === "kvp");
  if (Array.isArray(backendResponse?.Data)) {
    TableTab = backendResponse?.Data?.filter((element) => {
      return element?.BlockType === "table";
    });
  }
  let FindTable = TableTab?.find((element) => element?.BlockType === "table");

  const export_As_Json = () => {
    let generate_Kvp_Json_File = () => {
      if (FindKvp?.BlockType) {
        const kvpData = response?.Data?.filter((block) => {
          return currentTab === block.TabTitle && block.BlockType === "kvp";
        });
        const jsonContent = JSON.stringify(kvpData);
        return jsonContent;
      }
    };

    let generate_Table_Json_File = () => {
      if (FindTable?.BlockType) {
        const tableTdData = backendResponse?.Data?.filter((block) => {
          return currentTab === block.TabTitle && block.BlockType === "table";
        });
        const jsonContent = JSON.stringify(tableTdData);
        return jsonContent;
      }
    };

    let jsonContent1 = generate_Kvp_Json_File();
    let jsonContent2 = generate_Table_Json_File();

    const Link = document.createElement("a");
    Link.href = URL.createObjectURL(
      new Blob([jsonContent1, jsonContent2], { type: "application/json" })
    );

    Link.download = [`${currentTab}.json`];
    Link.download = [`${currentTab}.json`];
    Link.style.display = "none";
    document.body.appendChild(Link);
    Link.click();
    document.body.removeChild(Link);
  };

  const export_As_Csv = () => {
    let kvp_Csv = () => {
      if (FindKvp?.BlockType) {
        return Papa.unparse(
          response?.Data?.filter(
            (block) =>
              block.TabTitle === currentTab && block.BlockType === "kvp"
          ),
          { header: true }
        );
      }
    };

    let table_Csv = () => {
      if (FindTable?.BlockType) {
        return Papa.unparse(
          backendResponse?.Data?.filter(
            (block) =>
              block.TabTitle === currentTab && block.BlockType === "table"
          ),
          { header: true }
        );
      }
    };

    const csvContent1 = kvp_Csv();
    const csvContent2 = table_Csv();

    const Link = document.createElement("a");
    Link.href = URL.createObjectURL(
      new Blob([csvContent1, csvContent2], { type: "text/csv" })
    );

    Link.download = [`${currentTab}.csv`];
    Link.download = [`${currentTab}.csv`];
    Link.style.display = "none";
    document.body.appendChild(Link);
    Link.click();
    document.body.removeChild(Link);
  };

  const export_As_Xlsx = () => {
    let validate_Kvp,
      validate_Table,
      Kvp_Work_Sheet,
      Work_Breakdown_Structure_Kvp,
      table_Work_Sheet,
      Work_Breakdown_Structure_table,
      xlsx_Data;

    const s2ab = (sample) => {
      const bufferFormate = new ArrayBuffer(sample?.length);
      const view = new Uint8Array(bufferFormate);
      for (let i = 0; i < sample?.length; i++) {
        view[i] = sample?.charCodeAt(i) & 0xff;
      }
      return bufferFormate;
    };

    if (FindKvp?.BlockType) {
      const kvpDataxlsx = response?.Data?.filter(
        (block) => currentTab === block.TabTitle && block.BlockType === "kvp"
      );
      Work_Breakdown_Structure_Kvp = XLSX.utils.book_new();
      Kvp_Work_Sheet = XLSX.utils.json_to_sheet(kvpDataxlsx);
      validate_Kvp = Object.keys(Kvp_Work_Sheet).length >= 1;
    }

    const kvp_Tabs = () => {
      XLSX.utils.book_append_sheet(
        Work_Breakdown_Structure_Kvp,
        Kvp_Work_Sheet,
        "KVP Data"
      );
      xlsx_Data = XLSX.write(Work_Breakdown_Structure_Kvp, {
        bookType: "xlsx",
        type: "binary",
      });
      const kvpfileName = `${currentTab}.xlsx`;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const blob = new Blob([s2ab(xlsx_Data)], { type: fileType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = kvpfileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    if (validate_Kvp) {
      kvp_Tabs();
    }
    if (FindTable?.BlockType) {
      const tableData = backendResponse?.Data?.filter(
        (block) => currentTab === block.TabTitle && block.BlockType === "table"
      );
      const tableThData = tableData?.reduce((result, block) => {
        if (!result.includes(block.Key)) {
          result.push(block.Key);
        }
        return result;
      }, []);
      const tableTrData = tableData
        ?.map((block) => block.Value)
        .filter((value) => value !== "[]");
      function transposeArray(array) {
        return array?.[0]?.map((_, colIndex) => array?.map((row) => row?.[colIndex]));
      }
      const transposedArray = transposeArray(tableTrData);
      Work_Breakdown_Structure_table = XLSX.utils.book_new();
      table_Work_Sheet = XLSX.utils.aoa_to_sheet([
        tableThData,
        ...transposedArray,
      ]);
      validate_Table = Object.keys(table_Work_Sheet).length >= 1;
    }

    const table_Tabs = () => {
      XLSX.utils.book_append_sheet(
        Work_Breakdown_Structure_table,
        table_Work_Sheet,
        "Table Data"
      );
      xlsx_Data = XLSX.write(Work_Breakdown_Structure_table, {
        bookType: "xlsx",
        type: "binary",
      });
      const tableFileName = `${currentTab}.xlsx`;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([s2ab(xlsx_Data)], { type: fileType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = tableFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    if (validate_Table) {
      table_Tabs();
    }
  };

  const export_As_Pdf = (kvpTdData, tableTrData) => {
    if (FindKvp?.BlockType) {
      const doc = new jsPDF();
      const pdfWidth = doc.internal.pageSize.width;
      const pdfHeight = doc.internal.pageSize.height;
      doc.setTextColor(100);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Table - ${currentTab}`, 15, 15);
      const kvpData = kvpTdData();
      if (kvpData) {
        let startY = 30;
        kvpData.forEach((data) => {
          const key = data?.props?.children?.[0]?.props?.children;
          const value = data?.props?.children[1]?.props?.children;
          doc.text(`${key}: ${value}`, 15, startY, { maxWidth: pdfWidth - 30 });
          startY += 10;
          if (startY > pdfHeight - 20) {
            doc.addPage();
            startY = 30;
          }
        });
      }
      doc.save(`${currentTab}.pdf`);
    }

    if (FindTable?.BlockType) {
      if (tableTrData.length >= 1) {
        const element = document.getElementById("table");
        const opt = {
          margin: 0.5,
          filename: `${currentTab}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf().from(element).set(opt).save();
      }
    }
  };

  const export_PlainText_As_Pdf = () => {
    const doc = new jsPDF();
    const splitText = doc.splitTextToSize(
      responseText,
      doc.internal.pageSize.width - 35
    );
    doc.setTextColor(10);
    doc.setFont("courier", "normal");
    doc.setFontSize(12);
    doc.text(splitText, 20, 20);
    doc.save("PlainText.pdf");
  };

  const export_Redacted_File_As_Pdf = async (base64Url, fileName) => {
    const link = document.createElement("a");
    link.href = base64Url;
    link.target = "_blank";
    link.download = fileName;
    link.click();
  };

  return {
    export_As_Json,
    export_As_Csv,
    export_As_Xlsx,
    export_As_Pdf,
    export_PlainText_As_Pdf,
    export_Redacted_File_As_Pdf,
  };
};

export default useDownload;
