const useValidateRoute = () => {
  const validateSolutionRoutes = [
    "/",
    "/faqs",
    "/dashboard",
    "/extraction",
    "/bankStatements",
    "/generativeai",
    "/commercialRealEstate",
    "/redaction",
    "/recognition",
    "/classification",
    "/OutputScreen",
    "/privacyPloicy",
    "/predictiveAnalytics",
    "/profile",
    "/bankstatements",
    "/predictiveAnalytics/PredictiveAnalyticsOutputScreen",
    "/finStatExtraction"
  ];

  const validateServiceRoutes = [
    "/faqs",
    "/serviceDashboard",
    "/requestMonitoring",
    "/productInfo",
    "/customerSettings",
    "/processMetrics",
    "/systemLogs",
  ];

  const validateBillingRoutes = [
    "/faqs",
    "/billingMetrics",
    "/billingInfo",
    "/processedFileInfo",
  ];
  
  return {
    validateSolutionRoutes,
    validateServiceRoutes,
    validateBillingRoutes,
  };
};

export default useValidateRoute;
