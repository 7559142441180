import React, { useState, useContext, useEffect, memo } from "react";
import {
  FontAwesomeIcon,
  faPlus,
  faSave,
  faTrashCan,
} from "../../../../import/FontAwesomeIcons";
import { InputBox, CustomButton } from "../../../../components/index";
import ParentContext from "../../../../context/context";
import "./RentRollQuestions.css";
import RentRollPreDefinedQuestions from "../../utils/RentRollPreDefinedQuestions";

const RentRollQuestions = () => {
  const { dispatch } = useContext(ParentContext);
  const [templateQuestions, setTemplateQuestions] = useState(
    RentRollPreDefinedQuestions
  );
  const [editedLabel, setEditedLabel] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newField, setNewField] = useState("");
  const [showNewFieldInput, setShowNewFieldInput] = useState(false);

  useEffect(() => {
    dispatch({
      type: "SET_RENT_ROLL_NEWLY_ADDED_QUESTIONS",
      payload: templateQuestions,
    });
  }, []);

  const handleDelete = (id) => {
    const updatedQuestions = templateQuestions.filter((form) => form.id !== id);
    setTemplateQuestions(updatedQuestions);
    dispatch({
      type: "SET_RENT_ROLL_NEWLY_ADDED_QUESTIONS",
      payload: updatedQuestions,
    });
  };

  const handleSave = (id) => {
    const updatedQuestions = templateQuestions.map((form) => {
      if (form.id === id) {
        return { ...form, question: editedLabel };
      }
      return form;
    });
    setTemplateQuestions(updatedQuestions);
    setIsEditing(false);
  };

  const handleAddField = () => {
    const newId =
      templateQuestions.length > 0
        ? templateQuestions[templateQuestions.length - 1].id + 1
        : 1;
    const newTaxForm = { id: newId, question: newField };
    const updatedQuestions = [...templateQuestions, newTaxForm];
    setTemplateQuestions(updatedQuestions);
    setNewField("");
    setShowNewFieldInput(false);
    dispatch({
      type: "SET_RENT_ROLL_NEWLY_ADDED_QUESTIONS",
      payload: updatedQuestions,
    });
  };

  const handleInputChange = (event) => {
    setNewField(event.target.value);
  };

  const handleEditInputChange = (event) => {
    setEditedLabel(event.target.value);
  };

  const handleShowNewFieldInput = () => {
    setShowNewFieldInput(true);
  };
  return (
    <div className="rent-roll-questions">
      <span>
        <p
          style={{ marginLeft: "20px", marginBottom: "10px" }}
          className="heading"
        >
          Questions
        </p>
        <hr />
      </span>
      {templateQuestions?.map((Question, index) => (
        <div key={Question.id} className="rent-roll-question">
          {!isEditing || isEditing !== Question.id ? (
            <span className="para">
              {index + 1}. {Question.question}
            </span>
          ) : (
            <InputBox
              type="text"
              size="sm"
              value={editedLabel}
              onChange={handleEditInputChange}
              disabled={false}
            />
          )}
          {!isEditing || isEditing !== Question.id ? (
            <div className="template-edit-and-delete">
              <div className="template-delete">
                <CustomButton
                  size="sm"
                  variant="light"
                  onClick={() => handleDelete(Question.id)}
                >
                  <FontAwesomeIcon className="iq_svg" icon={faTrashCan} />
                </CustomButton>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <CustomButton
                size="sm"
                variant="success"
                onClick={() => handleSave(Question.id)}
              >
                <FontAwesomeIcon icon={faSave} />
                <span className="template-save">Save</span>
              </CustomButton>
            </React.Fragment>
          )}
        </div>
      ))}
      <div className="add-new-template">
        {!showNewFieldInput && (
          <div className="add-new-template-btn">
            <CustomButton
              size="md"
              variant="primary"
              onClick={handleShowNewFieldInput}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="create-new-template">Add Questions</span>
            </CustomButton>
          </div>
        )}
      </div>
      {showNewFieldInput && (
        <div className="add-new-template">
          <div className="add-new-template-input">
            <center>
              <InputBox
                type="text"
                size="sm"
                value={newField}
                onChange={handleInputChange}
                placeholder="Add New Template"
                disabled={false}
              />
            </center>
          </div>
          <div className="add-new-template-btn">
            <CustomButton
              size="sm"
              variant="primary"
              onClick={handleAddField}
              disabled={newField.length <= 0}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="create-new-template">Add</span>
            </CustomButton>

            <span style={{ marginLeft: "4px" }}>
              <CustomButton
                size="sm"
                variant="primary"
                onClick={() => setShowNewFieldInput(false)}
              >
                Back
              </CustomButton>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(RentRollQuestions);
