import React, { useState, useEffect, memo } from "react";
import "./Dashboard.css";
import "react-resizable/css/styles.css";
import { UserDashboard, AdminDashboard } from "./components/index";
import {
  userDashboardlayoutConfig,
  AdminDashboardlayoutConfig,
} from "./utils/layout";
import { useCharts } from "../../Hooks/index";
import getCurrentBreakpoint from "./utils/getCurrentBreakpoint";

const Dashboard = () => {
  const adminEmail = "ravitej.l@idexcel.com";
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    getCurrentBreakpoint()
  );

  const onBreakpointChange = () => {
    setCurrentBreakpoint(getCurrentBreakpoint());
  };

  useEffect(() => {
    window.addEventListener("resize", onBreakpointChange);
    return () => {
      window.removeEventListener("resize", onBreakpointChange);
    };
  }, []);

  const userDashboardCurrentLayout =
    userDashboardlayoutConfig[currentBreakpoint];
  const AdminDashboardCurrentLayout =
    AdminDashboardlayoutConfig[currentBreakpoint];

  const [selectedLayouts, setSelectedLayouts] = useState(
    userDashboardCurrentLayout?.map((item) => item.key)
  );

  const breakpoints = {
    xxl: 1920,
    xl: 1280,
    lg: 1024,
    md: 768,
    sm: 568,
    xs: 480,
    xxs: 0,
  };

  const cols = { xxl: 16, xl: 14, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

  const { userGraphs, adminGraphs } = useCharts();

  const handleLayoutSelection = (layoutKey, isChecked) => {
    if (isChecked) {
      setSelectedLayouts((prevSelectedLayouts) => [
        ...prevSelectedLayouts,
        layoutKey,
      ]);
    } else {
      setSelectedLayouts((prevSelectedLayouts) =>
        prevSelectedLayouts.filter((key) => key !== layoutKey)
      );
    }
  };

  const ReloadWebsite = () => {
    window.location.reload();
  };

  return (
    <div>
      {/* <div className="dashboard-header">
        <SearchBar />
        <div>
          <DashBoardCalendar />
          <LayoutDropdown
            handleLayoutSelection={handleLayoutSelection}
            selectedLayouts={selectedLayouts}
            currentLayout={
              account.username === adminEmail
                ? AdminDashboardCurrentLayout
                : userDashboardCurrentLayout
            }
          />
          <CustomButton size="sm" variant="primary" onClick={ReloadWebsite}>
            <FontAwesomeIcon icon={faRotateRight} />
          </CustomButton>
        </div>
      </div> 
         <AdminDashboard
           data={adminGraphs}
           breakpoints={breakpoints}
           cols={cols}
           onBreakpointChange={onBreakpointChange}
           currentLayout={AdminDashboardCurrentLayout}
           selectedLayouts={selectedLayouts}
         />
        */}
      <UserDashboard
        data={userGraphs}
        breakpoints={breakpoints}
        cols={cols}
        onBreakpointChange={onBreakpointChange}
        currentLayout={userDashboardCurrentLayout}
        selectedLayouts={selectedLayouts}
      />
    </div>
  );
};

export default memo(Dashboard);
