import React,{useContext,memo} from "react";
import ParentContext from "../../../context/context";
import { GenAiSummary } from "../../../services/Generative AI/components";
import {
  FontAwesomeIcon,
  faFileLines,
  faFilePdf,
} from "../../../import/FontAwesomeIcons";
import { ControlPanel } from "../../../components/index";
import { Tab, Tabs } from "react-bootstrap";

const GenAiTabViewer = (props) => {
  const { file_extension, viewerComponent } = props;
  const { state, dispatch } = useContext(ParentContext);
  const {
    serviceType,
    numPages,
    pageNumber,
    scale,
    rotate,
    redactionOutputFile,
  } = state;
  return (
    <div>
      <Tabs
        defaultActiveKey="File preview"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab
          eventKey="File preview"
          title={
            <span className="tab-title">
              <FontAwesomeIcon icon={faFilePdf} />
              File preview
            </span>
          }
        >
          <div
            className="tab-component"
            style={{
              padding: "0px",
              marginTop: serviceType === "Conversational AI" ? "-5px" : "0",
            }}
          >
            <div className="outputscreen-controllpannel">
              {file_extension === "pdf" && !redactionOutputFile && (
                <ControlPanel
                  scale={scale}
                  setScale={(scale) =>
                    dispatch({ type: "SET_SCALE", payload: scale })
                  }
                  numPages={numPages}
                  pageNumber={pageNumber}
                  setPageNumber={(pageNumber) =>
                    dispatch({
                      type: "SET_PAGE_NUMBER",
                      payload: pageNumber,
                    })
                  }
                  rotate={rotate}
                  setRotate={(rotate) =>
                    dispatch({ type: "SET_ROTATE", payload: rotate })
                  }
                />
              )}
            </div>
            <div className="outputscreen-pdfviewer">{viewerComponent}</div>
          </div>
        </Tab>
        <Tab
          eventKey="Summary"
          title={
            <span className="tab-title">
              <FontAwesomeIcon icon={faFileLines} />
              Summary
            </span>
          }
        >
          <div
            className="tab-component"
            style={{
              marginTop: serviceType === "Conversational AI" ? "-5px" : "0",
            }}
          >
            <GenAiSummary />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default memo(GenAiTabViewer);
