import React, { memo } from "react";
import Chart from "react-apexcharts";
import colors from "../../InferIq-Solution/utils/graphColors";

const BillingAreaChart = ({ response, showLegend }) => {
  const state = {
    series:response?.datasets?.map((dataset) => ({
      name: dataset?.name,
      data: dataset?.data,
    })),
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      colors: [...colors],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: response?.xaxis?.title,
        categories: response?.xaxis?.categories,
        title: {
          text: response?.xaxis?.title,
        },
      },
      yaxis: {
        title: {
          text: response?.yaxis?.title,
        },
        labels: {
          formatter: (val) => {
            return val?.toFixed(3) + "$";
          },
        },
        stepSize: 1,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
        y: {
          formatter: (val) => {
            return val?.toFixed(3) + "$";
          },
        },
      },
      legend: {
        show: showLegend,
        position: "right",
      },
    },
  };

  return (
    <Chart
      options={state?.options}
      series={state?.series}
      type="area"
      height={350}
    />
  );
};

export default memo(BillingAreaChart);
