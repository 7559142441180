let colors = [
  "#845EC2",
  "#1EB945",
  "#00B1FF",
  "#00BCD4",
  "#84AE33",
  "#FFCC96",
  "#9999CC",
  "#ADC5CF",
  "#00BBD9",
  "#008C81",
  "#4FFBDF",
  "#a6e22d",
  "#009EFA",
  "#D65DB1",
  "#a99dfb",
  "#00C9A7",
  "#a8dadc",
  "#a9def9",
  "#ef476f",
  "#FF9671",
  "#FFC75F",
  "#F9F871",
  "#84a59d",
  "#76c893",
  "#2ec4b6",
  "#e27396",
  "#00a5cf",
  "#ffff3f",
  "#C34A36",
  "#ffb703",
  "#ee6c4d",
  "#adc178",
  "#9b5de5",
];

export default colors;
