import React, { memo, useMemo, useContext } from "react";
import { TopBar, Footer, Sidebar, CollapsedSideBar } from "./components/index";
import "./Layout.css";
import { useMediaQuery } from "../Hooks/index";
import ParentContext from "../context/context";
import { Container, Row, Col } from "react-bootstrap";

const Layout = (props) => {
  const { state } = useContext(ParentContext);
  const { isOpen, loading } = state;
  const { layOutAndTopBarmediaQuery } = useMediaQuery();

  const sidebarContent = useMemo(
    () =>
      isOpen ? (
        <Sidebar />
      ) : (
        <span style={{ pointerEvents: loading ? "none" : "auto" }}>
          <CollapsedSideBar />
        </span>
      ),
    [isOpen, loading]
  );
  return (
    <Container fluid>
      <main className="layout-container">
        <Row>
          <Col sm={4}>
            <aside className="layout-sidebar">{sidebarContent}</aside>
          </Col>
          <Col sm={8}>
            <section
              className="layout-mainbar"
              style={layOutAndTopBarmediaQuery}
            >
              <TopBar />
              <article className="layout-card">{props.children}</article>
              {!loading && <Footer />}
            </section>
          </Col>
        </Row>
      </main>
    </Container>
  );
};

export default memo(Layout);
