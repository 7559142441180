import "./DropDown.css";

const DropDown = (props) => {
  const { Allfiles, setSelectedOption, title } = props;
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  return (
    <>
      <select
        className="multiple-files-dropdown"
        onChange={(event) => handleOptionSelect(event.target.value)}
      >
        <option value="0">{title}</option>
        {Allfiles?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  );
};

export default DropDown;
