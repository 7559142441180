export default [
    "Names",
    "Address",
    "Date of birth",
    "GPS coordinates",
    " Driver's license",
    "Phone number",
    "Email Address",
    "ZIP Code",
    " Contact information",
    "social security number",
    "process number",
    "medical record number",
    "national clinic code",
    "license plate",
    "IP address",
    "Age",
    "Gender",
    "Ethnicity",
    "Grades",
    "salary",
    "job position",
];