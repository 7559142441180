export default[
    {
        id: Math.random(),
        label: "Corporate Email",
        type: "email",
        name: "email",
        placeholder: "Enter your Email id...",
        required: true
    },
    {
        id: Math.random(),
        label: "Password",
        type: "password",
        name: "password",
        placeholder: "Enter your Password...",
        required: true
    },
]