import React from "react";
import "./BillingInfo.css";
import {
  BillingInfoUpdateOptions,
  BillingInfoDisplay,
} from "./Components/index";
import useBillingInfo from "../Hooks/useBillingInfo";

const BillingInfo = () => {
  const {
    BillingInfoInputs,
    BillingInfoValues,
    setCosts,
    isEditing,
    setIsEditing,
  } = useBillingInfo();
  
  const handleInputChange = (heading, value) => {
    setCosts((prevCosts) => ({
      ...prevCosts,
      [heading]: value,
    }));
  };

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => setIsEditing(false);

  return (
    <div style={{ margin: "10px" }}>
      {isEditing ? (
        <BillingInfoUpdateOptions
          handleInputChange={handleInputChange}
          handleSaveClick={handleSaveClick}
          BillingInfoInputs={BillingInfoInputs}
        />
      ) : (
        <BillingInfoDisplay
          handleEditClick={handleEditClick}
          BillingInfoValues={BillingInfoValues}
        />
      )}
    </div>
  );
};

export default BillingInfo;
