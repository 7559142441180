import React,{useState} from "react";
import {
  FontAwesomeIcon,
  faClipboard,
  faCopy,
  faSortDown,
  faSortUp,
} from "../../../../import/FontAwesomeIcons";
import { CustomButton } from "../../../../components/index";
import "./GenAiAccordions.css";
import { useRenderLine } from "../../../../Hooks/index";

const GenAiAccordions = ({ displayData }) => {
  // const [accordionState, setAccordionState] = React.useState([]);
  const [accordionState, setAccordionState] = useState(
    new Array(displayData?.length)?.fill(true)
  );
  const [buttonStates, setButtonStates] = useState(
    new Array(displayData?.length)?.fill(
      <FontAwesomeIcon className="iq_svg" icon={faClipboard} />
    )
  );

  const { renderTextInNewLines } = useRenderLine();

  // const toggleAccordion = (index) => {
  //   const newState = [...accordionState];
  //   newState[index] = !newState[index];
  //   // newState.fill(false);
  //   // newState[index] = true;
  //   setAccordionState(newState);
  // };

  const toggleAccordion = (index) => {
    const newState = [...accordionState];
    newState[index] = !newState[index];
    setAccordionState(newState);
  };

  const copyToClipboard = (answer, index) => {
    navigator.clipboard.writeText(answer).then(() => {
      const newButtonStates = [...buttonStates];
      newButtonStates[index] = (
        <FontAwesomeIcon className="iq_svg" icon={faCopy} />
      );
      setButtonStates(newButtonStates);
      setTimeout(() => {
        const resetButtonStates = [...buttonStates];
        resetButtonStates[index] = (
          <FontAwesomeIcon className="iq_svg" icon={faClipboard} />
        );
        setButtonStates(resetButtonStates);
      }, 2000);
    });
  };

  return (
    <React.Fragment>
      {displayData?.map(({ id, question, answer, questions }, index) => (
        <div className="genai_accordion" key={id}>
          <div
            onClick={() => toggleAccordion(index)}
            className={`accordion_question ${
              accordionState[index] ? "toggled" : ""
            }`}
          >
            <span style={{ width: "90%" }} className="para" id="question">
              {index + 1}. {question}
            </span>
            <span style={{ width: "10%" }}>
              {accordionState[index] ? (
                <FontAwesomeIcon icon={faSortDown} />
              ) : (
                <FontAwesomeIcon icon={faSortUp} />
              )}
            </span>
          </div>
          {accordionState[index] && (
            <div className="accordion_answer">
              <span>
                {answer && (
                  <span style={{ width: "90%" }} className="para" id="answer">
                    {renderTextInNewLines(answer)}
                  </span>
                )}
              </span>
              {!questions && (
                <span style={{ width: "10%" }}>
                  <CustomButton
                    size="md"
                    variant="light"
                    onClick={() => copyToClipboard(answer, index)}
                    // className="copy_button"
                  >
                    {buttonStates[index]}
                  </CustomButton>
                </span>
              )}
            </div>
          )}
        </div>
      ))}
    </React.Fragment>
  );
};

export default GenAiAccordions;
