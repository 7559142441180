import React, { useContext, memo } from "react";
import {
  FontAwesomeIcon,
  faFileArrowDown,
  faXmark,
} from "../../../import/FontAwesomeIcons";
import { CustomButton } from "../../../components/index";
import { OverlayTrigger, Modal, Tooltip } from "react-bootstrap";
import CreHistoryPdfViewer from "./CreHistoryPdfViewer";
import ParentContext from "../../../context/context";

const CreHistoryPdfViewerModal = (props) => {
  const { dispatch } = useContext(ParentContext);
  const handleExitCondition = () => {
    props.setCreHistoryPdfViewerModalShow(false);
    dispatch({ type: "SET_RENT_ROLL_PREVIEW_FILE", payload: "" });
  };
  return (
    <Modal
      size="lg"
      show={props.creHistoryPdfViewerModalShow}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName="modal-90w"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="cre-pdf-modal-details">
            <div className="cre-pdf-modal-header">
              <span className="cre-pdf-modal-filename">{props.fileName}</span>
              <span className="cre-pdf-modal-dateAndTime">
                {props.dateAndTime}
              </span>
              <span className="cre-pdf-modal-llmType">{props.llmType}</span>
            </div>
            <div className="cre-pdf-modal-options">
              <CustomButton
                variant="primary"
                size="sm"
                onClick={props.fileDownload}
                data-testid="cre-modal-download-button"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip">Download</Tooltip>}
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </OverlayTrigger>
              </CustomButton>

              <CustomButton
                variant="primary"
                size="sm"
                onClick={handleExitCondition}
                data-testid="cre-modal-close-button"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip">View</Tooltip>}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </OverlayTrigger>
              </CustomButton>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreHistoryPdfViewer />
      </Modal.Body>
    </Modal>
  );
};

export default memo(CreHistoryPdfViewerModal);
