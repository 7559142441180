import React from "react";
import "./ReqMTableHeader.css";

const ReqMTabelHeader = () => {
  return (
    <div className="para rm-table-header ">
      <span className="para rm-table-header-sno">S No</span>
      <span className="para rm-table-header-service">Service</span>
      <span className="para rm-table-header-form-type">Form Type</span>
      <span className="para rm-table-header-file-upload-time">
        File upload Time
      </span>
      <span className="para rm-table-header-req-id">
        Parent Request Id
      </span>
      <span className="para rm-table-header-status">Status</span>
    </div>
  );
};

export default ReqMTabelHeader;
