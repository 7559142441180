const initialState = {
  redactionDoc: "",
  redactionSelectedFields: "",
  backendResponse: [],
  responseError: false,
  errorMessage: "",
  selectedValue: "",
  serviceType: "",
  loading: false,
  uploadedFiles: [],
  multipleFileCheck: false,
  selectedOption: 0,
  numPages: 1,
  pageNumber: 1,
  scale: 1.0,
  rotate: 0,
  isOpen: true,
  breadcrumbs: [],
  responseText: "",
  imageDataURL: null,
  redactionOutputFile: false,
  activeIndex: null,
  file: false,
  email: "",
  resetUploads: false,
  dataRedactionCheck: true,
  autoPiiRedactionCheck: false,
  activeTab: "redaction_settings",
  genAiTemplateRadioOptionsAndCheck: null,
  genaiNewlyAddedQuestions: [],
  genaiRedactionOutputEditing: false,
  genaiRedactionCheckfields: "",
  genaiOutput: "",
  genaiRedactionOutputEditedText: "",
  submitClicked: false,
  getLatestRequestId: "",
  currentPage: "",
  bankStatementActiveTab: 0,
  rentRollActiveTab: "questions",
  fileRemoved: true,
  selectedLlmTypeType: "",
  rentRollNewlyAddedQuestions: [],
  displayText: false,
  creResponse: [],
  dashboardResponse: [],
  creHistoryResponse: [],
  notification: [],
  rentRollPreviewFile: "",
  getRedactioOutputEventKey: "raw_text",
  templateQuestionsLength: null,
  status: 5,
  genAiHistoryResponse: [],
  formData: {
    Age: "",
    ApplicantIncome: "",
    LoanAmount: "",
    CreditScore: "",
    MonthsEmployed: "",
    NumCreditLines: "",
    InterestRate: "",
    LoanTerm: "",
    DTIRatio: "",
    Education: "",
    EmploymentType: "",
    MaritalStatus: "",
    HasMortgage: "",
    HasDependents: "",
    LoanPurpose: "",
  },
  requestMonitoringData: [],
  paActiveTab: "Fill the Form",
  requestMonitoringErrLogs: "",
  billingMetricesAllResponse: [],
  allChartsBillingDates: [new Date()],
  clientCompanyName: "",
  clientOnboardingActiveTab: "Client Onboarding",
  checkBoxServicesValues: [],
  checkBoxllmsValues: [],
  checkBoxEnvValues: [],
  processedFilesInfo: [],

  signInFormData: {
    email: "",
    password: "",
  },
  signUpFormData: {
    sname: "",
    company: "",
    email: "",
    jobTitle: "",
    password: "",
    confirmPassword: "",
  },
  forgetPasswordFormData: {
    email: "",
    verificationCode: "",
    password: "",
    confirmPassword: "",
  },
  signUpOtp: "",
  showPassword: false,
  isSignIn: true,
  isSignUp: false,
  isForgotPassword: false,
  formError: "",
  termsAndConditions: false,
  signInBackendRes: [],
  signUpBackendRes: [],
  forgetPassBackendRes: [],
  signUpVerifiBackendRes: [],

  selectedPages: [],
  getSelectedPages: [0],

  excelData: [],
  excelSheetIndex: 0,
  getUpdatePasswordStatus:""
};
export default initialState;
