import React, { useEffect, useContext, useMemo, useCallback } from "react";
import "./DragAndDrop.css";
import ParentContext from "../../context/context";
import FileUpload from "../../images/svg/FileUpload.svg";
import File from "../../images/svg/File.svg";
import { useLocation } from "react-router";
import { Container, Row, Stack } from "react-bootstrap";
import useToasts from "../../Hooks/useToasts";
import * as XLSX from "xlsx";

const DropDocFile = (props) => {
  return (
    <div onClick={() => props.on_Button_Click()}>
      <input
        type="file"
        id="file"
        onChange={props.handle_File_Event}
        multiple={props.multiple}
        ref={props.inputFile}
        accept="image/*, application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
        style={{
          display: "none",
        }}
      />
      {props.displayText === false && (
        <Stack gap={1}>
          <div
            className="drag-and-drop-img p-2"
            style={{
              marginTop: props.serviceType === "Rent Roll" ? "10px" : "0px",
            }}
          >
            <img
              src={FileUpload}
              alt="upload"
              style={{
                height: props.serviceType === "Rent Roll" ? "70px" : "",
                marginTop: props.serviceType === "Rent Roll" ? "-5px" : "0px",
              }}
              draggable={false}
            />
          </div>
          <div className="drag-and-drop-title">
            <p
              style={{
                fontSize: props.serviceType === "Rent Roll" ? "1rem" : "",
              }}
            >
              Drag & Drop
            </p>
            <span
              style={{
                fontSize: props.serviceType === "Rent Roll" ? "1rem" : "",
                textAlign: "center",
                marginTop: props.serviceType === "Rent Roll" ? "-10px" : "",
              }}
            >
              or
            </span>
            <button type="button" className="drag-and-drop-btn">
              Browse
            </button>
          </div>
        </Stack>
      )}
    </div>
  );
};

const DisplayText = (props) => {
  return (
    <div className="drag-and-drop-file-msg p-10">
      {props.displayText && (
        <div className="drag-and-drop-file-msg-img">
          <img src={File} alt="file" draggable={false} />
          <p>{props.render_File_Name()}</p>
          <button
            className="drag-and-drop-file-msg-btn"
            onClick={props.Remove_Uploaded_File}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
};

const DragAndDrop = (props) => {
  const { state, dispatch } = useContext(ParentContext);
  const { multipleFileCheck, serviceType, displayText } = state;

  const location = useLocation();
  const { Toast } = useToasts();

  const handle_File_Event = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const validFiles = [];
    let fileExtension;
    const acceptedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "pdf",
      "xlsx",
      "xls",
      "csv",
    ];

    const countExtensions = (fileName, allowedExtensions) => {
      const extensionRegex = new RegExp(
        `\\.(?:(${allowedExtensions.join("|")}))`,
        "gi"
      );
      const matches = fileName.match(extensionRegex) || [];
      return matches.length;
    };

    chosenFiles?.forEach((file) => {
      const fileNameParts = file.name.split(".");
      const extensionCount = countExtensions(file.name, acceptedExtensions);
      fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (extensionCount !== 1) {
        Toast(
          "error",
          `File "${file.name}" has double extensions and is not accepted.`
        );
      } else if (!acceptedExtensions.includes(fileExtension)) {
        Toast(
          "error",
          `File "${file.name}" has an invalid extension and is not supported.`
        );
      } else {
        validFiles.push(file);
      }
    });

    if (fileExtension === "xls" || "xlsx" || "csv") {
      const reader = new FileReader();

      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const sheetsData = [];

        wb.SheetNames.forEach((sheetName) => {
          const ws = wb.Sheets[sheetName];
          const excelData = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
          });

          const formattedData = excelData.map((row) =>
            row.map((cell) => {
              const cellObj = { value: cell ? cell.toString() : "" };
              return cellObj;
            })
          );

          sheetsData.push(formattedData);
        });

        dispatch({ type: "SET_EXCEL_DATA", payload: sheetsData });
      };

      reader.readAsBinaryString(validFiles[0]);
    }

    handle_Upload_Files(validFiles);
    props.setFile(validFiles[0]);
    e.target.value = null;
  };

  const handle_Upload_Files = (files) => {
    const uploaded = [];
    if (multipleFileCheck) {
      uploaded.push(...files);
    } else {
      if (files.length > 0) {
        uploaded.push(files[0]);
      }
    }
    if (uploaded.length === 1) {
      dispatch({ type: "SET_DISPLAY_TEXT", payload: uploaded[0].name });
      props.setFile(uploaded[0]);
    } else {
      dispatch({
        type: "SET_DISPLAY_TEXT",
        payload: uploaded.length + " Files Uploaded",
      });
    }
    props.setFile(uploaded.length > 0 ? uploaded[0] : null);
    dispatch({ type: "SET_UPLOADED_FILES", payload: uploaded });
    dispatch({ type: "SET_FILE_REMOVED", payload: uploaded.length });
  };

  const inputFile = React.useRef(null);

  const on_Button_Click = (e) => {
    inputFile.current.click();
  };

  const dropzoneRef = React.useRef(null);

  const handle_Drop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.prototype.slice.call(e.dataTransfer.files);
    handle_Upload_Files(droppedFiles);
    props.setFile(droppedFiles[0]);
  };

  const handle_Drag_Over = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    dispatch({ type: "SET_UPLOADED_FILES", payload: [] });
  }, [location.pathname, dispatch]);

  const render_File_Name = () => {
    const lines = [];
    let startIndex = 0;
    if (typeof displayText === "string") {
      while (startIndex < displayText.length) {
        const endIndex = startIndex + 20;
        const line = displayText.substring(startIndex, endIndex);
        lines.push(
          <div className="para" key={startIndex}>
            {line}
          </div>
        );
        startIndex = endIndex;
      }
    }
    return lines;
  };

  const Remove_Uploaded_File = () => {
    dispatch({ type: "SET_FILE_REMOVED", payload: false });
    dispatch({ type: "SET_DISPLAY_TEXT", payload: false });
    dispatch({ type: "SET_UPLOADED_FILES", payload: [] });
  };

  return (
    <Container>
      <div
        className="drag-and-drop"
        id="dropzone"
        onDrop={handle_Drop}
        onDragOver={handle_Drag_Over}
        ref={dropzoneRef}
        style={props.style}
      >
        <Row xs={12}>
          <DisplayText
            displayText={displayText}
            render_File_Name={render_File_Name}
            Remove_Uploaded_File={Remove_Uploaded_File}
          />
        </Row>
        <Row xs={12}>
          <DropDocFile
            serviceType={serviceType}
            displayText={displayText}
            handle_File_Event={handle_File_Event}
            inputFile={inputFile}
            on_Button_Click={on_Button_Click}
            multiple={props.multiple}
          />
        </Row>
      </div>
    </Container>
  );
};

export default DragAndDrop;
