import { useContext } from "react";
import axios from "axios";
import ParentContext from "../context/context";
import env from "../env/env.js";
import CryptoJS from "crypto-js";

const useUpdatePassword = () => {
  const { state, dispatch } = useContext(ParentContext);

  let updatedPassData = {
    email: sessionStorage.getItem("frgtEmail"),
    code: state.forgetPasswordFormData.verificationCode,
    new_password: state.forgetPasswordFormData.password,
    confirmPassword: state.forgetPasswordFormData.confirmPassword,
  };

  const encryptData = (data) => {
    let encKeys = JSON?.parse(sessionStorage?.getItem("qes")) || [];
    const encryptionKey = encKeys?.eyk;
    const initVector = encKeys?.iv;
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(initVector);

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.CFB,
      iv: iv,
      padding: CryptoJS.pad.NoPadding,
    });

    return encrypted?.toString();
  };

  const updatePassword = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    await axios
      .post(
        `${env.API_GATEWAY}/authenticate`,
        {
          callType: "confirm_forgot_password",
          formData: encryptData(updatedPassData),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_UPDATE_PASSWORD_STAT",
          payload: response.data,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
      });
  };

  return { updatePassword };
};

export default useUpdatePassword;
