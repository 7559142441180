import React, { useContext } from "react";
import { CustomButton } from "../../../components";
import ParentContext from "../../../context/context";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { inputList, dropDownList } from "../utils/InputData";

const PaForm = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { formData } = state;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "SET_FORM_DATA",
      payload: {
        ...formData,
        [name]: value,
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {inputList.map((item) => {
          const {
            id,
            toolTip,
            label,
            type,
            name,
            placeholder,
            title,
            pattern,
            required,
            min,
            minLength,
            maxLength,
            max,
          } = item;
          return (
            <div key={id} className="pa-form-input-cont">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="para">{toolTip}</Tooltip>}
              >
                <label for="input" className="pa-form-input-label">
                  {label}
                </label>
              </OverlayTrigger>
              <input
                type={type}
                name={name}
                value={formData.name}
                placeholder={placeholder}
                onChange={handleInputChange}
                className="pa-form-input"
                title={title}
                pattern={pattern}
                required={required}
                min={min}
                minLength={minLength}
                maxLength={maxLength}
                max={max}
              />
            </div>
          );
        })}
        {dropDownList.map((item) => {
          const { id, toolTip, label, name, required, value } = item;
          return (
            <div key={id} className="pa-form-input-cont">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="para">{toolTip}</Tooltip>}
              >
                <label for="input" className="pa-form-input-label">
                  {label}
                </label>
              </OverlayTrigger>
              <select
                className="pa-form-input itemselect"
                name={name}
                value={formData?.name}
                onChange={handleInputChange}
                required={required}
              >
                {value.map((item) => {
                  const { id, selected, hidden, disabled, label,value } = item;
                  return (
                    <option
                      key={id}
                      value={value}
                      selected={selected}
                      hidden={hidden}
                      disabled={disabled}
                    >
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "20px 10px",
        }}
      >
        <CustomButton variant="primary" size="md" type="submit">
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default PaForm;
