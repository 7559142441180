import React from "react";
import Input from "../../../components/Input/Input";
import CustomButton from "../../../components/Button/CustomButton";
import gerrenTick from "../../../images/greenTick.svg";
import Timer from "./Timer";

export const SiginUpVerificationForm = (props) => {
  return (
    <form onSubmit={props.handleSignUpVerifiCode}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <img src={gerrenTick} alt="tickMark" width="80px" height="80px" />
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <h6>Registration Successful.</h6>
          <span className="para">
            Please check your corporate email for the verification code.
          </span>
        </div>
      </div>
      <div>
        <Input
          label="Verification"
          type="text"
          name="signUpOtp"
          value={props.signUpOtp}
          placeholder="Enter your verification code..."
          handleChange={props.handlesignUpOtp}
          required={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          marginTop: "10px",
        }}
      >
        <div>
          {props.disabled && <Timer initialMinute={2} initialSeconds={0} />}
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          <CustomButton variant="primary" size="md" type="submit">
            Submit
          </CustomButton>
          <CustomButton
            variant="secondary"
            size="md"
            onClick={props.handleResendSignUpVerifiCode}
            disabled={props.disabled}
          >
            Re-send
          </CustomButton>
        </div>
      </div>
      {props.formError && (
        <p className="signin_form_error">{props.formError}</p>
      )}
    </form>
  );
};
