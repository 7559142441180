import { useContext } from "react";
import env from "../../InferIq-Solution/env/env";
import ParentContext from "../../InferIq-Solution/context/context";
import axios from "axios";
import sign from "jwt-encode";
import {
  secretKeyJwt,
  token,
  userDetails,
} from "../../InferIq-Solution/Auth/authConfig";

const useProcessedFileInfo = () => {
  const { state, dispatch } = useContext(ParentContext);

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const getProcessedFilesInfo = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    dispatch({ type: "SET_RES_ERROR", payload: false });
    await axios
      .post(
        `${env.API_GATEWAY + "/requestmonitor"}`,
        {
          callType: "documentPages",
          userId: userDetails?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_PROCESSED_FILES_INFO",
          payload: response.data,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
        dispatch({ type: "SET_RES_ERROR", payload: true });
      });
  };
  const getDocumentFromS3PregignedUrl = async (presignedUrl) => {
    const response = await axios.get(presignedUrl, {
      responseType: "blob",
    });
    return response;
  };

  const downloadFile = async (presignedUrl, filename) => {
    try {
      const response = await getDocumentFromS3PregignedUrl(presignedUrl);
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${filename}.csv`;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const getProcessedFilesInfoDownload = async (fromDate, toDate) => {
    dispatch({ type: "SET_RES_ERROR", payload: false });
    await axios
      .post(
        `${env.API_GATEWAY + "/requestmonitor"}`,
        {
          callType: "documentPages",
          download:true,
          from_date: fromDate === "undefined-NaN-undefined" ? "" : fromDate,
          to_date: toDate === "undefined-NaN-undefined" ? "" : toDate,
          userId: userDetails?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
       downloadFile(response.data.presignedUrl,"filename");
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
        dispatch({ type: "SET_RES_ERROR", payload: true });
      });
  };

  return { getProcessedFilesInfo, getProcessedFilesInfoDownload };
};

export default useProcessedFileInfo;
