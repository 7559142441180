let FaqsList = [
    {
        id: 1,
        question: "Does InferIQ offer integration with other third-party tools or platforms?",
        content:
            "Yes, InferIQ supports integrations with popular third-party tools and platforms. We have integrations with tools like CRM systems, email marketing platforms, and more. Our support team can provide information on specific integrations available and guide you through the setup process.",
    },
    {
        id: 2,
        question: "How often is InferIQ updated with new features and enhancements?",
        content:
            "We are committed to continually improving InferIQ. We release regular updates and enhancements based on customer feedback and industry trends. Updates typically include new features, performance improvements, and bug fixes to ensure you have the best user experience.",
    },
    {
        id: 3,
        question: "Can multiple users within my organization access InferIQ?",
        content:
            "Yes, InferIQ supports multiple user accounts. Depending on your subscription plan, you can invite and manage multiple users within your organization, each with its own access privileges and roles.",
    },
    {
        id: 4,
        question: "Is there a limit to the number of users or data storage capacity in InferIQ?",
        content:
            "The user and data storage capacity in InferIQ may vary depending on your chosen subscription plan. We offer different plans with varying limits, and you can select the one that suits your organization's needs.",
    },
    {
        id: 5,
        question: "How secure is InferIQ in terms of data protection and privacy?",
        content:
            "Data protection and privacy are top priorities for InferIQ. We employ industry-standard security measures such as encryption, access controls, and regular data backups to ensure the confidentiality and integrity of your data. Additionally, we adhere to relevant data protection regulations and follow best practices for secure data handling.",
    },
    {
        id: 6,
        question: "What level of customer support is provided for technical issues or inquiries related to InferIQ?",
        content: "We provide customer support to assist you with any technical issues or inquiries related to InferIQ. Our support team is available via email, live chat, or phone during business hours to address your concerns and provide timely assistance."
    }, {
        id: 7,
        question: "Is there any training or onboarding assistance available for using InferIQ?",
        content: "Yes, we offer training and onboarding assistance for using InferIQ. Depending on your subscription plan, we provide resources such as documentation, tutorials, and personalized onboarding sessions to help you get started and make the most of the software."
    }, {
        id: 8,
        question: "Can I export or back up my data from InferIQ?",
        content: "Yes, you can export or back up your data from InferIQ. We provide data export functionalities, allowing you to download your data in standard formats for backup or migration purposes."
    }, {
        id: 9,
        question: "Does InferIQ comply with industry-specific regulations or standards?",
        content: "Yes, InferIQ is designed to comply with industry-specific regulations and standards. We take data privacy and compliance seriously, and our software is built to meet the requirements of relevant regulations such as GDPR, HIPAA, and more."
    }, {
        id: 10,
        question: "Are there any additional costs or fees associated with using InferIQ?",
        content: "The pricing for InferIQ typically includes the core features and functionalities based on your chosen subscription plan. However, additional costs or fees may apply for add-on features, integrations, or customizations beyond the scope of your subscription plan. Our sales team can provide detailed information on any additional costs associated with specific requirements."
    }, {
        id: 11,
        question: "Can I customize the user interface or branding of InferIQ for a consistent look?",
        content: "Depending on your subscription plan, you may have options to customize the user interface or branding of InferIQ. This allows you to align the software with your organization's brand identity and create a consistent user experience for your team."
    },
    {
        id: 12,
        question: "How can I track or monitor usage and analytics within InferIQ?",

        content: " InferIQ provides built-in analytics and reporting features to help you track and monitor usage. You can access dashboards, generate reports, and gain insights into user activities, task progress, and other relevant metrics to make informed decisions."
    }, {
        id: 13,
        question: "Can I scale up or down my subscription plan for InferIQ based on changing business needs?",

        content: "Yes, we understand that your business needs may evolve over time. InferIQ typically offers flexibility to scale up or down your subscription plan, allowing you to adjust your resources and features based on changing requirements. Our sales team can assist you with upgrading or downgrading your subscription as needed."
    }, {
        id: 14,
        question: "What happens if there is a service outage or downtime with InferIQ?",

        content: "We strive to maintain high availability and minimize service disruptions. In the event of a service outage or downtime, our technical team works diligently to resolve the issue and restore normal operations as quickly as possible. We provide notifications and updates to keep you informed during such situations."
    }, {
        id: 15,
        question: "Are there any specific system requirements or compatible browsers for using InferIQ?",

        content: "InferIQ is designed to be compatible with modern web browsers and devices. While specific system requirements may vary, typically, InferIQ can be accessed through popular web browsers such as Chrome, Firefox, Safari, or Edge. We recommend using the latest browser versions for the best experience."
    }, {
        id: 16,
        question: "Can I request new features or provide feedback to the InferIQ provider?",

        content: "Yes, we value feedback from our users. We encourage you to provide feedback, suggestions, or feature requests to help us improve InferIQ. You can usually submit your feedback through our support channels, and our product team takes customer input into consideration for future updates and enhancements."
    }, {
        id: 17,
        question: "How does the pricing for InferIQ work?",

        content: " InferIQ offers tiered pricing plans based on the number of users and features required. You can visit our website or contact our sales team for detailed pricing information."
    }, {
        id: 18,
        question: " Is there a free trial available for InferIQ?",

        content: " Yes, we offer a free trial of InferIQ. You can sign up on our website to access the trial and experience the features and functionality firsthand."
    }, {
        id: 19,
        question: "Can I customize InferIQ to fit my specific business needs?",

        content: "Yes, InferIQ is designed to be flexible and customizable. Depending on your subscription plan, you can often customize workflows, fields, and configurations to align InferIQ with your specific business requirements."
    }, {
        id: 20,
        question: "What happens to my data if I decide to cancel my subscription to InferIQ?",

        content: " If you decide to cancel your subscription, you will have the option to export your data from InferIQ. We provide data export functionalities to ensure that you can retain your important information even after canceling your subscription."
    }
];
export default FaqsList;