import "./TopBar.css";
import { FontAwesomeIcon, faXmark } from "../../../import/FontAwesomeIcons";

const Notification = (props) => {
  const { notificationData, notificationOff, handleClose, modalRef } = props;

  const updatedResponses = notificationData?.flat()?.filter((response, index, self) =>
    index === self.findIndex((item) => (
      item.time_stamp === response.time_stamp
    ))
  );
  
  return (
    <div className="notification" ref={modalRef}>
      <div className="notification-header">
        <span className="para">Notification</span>
        <span onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} />
        </span>
      </div>
      <div className="notification-body">
        {notificationData ? (
          updatedResponses?.map((innerArray, outerIndex) => (
            <div className="notification-info" key={outerIndex}>
                <span className="notification-card" >
                  <span className="para">{innerArray?.file_name}</span>
                  <span className="para">{innerArray?.time_stamp}</span>
                </span>
            </div>
          ))
        ) : (
          <div className="notification-off">
            <img
              src={notificationOff}
              alt="notificationOff"
              width="90px"
              height="90px"
            />
            <p>No Notifications Yet.</p>
            <span className="para">
              You nave no notifications right now. <br />
              Come back later.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;