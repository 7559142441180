import React, { memo } from "react";
import Chart from "react-apexcharts";

const BillingHeatMap = ({ response }) => {
  const state = {
    series: response?.datasets,
    options: {
      chart: {
        height: 350,
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#008FFB"],
      xaxis: {
        categories: response?.xaxis?.categories,
        title: {
          text: response?.xaxis?.title,
        },
        labels: {
          formatter: (val) => {
            return val + "w";
          },
        },
      },
      yaxis: {
        title: {
          text: response?.yaxis?.title,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return val?.toFixed(3) + "$";
          },
        },
      },
    },
  };

  return (
    <Chart
      options={state?.options}
      series={state?.series}
      type="heatmap"
      height={350}
    />
  );
};

export default memo(BillingHeatMap);
