import { useContext } from "react";
import axios from "axios";
import ParentContext from "../context/context";
import { encryptData } from "../utils/crypto";
import { useLocation, useNavigate } from "react-router-dom";
import env from "../env/env.js";
import { generateSecretString } from "../utils/generateSecretString.js";
import { sha256 } from "js-sha256";

const headers = {
  "Content-Type": "application/json",
};

const useSignInApi = () => {
  const { state, dispatch } = useContext(ParentContext);
  let codeVerifier = generateSecretString(16);
  let navigate = useNavigate();
  let location = useLocation();
  let keys;
  
  const getSignInData = async () => {
    dispatch({ type: "SET_LOADING", payload: true });

    await axios
      .post(
        `${env.API_GATEWAY}/authenticate`,
        {
          authType: "sign_in",
          callType: "codeChallenge",
          codeChallenge: sha256(codeVerifier),
          email: state.signInFormData.email,
          environment: "sandbox",
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data) {
          const encryptionKey = response.data.key;
          const initVector = response.data.iv;
          const userId = response.data.userId;
          const jwtKey = response.data.jwtKey;
          const hashkey = response.data.hashkey;
          keys = {
            eyk: encryptionKey,
            iv: initVector,
            jk: jwtKey,
            hk: hashkey,
          };
          axios
            .post(
              `${env.API_GATEWAY}/authenticate`,
              {
                authType: "sign_in",
                callType: "codeVerifier",
                formData: encryptData(
                  state.signInFormData.password,
                  encryptionKey,
                  initVector
                ),
                userId: userId,
                email: state.signInFormData.email,
                codeVerifier: codeVerifier,
                environment: "sandbox",
                hashkey: hashkey,
              },
              {
                headers: headers,
              }
            )
            .then((response) => {
              if (response.data) {
                let completeResponse = [keys, response.data];
                sessionStorage.setItem("usd", JSON.stringify(completeResponse));
                navigate("/", { state: { from: location } });
                window.location.reload();
                return;
              }
              dispatch({ type: "SET_LOADING", payload: false });
            })
            .catch((error) => {
              dispatch({ type: "SET_ERR_MSG", payload: error.message });
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { getSignInData };
};

export default useSignInApi;
