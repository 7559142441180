import React, { useContext, useEffect, memo } from "react";
import ParentContext from "../../context/context";
import { FileUploadForm, InferSideImage } from "../../components/index";
import { userPermission } from "../../Auth/authConfig";
// import { userPermissionLocal } from "../../utils/userPermission";
import { FinancialStatementImg } from "../../images/sideContent";

const FinStatExtraction = () => {
  const { dispatch } = useContext(ParentContext);

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "FinStat Extraction" });
  }, [dispatch]);

  return (
    <FileUploadForm
      RadioOptions={userPermission?.map(
        (item) => item.label === "FinStat Extraction" && item.RadioOptions
      )}
      sideContent={
        <InferSideImage
          image={FinancialStatementImg}
          title="Financial Statement Extraction"
        >
          Financial statements are a set of documents that show your company's
          financial status at a specific point in time. They include key data on
          what your company owns and owes and how much money it has made and
          spent. Balance sheet: The balance sheet provides an overview ot
          assets, liabilities, and shareholders' equity as a snapshot in time.
          Income Statement The income statement primarily focuses on a company's
          revenues and expenses during a particular period. Once expenses are
          subtracted from revenues, the statement produces a company's proflt
          figure called net income.
        </InferSideImage>
      }
      ButtonText="Next"
    />
  );
};

export default memo(FinStatExtraction);
