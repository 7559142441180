import React from "react";

const Table = (props) => {
  const { kvpTdData, tableTrData, tableThData } = props;

  return (
    <main data-testid="table_wrapper" className="table_container">
      {kvpTdData() ? (
        <table data-testid="table" className="table">
          <tbody className="tbody">{kvpTdData()}</tbody>
        </table>
      ) : (
        <></>
      )}
      {tableTrData?.length >= 1 ? (
        <table id="table" className="table">
          <thead className="thead">
            <tr className="tr">
              {tableThData?.map((heading, index) => (
                <th className="th" key={index}>
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tbody">
            {tableTrData?.[0]?.map((value, index1) => (
              <tr className="tr" key={index1}>
                {tableTrData?.map((col, index) => (
                  <td className="td" key={index}>
                    {col[index1]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </main>
  );
};

export default Table;
