export default [
    {
        id: 1,
        question: "What is the total monthly and annual rental income for the property ?",
    },
    {
        id: 2,
        question: "What is the occupancy rate of the property ?",
    },
    {
        id: 3,
        question: "What is the average lease term for tenants ?",
    },
    {
        id: 4,
        question: "What is the ratio of residential to commercial tenants ?",
    },
    {
        id: 5,
        question: "Have there been significant fluctuations in the rent collection in recent years.",
    },
]
