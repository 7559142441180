import { toast } from "react-toastify";

const useToasts = () => {
  
  const Props = {
    position: "top-center",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  const Toast = (type, message, autoClose = 4000) => {
    switch (type) {
      case "success":
        return toast.success(<span className="para">{message}</span>, Props, autoClose);
      case "info":
        return toast.info(<span className="para">{message}</span>, Props, autoClose);
      case "warn":
        return toast.warn(<span className="para">{message}</span>, Props, autoClose);
      case "error":
        return toast.error(<span className="para">{message}</span>, Props, autoClose);
      default:
        return toast(<span className="para">{message}</span>, Props, autoClose);
    }
  };

  return { Toast };
};

export default useToasts;
