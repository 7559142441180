import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge } from "react-bootstrap";

const CompleteInfoTable = ({
  tableHeader,
  tableFilteredData,
  handleCopyButtonClick,
  setButtonState,
  buttonState,
}) => {
  return (
    <table className="table">
      <thead className="thead">
        <tr className="tr">
          {tableHeader?.map((headerItem, index) => (
            <th className="th" key={index}>
              {headerItem}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="tbody">
        {tableFilteredData?.map((item, index) => (
          <tr className="tr" key={item.request_id}>
            <td className="td">{index + 1}</td>
            <td className="td">
              {item.filename === "" ? "Not Available" : item.filename}
            </td>
            <td className="td">
              {item.service === "" ? "Not Available" : item.service}
            </td>
            <td className="td">
              {item.form_type === "" ? "Not Available" : item.form_type}
            </td>
            <td
              className="td"
              onClick={(e) => {
                e.stopPropagation();
                handleCopyButtonClick(item.request_id);
              }}
              onMouseOver={() => {
                setButtonState(<FontAwesomeIcon icon={faClipboard} />);
              }}
              onMouseOut={() => {
                setButtonState(
                  <FontAwesomeIcon
                    icon={faClipboard}
                    style={{ visibility: "hidden" }}
                  />
                );
              }}
            >
              {item.request_id}
              {item.request_id && (
                <span
                  id="copyButton_childId"
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                >
                  {buttonState}
                </span>
              )}
            </td>
            <td className="td">
              <Badge pill bg="primary">
                {item.page_count}
              </Badge>
            </td>
            <td className="td">{item.upload_time}</td>
            <td className="td">{item.number_questions}</td>
            <td className="td">
              <Badge pill bg={item.status ? "success" : "danger"}>
                {item.status ? "success" : "failed"}
              </Badge>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CompleteInfoTable;
