const CreHistoryHeader = () => {
  return (
    <div className="cre-history-table-header">
      <span className="cre-history-table-header-sno">S No</span>
      <span className="cre-history-table-header-filename">File Name</span>
      <span className="cre-history-table-header-dateAndTime">Date & Time</span>
      <span className="cre-history-table-header-llm_type">Llm Type</span>
      <span className="cre-history-table-header-status">Status</span>
      <span className="cre-history-table-header-download">Download</span>
      <span className="cre-history-table-header-preview">Preview</span>
    </div>
  );
};

export default CreHistoryHeader;
