import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import useProcessedFileInfo from "../Hooks/useProcessedFileInfo";
import ParentContext from "../../InferIq-Solution/context/context";
import { Tab, Tabs } from "react-bootstrap";
import { CustomButton } from "../../InferIq-Solution/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faCopy,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../InferIq-Service-Dashboard/Spinner/Spinner";
import Card from "./Card";
import CompleteInfoTable from "./CompleteInfoTable";
import ServiceWiseInfoTable from "./ServiceWiseInfoTable";
import RangeDatePicker from "./RangeDatePicker/RangeDatePicker";
import { useToasts } from "../../InferIq-Solution/Hooks";
import { getDaysBetweenDates, ModifiDate } from "../utils/ModifiDate";

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func(...args), delay);
  };
};

const ProcessedFilesInfo = () => {
  const [buttonState, setButtonState] = useState(
    <FontAwesomeIcon icon={faClipboard} style={{ visibility: "hidden" }} />
  );
  const { state, dispatch } = useContext(ParentContext);
  const { processedFilesInfo, loading, allChartsBillingDates } = state;
  const { getProcessedFilesInfo, getProcessedFilesInfoDownload } =
    useProcessedFileInfo();
  const [filterText, setFilterText] = useState("");

  const { Toast } = useToasts();
  useEffect(() => {
    getProcessedFilesInfo();
  }, []);

  const handleCopyButtonClick = (reqId) => {
    navigator.clipboard.writeText(reqId).then(() => {
      setButtonState(<FontAwesomeIcon icon={faCopy} />);
      setTimeout(() => {
        setButtonState(
          <FontAwesomeIcon
            icon={faClipboard}
            style={{ visibility: "hidden" }}
          />
        );
      }, 2000);
    });
  };

  const debouncedSetFilterText = useCallback(
    debounce((text) => setFilterText(text), 300),
    []
  );

  const handleFilterTextChange = (event) => {
    debouncedSetFilterText(event.target.value);
  };

  const filteredData = useMemo(() => {
    if (!filterText) return processedFilesInfo?.completeData?.tableData || [];
    return processedFilesInfo?.completeData?.tableData?.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(filterText.toLowerCase())
      )
    );
  }, [filterText, processedFilesInfo]);

  const groupedData = useMemo(() => {
    return processedFilesInfo?.servicesData?.serviceBodyData?.reduce(
      (acc, item) => {
        if (!acc[item.service]) {
          acc[item.service] = [];
        }
        acc[item.service].push(item);
        return acc;
      },
      {}
    );
  }, [processedFilesInfo]);

  const CumulativeData = useMemo(
    () => [
      {
        text: "Average Pages Processed Per Day",
        value: processedFilesInfo?.cardsData?.averagePagesProcessedPerDay,
      },
      {
        text: "Total Success Requests",
        value: processedFilesInfo?.cardsData?.totalSuccessRequests,
      },
      {
        text: "Total Success FullPages",
        value: processedFilesInfo?.cardsData?.totalSuccessfulPages,
      },
      {
        text: "Total Failed Requests",
        value: processedFilesInfo?.cardsData?.totalFailedRequests,
      },
      {
        text: "Total Failed Pages",
        value: processedFilesInfo?.cardsData?.totalFailedPages,
      },
      {
        text: "No of Documents Processed Per Day",
        value: processedFilesInfo?.cardsData?.averageDocumentsProcessedPerDay,
      },
    ],
    [processedFilesInfo]
  );

  const fromDate = ModifiDate(
    allChartsBillingDates?.length > 1
      ? allChartsBillingDates?.[0]?.format()
      : ""
  );
  const toDate = ModifiDate(
    allChartsBillingDates?.length > 1
      ? allChartsBillingDates?.[1]?.format()
      : ""
  );
  const daysBetween = getDaysBetweenDates(fromDate, toDate);

  const handleAllGraphsDateChange = (dateObjects) => {
    dispatch({ type: "SET_ALL_CHARTS_BILLING_DATES", payload: dateObjects });
  };

  const handleDownload = () => {
    if (fromDate && toDate === "undefined-NaN-undefined") {
      Toast("info", "Please select a date range before downloading");
    } else {
      if (daysBetween >= 92) {
        Toast("info", "Please select a date range <92 days");
      } else {
        getProcessedFilesInfoDownload(fromDate, toDate);
      }
    }
  };

  return (
    <div className="RequestMonitoring-container">
      <div
        style={{
          border: "1px solid transparent",
          borderRadius: "5px",
          marginBottom: "8px",
          boxShadow: "rgba(0, 0, 0, .24) 0 0 2px",
          width: "95%",
          height: "auto",
          padding: "10px",
        }}
      >
        <div style={{ fontSize: "18px", fontWeight: "500" }}>
          Cumulative Data
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            alignContent: "stretch",
            flexWrap: "wrap",
          }}
        >
          {loading ? (
            <div className="rm-fallback-ui">
              <div>
                <Spinner />
              </div>
              <span className="para">Loading Data, Please Wait...</span>
            </div>
          ) : (
            <>
              {CumulativeData.map((item, index) => (
                <Card text={item.text} key={index} value={item.value} />
              ))}
            </>
          )}
        </div>
      </div>
      <div
        className="RequestMonitoring-card-container"
        style={{ padding: "10px" }}
      >
        <Tabs
          defaultActiveKey="completeInfo"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab eventKey="completeInfo" title="Complete Info">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <CustomButton
                variant="light"
                size="sm"
                onClick={() => getProcessedFilesInfo()}
              >
                <FontAwesomeIcon icon={faRotateRight} />
              </CustomButton>
              <input
                type="search"
                placeholder="search..."
                onChange={handleFilterTextChange}
                style={{
                  width: "350px",
                  height: "35px",
                  marginLeft: "10px",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  marginTop: "5px",
                }}
              />
              <RangeDatePicker
                billingDates={allChartsBillingDates}
                handleDateChange={handleAllGraphsDateChange}
                dateFormat="YYYY MMM DD"
                minDate="2020 nov 10"
                maxDate={new Date()}
                handleSubmit={handleDownload}
              />
            </div>
            <hr />
            {loading ? (
              <div className="rm-fallback-ui">
                <div>
                  <Spinner />
                </div>
                <span className="para">Loading Data, Please Wait...</span>
              </div>
            ) : (
              <CompleteInfoTable
                tableHeader={processedFilesInfo?.completeData?.tableHeader}
                tableFilteredData={filteredData}
                handleCopyButtonClick={handleCopyButtonClick}
                setButtonState={setButtonState}
                buttonState={buttonState}
              />
            )}
          </Tab>
          <Tab eventKey="serviceWiseInfo" title="Service Wise Info">
            {loading ? (
              <div className="rm-fallback-ui">
                <div>
                  <Spinner />
                </div>
                <span className="para">Loading Data, Please Wait...</span>
              </div>
            ) : (
              <ServiceWiseInfoTable
                groupedData={groupedData}
                tableHeader={
                  processedFilesInfo?.servicesData?.serviceDataHeader
                }
              />
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ProcessedFilesInfo;
