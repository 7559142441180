import React, { useContext } from "react";
import jszip from "jszip";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import ParentContext from "../context/context";
import { useToasts } from "../Hooks/index";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

const DownloadComponent = () => {
  const { state } = useContext(ParentContext);
  const { backendResponse, serviceType } = state;

  const { Toast } = useToasts();

  const handleDownload = async () => {
    const zip = new jszip();
    Toast("success", "Please wait. Files are downloading... ");
    if (serviceType !== "Bank Statement") {
      for (const pageIndex in backendResponse?.Data) {
        const page = backendResponse?.Data?.[pageIndex];

        const pageFolder = zip.folder(`${pageIndex}`);

        for (const tableIndex in page) {
          const table = page[tableIndex];
          const transposedData = transposeData(table);
          function transposeData(data) {
            return data?.[0]?.Value?.map((_, colIndex) =>
              data?.map((row) => row?.Value[colIndex])
            );
          }
          //? Convert CSV content to XLSX
          const ws = XLSX.utils.json_to_sheet(transposedData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const xlsxBlob = new Blob(
            [s2ab(XLSX.write(wb, { bookType: "xlsx", type: "binary" }))],
            {
              type: "application/octet-stream",
            }
          );
          pageFolder.file(`${tableIndex}.xlsx`, xlsxBlob);
        }
      }
    }

    // BANK STATEMENT DOWNLOAD
    if (serviceType === "Bank Statement") {
      for (const account in backendResponse?.data[0]) {
        const page = backendResponse?.data?.[0][account];

        const pageFolder = zip.folder(`${account}`);

        const ws = XLSX.utils.json_to_sheet(page);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const xlsxBlob = new Blob(
          [s2ab(XLSX.write(wb, { bookType: "xlsx", type: "binary" }))],
          {
            type: "application/octet-stream",
          }
        );
        pageFolder.file(`${account}.xlsx`, xlsxBlob);
      }
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${serviceType}.zip`);
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  return (
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Download</Tooltip>}
      >
        <FontAwesomeIcon icon={faFileArrowDown} onClick={handleDownload} />
      </OverlayTrigger>
  );
};

export default DownloadComponent;
