import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children, isAuthenticated }) => {
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/", { state: { from: location } });
    }
  }, [isAuthenticated, navigate, location]);

  return isAuthenticated ? children : null;
};

export default PrivateRoute;
