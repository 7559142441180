import { useContext, useEffect, useRef, useState } from "react";
import ParentContext from "../context/context";
import events from "../utils/windowEvents";
import { useNavigate } from "react-router";
import { useToasts } from "../Hooks";

const AutoAppLogout = ({ children }) => {
  const { state, setLoggedIn } = useContext(ParentContext);
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const { Toast } = useToasts();
  const [warnNotificationShown, setWarnNotificationShown] = useState(false);
  const [errorNotificationShown, setErrorNotificationShown] = useState(false);

  let expiresIn;
  const getExpirationTime = () => {
    const storedExpirationTime = sessionStorage?.getItem("expirationTime");
    if (storedExpirationTime) {
      return parseInt(storedExpirationTime, 10);
    }

    const userData = JSON?.parse(sessionStorage?.getItem("usd")) || [];
    let parsedUserData = userData.flat();
    expiresIn = parsedUserData[1]?.AuthenticationResult?.ExpiresIn || 0;
    const newExpirationTime = Date.now() + expiresIn * 1000;
    sessionStorage?.setItem("expirationTime", newExpirationTime.toString());
    return newExpirationTime;
  };

  const logout = () => {
    sessionStorage?.removeItem("usd");
    sessionStorage?.removeItem("expirationTime");
    setLoggedIn(false);
    state.signInBackendRes = [];
    navigate("/");
    window.location.reload();
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  let logoutTimer = formatTime(timeLeft);
  // process.env.NODE_ENV !== "production" && console.log(logoutTimer);

  useEffect(() => {
    const startTimer = () => {
      const expirationTime = getExpirationTime();
      const currentTime = Date.now();
      const remainingTime = Math.max(
        0,
        Math.floor((expirationTime - currentTime) / 1000)
      );

      setTimeLeft(remainingTime);
      setWarnNotificationShown(false);
      setErrorNotificationShown(false);

      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            logout();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    };

    const resetTimer = () => {
      sessionStorage.removeItem("expirationTime");
      startTimer();
    };

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    startTimer();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, []);

  useEffect(() => {
    const checkOnFocus = () => {
      if (timeLeft === 0) {
        window.location.reload();
      }
    };
    window.addEventListener("focus", checkOnFocus);
    return () => {
      window.removeEventListener("focus", checkOnFocus);
    };
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft <= 600 && timeLeft > 60 && !warnNotificationShown) {
      Toast(
        "warn",
        "Your session will expire in less than 3 minutes. Please login again to continue."
      );
      setWarnNotificationShown(true);
    } else if (timeLeft <= 60 && timeLeft > 0 && !errorNotificationShown) {
      Toast(
        "error",
        "Your session will expire in less than 1 minute. Please login again to continue."
      );
      setErrorNotificationShown(true);
    }
  }, [timeLeft, warnNotificationShown, errorNotificationShown]);

  return children;
};

export default AutoAppLogout;
