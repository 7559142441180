import { useEffect } from "react";
import { FileUploader } from "../../../components";
import {
  FontAwesomeIcon,
  faClockRotateLeft,
  faUpload,
} from "../../../import/FontAwesomeIcons";
import { Tab, Tabs } from "react-bootstrap";
import { useGenAiHistoryApiCall } from "../../../Hooks/index";
import { GenAiHistory } from "./index";

const GenAiInputTab = (props) => {
  const { fetchGenAiHistoryData } = useGenAiHistoryApiCall();

  const handleHistoryTabClick = () => {
    fetchGenAiHistoryData();
    props.setHistoryTabActive(true);
  };

  useEffect(() => {
    let fetchDataInterval;
    if (props.historyTabActive) {
      fetchDataInterval = setInterval(() => {
        fetchGenAiHistoryData();
      }, 60000);
    }
    return () => {
      clearInterval(fetchDataInterval);
    };
  }, [props.historyTabActive]);

  return (
    <Tabs
      defaultActiveKey="Upload file"
      id="justify-tab-example"
      className="mb-3"
      justify
      onSelect={(eventKey) => {
        eventKey === "History" && handleHistoryTabClick();
        eventKey === "Upload file" && props.setHistoryTabActive(false);
      }}
    >
      <Tab
        eventKey="Upload file"
        title={
          <span className="tab-title">
            <FontAwesomeIcon icon={faUpload} />
            Upload file
          </span>
        }
      >
        <FileUploader
          BatchProcessing={props.BatchProcessing}
          RadioOptions={props.RadioOptions}
          ButtonText={props.ButtonText}
          RadioHandler={props.Radio_Handler}
        />
      </Tab>
      <Tab
        eventKey="History"
        title={
          <span className="tab-title">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            History
          </span>
        }
      >
        <GenAiHistory />
      </Tab>
    </Tabs>
  );
};

export default GenAiInputTab;
