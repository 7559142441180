import React, { useContext } from "react";
import { CustomButton, DragAndDrop } from "../../../components";
import ParentContext from "../../../context/context";
const PaFileUploader = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { resetUploads } = state;
  return (
    <div className="pa-uploadfile-container">
      <div style={{ padding: "10px", width: "100%" }}>
        <DragAndDrop
          style={{ height: "250px" }}
          resetUploads={resetUploads}
          setFile={(file) =>
            dispatch({
              type: "SET_FILE",
              payload: file,
            })
          }
        />
      </div>
      <div style={{ marginTop: "15px" }}>
        <CustomButton variant="primary" size="md">
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default PaFileUploader;
