import React, { useContext, useEffect, useMemo, useState } from "react";
import ParentContext from "../../context/context";
import EditTransactionModal from "./EditTransactionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faEdit,
  faPlusSquare,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import ExtractionReport from "./ExtractionReport/ExtractionReport";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddMissingTransaction from "./AddMissingTransaction";

const BankStatementTable = (props) => {
  const { state } = useContext(ParentContext);
  const { backendResponse, currentPage } = state;
  const {
    tableData,
    selectedRow,
    rowRefs,
    searchRow,
    setSearchRow,
    setSelectedRow,
    tableAccNo,
    showAccReport,
    setShowAccReport,
    serviceType,
  } = props;
  const [show, setShow] = useState(false);
  const [addTransactnModel, setAddTransactnModel] = useState(false);
  const [editableTransactionData, setEditableTransactionData] = useState(null);
  const [commonTransactionData, setCommonTransactionData] = useState(null)

  const tableHeaders =
    serviceType === "Bank Statement" &&
    Object.keys(tableData?.data[0][tableAccNo][0]);
  const tableBodyRowValues =
    serviceType === "Bank Statement" && tableData?.data[0][tableAccNo];

  const transposedData =
    serviceType !== "Bank Statement" && transposeData(tableData);

  function transposeData(data) {
    if (
      !Array.isArray(data) ||
      data.length === 0 ||
      !Array.isArray(data[0].Value)
    ) {
      console.error("Invalid table data format");
      return [];
    }
    const numRows = data[0]?.Value?.length;
    const numCols = data?.length;
    const transposedData = Array.from({ length: numRows }, () =>
      Array(numCols).fill([])
    );
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        transposedData[row][col] = data[col].Value[row];
      }
    }
    return transposedData;
  }

  useEffect(() => {
    serviceType === "Bank Statement" &&
      rowRefs?.current?.filter((ref) => {
        if (ref?.id === selectedRow) {
          ref?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      });
  }, [rowRefs, selectedRow]);

  return (
    <React.Fragment>
      {serviceType !== "Bank Statement" &&
        backendResponse?.Data[currentPage] && (
          <table id="BsTable" className="table1">
            <thead className="thead1">
              <tr className="tr1">
                {transposedData[0].map((header, headerIndex) => (
                  <th className="th1" key={headerIndex}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tbody1">
              {transposedData.slice(1).map((transposedRow, rowIndex) => (
                <tr className="tr1" key={rowIndex}>
                  {transposedRow.map((cell, cellIndex) => (
                    <td className="td1" key={cellIndex}>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      {serviceType === "Bank Statement" && showAccReport && (
        <div className="extraction_report">
          <ExtractionReport
            tableAccNo={tableAccNo}
            showAccReport={showAccReport}
            setShowAccReport={setShowAccReport}
            accReport={tableData}
          />
        </div>
      )}
      {serviceType === "Bank Statement" &&
        tableHeaders &&
        tableBodyRowValues && (
          <table id="bsTable" className="table1">
            <thead className="thead1">
              <tr className="tr1">
                <th className="th1">VERIFY DATA</th>
                {tableHeaders?.map((header, headerIndex) => (
                  <th className="th1" key={headerIndex}>
                    {header.replace("_", " ")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tbody1">
              {tableBodyRowValues.map((rowValues, rowIndex) => {
                return (
                  <tr
                    className="tr1"
                    id={rowValues.TEXTRACT_ID}
                    key={rowIndex}
                    ref={(el) => (rowRefs.current[rowIndex] = el)} // Store the row refs
                    style={{
                      // Highlight the selected row
                      backgroundColor:
                        (selectedRow === rowValues.TEXTRACT_ID ||
                          searchRow === rowValues.TEXTRACT_ID) &&
                        "#cdcdda75",
                      border:
                        (selectedRow === rowValues.TEXTRACT_ID ||
                          searchRow === rowValues.TEXTRACT_ID) &&
                        "3px dashed #4b4b4b",
                    }}
                  >
                    <td className="verify_data">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Approve</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="approve"
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Edit</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={() => {
                            setEditableTransactionData(rowValues);
                            setShow(true);
                          }}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">Add Transaction</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlusSquare}
                          onClick={() => {
                            setCommonTransactionData(rowValues);
                            setAddTransactnModel(true);
                          }}
                        />
                      </OverlayTrigger>
                    </td>
                    {Object.entries(rowValues).map(
                      ([cellKey, cellValue], cellIndex) => {
                        const { TEXTRACT_ID, PAGE_NUMBER } = rowValues;
                        if (cellKey === "PAGE_NUMBER") {
                          return (
                            <td className="td1" key={cellIndex}>
                              <div className="page_number_search">
                                {cellValue}
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  onClick={() => {
                                    setSelectedRow(TEXTRACT_ID);
                                    setSearchRow(
                                      TEXTRACT_ID,
                                      parseInt(PAGE_NUMBER)
                                    );
                                  }}
                                  className="searchIcon"
                                />
                              </div>
                            </td>
                          );
                        } else {
                          return (
                            <td className="td1" key={cellIndex}>
                              {cellValue}
                            </td>
                          );
                        }
                      }
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      {serviceType === "Bank Statement" && editableTransactionData && (
        <EditTransactionModal
          transactionData={editableTransactionData}
          show={show}
          setShow={setShow}
        />
      )}
      {serviceType === "Bank Statement" && commonTransactionData && (
        <AddMissingTransaction
          transactionData={commonTransactionData}
          show={addTransactnModel}
          setShow={setAddTransactnModel}
        />
      )}
    </React.Fragment>
  );
};

export default BankStatementTable;
