export const ModifiDate = (date) => {
  const parts = date && date.split(" ");
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  const monthNumber = (new Date(Date.parse(month + " 1, 2022")).getMonth() + 1)
    .toString()
    .padStart(2, "0");

  const formattedDate = `${year}-${monthNumber}-${day}`;
  return formattedDate;
};

export const getDaysBetweenDates = (fromDate, toDate) => {
  const start = new Date(fromDate);
  const end = new Date(toDate);
  const timeDifference = end.getTime() - start.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return daysDifference + 1;
};
