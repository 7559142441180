import React,{memo} from "react";
import { Bar } from "react-chartjs-2";
import ChartsFallBackUi from "../ChartsFallBackUi/ChartsFallBackUi";

const generateHistogramData = (histogramChartResponse) => {
  const { binCount, binSize, datasets } = histogramChartResponse;
  const bins = Array.from({ length: binCount }, (_, index) => ({
    lower: index * binSize,
    upper: (index + 1) * binSize,
    count: datasets.filter(
      (value) => value >= index * binSize && value < (index + 1) * binSize
    ).length,
  }));

  const labels = bins.map((bin) => `${bin.lower}-${bin.upper}`);
  const data = bins.map((bin) => bin.count);

  return { labels, data };
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Histogram Chart",
    },
  },
  scales: {
    y: {
      begainAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
};

const HistogramChart = (props) => {
  const { histogramChartResponse } = props;
  const { labels, data } = generateHistogramData(histogramChartResponse);

  if (!histogramChartResponse || !histogramChartResponse?.datasets) {
    return <ChartsFallBackUi />;
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: "Frequency",
        data,
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
    ],
  };

  return <Bar options={options} data={chartData} />;
};

export default memo(HistogramChart);
