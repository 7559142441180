import React, { useContext, useEffect, memo } from "react";
import ParentContext from "../../context/context";
import { InferCarousel, FileUploadForm } from "../../components/index";
import { userPermission } from "../../Auth/authConfig";

const Extraction = () => {
  const { dispatch } = useContext(ParentContext);

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "Extraction" });
  }, [dispatch]);

  return (
    <FileUploadForm
      RadioOptions={userPermission?.map(
        (item) => item.label === "Extraction" && item.RadioOptions
      )}
      sideContent={<InferCarousel />}
      ButtonText="Next"
    />
  );
};

export default memo(Extraction);
