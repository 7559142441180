import React, { useState, useContext, useEffect, memo } from "react";
import ParentContext from "../../context/context";
import "./CommercialRealEstate.css";
import { InferSideImage } from "../../components/index";
import { Col, Container, Row } from "react-bootstrap";
import { RentRollImg } from "../../images/sideContent/index";
import {
  useCREApiCall,
  useCreHistoryApiCall,
  useToasts,
} from "../../Hooks/index";
import CreTabViewer from "./components/CreTabViewer";
import { userPermission } from "../../Auth/authConfig";

const CommercialRealEstate = () => {
  const [historyTabActive, setHistoryTabActive] = useState(false);
  const { state, dispatch } = useContext(ParentContext);
  const {
    selectedValue,
    resetUploads,
    uploadedFiles,
    rentRollNewlyAddedQuestions,
    selectedPages,
  } = state;

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "Rent Roll" });
  }, [dispatch]);

  const handleChange = (e) => {
    dispatch({ type: "SET_SEL_VALUE", payload: e.target.value });
  };
  const handleRadioLlm = (e) => {
    dispatch({ type: "SET_SELECTED_LLM_TYPE", payload: e.target.value });
  };
  const { handleSubmit } = useCREApiCall();
  const { fetchCreHistoryData } = useCreHistoryApiCall();
  const { Toast } = useToasts();

  const handleHistoryTabClick = () => {
    fetchCreHistoryData();
    setHistoryTabActive(true);
  };

  useEffect(() => {
    let fetchDataInterval;
    if (historyTabActive) {
      fetchDataInterval = setInterval(() => {
        fetchCreHistoryData();
      }, 5000);
    }
    return () => {
      clearInterval(fetchDataInterval);
    };
  }, [historyTabActive]);

  const isRequiredFieldsFilled = Boolean(
    selectedValue  && uploadedFiles.length > 0
  );

  const submitCre = (event) => {
    event?.preventDefault();
    if (isRequiredFieldsFilled) {
      handleSubmit(
        event,
        rentRollNewlyAddedQuestions,
        selectedValue,
        selectedPages
      );
      Toast(
        "success",
        "File uploaded successfully. For Results click on the history tab."
      );
      dispatch({ type: "SET_DISPLAY_TEXT", payload: false });
      dispatch({ type: "SET_UPLOADED_FILES", payload: [] });
      dispatch({ type: "SET_FILE", payload: "" });
    } else {
      Toast(
        "error",
        "Input variable missing, please check if file type is selected or file is uploaded."
      );
    }
  };

  return (
    <Container fluid>
      <Row>
        <div className="rent-roll-container">
          <Col xs={!historyTabActive ? 8 : 12}>
            <CreTabViewer
              setHistoryTabActive={setHistoryTabActive}
              dispatch={dispatch}
              selectedValue={selectedValue}
              resetUploads={resetUploads}
              RadioOptions={userPermission?.map(
                (item) => item.label === "Rent Roll" && item.RadioOptions
              )}
              handleChange={handleChange}
              handleRadioLlm={handleRadioLlm}
              handleHistoryTabClick={handleHistoryTabClick}
              submitCre={submitCre}
            />
          </Col>
          {!historyTabActive && (
            <Col xs={4}>
              <div className="rent-roll-content">
                <InferSideImage image={RentRollImg} title="Rent Roll">
                  A Rent roll is a record of rent revenue for all of your
                  properties with valid leases. It shows you all the information
                  having to do with rent for each unit you own. A rent roll is
                  used by investors, lenders, and mortgage brokers to assess the
                  current and potential value of a multi-family property and to
                  verify rent income.
                </InferSideImage>
              </div>
            </Col>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default memo(CommercialRealEstate);
