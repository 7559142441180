import React, { memo } from "react";
import { LabelSwitch } from "../../../components/index";

const AdminControlledQuestionnaire = (props) => {
  const {
    isEditTemplateDisabled,
    isAddQuestionDisabled,
    isDeleteQuestionDisabled,
    handleDeleteQuestionSwitchChange,
    handleEditTemplateSwitchChange,
    handleAddQuestionSwitchChange,
  } = props;

  return (
    <div>
      <h6>Admin Controlled Questionnaire</h6>
      <hr />
      <div style={{ marginLeft: "5%" }}>
        <LabelSwitch
          label="Edit Template Visible Only To Admins"
          value="Edit Template Visible Only To Admins"
          Checked={isEditTemplateDisabled}
          onChange={handleEditTemplateSwitchChange}
        />
        <LabelSwitch
          label="Disable Add Question Button"
          value="Disable Add Question Button"
          Checked={isAddQuestionDisabled}
          onChange={handleAddQuestionSwitchChange}
        />
        <LabelSwitch
          label="Disable Delete Question Button"
          value="Disable Delete Question Button"
          Checked={isDeleteQuestionDisabled}
          onChange={handleDeleteQuestionSwitchChange}
        />
      </div>
    </div>
  );
};

export default memo(AdminControlledQuestionnaire);
