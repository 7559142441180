import React from "react";
import Chart from "react-apexcharts";

const PaDonutChart = ({response}) => {
  const state = {
    series: response?.datasets,
    options: {
      chart: {
        type: "donut",
      },
      colors: ["#e23e3d", "#1290eba3"],
      legend: { show: true },
      dataLabels: { enabled: true },
      tooltip: { enabled: true },
      labels: response?.labels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return <Chart options={state?.options} series={state?.series} type="donut" height={200}/>;
};

export default PaDonutChart;
