import React, { memo, useContext, useEffect, useState } from "react";
import ParentContext from "../../../context/context";
import DropDown from "../MultipleFIleDropDown/DropDown";
import TabTitleContext from "../../../context/TabTitleContext";
import { CustomButton } from "../../index";
import {
  useRedactionApi,
  useDownload,
  useToasts,
  useStatus,
} from "../../../Hooks/index.js";
import BankStatementDropdown from "../BankStatementDropdown";
import DownloadComponent from "../../DownloadComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PdfFile,
  JsonFile,
  CsvFile,
  XlsxFile,
} from "../../../images/svg/index.js";
import "./TableButtons.css";
import EditAccountDetails from "../EditAccountDetails.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTable } from "@fortawesome/free-solid-svg-icons";

function DownloadOptions(props) {
  return (
    <div className="table-buttons-download-options-container">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Export Json</Tooltip>}
      >
        <div className="table-buttons-download-options-icon">
          <img
            src={JsonFile}
            alt="JsonFile"
            onClick={() => props.export_As_Json()}
          />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Export Csv</Tooltip>}
      >
        <div className="table-buttons-download-options-icon">
          <img
            src={CsvFile}
            alt="CsvFile"
            onClick={() => props.export_As_Csv()}
          />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Export Xlsx</Tooltip>}
      >
        <div className="table-buttons-download-options-icon">
          <img
            src={XlsxFile}
            alt="XlsxFile"
            onClick={() => props.export_As_Xlsx()}
          />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Export Pdf</Tooltip>}
      >
        <div className="table-buttons-download-options-icon">
          <img
            src={PdfFile}
            alt="PdfFile"
            onClick={() =>
              props.export_As_Pdf(props.kvpTdData, props.tableTrData)
            }
          />
        </div>
      </OverlayTrigger>
    </div>
  );
}

const TableButtons = (props) => {
  const {
    kvpTdData,
    tableTrData,
    setIsViewTableModal,
    isViewTableModal,
    accEditDetails,
    currentActiveAcc,
  } = props;
  const { state, dispatch } = useContext(ParentContext);
  const {
    serviceType,
    redactionDoc,
    multipleFileCheck,
    loading,
    selectedValue,
    redactionOutputFile,
    submitClicked,
  } = state;
  const { currentTab, response } = useContext(TabTitleContext);
  const { submit_Redaction_Fields } = useRedactionApi();
  const {
    export_As_Json,
    export_As_Csv,
    export_As_Xlsx,
    export_As_Pdf,
    export_PlainText_As_Pdf,
    export_Redacted_File_As_Pdf,
  } = useDownload(currentTab, response);
  const { Toast } = useToasts();
  const { parsedStatus, style, statusMsgForRedactionTab } = useStatus();
  const [editAccModal, setEditAccModal] = useState(false);

  const base64Url = redactionDoc?.display_file;
  const fileName = "Redacted_BankForm.pdf";

  useEffect(() => {
    dispatch({ type: "SET_REDAC_OUT_FILE", payload: false });
  }, [dispatch]);

  if (loading) {
    return (
      <div className="container-loader2">
        <div className="progress-container">
          <div className="progress" style={{ width: "230px" }}>
            <div
              className="progress-done progress-bar-animated progress-bar-striped"
              style={style}
            >
              {parsedStatus}%
            </div>
          </div>
          <div className="progress-message para">
            {statusMsgForRedactionTab}
          </div>
        </div>
      </div>
    );
  }

  const handeleRedactionSubmit = () => {
    if (props.checkedState) {
      Toast("warn", "Please select the Redaction entites to continue.");
    } else {
      submit_Redaction_Fields();
    }
  };

  return (
    <div className="table-buttons">
      {multipleFileCheck && selectedValue === "Tax Forms" && (
        <DropDown
          title="Select File"
          Allfiles={props.Allfiles}
          setSelectedOption={props.setSelectedOption}
        />
      )}
      <div className="table-buttons-download-options">
        {(serviceType === "Extraction" || serviceType === "Bank Statement") &&
          ["Bank Statement", "IRS Forms", "Application Form"].includes(
            selectedValue
          ) && (
            <BankStatementDropdown
              BankStatementHandleChangePage={
                props.BankStatementHandleChangePage
              }
            />
          )}
        <div className="bank-stt-table-options">
          {serviceType === "Bank Statement" && !isViewTableModal && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">View Full Table</Tooltip>}
            >
              <FontAwesomeIcon
                icon={faTable}
                onClick={() => setIsViewTableModal(true)}
              />
            </OverlayTrigger>
          )}
          {serviceType === "Bank Statement" && (
            <div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Edit</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faPencil}
                  onClick={() => setEditAccModal(true)}
                />
              </OverlayTrigger>
              {
                currentActiveAcc !== "not_found" &&
                <EditAccountDetails
                  show={editAccModal}
                  setShow={setEditAccModal}
                  accEditDetails={accEditDetails}
                  currentActiveAcc={currentActiveAcc}
                />
              }
            </div>
          )}
          {(serviceType === "Extraction" || serviceType === "Bank Statement") &&
            ["Bank Statement", "IRS Forms", "Application Form"].includes(
              selectedValue
            ) && <DownloadComponent />}
        </div>

        {!redactionOutputFile &&
          serviceType !== "Redaction" &&
          ![
            "Bank Statement",
            "Credit Statement",
            "Forms",
            "IRS Forms",
            "Application Form",
          ].includes(selectedValue) && (
            <DownloadOptions
              kvpTdData={kvpTdData}
              tableTrData={tableTrData}
              export_As_Json={export_As_Json}
              export_As_Csv={export_As_Csv}
              export_As_Xlsx={export_As_Xlsx}
              export_As_Pdf={export_As_Pdf}
            />
          )}

        {!redactionOutputFile &&
          submitClicked &&
          serviceType === "Redaction" &&
          selectedValue === "Plain Text" && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Export Pdf</Tooltip>}
            >
              <div className="table-buttons-download-options-icon">
                <img
                  src={PdfFile}
                  alt="PdfFile"
                  onClick={() => export_PlainText_As_Pdf()}
                />
              </div>
            </OverlayTrigger>
          )}
        {redactionOutputFile &&
          serviceType === "Redaction" &&
          selectedValue === "Forms" && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Export Pdf</Tooltip>}
            >
              <div className="table-buttons-download-options-icon">
                <img
                  src={PdfFile}
                  alt="PdfFile"
                  onClick={() =>
                    export_Redacted_File_As_Pdf(base64Url, fileName)
                  }
                />
              </div>
            </OverlayTrigger>
          )}
      </div>
      {!redactionOutputFile &&
        !submitClicked &&
        serviceType === "Redaction" && (
          <CustomButton
            size="sm"
            variant="primary"
            onClick={handeleRedactionSubmit}
          >
            Submit
          </CustomButton>
        )}
    </div>
  );
};

export default memo(TableButtons);
