import React from "react";
import { Badge } from "react-bootstrap";

const CheckBoxList = ({
  options,
  title,
  selectTitle,
  selectedValues,
  handleChange,
}) => {
  return (
    <div className="checklist-container">
      <div className="checklist-options">
        <div className="para">{title} :</div>
        <hr />
        <div
          style={{
            border: "1px solid transperent",
            borderRadius: "5px",
            padding: "8px",
            backgroundColor: "rgb(204 204 204 / 35%)",
          }}
        >
          {options.map((option) => (
            <div key={option.value} className="checklist-options-input">
              <input
                type="checkbox"
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={() => handleChange(option.value)}
              />
              <label className="para checklist-options-input-label">
                {option.value}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="checklist-options">
        <div className="para">{selectTitle}:</div>
        <hr />
        <div
          className="checklist-options-values"
          style={{
            border: "1px solid transperent",
            borderRadius: "5px",
            padding: "8px",
            backgroundColor: "rgb(204 204 204 / 35%)",
            width: "100%",
            height: "auto",
          }}
        >
          {selectedValues.length === 0 ? (
            <span className="para">No {title} selected yet.</span>
          ) : (
            selectedValues.map((value) => (
              <span key={value} style={{ marginBottom: "2px" }}>
                <Badge bg="primary">{value}</Badge>
              </span>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckBoxList;
