import { useContext } from "react";
import axios from "axios";
import ParentContext from "../context/context";
import env from "../env/env.js";
import { encryptData } from "../utils/crypto";

const useSignUpResendVerificationCodeApi = () => {
  const { state, dispatch } = useContext(ParentContext);

  const getSignUpResendVerifiCode = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    await axios
      .post(
        `${env.API_GATEWAY}/extractData`,
        {
          callType: "resend_confirmation_code",
          formData: encryptData(
            sessionStorage.getItem("email"),
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_SIGNUP_VERIFI_BACKEND_RES",
          payload: response.data,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
      });
  };

  return { getSignUpResendVerifiCode };
};

export default useSignUpResendVerificationCodeApi;
