import { useState } from "react";

const useBillingInfo = () => {
  const initialCosts = {
    extraction: 0,
    redaction: 0,
    calud: 0,
    chatgpt: 0,
    totalFixedCost: 0,
  };
  const [costs, setCosts] = useState(initialCosts);
  const [isEditing, setIsEditing] = useState(false);

  let BillingInfoInputs = [
    {
      id: 1,
      label: "Cost of Extraction per Page:",
      tag: "extraction",
      value: costs.extraction,
    },
    {
      id: 2,
      label: " Cost of Redaction per Page:",
      tag: "redaction",
      value: costs.redaction,
    },
    {
      id: 3,
      label: " Cost of Calud per Page:",
      tag: "calud",
      value: costs.calud,
    },
    {
      id: 4,
      label: "Cost of ChatGPT per Page:",
      tag: "chatgpt",
      value: costs.chatgpt,
    },
    {
      id: 5,
      label: "Total Fixed Cost:",
      tag: "totalFixedCost",
      value: costs.totalFixedCost,
    },
  ];

  let BillingInfoValues = [
    {
      id: 1,
      value: costs.extraction,
      label: "Cost of Extraction per Page",
    },
    {
      id: 2,
      value: costs.redaction,
      label: "Cost of Redaction per Page",
    },
    {
      id: 3,
      value: costs.calud,
      label: "Cost of Calud per Page",
    },
    {
      id: 4,
      value: costs.chatgpt,
      label: "Cost of ChatGPT per Page",
    },
    {
      id: 5,
      value: costs.totalFixedCost,
      label: "Total Fixed Cost for InferIQ",
    },
  ];
  return {
    BillingInfoInputs,
    BillingInfoValues,
    setCosts,
    isEditing,
    setIsEditing,
  };
};

export default useBillingInfo;
