import React, { memo, useCallback, useEffect, useRef } from "react";
import { CustomButton } from "../../../components";
import fileUploadImg from "../../../images/svg/icons8-cloud-upload-96.png";
import { useToasts } from "../../../Hooks";
import { useNavigate } from "react-router";

const ErrorHandler = (props) => {
  const { Toast } = useToasts();
  const navigate = useNavigate();
  const toastTimeoutDuration = 8000;
  const timeDuration = 7000;
  const hasShownToast = useRef(false);
  const DID_NOT_GET_THE_RESPONSE_FROM_API = "Did not get the response from API";
  const SOMETHING_WENT_WRONG = "Something went wrong";

  useEffect(() => {
    if (
      props.backendResponse === SOMETHING_WENT_WRONG &&
      !hasShownToast.current
    ) {
      Toast(
        "error",
        "File processing unsuccessful. Please try again. If the problem persists, please contact the administrator.",
        toastTimeoutDuration
      );
      setTimeout(() => {
        navigate("/bankstatements", { replace: true });
        window.location.reload();
      }, timeDuration);

      hasShownToast.current = true;
    }
  }, [props.backendResponse, Toast, navigate]);

  return (
    <div className="backendresponse-fail-msg">
      {props.backendResponse === DID_NOT_GET_THE_RESPONSE_FROM_API ? (
        <div>
          <img src={fileUploadImg} alt="fileupload" draggable={false} />
          <span>
            File processing unsuccessful. Please try again. <br />
            If the problem persists, please contact the administrator.
          </span>
        </div>
      ) : (
        <div>
          <img src={fileUploadImg} alt="fileupload" draggable={false} />
          <span>Please re-upload the document.</span>
        </div>
      )}

      {props.backendResponse !== SOMETHING_WENT_WRONG && (
        <CustomButton
          variant="primary"
          size="sm"
          onClick={() => props.BackToHome()}
        >
          Go to Home
        </CustomButton>
      )}
    </div>
  );
};

export default memo(ErrorHandler);
