import React, { useState, memo, Fragment } from "react";
import { CustomButton } from "../../../components/index";
import { Modal } from "react-bootstrap";
import { SunburstChart } from "../components/charts/index";
import { sunbrustChartResponse } from "../utils/ChartsResponse";

const GridItem = (props) => {
  const { title, chart, toggel } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
      <div className="userDashboard-grid-header-container">
        {title && <p className="userDashboard-grid-heading heading">{title}</p>}
        {toggel && (
          <span>
            <CustomButton size="sm" variant="primary" onClick={handleShow}>
              {toggel}
            </CustomButton>
          </span>
        )}
      </div>
      {chart && <div className="userDashboard-grid-content">{chart}</div>}
      {show && (
        <Modal size="lg" centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>SunbrustChart</Modal.Title>
          </Modal.Header>
          <hr />
          <Modal.Body>
            <SunburstChart data={sunbrustChartResponse} height="600px" />
          </Modal.Body>
          <hr />
          <Modal.Footer>
            <CustomButton size="sm" variant="primary" onClick={handleClose}>
              Close
            </CustomButton>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};

export default memo(GridItem);
