import React, { useContext, useEffect, memo } from "react";
import ParentContext from "../../context/context";
import { FileUploadForm, InferSideImage } from "../../components/index";
import { RecognitionImg } from "../../images/sideContent/index.js";
import { userPermission } from "../../Auth/authConfig";

const Recognition = () => {
  const { dispatch } = useContext(ParentContext);

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "Recognition" });
  }, [dispatch]);

  return (
    <FileUploadForm
      RadioOptions={userPermission?.map(
        (item) => item.label === "Recognition" && item.RadioOptions
      )}
      sideContent={
        <InferSideImage image={RecognitionImg} title="Recognition">
          InferIQ recognizer module is an intelligent framework that identifies
          specific information from diverse documents. It utilizes advanced
          machine learning algorithms to analyze textual and visual elements,
          accurately recognizing and capturing essential data. This efficient
          tool enhances productivity by improving the efficiency of data
          extraction and accelerating information processing.
        </InferSideImage>
      }
      ButtonText="Next"
    />
  );
};

export default memo(Recognition);
