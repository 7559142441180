import React, { useContext } from "react";
import "./AuthFallback.css";
import Modal from "react-bootstrap/Modal";
import ParentContext from "../../context/context";
import CustomButton from "../Button/CustomButton";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightToBracket,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

const AuthFallback = (props) => {
  const { state, setLoggedIn } = useContext(ParentContext);
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.removeItem("usd");
    sessionStorage?.removeItem("expirationTime");
    setLoggedIn(false);
    state.signInBackendRes = [];
    navigate("/");
    window.location.reload();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            style={{ marginRight: "10px", color: "#c82333" }}
          />
          Unable to restore session
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          We encountered an error trying to restore your previous session.
          <br />
          Kindly Login again to continue. If the issue persists, please contact
          support team.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton size="sm" variant="danger" onClick={logout}>
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            icon={faArrowRightToBracket}
          />
          Go To Login
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AuthFallback;
