import React from "react";
import "./PageNotFound.css";
import logo from "../../images/InferIQ-Blue.png";

const PageNotFound = () => {
  return (
    <div className="pnf_container">
      <img src={logo} alt="inferiqLogo" />
      <div
        className="pagenotfound"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 2px",
        }}
      >
        <p className="pagenotfound-404">404</p>
        <p className="pagenotfound-pnf">Page not found.</p>
        <p className="pagenotfound-msg">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <a href="/" className="pagenotfound-redirect" draggable={false}>
          Back to Home page.
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
