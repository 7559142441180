import { Modal } from "react-bootstrap";
import "./EditTransactionModal.css";
import CustomButton from "../Button/CustomButton";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddMissingTransaction = (props) => {
  const { show, setShow, transactionData } = props;
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="bsEditModal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Transaction
          <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bsEditModalForm">
          <div className="wrap">
            {Object.entries(transactionData).map(([key, value]) => {
              return (
                key !== 'TEXTRACT_ID' && key !== 'CONFIDENCE' && (
                <div key={key} className="editField">
                  <label>{key.replaceAll("_", " ")}</label>
                  <input
                    type="text"
                    value={
                      (key == "ACCOUNT_ADDRESS") ||
                      (key == "ACCOUNT_NUMBER") ||
                      (key == "BANK_NAME") ||
                      (key == "BANK_CITY") ||
                      (key == "BANK_STATE") ||
                      (key == "DOCUMENT_ID") ||
                      (key == "DOCUMENT_TYPE")
                        ? value
                        : ""
                    }
                  />
                </div>)
              );
            })}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton size="sm" variant="danger" onClick={() => setShow(false)}>
          Remove
        </CustomButton>
        <CustomButton size="sm" variant="success">
          Save
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMissingTransaction;
