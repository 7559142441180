import React, { useState, Fragment } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ImageControllPannel from "../Table/TableButtons/ImageControllPannel";

const Image = (props) => {
  return (
    <div className="Outputscreen-imgViewer">
      <img
        style={{
          transform: `rotate(${props.rotationAngle}deg)`,
        }}
        src={props.image}
        draggable={false}
      />
    </div>
  );
};

const ImageViewer = (props) => {
  const [rotationAngle, setRotationAngle] = useState(0);
  const rotateImage = (angle) => {
    setRotationAngle((prevAngle) => prevAngle + angle);
  };

  return (
    <TransformWrapper initialScale={1}>
      {({ zoomIn, zoomOut, resetTransform, centerView }) => (
        <Fragment>
          <ImageControllPannel
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            resetTransform={resetTransform}
            rotate={rotateImage}
            centerView={centerView}
          />
          <TransformComponent>
            <Image rotationAngle={rotationAngle} image={props.image} />
          </TransformComponent>
        </Fragment>
      )}
    </TransformWrapper>
  );
};

export default ImageViewer;
