import React from "react";
import BG from "../../images/BG.png";

export const LoginLeftContainer = (props) => {
  return (
    <div className="loginpage-left-container">
      <img src={BG} alt="" />
      <div className="loginpage-left-container-content">
        {/* <img src={logo2} alt="inferiqLogo" /> */}
        <p>
          Unlock <br />
          <span>the Power of </span>
          <span
            style={{
              color: "rgb(18, 144, 235)",
            }}
          >
            GenAl
          </span>
        </p>
        <div className="loginpage-left-container-content-image">
          {props.Images.map(({ id, img, text }) => (
            <span key={id} className="loginpage-left-container-content-images">
              <img src={img} alt="image" />
              <p>{text}</p>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
