import React, { memo } from "react";
import Chart from "react-apexcharts";
import colors from "../../InferIq-Solution/utils/graphColors";

const BillingTreeMapChart = ({ response }) => {
  const state = {
    series: response?.datasets,
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
        toolbar: {
          show: false,
        },
      },
      colors: [...colors],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return val?.toFixed(3) + "$";
          },
        },
      },
    },
  };
  return (
    <Chart
      options={state?.options}
      series={state?.series}
      type="treemap"
      height={350}
    />
  );
};

export default memo(BillingTreeMapChart);
