import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  memo,
} from "react";
import "./SideBar.css";
import ParentContext from "../../../context/context";
import { Container, Row, Col } from "react-bootstrap";
import { SideBarHeader, SidebarBody } from "./components/index";
import { useLocation } from "react-router";
import { useValidateRoute } from "../../../Hooks/index";
import { userPermission } from "../../../Auth/authConfig";
import {
  SideBarRoutesIcons,
  serviceDashboardRoutesIcons,
  billingDashboardRoutesIcons,
} from "../../../utils/SideBarRoutesIcons";
import { userPermission1 } from "../../../utils/permissionConfig";
import { userPermissionLocal } from "../../../utils/userPermission";

const Sidebar = () => {
  const { state, dispatch, currentItem } = useContext(ParentContext);
  const { breadcrumbs, isOpen, activeIndex, loading, backendResponse } = state;
  const [currentIndex, setCurrentIndex] = useState(activeIndex);

  const history = useLocation();

  let clientPermission = userPermission1(userPermission, SideBarRoutesIcons);

  let serviceDashboardPermission = userPermission1(
    userPermissionLocal.serviceDashboardRoutes,
    serviceDashboardRoutesIcons
  );

  let billingDashboardPermission = userPermission1(
    userPermissionLocal.billingDashboardRoutes,
    billingDashboardRoutesIcons
  );

  useEffect(() => {
    if (activeIndex !== undefined) {
      setCurrentIndex(activeIndex);
    }
  }, [activeIndex]);

  const handle_Click = useCallback(
    (id) => {
      setCurrentIndex(id);
      dispatch({ type: "SET_ACTIVE_INDEX", payload: id });
      dispatch({ type: "SET_DISPLAY_TEXT", payload: false });
      dispatch({
        type: "SET_CURRENT_PAGE",
        payload: Object.keys(backendResponse?.Data ?? {})?.[0],
      });
      dispatch({ type: "SET_BACKEND_RES", payload: [] });
      localStorage.removeItem("templateQuestions");
    },
    [dispatch]
  );

  let ActiveSideBarRoutes;
  switch (currentItem) {
    case "/":
      if (currentItem) {
        ActiveSideBarRoutes = clientPermission;
      }
      break;
    case "/serviceDashboard":
      if (currentItem) {
        ActiveSideBarRoutes = serviceDashboardPermission;
      }
      break;
    case "/billingMetrics":
      if (currentItem) {
        ActiveSideBarRoutes = billingDashboardPermission;
      }
      break;
  }

  const find_Active_Bread_Crum = ActiveSideBarRoutes?.map(({ id, path }) => {
    return { path, id };
  })?.find(({ path }) => path === history.pathname);

  useEffect(() => {
    dispatch({ type: "SET_ACTIVE_INDEX", payload: find_Active_Bread_Crum?.id });
  }, [breadcrumbs, dispatch]);

  const {
    validateSolutionRoutes,
    validateServiceRoutes,
    validateBillingRoutes,
  } = useValidateRoute();

  return (
    <Container fluid>
      <Row>
        <Col xs={{ order: "first" }}>
          <div
            className={`sidebar ${isOpen === true ? "active" : ""}`}
            style={{
              left: isOpen ? 0 : " -18%",
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <div className="sidebar-core">
              <SideBarHeader />
              {validateSolutionRoutes.includes(currentItem) && (
                <SidebarBody
                  activeIndex={currentIndex}
                  handle_Click={handle_Click}
                  SideBarRoutes={clientPermission}
                />
              )}
              {validateServiceRoutes.includes(currentItem) && (
                <SidebarBody
                  activeIndex={currentIndex}
                  handle_Click={handle_Click}
                  SideBarRoutes={serviceDashboardPermission}
                />
              )}
              {validateBillingRoutes.includes(currentItem) && (
                <SidebarBody
                  activeIndex={currentIndex}
                  handle_Click={handle_Click}
                  SideBarRoutes={billingDashboardPermission}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(Sidebar);
