import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  faTriangleExclamation,
  faSave,
  faXmark,
  faTrashCan,
  faPlus,
} from "../../../../import/FontAwesomeIcons";
import { ConfirmationDialog } from "../../../../components/index";
import {
  GenAiAccordions,
  GenAiHeaderButtons,
  GenAiQuestions,
  GenAiRedactionOutPut,
  GenAiSwitches,
  GenAiTemplates,
  GenAiTemplatesDescription,
} from "../index";
import { useGenAiApi, useToasts } from "../../../../Hooks/index";
import ParentContext from "../../../../context/context";
import { useNavigate } from "react-router";
import RedactionLoader from "../../../../components/Table/TableButtons/RedactionLoader";
import html2pdf from "html2pdf.js";
import "./GenaiTabs.css";
import GenAiAlertModal from "./GenAiAlertModal";

const GenaiTabs = () => {
  let NextButtonDisable,
    goToNextStep,
    RightButtonText,
    DontShowButton,
    LeftButtonStyles,
    HeaderButtonsStyles,
    goToPreviousStep,
    RedactionOutputButtonsStyles,
    RedactionEditButton;

  const { dispatch, state } = useContext(ParentContext);

  const {
    loading,
    dataRedactionCheck,
    autoPiiRedactionCheck,
    activeTab,
    genAiTemplateRadioOptionsAndCheck,
    genaiOutput,
    getRedactioOutputEventKey,
    templateQuestionsLength,
  } = state;
  const [genaicheckedState, setGenAiCheckedState] = useState([]);
  const [genaiOptionChecked, setGenAiOptionChecked] = useState(false);
  const [editButtonActive, setEditButtonActive] = useState(false);
  const [addQuestionButtonActive, setAddQuestionButtonActive] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const is_Button_Disabled =
    genaicheckedState.every((checked) => !checked) &&
    !autoPiiRedactionCheck &&
    dataRedactionCheck;

  const handleShow = (breakpoint) => {
    setFullscreen(breakpoint);
    setShow(true);
  };

  const {
    handle_Genai_Redacted_Fileds,
    handle_Genai_Query,
    terminate_handleupload,
    handleButtonClick,
    tabs,
    disablePrevious,
  } = useGenAiApi();
  const { Toast } = useToasts();

  const handleOptionSelect = (option) => {
    dispatch({
      type: "SET_GENAI_TEMPLATE_RADIO_OPTIONS_AND_CHECK",
      payload: option,
    });
  };

  useEffect(() => {
    dispatch({ type: "SET_GENAI_OUTPUT", payload: [] });
    dispatch({ type: "SET_ACTIVE_TAB", payload: "redaction_settings" });
  }, [dispatch]);

  const navigate = useNavigate();
  const backToGenAi = React.useCallback(() => {
    terminate_handleupload();
    navigate("/generativeai");
  }, [navigate]);

  if (loading) {
    return (
      <React.Fragment>
        <RedactionLoader />
      </React.Fragment>
    );
  }

  if (activeTab === "genaioutput") {
    DontShowButton = {
      display: "none",
    };
  }

  const redactionOutputNextHandler = () => {
    if (editButtonActive) {
      Toast(
        "warn",
        "Text edit option is enabled, please save the changes to proceed."
      );
    } else {
      handleShow();
    }
  };

  const redactionSettingsNextHandler = () => {
    if (is_Button_Disabled) {
      Toast(
        "warn",
        "Please select one of the redaction options or disable Data redaction to continue"
      );
    } else {
      handle_Genai_Redacted_Fileds();
    }
  };

  const handleConfirmationYes = () => {
    handleButtonClick("previous");
    dispatch({ type: "SET_GENAI_OUTPUT", payload: [] });
    dispatch({ type: "SET_ACTIVE_TAB", payload: "redaction_settings" });
    setShowConfirmation(false);
  };

  const handleConfirmationNo = () => {
    setShowConfirmation(false);
  };

  const redactionStatementNextHandler = () => {
    if (!genAiTemplateRadioOptionsAndCheck) {
      Toast(
        "warn",
        "Please select one of the select case options to continue."
      );
    } else {
      handleButtonClick("next");
    }
  };

  const redactionQuestionsNextHandler = () => {
    if (addQuestionButtonActive) {
      Toast("warn", "Please add custom question to continue.");
    } else if (templateQuestionsLength <= 0) {
      Toast("warn", "There should be atleast one question to continue");
    } else {
      handle_Genai_Query();
    }
  };

  switch (activeTab) {
    case "redaction_settings":
      goToNextStep = () => redactionSettingsNextHandler();
      RightButtonText = "Submit";
      LeftButtonStyles = { display: "none" };
      RedactionOutputButtonsStyles = { display: "none" };
      RedactionEditButton = { display: "none" };
      HeaderButtonsStyles = {
        justifyContent: "space-between",
      };
      break;
    case "redaction_output":
      goToNextStep = () => redactionOutputNextHandler();
      goToPreviousStep = () => setShowConfirmation(true);
      RightButtonText = "Next";
      break;
    case "statement":
      goToNextStep = () => redactionStatementNextHandler();
      goToPreviousStep = () => {
        handleButtonClick("previous");
        setShow(false);
      };
      RightButtonText = "Next";
      RedactionEditButton = { display: "none" };
      RedactionOutputButtonsStyles = { display: "none" };
      LeftButtonStyles = { width: "47%" };
      break;
    case "questions":
      goToNextStep = () => redactionQuestionsNextHandler();
      goToPreviousStep = () => handleButtonClick("previous");
      RightButtonText = "Submit";
      RedactionEditButton = { display: "none" };
      RedactionOutputButtonsStyles = { display: "none" };
      LeftButtonStyles = { width: "47%" };
      break;
    case "genaioutput":
      NextButtonDisable = true;
      RightButtonText = "Next";
      goToPreviousStep = () => handleButtonClick("previous");
      RedactionEditButton = { display: "none" };
      LeftButtonStyles = { width: "21%" };
      RedactionOutputButtonsStyles = {
        justifyContent: "flex-end",
        marginLeft: "216px",
      };
      break;
  }

  const Redacted_Output_export_As_Pdf = () => {
    if (editButtonActive) {
      Toast(
        "warn",
        "Text edit option is enabled, please save the changes to download the data."
      );
    } else {
      const element = document.getElementById("redactedText");
      const opt = {
        margin: 0.5,
        filename: "redactedText.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf().from(element).set(opt).save();
    }
  };

  const Ai_Output_export_As_Pdf = () => {
    let content = "";
    genaiOutput?.forEach(({ question, answer, id }) => {
      content += `
      <div>
        <h6>${id}.${question}</h6>
        <p>${answer}</p>
      </div>
      <hr />
      <br/>
    `;
    });
    const element = document.createElement("div");
    element.innerHTML = content;
    const opt = {
      margin: 0.5,
      filename: "AiOutput.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
  };

  const genai_Redaction_Output_HandleEdit = () => {
    setEditButtonActive(true);
    dispatch({ type: "SET_GENAI_REDACTION_OUTPUT_EDITING", payload: true });
  };

  return (
    <div className="genai_tabs">
      <GenAiHeaderButtons
        NextButtonDisable={NextButtonDisable}
        goToNextStep={goToNextStep}
        RightButtonText={RightButtonText}
        DontShowButton={DontShowButton}
        LeftButtonStyles={LeftButtonStyles}
        HeaderButtonsStyles={HeaderButtonsStyles}
        goToPreviousStep={goToPreviousStep}
        RedactionOutputButtonsStyles={RedactionOutputButtonsStyles}
        RedactionEditButton={RedactionEditButton}
        activeTab={activeTab}
        getRedactioOutputEventKey={getRedactioOutputEventKey}
        editButtonActive={editButtonActive}
        tabs={tabs}
        disablePrevious={disablePrevious}
        Redacted_Output_export_As_Pdf={Redacted_Output_export_As_Pdf}
        Ai_Output_export_As_Pdf={Ai_Output_export_As_Pdf}
        genai_Redaction_Output_HandleEdit={genai_Redaction_Output_HandleEdit}
      />
      <div className="genai_tabs_component">
        {tabs.map((tab) => (
          <div
            key={tab.key}
            className={`genai_tab-pane ${
              tab.key === activeTab ? "active" : ""
            }`}
          >
            {tab.key === activeTab && (
              <React.Fragment>
                {tab.key === "redaction_settings" && (
                  <GenAiSwitches
                    genaicheckedState={genaicheckedState}
                    setGenAiCheckedState={setGenAiCheckedState}
                  />
                )}
                {tab.key === "redaction_output" && (
                  <div className="genai_redaction_output_tab">
                    <GenAiRedactionOutPut
                      saveIcon={faSave}
                      cancelIcon={faXmark}
                      setEditButtonActive={setEditButtonActive}
                    />
                    {show && (
                      <GenAiAlertModal
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                        onCancel={() => backToGenAi()}
                        onProceed={() => handleButtonClick("next")}
                      />
                    )}
                  </div>
                )}
                {tab.key === "statement" && (
                  <div className="genai_statement_tab">
                    <div className="genai_statement_template_container">
                      <span className="heading">Select Template</span>
                      <hr />
                      <GenAiTemplates
                        options={genaiOutput?.template_response}
                        onSelectOption={handleOptionSelect}
                        setGenAiOptionChecked={setGenAiOptionChecked}
                      />
                    </div>
                    {genaiOptionChecked && (
                      <div className="genai_statement_description_container">
                        <span className="heading">Description</span>
                        <hr />
                        <GenAiTemplatesDescription />
                      </div>
                    )}
                  </div>
                )}
                {tab.key === "questions" && (
                  <div className="genai_questions_tab">
                    <GenAiQuestions
                      deleteIcon={faTrashCan}
                      addIcon={faPlus}
                      addButtonText="Add Questions"
                      setAddQuestionButtonActive={setAddQuestionButtonActive}
                    />
                  </div>
                )}
                {tab.key === "genaioutput" && (
                  <div className="genai_aioutput">
                    <GenAiAccordions displayData={genaiOutput} />
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        ))}
        {showConfirmation && (
          <ConfirmationDialog
            icon={faTriangleExclamation}
            confirmationMessage="Changes made will be lost."
            handleConfirmationNo={handleConfirmationNo}
            handleConfirmationYes={handleConfirmationYes}
            cancel="Cancel"
            confirm="Confirm"
          />
        )}
      </div>
    </div>
  );
};

export default GenaiTabs;
