import {
  faMoneyBillTrendUp,
  faGaugeSimpleHigh,
  faIdCardClip,
} from "@fortawesome/free-solid-svg-icons";

const options = [
  {
    id: Math.random(),
    path: "/",
    label: "Solution",
    icon: faIdCardClip,
    validateRoutes: [
      "/",
      "/faqs",
      "/profile",
      "/dashboard",
      "/extraction",
      "/bankStatements",
      "/generativeai",
      "/bankstatements",
      "/commercialRealEstate",
      "/redaction",
      "/recognition",
      "/classification",
      "/OutputScreen",
      "/predictiveAnalytics",
      "/predictiveAnalytics/PredictiveAnalyticsOutputScreen",
      "/finStatExtraction"
    ],
  },
  {
    id: Math.random(),
    path: "/serviceDashboard",
    label: "Service Dashboard",
    icon: faGaugeSimpleHigh,
    validateRoutes: [
      "/faqs",
      "/serviceDashboard",
      "/requestMonitoring",
      "/productInfo",
      "/customerSettings",
      "/processMetrics",
      "/systemLogs",
    ],
  },
  {
    id: Math.random(),
    path: "/billingMetrics",
    label: "Billing Dashboard",
    icon: faMoneyBillTrendUp,
    validateRoutes: [
      "/faqs",
      "/billingMetrics",
      "/billingInfo",
      "/clientOnboarding",
      "/processedFileInfo"
    ],
  },
];

export default options;
