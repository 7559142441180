import React, { memo } from "react";
import Chart from "react-apexcharts";
import colors from "../../InferIq-Solution/utils/graphColors";

const BillingBarChart = ({ response, showLegend }) => {
  const state = {
    series: response?.datasets?.map((dataset, index) => ({
      name: dataset?.name,
      data: dataset?.data,
    })),
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [...colors],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: response?.xaxis?.categories,
        title: {
          text: response?.yaxis?.title,
        },
        labels: {
          formatter: (val) => {
            return val?.toFixed(3) + "$";
          },
        },
        stepSize: 2,
      },
      yaxis: {
        min: response?.yaxis?.minValue,
        max: response?.yaxis?.maxValue,
        title: {
          text: response?.xaxis?.title,
        },
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return val?.toFixed(3) + "$";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: showLegend,
        position: "right",
        horizontalAlign: "left",
      },
    },
  };

  return (
    <Chart
      options={state?.options}
      series={state?.series}
      type="bar"
      height={350}
    />
  );
};

export default memo(BillingBarChart);
