import React, { useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { faRectangleList, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaFileUploader from "./PaFileUploader";
import PaForm from "./PaForm";
import ParentContext from "../../../context/context";

const PaTabViewer = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { paActiveTab } = state;

  const handleTabSelect = (selected) => {
    dispatch({ type: "SET_PA_ACTIVE_TAB", payload: selected });
  };
  return (
    <div className="pa-tabs-container">
      <Tabs
        id="justify-tab-example"
        className="mb-3"
        justify
        activeKey={paActiveTab}
        onSelect={handleTabSelect}
      >
        <Tab
          eventKey="Fill the Form"
          title={
            <span className="tab-title">
              <FontAwesomeIcon icon={faRectangleList} />
              Fill The Form
            </span>
          }
        >
          <div
            style={{
              border: "2px solid transperent",
              borderRadius: "5px",
              padding: "0 10px",
            }}
          >
            <PaForm />
          </div>
        </Tab>
        <Tab
          eventKey="Upload file"
          title={
            <span className="tab-title">
              <FontAwesomeIcon icon={faUpload} />
              Upload File
            </span>
          }
          disabled
        >
          <PaFileUploader />
        </Tab>
      </Tabs>
    </div>
  );
};

export default PaTabViewer;
