import React from "react";
import { CustomButton } from "../../../InferIq-Solution/components";
import {
  FontAwesomeIcon,
  faFloppyDisk,
  faXmark,
  faHandHoldingDollar,
  faDollarSign,
} from "../../imports/FontAwesomeIcon";

const BillingInfoUpdateOptions = (props) => {
  return (
    <div className="billing-info-update-options">
      <div className=" billing-info-update-options-heading heading">
        <span>Billing Updation</span>
      </div>
      <hr />
      <div className="billing-info-update-options-inputs">
        {props.BillingInfoInputs.map((items) => (
          <div key={items.id}>
            <label>
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faHandHoldingDollar}
              />
              {items.label}
            </label>
            <span className="billing-info-update-options-input">
              <FontAwesomeIcon icon={faDollarSign} />
              <input
                type="number"
                value={items.value}
                onChange={(e) =>
                  props.handleInputChange(
                    `${items.tag}`,
                    parseFloat(e.target.value)
                  )
                }
              />
            </span>
          </div>
        ))}
      </div>
      <hr />
      <div className="billing-info-update-options-controls">
        <CustomButton
          variant="success"
          size="sm"
          onClick={props.handleSaveClick}
        >
          <FontAwesomeIcon icon={faFloppyDisk} />
          Save
        </CustomButton>
        <span style={{ marginLeft: "10px" }}>
          <CustomButton
            variant="danger"
            size="sm"
            onClick={props.handleSaveClick}
          >
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faXmark} />
            Cancel
          </CustomButton>
        </span>
      </div>
    </div>
  );
};

export default BillingInfoUpdateOptions;
