import React, { useContext, memo } from "react";
import { ProcessCard, CreHistoryHeader } from "./index";
import ParentContext from "../../../context/context";

const CreHistory = () => {
  const { state } = useContext(ParentContext);
  const { creHistoryResponse } = state;
  return (
    <div className="cre-history-container">
      <CreHistoryHeader />
      {creHistoryResponse
        ? creHistoryResponse?.map((data, index) => {
            return (
              <span key={index}>
                <ProcessCard
                  sNo={index + 1}
                  fileName={data.file_name}
                  dateAndTime={data.document_upload_datetime}
                  status={data.file_status}
                  llmType={data.llmtype}
                  request_Id={data.request_Id}
                />
              </span>
            );
          })
        : null}
    </div>
  );
};

export default memo(CreHistory);
