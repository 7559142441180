import React, { Fragment, useState } from "react";
import "./ReqMCard.css";
import Badge from "react-bootstrap/Badge";
import ReqMModal from "../ReqMModal/ReqMModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ReqMCard = ({ data, ParentrequestId,phaseName,childId }) => {
  const [showInfo, SetShowInfo] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  const handleShowInfo = (event) => {
    event.preventDefault()
    SetShowInfo(true);
  };
  const handleCloseInfo = () => SetShowInfo(false);

  let status = data?.status;
  let ConvertedValue;
  if (status === "true" || status === "false") {
    ConvertedValue = status === "true";
  } else {
    ConvertedValue = status;
  }

  const cardStyle = {
    borderColor: ConvertedValue
      ? "#1e7e34"
      : ConvertedValue === ""
      ? "gray"
      : "#dc3545",
    backgroundColor: ConvertedValue
      ? "rgb(165 217 177 / 35%)"
      : ConvertedValue === ""
      ? "#c5c6d0"
      : "rgb(227 148 155 / 35%)",
    color: ConvertedValue === "" ? "#ffff" : "#102447",
    position: "relative",
  };

  return (
    <Fragment>
      <div
        className="rm-card"
        style={cardStyle}
        onMouseOver={() => setShowDescription(true)}
        onMouseOut={() => setShowDescription(false)}
      >
        <div className="rm-card-header para">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip> {data?.stage_name}</Tooltip>}
          >
            <div className="rm-card-header-title">{data?.stage_name}</div>
          </OverlayTrigger>
          {ConvertedValue !== true && ConvertedValue !== "" && (
            <div style={{ marginLeft: "10px" }} className="para">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Click to get info</Tooltip>}
              >
                <Badge pill bg="info" onClick={handleShowInfo}>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </Badge>
              </OverlayTrigger>
            </div>
          )}
        </div>
        {showDescription && ConvertedValue !== "" && (
          <div className="rm-desc-card para" style={{ position: "absolute" }}>
            <span className="rm-desc-card-title">Description</span>
            <hr />
            <span className="rm-desc-card-body">{data?.description}</span>
          </div>
        )}
      </div>
      {showInfo && (
        <ReqMModal
          showInfo={showInfo}
          handleCloseInfo={handleCloseInfo}
          ParentrequestId={ParentrequestId}
          stageName={data?.stage_name}
          phaseName={phaseName}
          childId={childId}
        />
      )}
    </Fragment>
  );
};

export default ReqMCard;
