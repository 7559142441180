import {
  faFolderTree,
  faChartLine,
  faFolderOpen,
  faFilePen,
  faFileInvoice,
  faCommentDots,
  faGears,
  faTarpDroplet,
  faMoneyBillWave,
  faComputer,
  faSliders,
  faUsersGear,
  faMicrochip,
  faFileInvoiceDollar,
  faGaugeHigh,
  faFileContract,
  faNetworkWired,
  faChartPie,
  faBuildingColumns,
} from "@fortawesome/free-solid-svg-icons";

const SideBarRoutes = [
  {
    id: Math.random(),
    label: "Dashboard",
    path: "/dashboard",
    icon: faChartLine,
    disabled: true,
  },
  {
    id: Math.random(),
    label: "Extraction",
    path: "/extraction",
    icon: faFolderTree,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Bank Statements",
    path: "/bankStatements",
    icon: faBuildingColumns,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Conversational AI",
    path: "/generativeai",
    icon: faCommentDots,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Rent Roll",
    path: "/commercialRealEstate",
    icon: faTarpDroplet,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Predictive Analytics",
    path: "/predictiveAnalytics",
    icon: faChartPie,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Redaction",
    path: "/redaction",
    icon: faFilePen,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Recognition",
    path: "/recognition",
    icon: faFileInvoice,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Classification",
    path: "/classification",
    icon: faFolderOpen,
    disabled: false,
  },
  // {
  //   id: 8,
  //   label: "Setting",
  //   path: "/setting",
  //   icon: faGears,
  //   disabled: true,
  // },
];

const serviceDashboardRoutes = [
  {
    id: Math.random(),
    label: "Dashboard",
    path: "/serviceDashboard",
    icon: faGaugeHigh,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Request Monitoring",
    path: "/requestMonitoring",
    icon: faNetworkWired,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Product Info",
    path: "/productInfo",
    icon: faFileContract,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Customer Settings",
    path: "/customerSettings",
    icon: faSliders,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Process Metrics",
    path: "/processMetrics",
    icon: faMicrochip,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "System Logs",
    path: "/systemLogs",
    icon: faComputer,
    disabled: false,
  },
];

const billingDashboardRoutes = [
  {
    id: Math.random(),
    label: "Billing Metrics",
    path: "/billingMetrics",
    icon: faMoneyBillWave,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Billing Info",
    path: "/billingInfo",
    icon: faFileInvoiceDollar,
    disabled: false,
  },
  {
    id: Math.random(),
    label: "Client Onboarding",
    path: "/clientOnboarding",
    icon: faFileInvoiceDollar,
    disabled: false,
  },
];

export { SideBarRoutes, serviceDashboardRoutes, billingDashboardRoutes };
