import React, { useState, useEffect, useRef } from "react";
import { CustomButton } from "../../../InferIq-Solution/components";
import {
  FontAwesomeIcon,
  faBars,
  faDollarSign,
  faFilePen,
  faHandHoldingDollar,
} from "../../imports/FontAwesomeIcon";
import { useToasts } from "../../../InferIq-Solution/Hooks";

const BillingInfoDisplay = (props) => {
  const { Toast } = useToasts();
  const [showToggels, setShowToggels] = useState(false);
  const modalRef = useRef();
  const handleAlert = () => {
    Toast("info", "Please click on the update button to change the pricing");
  };
  const handleShowToggel = () => setShowToggels(true);
  const handleCloseToggel = () => setShowToggels(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showToggels &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        handleCloseToggel();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showToggels]);

  let DownloadOptions = [
    {
      id: 1,
      label: "Download as Pdf",
    },
    {
      id: 2,
      label: "Download as Xlsx",
    },
  ];

  return (
    <div className="billing-info-display">
      <div className="billing-info-display-heading">
        <span className="heading">Billing Information</span>
        <div>
          <CustomButton
            varient="secondary"
            size="sm"
            onClick={handleShowToggel}
            disabled={false}
          >
            <FontAwesomeIcon icon={faBars} />
          </CustomButton>
          {showToggels && (
            <div className="toggel-download-options" ref={modalRef}>
              {DownloadOptions.map((item) => (
                <span
                  className="para toggel-download-options-val"
                  key={item.id}
                >
                  {item.label}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="billing-info-display-values">
        {props.BillingInfoValues.map((item) => (
          <div className="" key={item.id}>
            <span className="billing-info-display-values-title">
              <FontAwesomeIcon icon={faHandHoldingDollar} />
              {item.label} :
            </span>
            <span
              className="billing-info-display-values-cost"
              onClick={handleAlert}
            >
              <FontAwesomeIcon icon={faDollarSign} />
              {item.value}
            </span>
          </div>
        ))}
      </div>
      <hr />
      <div className="billing-info-display-controls">
        <CustomButton
          variant="primary"
          size="sm"
          onClick={props.handleEditClick}
        >
          <FontAwesomeIcon icon={faFilePen} />
          Update
        </CustomButton>
      </div>
    </div>
  );
};
export default BillingInfoDisplay;
