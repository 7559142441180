import React from "react";
import "./ConfirmationDialog.css";
import { CustomButton } from "../index";
import { FontAwesomeIcon, faXmark } from "../../import/FontAwesomeIcons";

const ConfirmationDialogHeader = (props) => {
  return (
    <div className="confirmation-dialog-text">
      <FontAwesomeIcon
        style={{
          color: "rgb(226, 171, 52)",
          marginRight: "10px",
        }}
        icon={props.icon}
      />
      <p className="para">{props.confirmationMessage}</p>
      <FontAwesomeIcon
        style={{
          color: "rgb(71, 70, 66)",
          marginLeft: "10px",
        }}
        onClick={props.handleConfirmationNo}
        icon={faXmark}
      />
    </div>
  );
};

const ConfirmationDialogFooter = (props) => {
  return (
    <div className="confirmation-dialog-buttons">
      <CustomButton
        size="sm"
        variant="secondary"
        onClick={props.handleConfirmationNo}
      >
        {props.cancel}
      </CustomButton>
      <span
        style={{
          marginLeft: "10px",
        }}
      >
        <CustomButton
          size="sm"
          variant="primary"
          onClick={props.handleConfirmationYes}
        >
          {props.confirm}
        </CustomButton>
      </span>
    </div>
  );
};

const ConfirmationDialog = (props) => {
  const {
    icon,
    confirmationMessage,
    handleConfirmationNo,
    handleConfirmationYes,
    cancel,
    confirm,
  } = props;
  return (
    <div className="confirmation-dialog">
      <ConfirmationDialogHeader
        icon={icon}
        confirmationMessage={confirmationMessage}
        handleConfirmationNo={handleConfirmationNo}
      />
      <ConfirmationDialogFooter
        handleConfirmationNo={handleConfirmationNo}
        handleConfirmationYes={handleConfirmationYes}
        cancel={cancel}
        confirm={confirm}
      />
    </div>
  );
};

export default ConfirmationDialog;
