import React from "react";

const GenAiHistoryTabelHeader = () => {
  return (
    <div className="genai-history-table-header">
      <span className="genai-history-table-header-sno">S No</span>
      <span className="genai-history-table-header-filename">File Name</span>
      <span className="genai-history-table-header-dateAndTime">Date & Time</span>
      <span className="genai-history-table-header-llm_type">Llm Type</span>
      <span className="genai-history-table-header-status">Status</span>
      <span className="genai-history-table-header-download">Download</span>
    </div>
  );
};

export default GenAiHistoryTabelHeader;
