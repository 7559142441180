import React, { useContext, useState, useEffect } from "react";
import { CustomButton, Table } from "../../../../components/index";
import {
  FontAwesomeIcon,
  faFileLines,
} from "../../../../import/FontAwesomeIcons";
import "./GenAiRedactionOutPut.css";
import ParentContext from "../../../../context/context";
import { Tabs, Tab } from "react-bootstrap";
import { useRenderLine, useToasts } from "../../../../Hooks/index";

const GenAiRedactionOutPut = ({
  saveIcon,
  cancelIcon,
  setEditButtonActive,
}) => {
  const { state, dispatch } = useContext(ParentContext);
  const {
    genaiOutput,
    genaiRedactionOutputEditing,
    genaiRedactionOutputEditedText,
  } = state;

  const [redactedtext, setRedactedText] = useState(
    genaiOutput?.redact_response
  );

  const [currentTab, setCurrentTab] = useState(
    genaiOutput?.redact_table_response?.[0]?.TabTitle
  );

  const { renderTextInNewLines } = useRenderLine();
  const { Toast } = useToasts();

  const handleSave = () => {
    if (genaiRedactionOutputEditedText === "") {
      Toast("warn", "There is no data to process.");
    } else {
      Toast("success", "Changes Saved");
      setRedactedText(genaiRedactionOutputEditedText);
      dispatch({
        type: "SET_GENAI_REDACTION_OUTPUT_EDITING",
        payload: false,
      });
      setEditButtonActive(false);
    }
  };

  useEffect(() => {
    setRedactedText(genaiOutput?.redact_response);
    dispatch({
      type: "SET_GENAI_REDACTION_OUTPUT_EDITED_TEXT",
      payload: genaiOutput?.redact_response,
    });
  }, []);

  const handleCancel = () => {
    dispatch({
      type: "SET_GENAI_REDACTION_OUTPUT_EDITED_TEXT",
      payload: redactedtext,
    });
    dispatch({ type: "SET_GENAI_REDACTION_OUTPUT_EDITING", payload: false });
    setEditButtonActive(false);
  };

  const handleChange = (event) => {
    dispatch({
      type: "SET_GENAI_REDACTION_OUTPUT_EDITED_TEXT",
      payload: event.target.value,
    });
  };

  let all_tabs = [];
  if (genaiOutput?.redact_table_response !== "") {
    genaiOutput?.redact_table_response?.map((blocks) => {
      all_tabs.push(blocks.TabTitle);
    });
  }
  all_tabs = [...new Set(all_tabs)];

  const kvpTdData = () => {
    return genaiOutput?.redact_table_response
      ?.filter((block) => {
        return currentTab === block.TabTitle && block.BlockType === "kvp";
      })
      .map((block, index) => {
        return (
          <tr key={index}>
            <td>{block.Key}</td>
            <td>{block.Value}</td>
          </tr>
        );
      });
  };

  let tableTdData;
  if (genaiOutput?.redact_table_response !== "") {
    tableTdData = genaiOutput?.redact_table_response?.filter((block) => {
      return currentTab === block.TabTitle && block.BlockType === "table";
    });
  }
  let tableThData = [];
  let tableTrData = [];

  if (tableTdData) {
    tableTdData?.forEach((block) => {
      if (!tableThData.includes(block.Key)) {
        tableThData.push(block.Key);
      }

      if (block.Value !== "[]") {
        tableTrData.push(block.Value);
      }
    });
  }

  return (
    <React.Fragment>
      {!genaiRedactionOutputEditing && (
        <div className="genai_redaction_output">
          <Tabs
            defaultActiveKey="raw_text"
            id="justify-tab-example"
            className="mb-3"
            justify
            onSelect={(tab) => {
              setCurrentTab(tab);
              dispatch({
                type: "SET_GET_REDACTION_OUTPUT_EVENT_KEY",
                payload: tab,
              });
            }}
          >
            <Tab
              eventKey="raw_text"
              title={
                <span className="tab-title">
                  <FontAwesomeIcon icon={faFileLines} />
                  Raw Text
                </span>
              }
            >
              <div className="genai_redaction_output_body">
                <p className="para" id="redactedText">
                  {redactedtext === "" ? (
                    <span className="redaction_output_fallback ">No text</span>
                  ) : (
                    renderTextInNewLines(redactedtext)
                  )}
                </p>
              </div>
            </Tab>
            {all_tabs?.map((tab, index) => {
              return (
                <Tab key={index} eventKey={`${tab}`} title={tab}>
                  <div className="Extraction">
                    <div className="Extraction-tables">
                      <Table
                        kvpTdData={kvpTdData}
                        tableThData={tableThData}
                        tableTrData={tableTrData}
                      />
                    </div>
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        </div>
      )}
      {genaiRedactionOutputEditing && (
        <React.Fragment>
          <textarea
            value={genaiRedactionOutputEditedText}
            className="genai_redaction_textarea para"
            onChange={handleChange}
          />
          <CustomButton size="sm" variant="success" onClick={handleSave}>
            <FontAwesomeIcon icon={saveIcon} />
            <span style={{ marginLeft: "4px" }}>Save</span>
          </CustomButton>
          <span style={{ marginLeft: "8px" }}>
            <CustomButton size="sm" variant="warning" onClick={handleCancel}>
              <FontAwesomeIcon icon={cancelIcon} />
              <span style={{ marginLeft: "4px" }}>Cancel</span>
            </CustomButton>
          </span>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default GenAiRedactionOutPut;
