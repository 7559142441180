import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { RedactionCheckBox } from "../../../components";

const RedactionSelectFields = (props) => {
  return (
    <div className="Redaction-select-fields">
      <Tabs
        defaultActiveKey="Select fields to Redact"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="Select fields to Redact" title="Select fields to Redact">
          <div
            data-testid="Redaction_table_maincontainer"
            className="Redaction_table_switch_checkfields_maincontainer"
          >
            {props.fields?.map((value, index) => {
              return (
                <div key={index} className="Redaction_table_switch_checkfields">
                  <RedactionCheckBox
                    name={value}
                    label={value}
                    checked={props.checkedState[index]}
                    onChange={() => props.CheckboxChangeHandler(index)}
                  />
                </div>
              );
            })}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default RedactionSelectFields;
