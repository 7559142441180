import React, { useCallback, useContext } from "react";
import { Radio } from "../../../components";
import ParentContext from "../../../context/context";

const PaOptions = (props) => {
  const { state, dispatch } = useContext(ParentContext);
  const { selectedValue } = state;
  const RadioHandler = useCallback(
    (e) => {
      dispatch({ type: "SET_SEL_VALUE", payload: e.target.value });
    },
    [dispatch]
  );
  const findObjectInArray = (array) => {
    return array.filter((item) => typeof item === "object" && item !== null);
  };

  let RadioOptions = findObjectInArray(
    props.PredictiveAnalyticsRadioOptions.flat()
  );

  return (
    <div className="pa-options">
      {RadioOptions?.map(({ id, label, value, group }) => (
        <span className="radio-wrapper" key={id}>
          <Radio
            label={label}
            value={value}
            group={group}
            Checked={selectedValue === value}
            onChange={RadioHandler}
          />
        </span>
      ))}
    </div>
  );
};

export default PaOptions;
