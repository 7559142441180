import React, { memo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartsFallBackUi from "../ChartsFallBackUi/ChartsFallBackUi";
import colors from "../../../../utils/graphColors";
ChartJS.register(ArcElement, Tooltip, Legend, Title);

export const options = {
  responsive: true,
  elements: {
    center: {
      legend: { display: true, position: "right" },
      text: "Red is 2/3 the total numbers",
      color: "#FF6384",
      fontStyle: "Arial",
      sidePadding: 20,
      minFontSize: 20,
      lineHeight: 25,
    },
  },
  plugins: {
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const DonutChart = (props) => {
  const { donutChartResponse } = props;

  if (!donutChartResponse || !donutChartResponse?.datasets) {
    return <ChartsFallBackUi />;
  }

  const dataWithStyles = {
    ...donutChartResponse,
    datasets: [
      {
        ...donutChartResponse.datasets[0],
        backgroundColor: colors.slice(0, donutChartResponse.labels.length),
        borderWidth:2,
        borderColor:"#ccc"
      },
    ],
  };

  return <Doughnut data={dataWithStyles} options={options} />;
};

export default memo(DonutChart);
