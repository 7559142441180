import React, { useContext, useState } from "react";
import ParentContext from "../../context/context";
import "./BankStatementDropdown.css";
import { FontAwesomeIcon, faAngleDown } from "../../import/FontAwesomeIcons";

const BankStatementDropdown = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { backendResponse, currentPage } = state;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChangePage = (page) => {
    dispatch({ type: "SET_CURRENT_PAGE", payload: page });
    dispatch({ type: "SET_BANKSTATEMENT_ACTIVE_TAB", payload: 0 });
    closeDropdown();
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevOpen) => !prevOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const dropdownOptions = Object.keys(backendResponse?.Data ?? {})
    .filter((page) => page !== currentPage)
    .map((page) => (
      <div
        key={page}
        className="cdropdown-option para"
        onClick={() => handleChangePage(page)}
      >
        {page}
      </div>
    ));

  return (
    <div className="custom-dropdown">
      <div
        className={`cdropdown-header para ${dropdownOpen ? "open" : ""}`}
        onClick={toggleDropdown}
      >
        {currentPage} <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {dropdownOpen && (
        <div className="cdropdown-options">{dropdownOptions}</div>
      )}
    </div>
  );
};

export default BankStatementDropdown;
