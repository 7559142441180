import React, { useEffect, useState, memo } from "react";
import { InputBox, CustomButton } from "../../../../components/index";
import {
  FontAwesomeIcon,
  faPencil,
  faSave,
} from "../../../../import/FontAwesomeIcons";

const EditableTable = ({ headers, values }) => {
  const [data, setData] = useState(values);

  useEffect(() => {
    const storedData = sessionStorage.getItem("tableData");
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);

  const handleEdit = (id) => {
    const updatedData = data.map((row) => {
      if (row.id === id) {
        return { ...row, edit: true };
      }
      return row;
    });
    setData(updatedData);
  };

  const handleSave = (id) => {
    const updatedData = data.map((row) => {
      if (row.id === id) {
        return { ...row, edit: false };
      }
      return row;
    });
    setData(updatedData);
    sessionStorage.setItem("tableData", JSON.stringify(updatedData));
  };

  const handleChange = (id, field, value) => {
    const updatedData = data.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setData(updatedData);
  };

  return (
    <table>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id}>
            <td>{row.id}</td>
            <td>
              {row.edit ? (
                <InputBox
                  size="sm"
                  type="text"
                  value={row.name}
                  onChange={(e) => handleChange(row.id, "name", e.target.value)}
                />
              ) : (
                row.name
              )}
            </td>
            <td>
              {row.edit ? (
                <InputBox
                  size="sm"
                  type="text"
                  value={row.roll}
                  onChange={(e) => handleChange(row.id, "roll", e.target.value)}
                />
              ) : (
                row.roll
              )}
            </td>
            <td>
              {row.edit ? (
                <InputBox
                  size="sm"
                  type="text"
                  value={row.created}
                  onChange={(e) =>
                    handleChange(row.id, "created", e.target.value)
                  }
                />
              ) : (
                row.created
              )}
            </td>
            <td>
              {row.edit ? (
                <InputBox
                  size="sm"
                  type="text"
                  value={row.Status}
                  onChange={(e) =>
                    handleChange(row.id, "Status", e.target.value)
                  }
                />
              ) : (
                row.Status
              )}
            </td>

            <td>
              {row.edit ? (
                <CustomButton
                  size="sm"
                  variant="success"
                  onClick={() => handleSave(row.id)}
                  // disabled={true}
                >
                  <FontAwesomeIcon icon={faSave} />
                  <span>save</span>
                </CustomButton>
              ) : (
                <CustomButton
                  size="sm"
                  variant="info"
                  onClick={() => handleEdit(row.id)}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </CustomButton>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default memo(EditableTable);
