import React, { Fragment, useContext } from "react";
import CustomButton from "../../../components/Button/CustomButton";
import Input from "../../../components/Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import "./SignInForm.css";
import SignInInputData from "../utils/SignInInputData";
import ParentContext from "../../../context/context";
import { Link } from "react-router-dom";

const SignInForm = ({
  handleSubmit,
  formError,
  handleInputChange,
  showSignUp,
  showForgotPassword,
  showPassword,
  handleShowPassword,
  signInFormData,
  showSignUpVerificationForm,
  handleTermsAndConditions,
}) => {
  const { state } = useContext(ParentContext);
  const { termsAndConditions } = state;
  const { loading } = state;

  return (
    <Fragment>
      <form onSubmit={handleSubmit} className="signin_form_container">
        <div className="signin_form_input">
          {SignInInputData.map((item) => {
            const { id, label, type, name, placeholder, required } = item;
            return (
              <Fragment key={id}>
                <Input
                  label={label}
                  type={showPassword ? "text" : type}
                  name={name}
                  value={signInFormData.name}
                  placeholder={placeholder}
                  handleChange={handleInputChange}
                  required={required}
                />
              </Fragment>
            );
          })}
        </div>
        <div className="signin_form_btn_cont">
          <div className="signin_form_btn_redirect">
            <div className="signin_form_show_pass">
              <input
                type="checkbox"
                checked={showPassword}
                onChange={handleShowPassword}
              />
              <div style={{ marginLeft: "5px" }}>Show Password</div>
            </div>
            {/* <span className="signin_form_req_demo" onClick={showSignUp}>
              Register for InferlQ Demo
            </span> */}
          </div>
          <CustomButton variant="primary" size="md" type="submit">
            {!loading && (
              <FontAwesomeIcon
                style={{ marginRight: "10px" }}
                icon={faArrowRightToBracket}
              />
            )}
            {loading ? (
              <span
                className="para"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Please wait while we are signing you in...
              </span>
            ) : (
              "Sign In"
            )}
          </CustomButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="signin_form_show_pass"
              style={{ marginTop: "15px" }}
            >
              <input
                type="checkbox"
                checked={termsAndConditions}
                onChange={handleTermsAndConditions}
              />
              <div style={{ marginLeft: "5px" }}>
                <Link to="/termsAndConditions" target="_blank">
                  I accept the terms and Conditions.
                </Link>
              </div>
            </div>
            <span
              className="signin_form_forget_pass"
              onClick={showForgotPassword}
            >
              Forgot password
            </span>
            {/* <span
              className="signin_form_forget_pass"
              onClick={showSignUpVerificationForm}
            >
              Verifiy Account
            </span> */}
          </div>
        </div>
        {formError && <p className="signin_form_error">{formError}</p>}
      </form>
    </Fragment>
  );
};

export default SignInForm;
