import { Modal } from "react-bootstrap";
import "./EditTransactionModal.css";
import CustomButton from "../Button/CustomButton";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditTransactionModal = (props) => {
  const { transactionData, show, setShow } = props;
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="bsEditModal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Modify Transaction Data
          <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bsEditModalForm">
          <div className="wrap">
            {Object.entries(transactionData).map(([key, value]) => {
              return (
                <div key={key} className="editField">
                  <label>{key.replace("_", " ")}</label>
                  <input type="text" value={value} />
                </div>
              );
            })}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton size="sm" variant="danger" onClick={() => setShow(false)}>
          Remove
        </CustomButton>
        <CustomButton size="sm" variant="success">
          Save
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTransactionModal;
