import React, { useState, useContext, useRef } from "react";
import "./PdfViewer.css";
import { Document, Page, pdfjs } from "react-pdf";
import ParentContext from "../../context/context";
import "../Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

const PdfLoader = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div className="container-loader3">
      <div className="loading">
        <div className="ball one"></div>
        <div className="ball two"></div>
        <div className="ball three"></div>
        <div className="ball four"></div>
      </div>
    </div>
  );
};

const PdfViewer = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [isLoading, setIsLoading] = useState(true);
  const rowRefs = useRef([]);

  const { state, dispatch } = useContext(ParentContext);

  const { pageNumber, scale, rotate } = state;
  const on_Document_Load_Success = ({ numPages }) => {
    dispatch({ type: "SET_NUM_PAGES", payload: numPages });
    setIsLoading(false);
  };

  const { pdf, onRowClick, searchRow, setSearchRow, serviceType, backendResponse } = props;

  const [pageSizes, setPageSizes] = useState({});

  // Capture the page sizes for each page after they are rendered
  const onPageLoadSuccess = (page, pageNumber) => {
    const { width, height } = page.getViewport({ scale: 1 });
    setPageSizes((prev) => ({
      ...prev,
      [pageNumber]: { width, height },
    }));
  };

  // Map/Get all the account transaction's textract ID
  const accNumber = backendResponse && Object.keys(backendResponse?.data[0])
  const cellTextractID = accNumber && accNumber?.map(accNo => {
    return backendResponse?.data[0][accNo]?.map(cell => cell?.TEXTRACT_ID)
  })

  // Filter all cell cordinates having cellTexttractID
  const highlights = backendResponse?.cellHighlight.filter((cell) => {return cellTextractID[0]?.includes(cell?.textractId)});

  // To the above highlights, add confidence and textractID from account transaction details
  const highLightWithConfidence = accNumber?.map(accNo => {
    return backendResponse?.data[0][accNo]?.map(
        (cell, index) => {
          return {
            ...highlights[index],
            confidence: cell?.CONFIDENCE,
            textractId: cell?.TEXTRACT_ID,
          };
        }
      );
  })

  return (
    <div className="PdfViewer">
      <PdfLoader isLoading={isLoading} />
      <Document
        file={pdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={on_Document_Load_Success}
      >
        <div style={{ position: "relative" }}>
          <Page
            size="A4"
            pageNumber={pageNumber}
            scale={scale}
            rotate={rotate}
            onLoadSuccess={(page) => onPageLoadSuccess(page, pageNumber)}
          />

          {serviceType === 'Bank Statement' && highLightWithConfidence[0] &&
            highLightWithConfidence[0]
              .filter((hl) => parseInt(hl.pageNo, 10) === pageNumber)
              .map((hl, index) => {
                const { Width, Height, Left, Top } = hl.boundingBox;
                const confidence = hl.confidence * 100;
                const textractID = hl.textractId;
                const pageSize = pageSizes[pageNumber];

                // Calculate actual position on the page
                const top = Top * pageSize?.height + 10 || 0;
                const left = Left * pageSize?.width || 0;
                const width = Width * pageSize?.width || 0;
                const height = Height * pageSize?.height || 0;

                return (
                  <div
                    key={index}
                    style={{
                      top: `${top}px`,
                      left: `${left}px`,
                      width: `${width}px`,
                      height: `${height}px`,
                      backgroundColor:
                        confidence >= 70
                          ? "rgb(103 220 111 / 40%)"
                          : "rgb(233 39 39 / 40%)", // Highlight BG color
                      border:
                        confidence >= 70
                          ? "1px solid #4ba66b"
                          : "1px solid #dc4e4e", // Highlight border
                    }}
                    onClick={(el) => {
                      onRowClick(textractID);
                      setSearchRow(textractID);
                      rowRefs.current[textractID] = el
                    }}
                    className="cellHightlight"
                  >
                    {searchRow === textractID && (
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="highlightArrow"
                      />
                    )}
                  </div>
                );
              })}
        </div>
      </Document>
    </div>
  );
};

export default PdfViewer;
