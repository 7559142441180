import React from "react";
import "./ErrorModal.css";
import { Modal } from "react-bootstrap";
import { CustomButton } from "../index";

const ErrorModal = (props) => {
  const {
    show,
    onHide,
    centered,
    keyboard,
    icon,
    onClick,
    title,
    descriptionHeading,
    descriptionText,
    buttonText,
  } = props;

  sessionStorage.setItem("requestId", "");

  return (
    <Modal
      show={show}
      size="md"
      backdrop="static"
      keyboard={keyboard}
      onHide={onHide}
      centered={centered}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="ErrorBoundaryHeader">
            {icon}
            <p>{title}</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ErrorBoundaryBody">
          <p>
            <span>{descriptionHeading}</span>
            <span>{descriptionText}</span>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton variant="primary" size="md" onClick={onClick}>
          {buttonText}
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
