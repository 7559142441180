import React, { Fragment, useState } from "react";
import {
  FontAwesomeIcon,
  faCaretDown,
  faClipboard,
  faCopy,
} from "../../../import/FontAwesomeIcons";
import { OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import ReqMFlowChart from "../ReqMFlowChart/ReqMFlowChart";
import ReqMSubCardBody from "../ReqMSubCardTable/ReqMSubCardBody";
import ReqMSubCardHeader from "../ReqMSubCardTable/ReqMSubCardHeader";

const ReqMTableBody = (props) => {
  const [openCardIndex, setOpenCardIndex] = useState(null);
  const [buttonStates, setButtonStates] = useState(
    new Array(props?.stages_data?.length)?.fill(
      <FontAwesomeIcon icon={faClipboard} />
    )
  );

  const handleSubCardHeaderClick = (index) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const copyToClipboard = (requestId, index) => {
    navigator.clipboard.writeText(requestId).then(() => {
      const newButtonStates = [...buttonStates];
      newButtonStates[index] = <FontAwesomeIcon icon={faCopy} />;
      setButtonStates(newButtonStates);
      setTimeout(() => {
        const resetButtonStates = [...buttonStates];
        resetButtonStates[index] = <FontAwesomeIcon icon={faClipboard} />;
        setButtonStates(resetButtonStates);
      }, 2000);
    });
  };

  return (
    <Fragment>
      {props.stages_data?.map((item, index) => {
        let getPhasesData = props?.stages_data?.map((item) => item?.phases)[
          index
        ];
        let stages = getPhasesData?.map((item) => item?.stages);
        let status = item.status === "true";
        return (
          <div
            key={item.request_id}
            className={`rm-table-cards  ${
              props.toggelCard[index] ? "open" : ""
            } `}
          >
            <div
              className={`rm-table-card`}
              onClick={() => props.openCard(index)}
            >
              <span className="para rm-table-header-sno">
                {index + 1 + (props.currentPage - 1) * props.itemPerPage}
              </span>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip> {item.service_name}</Tooltip>}
              >
                <span className="para rm-table-header-service">
                  {item.service_name.slice(0, 10)}...
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {item.file_type ? item.file_type : "Not recived"}
                  </Tooltip>
                }
              >
                <span className="para rm-table-header-form-type">
                  {item.file_type ? item.file_type.slice(0, 10) : "Not recived"}
                  ...
                </span>
              </OverlayTrigger>
              <span className="para rm-table-header-file-upload-time">
                {item.file_upload_time}
              </span>
              <span
                className="para rm-table-header-req-id"
                onMouseOver={() => {
                  document.getElementById(
                    `copyButton_${index}`
                  ).style.visibility = "visible";
                }}
                onMouseOut={() => {
                  document.getElementById(
                    `copyButton_${index}`
                  ).style.visibility = "hidden";
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(item.request_id, index);
                }}
              >
                {item.request_id}
                <span
                  id={`copyButton_${index}`}
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                    visibility: "hidden",
                  }}
                >
                  {buttonStates[index]}
                </span>
              </span>
              <span className="para rm-table-header-status">
                <Badge pill bg={status ? "success" : "danger"}>
                  {status ? "success" : "failed"}
                </Badge>
              </span>
              {getPhasesData && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Toogel</Tooltip>}
                >
                  <span className="rm-table-header-toggle">
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </OverlayTrigger>
              )}
            </div>
            {props.toggelCard[index] && getPhasesData && (
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  marginTop: "5px",
                }}
              >
                <hr />
                <Fragment>
                  <ReqMSubCardHeader />
                  {stages?.map((substage, stageIndex) => {
                    const filteredData = getPhasesData.filter(
                      (item) => item.file_status === "false"
                    );
                    let getPhaseName = filteredData?.map((item) => {
                      return item?.phase_name;
                    });
                    let getChildId = filteredData?.map((item) => {
                      return item?.child_id;
                    });
                    return (
                      <div
                        key={substage[0]?.stage_id}
                        className="rm-sub-card-container"
                      >
                        {getPhasesData?.map((item, phaseIndex) => (
                          <div key={item.index}>
                            {stageIndex === phaseIndex && (
                              <Fragment>
                                <ReqMSubCardBody
                                  parentReqId={item.request_id}
                                  substageLength={substage?.length}
                                  phaseIndex={phaseIndex}
                                  sNo={phaseIndex + 1}
                                  phaseName={item.phase_name}
                                  fileName={item.file_name}
                                  processingStartTime={
                                    item.processing_start_time
                                  }
                                  noOfPages={item.no_of_pages}
                                  fileStatus={item.file_status}
                                  childId={item.child_id}
                                  clickToOpen={() =>
                                    handleSubCardHeaderClick(
                                      phaseIndex === stageIndex
                                        ? stageIndex
                                        : null
                                    )
                                  }
                                />
                              </Fragment>
                            )}
                          </div>
                        ))}
                        {openCardIndex === stageIndex && (
                          <Fragment>
                            <hr />
                            <ReqMFlowChart
                              flowChartData={substage}
                              ParentrequestId={item?.request_id}
                              phaseName={getPhaseName[0]}
                              childId={getChildId[0]}
                            />
                          </Fragment>
                        )}
                      </div>
                    );
                  })}
                </Fragment>
              </div>
            )}
          </div>
        );
      })}
    </Fragment>
  );
};

export default ReqMTableBody;
