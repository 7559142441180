import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./InferIq-Solution/assets/index.css";
import InferIq from "./InferIq-Solution/InferIq";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes } from "./routes";

const App = () => {
  return <InferIq Routes={Routes} />;
};

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
