import { Modal } from "react-bootstrap";
import { CustomButton } from "../../../../components/index";
import DifferentPIIs from "../../utils/DifferentPIIs";

const AutoPiiModal = (props) => {
  const { show, fullscreen, onHide } = props;

  return (
    <Modal
      show={show}
      size="lg"
      fullscreen={fullscreen}
      onHide={onHide}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="heading">
            Definition of Personally identifiable information (PII)
          </span>
        </Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <span className="para">
          <strong>Name: </strong>
          full name, maiden name, mother’s maiden name, or alias
        </span>
        <br />
        <span className="para">
          <strong>Personal identification numbers: </strong>
          social security number (SSN), passport number, driver’s license
          number, taxpayer identification number, patient identification number,
          financial account number, or credit card number
        </span>
        <br />
        <span className="para">
          <strong>Personal address information: </strong>
          street address, or email address or telephone numbers
        </span>
        <br />
        <span className="para">
          <strong>Personal characteristics: </strong>
          photographic images (particularly of face or other identifying
          characteristics), fingerprints, or handwriting
        </span>
        <br />
        <span className="para">
          <strong>Biometric data: </strong>
          retina scans, voice signatures, or facial geometry
        </span>
        <br />
        <span className="para">
          <strong>Information identifying personally owned property: </strong>
          VIN number or title number
        </span>
        <br />
        <span className="para">
          <strong>Asset information: </strong>
          Internet Protocol (IP) or Media Access Control (MAC) addresses that
          consistently link to a particular person
        </span>
        <br />
        <span className="para">
          The following examples on their own do not constitute PII as more than
          one person could share these traits. However, when linked or linkable
          to one of the above examples, the following could be used to identify
          a specific person:
        </span>
        <br />
        <br />
        <strong className="para">
          Variables that are considered as PII in InferIQ are:
        </strong>
        <ul className="list-container">
          {DifferentPIIs.map((item, index) => (
            <li key={index} className="list-item">
              {item}
            </li>
          ))}
        </ul>
      </Modal.Body>
      <hr />
      <Modal.Footer>
        <CustomButton size="sm" variant="primary" onClick={onHide}>
          <span style={{ marginLeft: "5px" }}>Close</span>
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AutoPiiModal;
