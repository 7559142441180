export const lineChartResponse = {
    title: "Line Chart",
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "Dataset 1",
            data: [10, 1000, -200, 1500, -800, 1200, -400],
        },
        {
            label: "Dataset 2",
            data: [800, -600, 1200, -500, 900, -300, 100],
        },
    ],
};
export const donutChartResponse = {
    title: "Donut Chart",
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
        {
            label: "# of Votes",
            data: [12, 19, 3, 5, 2, 3],
        }]
}
export const histogramChartResponse = {
    title: "Histogram",
    binCount: 10,
    binSize: 100,
    datasets: [
        10, 125, 270, 380, 500, 750, 820, 640, 300, 750, 890, 960, 720, 400, 680,
        530, 150, 890, 630, 240, 880,
    ]
}
export const groupedBarChartResponse = {
    title: "Grouped Bar Chart",
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "Dataset 1",
            data: [500, -1000, 200, 1500, 800, 1200, 400],
            stack: "Stack 0",
        },
        {
            label: "Dataset 2",
            data: [300, 800, -1200, 500, 900, 600, -1000],
            stack: "Stack 0",
        },
        {
            label: "Dataset 3",
            data: [400, -700, 1300, 900, 600, 1000, 300],
            stack: "Stack 2",
        }, {
            label: "Dataset 4",
            data: [-400, -700, -1300, -900, -600, -1000, -300],
            stack: "Stack 2",
        },]
};
export const barChartResponse = {
    title: "Bar Chart",
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "Dataset 1",
            data: [500, 1000, 200, 1500, 800, 1200, 400],
        }, {
            label: "Dataset 2",
            data: [500, 1000, 200, 1500, 800, 1200, 400],
        }, {
            label: "Dataset 3",
            data: [500, 1000, 200, 1500, 800, 1200, 400],
        }, {
            label: "Dataset 4",
            data: [500, 1000, 200, 1500, 800, 1200, 400],
        }]
}
export const sunbrustChartResponse = {
    title: "SunBrust Chart",
    sunbrustChartResponse: [
        {
            id: "MRC",
            name: "Total Churn",
            fill: "#fff",
            label: {
                fontColor: "#2a333d",
                fontWeight: "bold"
            }
        },
        { id: "1.02", name: "Enterprise", parent: "MRC", fill: "#EB3223" },
        { id: "1.0", name: "Cloud & IT Services", parent: "MRC", fill: "#388286" },
        { id: "1.04", name: "Network", parent: "MRC", fill: "#4EAC5B" },
        { id: "1.03", name: "Financial Services", parent: "MRC", fill: "#113872" },
        {
            id: "1.01",
            name: "Content & Digital Media",
            parent: "MRC",
            fill: "#4a5b88"
        },
        { id: "2.09", name: "Business & Prof Services", parent: "1.02", value: 0 },
        { id: "2.01", name: "Services", parent: "1.0", value: 0 },
        { id: "2.1", name: "Energy & Utility", parent: "1.02", value: 0 },
        { id: "2.17", name: "Wholesale & Retail Trade", parent: "1.02", value: 0 },
        { id: "2.26", name: "Wireless Only", parent: "1.04", value: 0 },
        { id: "2.22", name: "Service Provider", parent: "1.03", value: 0 },
        { id: "2.05", name: "Gaming", parent: "1.01", value: 0 },
        { id: "2.06", name: "Publishing", parent: "1.01", value: 0 },
        { id: "2.2", name: "Insurance", parent: "1.03", value: 0 },
        { id: "2.19", name: "Capital Markets", parent: "1.03", value: 0 },
        { id: "2.14", name: "Manufacturing", parent: "1.02", value: 0 },
        { id: "2.21", name: "Multi", parent: "1.03", value: 0 },
        { id: "2.24", name: "Local & Specialty Telecom", parent: "1.04", value: 0 },
        { id: "2.25", name: "Major Telecommunications", parent: "1.04", value: 0 },
        { id: "2.0", name: "Infrastructure", parent: "1.0", value: 0 },
        { id: "2.02", name: "Software", parent: "1.0", value: 0 },
        { id: "2.13", name: "Legal", parent: "1.02", value: 0 },
        { id: "2.18", name: "Banking", parent: "1.03", value: 0 },
        { id: "2.15", name: "Other", parent: "1.02", value: 0 },
        { id: "2.07", name: "Video and Audio", parent: "1.01", value: 0 },
        { id: "2.11", name: "Government", parent: "1.02", value: 0 },
        { id: "2.03", name: "Advertising", parent: "1.01", value: 0 },
        { id: "2.12", name: "Healthcare & Life Sciences", parent: "1.02", value: 0 },
        { id: "2.08", name: "eCommerce", parent: "1.01", value: 0 },
        { id: "2.23", name: "Cable/Satellite TV", parent: "1.04", value: 0 },
        {
            id: "2.16",
            name: "Travel, Transport & Logistics",
            parent: "1.02",
            value: 0
        },
        { id: "2.04", name: "Content Delivery Network", parent: "1.01", value: 0 },
        { id: "3.24", name: "EAGLE INC.", parent: "2.09", value: 0 },
        { id: "3.04", name: "EAGLE INC.", parent: "2.01", value: 0 },
        { id: "3.2800000000000002", name: "EQUINIX", parent: "2.1", value: 0 },
        { id: "3.47", name: "FALCON", parent: "2.17", value: 0 },
        { id: "3.3", name: "FALCON", parent: "2.1", value: 0 },
        { id: "3.29", name: "EUROEXCHANGE, CO", parent: "2.1", value: 0 },
        { id: "3.69", name: "EQUINIX", parent: "2.26", value: 0 },
        { id: "3.58", name: "FALCON", parent: "2.22", value: 0 },
        { id: "3.17", name: "EQUINIX", parent: "2.05", value: 0 },
        { id: "3.5700000000000003", name: "EQUINIX", parent: "2.22", value: 0 },
        { id: "3.18", name: "DIGITALMOUNTAIN, INC", parent: "2.06", value: 0 },
        { id: "3.5300000000000002", name: "EQUINIX", parent: "2.2", value: 0 },
        { id: "3.51", name: "EQUINIX", parent: "2.19", value: 0 },
        { id: "3.39", name: "EQUINIX", parent: "2.14", value: 0 },
        { id: "3.54", name: "EQUINIX", parent: "2.21", value: 0 },
        { id: "3.63", name: "EQUINIX", parent: "2.24", value: 0 },
        { id: "3.67", name: "EQUINIX", parent: "2.25", value: 0 },
        { id: "3.01", name: "EQUINIX", parent: "2.0", value: 0 },
        { id: "3.1", name: "EQUINIX", parent: "2.02", value: 0 },
        { id: "3.36", name: "EQUINIX", parent: "2.13", value: 0 },
        { id: "3.49", name: "EQUINIX", parent: "2.18", value: 0 },
        { id: "3.05", name: "EQUINIX", parent: "2.01", value: 0 },
        { id: "3.42", name: "EQUINIX", parent: "2.15", value: 0 },
        { id: "3.21", name: "EQUINIX", parent: "2.07", value: 0 },
        { id: "3.4", name: "FALCON", parent: "2.14", value: 0 },
        { id: "3.32", name: "FALCON", parent: "2.11", value: 0 },
        { id: "3.06", name: "FALCON", parent: "2.01", value: 0 },
        { id: "3.55", name: "DIGITALMOUNTAIN, INC", parent: "2.22", value: 0 },
        { id: "3.11", name: "EUROEXCHANGE, CO", parent: "2.02", value: 0 },
        { id: "3.09", name: "EAGLE INC.", parent: "2.02", value: 0 },
        { id: "3.13", name: "eCONNECT", parent: "2.02", value: 0 },
        { id: "3.62", name: "DIGITALMOUNTAIN, INC", parent: "2.24", value: 0 },
        { id: "3.14", name: "EQUINIX", parent: "2.03", value: 0 },
        { id: "3.68", name: "FALCON", parent: "2.25", value: 0 },
        { id: "3.34", name: "EQUINIX", parent: "2.12", value: 0 },
        { id: "3.64", name: "EUROEXCHANGE, CO", parent: "2.24", value: 0 },
        { id: "3.38", name: "ABC CO.", parent: "2.14", value: 0 },
        { id: "3.65", name: "FALCON", parent: "2.24", value: 0 },
        { id: "3.43", name: "FALCON", parent: "2.15", value: 0 },
        { id: "3.25", name: "EQUINIX", parent: "2.09", value: 0 },
        { id: "3.22", name: "EQUINIX", parent: "2.08", value: 0 },
        { id: "3.59", name: "EQUINIX", parent: "2.23", value: 0 },
        { id: "3.33", name: "ABC CO.", parent: "2.12", value: 0 },
        { id: "3.44", name: "EQUINIX", parent: "2.16", value: 0 },
        { id: "3.31", name: "ABC CO.", parent: "2.11", value: 0 },
        { id: "3.27", name: "ABC CO.", parent: "2.1", value: 0 },
        { id: "3.15", name: "FALCON", parent: "2.03", value: 0 },
        { id: "3.08", name: "ABC CO.", parent: "2.02", value: 0 },
        { id: "3.41", name: "ABC CO.", parent: "2.15", value: 0 },
        { id: "3.23", name: "ABC CO.", parent: "2.09", value: 0 },
        { id: "3.0", name: "ABC CO.", parent: "2.0", value: 0 },
        { id: "3.03", name: "ABC CO.", parent: "2.01", value: 0 },
        { id: "3.61", name: "ABC CO.", parent: "2.24", value: 0 },
        { id: "3.52", name: "FALCON", parent: "2.19", value: 0 },
        { id: "3.16", name: "EQUINIX", parent: "2.04", value: 0 },
        { id: "3.45", name: "ABC CO.", parent: "2.17", value: 0 },
        { id: "3.48", name: "eCONNECT", parent: "2.17", value: 0 },
        { id: "3.56", name: "EAGLE INC.", parent: "2.22", value: 0 },
        { id: "3.2", name: "ABC CO.", parent: "2.07", value: 0 },
        { id: "3.35", name: "FALCON", parent: "2.12", value: 0 },
        { id: "3.12", name: "FALCON", parent: "2.02", value: 0 },
        { id: "3.02", name: "FALCON", parent: "2.0", value: 0 },
        { id: "3.37", name: "FALCON", parent: "2.13", value: 0 },
        { id: "3.26", name: "FALCON", parent: "2.09", value: 0 },
        { id: "3.19", name: "FALCON", parent: "2.06", value: 0 },
        { id: "3.46", name: "EQUINIX", parent: "2.17", value: 0 },
        { id: "3.5", name: "ABC CO.", parent: "2.19", value: 0 },
        { id: "3.07", name: "eCONNECT", parent: "2.01", value: 0 },
        { id: "3.66", name: "eCONNECT", parent: "2.24", value: 0 },
        { id: "3.6", name: "eCONNECT", parent: "2.23", value: 0 },
        { id: "4.41", name: "Interconnection", parent: "3.24", value: 0 },
        { id: "4.42", name: "Other", parent: "3.24", value: 0 },
        { id: "4.43", name: "Space & Power", parent: "3.24", value: 0 },
        { id: "4.08", name: "Interconnection", parent: "3.04", value: -44148.39 },
        { id: "4.09", name: "Space & Power", parent: "3.04", value: 0 },
        { id: "4.48", name: "Other", parent: "3.2800000000000002", value: 0 },
        { id: "4.73", name: "Interconnection", parent: "3.47", value: 0 },
        { id: "4.74", name: "Other", parent: "3.47", value: 0 },
        { id: "4.75", name: "Space & Power", parent: "3.47", value: 0 },
        { id: "4.52", name: "Other", parent: "3.3", value: 0 },
        { id: "4.49", name: "Interconnection", parent: "3.29", value: -45986 },
        { id: "4.5", name: "Other", parent: "3.29", value: 0 },
        { id: "4.51", name: "Space & Power", parent: "3.29", value: -32442 },
        { id: "5.2", name: "Interconnection", parent: "3.69", value: 0 },
        { id: "4.96", name: "Space & Power", parent: "3.58", value: 0 },
        { id: "4.34", name: "Interconnection", parent: "3.17", value: 0 },
        { id: "4.94", name: "Other", parent: "3.5700000000000003", value: 0 },
        { id: "4.35", name: "Interconnection", parent: "3.18", value: 0 },
        { id: "4.87", name: "Other", parent: "3.5300000000000002", value: 0 },
        { id: "4.82", name: "Interconnection", parent: "3.51", value: 0 },
        { id: "4.64", name: "Space & Power", parent: "3.39", value: 0 },
        { id: "4.63", name: "Interconnection", parent: "3.39", value: 0 },
        { id: "4.89", name: "Interconnection", parent: "3.54", value: 0 },
        { id: "5.08", name: "Space & Power", parent: "3.63", value: 0 },
        {
            id: "5.0600000000000005",
            name: "Interconnection",
            parent: "3.63",
            value: 0
        },
        { id: "4.9", name: "Space & Power", parent: "3.54", value: 0 },
        { id: "4.83", name: "Other", parent: "3.51", value: 0 },
        { id: "4.84", name: "Space & Power", parent: "3.51", value: 0 },
        { id: "5.16", name: "Other", parent: "3.67", value: 0 },
        { id: "4.01", name: "Interconnection", parent: "3.01", value: 0 },
        { id: "5.15", name: "Interconnection", parent: "3.67", value: 0 },
        { id: "4.21", name: "Interconnection", parent: "3.1", value: 0 },
        { id: "4.22", name: "Other", parent: "3.1", value: 0 },
        { id: "4.59", name: "Interconnection", parent: "3.36", value: 0 },
        { id: "4.8", name: "Space & Power", parent: "3.49", value: 0 },
        { id: "4.1", name: "Interconnection", parent: "3.05", value: 0 },
        { id: "4.68", name: "Space & Power", parent: "3.42", value: 0 },
        { id: "4.38", name: "Other", parent: "3.21", value: 0 },
        { id: "4.65", name: "Space & Power", parent: "3.4", value: -13954.35 },
        {
            id: "4.93",
            name: "Interconnection",
            parent: "3.5700000000000003",
            value: 0
        },
        { id: "4.54", name: "Interconnection", parent: "3.32", value: 0 },
        { id: "4.13", name: "Space & Power", parent: "3.06", value: -81265.09 },
        { id: "4.03", name: "Space & Power", parent: "3.01", value: 0 },
        { id: "4.91", name: "Interconnection", parent: "3.55", value: 0 },
        { id: "4.24", name: "Interconnection", parent: "3.11", value: 0 },
        { id: "4.25", name: "Space & Power", parent: "3.11", value: 0 },
        { id: "4.2", name: "Space & Power", parent: "3.09", value: -37656.388210226 },
        { id: "4.11", name: "Space & Power", parent: "3.05", value: 0 },
        { id: "4.28", name: "Space & Power", parent: "3.13", value: 0 },
        {
            id: "5.05",
            name: "Space & Power",
            parent: "3.62",
            value: -33496.248642325
        },
        { id: "5.03", name: "Interconnection", parent: "3.62", value: 0 },
        { id: "5.04", name: "Other", parent: "3.62", value: -32065.6807059826 },
        { id: "4.29", name: "Space & Power", parent: "3.14", value: 0 },
        { id: "4.23", name: "Space & Power", parent: "3.1", value: 0 },
        { id: "5.1899999999999995", name: "Other", parent: "3.68", value: 0 },
        { id: "4.57", name: "Space & Power", parent: "3.34", value: 0 },
        { id: "5.09", name: "Interconnection", parent: "3.64", value: 0 },
        { id: "4.02", name: "Other", parent: "3.01", value: 0 },
        { id: "4.88", name: "Space & Power", parent: "3.5300000000000002", value: 0 },
        { id: "4.62", name: "Other", parent: "3.38", value: 0 },
        { id: "5.11", name: "Space & Power", parent: "3.65", value: 0 },
        { id: "4.69", name: "Interconnection", parent: "3.43", value: 0 },
        { id: "4.45", name: "Space & Power", parent: "3.25", value: 0 },
        { id: "5.17", name: "Space & Power", parent: "3.67", value: 0 },
        { id: "5.21", name: "Space & Power", parent: "3.69", value: 0 },
        { id: "4.44", name: "Interconnection", parent: "3.25", value: 0 },
        { id: "4.39", name: "Other", parent: "3.22", value: 0 },
        { id: "4.98", name: "Space & Power", parent: "3.59", value: 0 },
        { id: "5.07", name: "Other", parent: "3.63", value: 0 },
        { id: "5.18", name: "Interconnection", parent: "3.68", value: 0 },
        { id: "4.55", name: "Other", parent: "3.33", value: -433286.418220643 },
        { id: "4.7", name: "Other", parent: "3.44", value: 0 },
        { id: "4.53", name: "Other", parent: "3.31", value: 0 },
        { id: "4.47", name: "Other", parent: "3.27", value: 0 },
        { id: "4.31", name: "Other", parent: "3.15", value: 0 },
        { id: "4.32", name: "Space & Power", parent: "3.15", value: 0 },
        { id: "4.3", name: "Interconnection", parent: "3.15", value: 0 },
        { id: "4.17", name: "Other", parent: "3.08", value: 0 },
        { id: "4.66", name: "Other", parent: "3.41", value: 0 },
        { id: "4.4", name: "Other", parent: "3.23", value: 0 },
        { id: "4.0", name: "Other", parent: "3.0", value: 0 },
        { id: "4.07", name: "Other", parent: "3.03", value: 0 },
        { id: "5.02", name: "Interconnection", parent: "3.61", value: 0 },
        {
            id: "4.86",
            name: "Interconnection",
            parent: "3.5300000000000002",
            value: 0
        },
        { id: "4.85", name: "Other", parent: "3.52", value: -195730.12 },
        { id: "4.33", name: "Other", parent: "3.16", value: 0 },
        { id: "4.71", name: "Other", parent: "3.45", value: 0 },
        { id: "4.76", name: "Interconnection", parent: "3.48", value: 0 },
        { id: "4.77", name: "Other", parent: "3.48", value: 0 },
        { id: "4.78", name: "Space & Power", parent: "3.48", value: 0 },
        { id: "4.92", name: "Interconnection", parent: "3.56", value: -149104.59 },
        { id: "4.79", name: "Other", parent: "3.49", value: 0 },
        { id: "4.37", name: "Other", parent: "3.2", value: 0 },
        { id: "4.67", name: "Interconnection", parent: "3.42", value: 0 },
        { id: "4.58", name: "Interconnection", parent: "3.35", value: 0 },
        { id: "4.27", name: "Space & Power", parent: "3.12", value: 0 },
        { id: "4.04", name: "Interconnection", parent: "3.02", value: 0 },
        { id: "5.1", name: "Interconnection", parent: "3.65", value: 0 },
        { id: "4.06", name: "Space & Power", parent: "3.02", value: 0 },
        { id: "4.6", name: "Interconnection", parent: "3.37", value: 0 },
        { id: "4.61", name: "Space & Power", parent: "3.37", value: 0 },
        { id: "4.46", name: "Interconnection", parent: "3.26", value: 0 },
        { id: "4.36", name: "Other", parent: "3.19", value: 0 },
        {
            id: "4.5600000000000005",
            name: "Interconnection",
            parent: "3.34",
            value: 0
        },
        { id: "4.72", name: "Other", parent: "3.46", value: 0 },
        { id: "4.26", name: "Interconnection", parent: "3.12", value: -85322 },
        { id: "4.95", name: "Space & Power", parent: "3.5700000000000003", value: 0 },
        { id: "4.05", name: "Other", parent: "3.02", value: 0 },
        { id: "4.12", name: "Interconnection", parent: "3.06", value: -26230.05 },
        { id: "4.97", name: "Interconnection", parent: "3.59", value: -886466.94 },
        { id: "4.8100000000000005", name: "Other", parent: "3.5", value: -436266.6 },
        { id: "4.18", name: "Interconnection", parent: "3.09", value: 0 },
        { id: "4.19", name: "Other", parent: "3.09", value: 0 },
        { id: "4.14", name: "Interconnection", parent: "3.07", value: 0 },
        { id: "4.15", name: "Other", parent: "3.07", value: 0 },
        { id: "4.16", name: "Space & Power", parent: "3.07", value: 0 },
        { id: "5.12", name: "Interconnection", parent: "3.66", value: 0 },
        { id: "5.13", name: "Other", parent: "3.66", value: 0 },
        { id: "5.140000000000001", name: "Space & Power", parent: "3.66", value: 0 },
        { id: "4.99", name: "Interconnection", parent: "3.6", value: 0 },
        { id: "5.0", name: "Other", parent: "3.6", value: 0 },
        { id: "5.01", name: "Space & Power", parent: "3.6", value: 0 }
    ]
    // [
    //     {
    //         id: "eu",
    //         name: "Infer IQ",
    //         fill: "#fff",
    //         label: {
    //             fontColor: "#2a333d",
    //             fontWeight: "bold",
    //         },
    //     },
    //     {
    //         id: "2.15",
    //         parent: "eu",
    //         name: "Eastern Europe",
    //         fill: "#f06362",
    //     },
    //     {
    //         id: "3.162",
    //         parent: "2.15",
    //         name: "Russia",
    //         value: 143989754,
    //     },
    //     {
    //         id: "3.163",
    //         parent: "2.15",
    //         name: "Ukraine",
    //         value: 44222947,
    //     },
    //     {
    //         id: "3.164",
    //         parent: "2.15",
    //         name: "Poland",
    //         value: 38170712,
    //     },
    //     {
    //         id: "3.165",
    //         parent: "2.15",
    //         name: "Romania",
    //         value: 19679306,
    //     },
    //     {
    //         id: "3.166",
    //         parent: "2.15",
    //         name: "Czechia",
    //         value: 10618303,
    //     },
    //     {
    //         id: "3.167",
    //         parent: "2.15",
    //         name: "Hungary",
    //         value: 9721559,
    //     },
    //     {
    //         id: "3.168",
    //         parent: "2.15",
    //         name: "Belarus",
    //         value: 9468338,
    //     },
    //     {
    //         id: "3.169",
    //         parent: "2.15",
    //         name: "Bulgaria",
    //         value: 7084571,
    //     },
    //     {
    //         id: "3.170",
    //         parent: "2.15",
    //         name: "Slovakia",
    //         value: 5447662,
    //     },
    //     {
    //         id: "3.171",
    //         parent: "2.15",
    //         name: "Moldova",
    //         value: 4051212,
    //     },
    //     {
    //         id: "3.172",
    //         parent: "2.15",
    //         name: "Cyprus",
    //         value: 1179551,
    //     },
    //     {
    //         id: "2.16",
    //         parent: "eu",
    //         name: "Northern Europe",
    //         fill: "#43c5f1",
    //     },
    //     {
    //         id: "3.173",
    //         parent: "2.16",
    //         name: "United Kingdom",
    //         value: 66181585,
    //     },
    //     {
    //         id: "3.174",
    //         parent: "2.16",
    //         name: "Sweden",
    //         value: 9910701,
    //     },
    //     {
    //         id: "3.175",
    //         parent: "2.16",
    //         name: "Denmark",
    //         value: 5733551,
    //     },
    //     {
    //         id: "3.176",
    //         parent: "2.16",
    //         name: "Finland",
    //         value: 5523231,
    //     },
    //     {
    //         id: "3.177",
    //         parent: "2.16",
    //         name: "Norway",
    //         value: 5305383,
    //     },
    //     {
    //         id: "3.178",
    //         parent: "2.16",
    //         name: "Ireland",
    //         value: 4761657,
    //     },
    //     {
    //         id: "3.179",
    //         parent: "2.16",
    //         name: "Lithuania",
    //         value: 2890297,
    //     },
    //     {
    //         id: "3.180",
    //         parent: "2.16",
    //         name: "Latvia",
    //         value: 1949670,
    //     },
    //     {
    //         id: "3.181",
    //         parent: "2.16",
    //         name: "Estonia",
    //         value: 1309632,
    //     },
    //     {
    //         id: "3.182",
    //         parent: "2.16",
    //         name: "Iceland",
    //         value: 335025,
    //     },
    //     {
    //         id: "3.183",
    //         parent: "2.16",
    //         name: "Guernsey and  Jersey",
    //         value: 165314,
    //     },
    //     {
    //         id: "3.184",
    //         parent: "2.16",
    //         name: "Isle of Man",
    //         value: 84287,
    //     },
    //     {
    //         id: "3.185",
    //         parent: "2.16",
    //         name: "Faroe Islands",
    //         value: 49290,
    //     },
    //     {
    //         id: "2.17",
    //         parent: "eu",
    //         name: "Southern Europe",
    //         fill: "#66c088",
    //     },
    //     {
    //         id: "3.186",
    //         parent: "2.17",
    //         name: "Italy",
    //         value: 59359900,
    //     },
    //     {
    //         id: "3.187",
    //         parent: "2.17",
    //         name: "Spain",
    //         value: 46354321,
    //     },
    //     {
    //         id: "3.188",
    //         parent: "2.17",
    //         name: "Greece",
    //         value: 11159773,
    //     },
    //     {
    //         id: "3.189",
    //         parent: "2.17",
    //         name: "Portugal",
    //         value: 10329506,
    //     },
    //     {
    //         id: "3.190",
    //         parent: "2.17",
    //         name: "Serbia",
    //         value: 8790574,
    //     },
    //     {
    //         id: "3.191",
    //         parent: "2.17",
    //         name: "Croatia",
    //         value: 4189353,
    //     },
    //     {
    //         id: "3.192",
    //         parent: "2.17",
    //         name: "Bosnia and Herzegovina",
    //         value: 3507017,
    //     },
    //     {
    //         id: "3.193",
    //         parent: "2.17",
    //         name: "Albania",
    //         value: 2930187,
    //     },
    //     {
    //         id: "3.194",
    //         parent: "2.17",
    //         name: "Republic of Macedonia",
    //         value: 2083160,
    //     },
    //     {
    //         id: "3.195",
    //         parent: "2.17",
    //         name: "Slovenia",
    //         value: 2079976,
    //     },
    //     {
    //         id: "3.196",
    //         parent: "2.17",
    //         name: "Montenegro",
    //         value: 628960,
    //     },
    //     {
    //         id: "3.197",
    //         parent: "2.17",
    //         name: "Malta",
    //         value: 430835,
    //     },
    //     {
    //         id: "3.198",
    //         parent: "2.17",
    //         name: "Andorra",
    //         value: 76965,
    //     },
    //     {
    //         id: "3.199",
    //         parent: "2.17",
    //         name: "Gibraltar",
    //         value: 34571,
    //     },
    //     {
    //         id: "3.200",
    //         parent: "2.17",
    //         name: "San Marino",
    //         value: 33400,
    //     },
    //     {
    //         id: "3.201",
    //         parent: "2.17",
    //         name: "Vatican City",
    //         value: 792,
    //     },
    //     {
    //         id: "2.18",
    //         parent: "eu",
    //         name: "Western Europe",
    //         fill: "#4a5b69",
    //     },
    //     {
    //         id: "3.202",
    //         parent: "2.18",
    //         name: "Germany",
    //         value: 82114224,
    //     },
    //     {
    //         id: "3.203",
    //         parent: "2.18",
    //         name: "France",
    //         value: 64979548,
    //     },
    //     {
    //         id: "3.204",
    //         parent: "2.18",
    //         name: "Netherlands",
    //         value: 17035938,
    //     },
    //     {
    //         id: "3.205",
    //         parent: "2.18",
    //         name: "Belgium",
    //         value: 11429336,
    //     },
    //     {
    //         id: "3.206",
    //         parent: "2.18",
    //         name: "Austria",
    //         value: 8735453,
    //     },
    //     {
    //         id: "3.207",
    //         parent: "2.18",
    //         name: "Switzerland",
    //         value: 8476005,
    //     },
    //     {
    //         id: "3.208",
    //         parent: "2.18",
    //         name: "Luxembourg",
    //         value: 583455,
    //     },
    //     {
    //         id: "3.209",
    //         parent: "2.18",
    //         name: "Monaco",
    //         value: 38695,
    //     },
    //     {
    //         id: "3.210",
    //         parent: "2.18",
    //         name: "Liechtenstein",
    //         value: 37922,
    //     },
    // ]
};

