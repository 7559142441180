import React, { useState } from "react";
import { dropDownList } from "../../utils/InputData";
import { TreeView } from "../TreeView";

const GetClientInfo = () => {
  const folder = {
    name: "Services",
    children: [
      {
        name: "Extraction",
        children: [{ name: "Mistrel" }],
      },
      {
        name: "Predictive Analytics",
        children: [{ name: "Claude" }],
      },
      {
        name: "Commercial Real Estate",
        children: [{ name: "Claude" }, { name: "Gpt3" }],
      },
      {
        name: "Redaction",
        children: [{ name: "Mistrel" }],
      },
      {
        name: "Generative AI",
        children: [{ name: "Claude" }, { name: "Gpt3" }],
      },
      {
        name: "Recognition",
      },
    ],
  };

  return (
    <div>
      <div>
        {/* <TreeView data={folder} /> */}
      </div>
      <div
        style={{
          margin: "3px",
          padding: "5px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span style={{ display: "inline-block" }} className="heading">
          Select Client
        </span>
      </div>
      <hr />
      <div style={{ margin: "3px", padding: "5px" }}>
        {dropDownList.map((item) => {
          const { id, toolTip, label, name, required, value } = item;
          return (
            <div
              key={id}
              className="pa-form-input-cont"
              style={{  marginBottom: "60px" }}
            >
              <label for="input" className="pa-form-input-label">
                {label}
              </label>

              <select
                className="pa-form-input itemselect"
                name={name}
                // value={formData?.name}
                // onChange={handleInputChange}
                required={required}
              >
                {value.map((item) => {
                  const { id, selected, hidden, disabled, label, value } = item;
                  return (
                    <option
                      key={id}
                      value={value}
                      selected={selected}
                      hidden={hidden}
                      disabled={disabled}
                    >
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GetClientInfo;
