const getCurrentBreakpoint = () => {
    const width = window.innerWidth;
    if (width >= 1920) return "xxl";
    else if (width >= 1280) return "xl";
    else if (width >= 1024) return "lg";
    else if (width >= 768) return "md";
    else if (width >= 568) return "sm";
    else if (width <= 568) return "xs";
    else return "xxs";
};
export default getCurrentBreakpoint;