import React from "react";
import {
  FontAwesomeIcon,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faRotate,
  faDownLeftAndUpRightToCenter,
  faArrowsToCircle,
} from "../../../import/FontAwesomeIcons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ImageControllPannelZoomOptions = (props) => {
  return (
    <button
      className="ImageControllPannel_Zoom_btn"
      onClick={props.OnClickfunction}
    >
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{props.lable}</Tooltip>}
      >
        {props.icon}
      </OverlayTrigger>
    </button>
  );
};

const ImageControllPannelResetOptions = (props) => {
  return (
    <button
      className="ImageControllPannel_Reset_btn"
      onClick={props.OnClickfunction}
    >
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{props.lable}</Tooltip>}
      >
        {props.icon}
      </OverlayTrigger>
    </button>
  );
};

const ImageControllPannel = (props) => {
  const { zoomIn, zoomOut, resetTransform, rotate, centerView } = props;
  const Image_Controll_Pannel_Zoom_Options = [
    {
      id: 1,
      lable: "zoomIn",
      icon: <FontAwesomeIcon icon={faMagnifyingGlassPlus} />,
      OnClickfunction: () => {
        zoomIn();
      },
    },
    {
      id: 2,
      lable: "zoomOut",
      icon: <FontAwesomeIcon icon={faMagnifyingGlassMinus} />,
      OnClickfunction: () => {
        zoomOut();
      },
    },
  ];

  let Image_Controll_Pannel_Reset_Options = [
    {
      id: 1,
      lable: "Rotate",
      icon: <FontAwesomeIcon icon={faRotate} />,
      OnClickfunction: () => {
        rotate(90);
      },
    },
    {
      id: 2,
      lable: "CenterView",
      icon: <FontAwesomeIcon icon={faArrowsToCircle} />,
      OnClickfunction: () => {
        centerView();
      },
    },
    {
      id: 3,
      lable: "Reset",
      icon: <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />,
      OnClickfunction: () => {
        resetTransform();
      },
    },
  ];

  return (
    <div className="ImageControllPannel">
      {Image_Controll_Pannel_Zoom_Options?.map(
        ({ id, icon, lable, OnClickfunction }) => {
          return (
            <ImageControllPannelZoomOptions
              key={id}
              icon={icon}
              lable={lable}
              OnClickfunction={OnClickfunction}
            />
          );
        }
      )}
      {Image_Controll_Pannel_Reset_Options?.map(
        ({ id, icon, lable, OnClickfunction }) => {
          return (
            <ImageControllPannelResetOptions
              key={id}
              icon={icon}
              lable={lable}
              OnClickfunction={OnClickfunction}
            />
          );
        }
      )}
    </div>
  );
};

export default ImageControllPannel;
