const inputList = [
  {
    id: Math.random(),
    toolTip: "The age of the borrower-",
    label: "Age:[Min - 18y & Max - 70y]",
    name: "Age",
    placeholder: "Enter Your Age...",
    title: "minimum 18years and maximum 70years",
    type: "number",
    pattern: "/[^0-9]/g",
    required: true,
    min: 18,
    max: 70,
    minLength: 2,
    maxLength: 2,
  },
  {
    id: Math.random(),
    toolTip: "The annual income of the borrower.",
    label: "Applicant Income:",
    name: "ApplicantIncome",
    placeholder: "Enter Your Income...",
    title: "minimum 15,000$ and maxmium 1,50,000$",
    type: "number",
    pattern: "/[^0-9]/g",
    required: true,
    min: 15000,
    max: 150000,
    minLength: 5,
    maxLength: 6,
  },
  {
    id: Math.random(),
    toolTip: "The amount of money being borrowed",
    label: "Loan Amount:",
    name: "LoanAmount",
    placeholder: "Enter Your Loan Amount...",
    title: "minimum 5,000 and maximum 2,50,000",
    type: "number",
    pattern: "/[^0-9]/g",
    required: true,
    min: 5000,
    max: 250000,
    minLength: 4,
    maxLength: 6,
  },
  {
    id: Math.random(),
    toolTip:
      "The credit score of the borrower, indicating their creditworthiness-",
    label: "Credit Score:[>300 & <850]",
    name: "CreditScore",
    placeholder: "Enter Your Credit Score...",
    title: "minimum 300 and maximum 850",
    type: "number",
    pattern: "/[^0-9]/g",
    required: true,
    min: 300,
    max: 850,
    minLength: 3,
    maxLength: 3,
  },
  {
    id: Math.random(),
    toolTip: " The number of months the borrower has been employed",
    label: "Months Employed:",
    name: "MonthsEmployed",
    placeholder: "Enter Your Months Employed...",
    title: "minimum 0 months and maximum 120 months",
    type: "number",
    pattern: "/[^0-9]/g",
    required: true,
    min: 0,
    max: 120,
    minLength: 1,
    maxLength: 3,
  },
  {
    id: Math.random(),
    toolTip: "The interest rate for the loan.",
    label: "Interest Rate:[Min - 2% & Max - 25%]",
    name: "InterestRate",
    placeholder: "Enter Your Interest Rate...",
    title: "minmum 2% and maximum 25%",
    type: "number",
    pattern: "/[^0-9]/g",
    required: true,
    min: 2,
    max: 25,
    minLength: 1,
    maxLength: 2,
  },
  {
    id: Math.random(),
    toolTip:
      "The Debt-to-Income ratio, indicating the borrower's debt compared to their income",
    label: "DTI Ratio:[Min - 0.1 & Max - 0.9]",
    placeholder: "Enter Your DTI Ratio...",
    title: "minimum value 0.1 and maximum value 0.9",
    type: "text",
    pattern:
      "^0.[1-9]$|^0.[0-9]{1,8}[1-9]$|^0.[0-9]{1,8}$|^1$|^0.[0-9]{1,7}[1-9]$",
    required: true,
    min: 0.1,
    max: 0.9,
    minLength: "",
    maxLength: "",
    name: "DTIRatio",
  },
];

const dropDownList = [
  {
    id: Math.random(),
    toolTip: "The number of credit lines the borrower has open.",
    label: "Num Credit Lines:",
    name: "NumCreditLines",
    required: true,
    value: [
      {
        id: Math.random(),
        label: "Select Your Num of Credit Lines...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: 1,
        value: 1,
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: 2,
        value: 2,
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: 3,
        value: 3,
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: 4,
        value: 4,
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
  {
    id: Math.random(),
    toolTip: "The term length of the loan in months.",
    label: "Loan Term:",
    name: "LoanTerm",
    value: [
      {
        id: Math.random(),
        label: "Select Your Loan Term...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: 3,
        value: 3,
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: 5,
        value: 5,
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: 10,
        value: 10,
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: 15,
        value: 15,
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: 20,
        value: 20,
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
  {
    id: Math.random(),
    toolTip:
      "The highest level of education attained by the borrower (PhD,Master's, Bachelor's, High School).",
    label: "Education:",
    name: "Education",
    required: true,
    value: [
      {
        id: Math.random(),
        label: "Select Your Education status...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: "Bachelor's",
        value: "Bachelor's",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Master's",
        value: "Master's",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "High School",
        value: "High School",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "PhD",
        value: "PhD",
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
  {
    id: Math.random(),
    toolTip:
      "The type of employment status of the borrower (Full-time,Part-time, Self-employed, Unemployed).",
    label: "Employment Type:",
    name: "EmploymentType",
    required: true,
    value: [
      {
        id: Math.random(),
        label: "Select Your Employment Type...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: "Full-time",
        value: "Full-time",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Unemployed",
        value: "Unemployed",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Self-employed",
        value: "Self-employed",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Part-time",
        value: "Part-time",
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
  {
    id: Math.random(),
    toolTip: "The marital status of the borrower (Single, Married, Divorced).",
    label: "Marital Status:",
    name: "MaritalStatus",
    required: true,
    value: [
      {
        id: Math.random(),
        label: "Select Your Marital Status...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: "Divorced",
        value: "Divorced",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Married",
        value: "Married",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Single",
        value: "Single",
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
  {
    id: Math.random(),
    toolTip: "Whether the borrower has a mortgage (Yes or No)-",
    label: "Has Mortgage:",
    name: "HasMortgage",
    required: true,
    value: [
      {
        id: Math.random(),
        label: "Select Your Has Mortgage...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: "Yes",
        value: "Yes",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "No",
        value: "No",
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
  {
    id: Math.random(),
    toolTip: "Whether the borrower has dependents (Yes or No).",
    label: "Has Dependents:",
    name: "HasDependents",
    required: true,
    value: [
      {
        id: Math.random(),
        label: "Select Your Has Dependents...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: "Yes",
        value: "Yes",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "No",
        value: "No",
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
  {
    id: Math.random(),
    toolTip:
      "The purpose of the loan (Home, Auto, Education, Business, Other)-",
    label: "Loan Purpose:",
    name: "LoanPurpose",
    required: true,
    value: [
      {
        id: Math.random(),
        label: "Select Your Has Dependents...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: "Other",
        value: "Other",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Auto",
        value: "Auto",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Business",
        value: "Business",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Home",
        value: "Home",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "Education",
        value: "Education",
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
  {
    id: Math.random(),
    toolTip: "Whether the loan has a co-signer (Yes or No).",
    label: "Has Co-Signer:",
    name: "HasCoSigner",
    required: true,
    value: [
      {
        id: Math.random(),
        label: "Select Your Co-Signer...",
        value: "",
        selected: true,
        hidden: true,
        disabled: true,
      },
      {
        id: Math.random(),
        label: "Yes",
        value: "Yes",
        selected: false,
        hidden: false,
        disabled: false,
      },
      {
        id: Math.random(),
        label: "No",
        value: "No",
        selected: false,
        hidden: false,
        disabled: false,
      },
    ],
  },
];

export { inputList, dropDownList };
