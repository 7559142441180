import React, { memo } from "react";
import anychart from "anychart";

class SunburstChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartContainer = React.createRef();
    let dataTree = anychart?.data?.tree(
      this.props?.data?.sunbrustChartResponse,
      "as-table"
    );

    let chart = anychart?.sunburst(dataTree);
    chart?.calculationMode("parent-independent");
    chart?.title("Total MRC Churn");
    chart?.palette(["#0288d1", "#d4e157", "#ff6e40", "#f8bbd0"]);
    chart?.labels().format("{%Name}\n{%Value}{scale:(1000000)(1)|( mln)}");
    chart?.tooltip().format("Population: {%Value}{scale:(1000000)(1)|( mln)}");
    chart?.fill(function () {
      return anychart?.color?.darken(this.parentColor, 0.15);
    });

    this.state = {
      chart: chart,
    };
  }

  componentDidMount() {
    this.state?.chart?.container(this?.chartContainer.current);
    this.state?.chart?.draw();
  }

  render() {
    return (
      <div
        ref={this?.chartContainer}
        style={{ width: "100%", height: `${this.props?.height}` }}
      ></div>
    );
  }
}

export default memo(SunburstChart);
