import React, { useContext } from "react";
import {
  FontAwesomeIcon,
  faAngleLeft,
  faAngleRight,
  faCircleInfo,
  faHouse,
} from "../../../../import/FontAwesomeIcons";
import { Radio, CustomButton } from "../../../../components/index";
import ParentContext from "../../../../context/context";
import { useToasts } from "../../../../Hooks/index";
import { Modal } from "react-bootstrap";
import LlmTypes from "../../utils/LlmTypes";

const GenAiAlertModal = (props) => {
  const { show, fullscreen, onHide, onProceed, onCancel } = props;
  const { state, dispatch } = useContext(ParentContext);
  const { selectedLlmType } = state;
  const { Toast } = useToasts();

  const handleRadioLlm = (e) => {
    dispatch({ type: "SET_SELECTED_LLM_TYPE", payload: e.target.value });
  };

  const handleProceed = () => {
    if (!selectedLlmType) {
      Toast(
        "warn",
        "Please select one of the large language models (LLM) to proceed."
      );
    } else {
      console.log("inside else");
      onProceed();
    }
  };

  let footerStyles = {
    marginLeft: "5px",
  };

  return (
    <div>
      <Modal show={show} fullscreen={fullscreen} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon
              style={{ marginRight: "15px", color: "rgb(0, 85, 213)" }}
              icon={faCircleInfo}
            />
            <span className="heading">Select large language model (LLM)</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="genai_llm_types">
              {LlmTypes?.map(({ id, label, group, value }) => (
                <span key={id}>
                  <Radio
                    label={label}
                    value={value}
                    group={group}
                    Checked={selectedLlmType === value}
                    onChange={handleRadioLlm}
                  />
                </span>
              ))}
            </div>
            {LlmTypes?.map(
              ({ id, value, description }) =>
                selectedLlmType &&
                selectedLlmType === value && (
                  <span key={id} className="para">
                    {description}
                  </span>
                )
            )}
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton size="sm" variant="primary" onClick={onHide}>
            <FontAwesomeIcon icon={faAngleLeft} />
            <span style={footerStyles}>Back</span>
          </CustomButton>
          <CustomButton size="sm" variant="primary" onClick={onCancel}>
            <FontAwesomeIcon icon={faHouse} />
            <span style={footerStyles}>Home</span>
          </CustomButton>
          <CustomButton size="sm" variant="success" onClick={handleProceed}>
            <span style={footerStyles}>
              Proceed <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GenAiAlertModal;
