import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  FontAwesomeIcon,
  faAngleLeft,
  faAngleRight,
} from "../../import/FontAwesomeIcons";

const PdfLoader = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div className="container-loader3">
      <div className="loading">
        <div className="ball one"></div>
        <div className="ball two"></div>
        <div className="ball three"></div>
        <div className="ball four"></div>
      </div>
    </div>
  );
};

const RedactedPdfViewerBtns = (props) => {
  return (
    <div className="Redacted-PdfViewer-btns">
      <button
        className="PdfViewer-btn"
        type="button"
        disabled={props.pageNumber <= 1}
        onClick={props.previousPage}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <p className="Redacted-PdfViewer-pages">
        Page {props.pageNumber || (props.numPages ? 1 : "--")} of{" "}
        {props.numPages || "--"}
      </p>
      <button
        className="PdfViewer-btn"
        type="button"
        disabled={props.pageNumber >= props.numPages}
        onClick={props.nextPage}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  );
};

const RedactionPdfViewer = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const on_Document_Load_Success = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setIsLoading(false);
  };
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(1);
  };
  const { pdf, title } = props;
  return (
    <div className="Redacted-PdfViewer">
      <div className="Redacted-pdfviewer-header">
        {numPages > 1 && (
          <RedactedPdfViewerBtns
            numPages={numPages}
            pageNumber={pageNumber}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        )}
      </div>
      <div className="Redacted-Pdf">
        <PdfLoader isLoading={isLoading} />
        <Document
          file={pdf}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={on_Document_Load_Success}
        >
          <Page size="A4" pageNumber={pageNumber} />
        </Document>
      </div>
    </div>
  );
};

export default RedactionPdfViewer;
