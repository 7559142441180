import React from "react";

const ServiceWiseInfoTable = ({ groupedData, tableHeader }) => {
  return (
    <React.Fragment>
      {groupedData &&
        Object?.entries(groupedData)?.map(([service, bodyData], index) => (
          <div key={index}>
            <div className="RequestMonitoring-container-header">
              <h6>{service.toUpperCase()}</h6>
            </div>
            <table className="table">
              <thead className="thead">
                <tr className="tr">
                  {tableHeader?.map((header, idx) => (
                    <th key={idx} className="th">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="tbody">
                {bodyData?.map((item, idx) => (
                  <tr className="tr" key={idx}>
                    <td className="td">{idx + 1}</td>
                    <td className="td">{item.form_type}</td>
                    <td className="td">{item.successful_page_count}</td>
                    <td className="td">{item.failed_page_count}</td>
                    <td className="td">{item.total_pages}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
    </React.Fragment>
  );
};

export default ServiceWiseInfoTable;
