import { useContext, useState } from "react";
import ParentContext from "../../InferIq-Solution/context/context";
import axios from "axios";
import env from "../../InferIq-Solution/env/env.js";
import sign from "jwt-encode";
import { secretKeyJwt, token, userDetails } from "../../InferIq-Solution/Auth/authConfig.js";

const useRequestMonitoringGetErrorLogs = () => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(ParentContext);

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);
  
  const getRequestMonitoringErrorLogs = async (
    ParentrequestId,
    stageName,
    phaseName,
    childId
  ) => {
    setLoading(true);
    dispatch({ type: "SET_RES_ERROR", payload: false });
    await axios
      .post(
        `${env.API_GATEWAY + "/requestmonitor"}`,
        {
          callType: "getErrorLogs",
          request_id: ParentrequestId,
          child_id: childId,
          userId:userDetails?.user_id,
          phase_name: phaseName,
          stage_name: stageName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_REQUEST_MONITORING_ERR_LOGS",
          payload: response.data,
        });
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
        dispatch({ type: "SET_RES_ERROR", payload: true });
      });
  };
  return { getRequestMonitoringErrorLogs, loading };
};

export default useRequestMonitoringGetErrorLogs;
