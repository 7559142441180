import React from "react";
import "./ReqMSubCardHeader.css"

const ReqMSubCardHeader = () => {
  return (
    <div className="para rm-sub-card-header1">
      <span className="para rm-sub-card-sno1">S No</span>
      <span className="para rm-sub-card-service1">Phase Name</span>
      <span className="para rm-sub-card-form-type1">File Name</span>
      <span className="para rm-sub-card-file-upload-time1">
        Processing Start Time
      </span>
      <span className="para rm-table-header-req-id1">Child Request Id</span>
      <span className="para rm-table-header-get-logs1">Logs</span>
      <span className="para rm-table-header-status1">Status</span>
    </div>
  );
};

export default ReqMSubCardHeader;
