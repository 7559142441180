export default [
    "#82cdff",
    "#049bff",
    "#2ec4b6",
    "#ffa0b4",
    "#e27396",
    "#00a5cf",
    "#C34A36",
    "#9b5de5",
    "#e6d9ff",
    "#adc178",
    "#ff4069",
    "#84a59d",
    "#ffe9d2",
    "#ee6c4d",
    "#d3f5f5",
    "#ffb703",
    "#F9F871",
    "#76c893",
    "#ffff3f",
    "#072929",
]