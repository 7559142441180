import React, { memo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartsFallBackUi from "../ChartsFallBackUi/ChartsFallBackUi";
import colors from "../../../../utils/graphColors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    title: {
      display: false,
      text: "Chart.js Bar Chart - Stacked",
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const GroupedBarChart = (props) => {
  const { groupedBarChartResponse } = props;

  if (!groupedBarChartResponse && !groupedBarChartResponse?.datasets) {
    return <ChartsFallBackUi />;
  }

  const datasetsWithStyles = groupedBarChartResponse?.datasets?.map(
    (dataset, index) => ({
      ...dataset,
      borderColor: colors[index % colors.length],
      backgroundColor: colors[index % colors.length],
    })
  );

  const dataWithStyles = {
    ...groupedBarChartResponse,
    datasets: datasetsWithStyles,
  };

  return <Bar options={options} data={dataWithStyles} />;
};

export default memo(GroupedBarChart);
