import React from "react";
import "./Profile.css";
import background from "../../images/profileBackground.png";
import { userDetails } from "../../Auth/authConfig";

const Profile = () => {
  return (
    <div style={{ width: "100%", height: "100vh", padding: "10px" }}>
      <div class="content-profile-page">
        <div class="profile-user-page card">
          <div class="img-user-profile">
            <img class="profile-bgHome" src={background} alt="bg" />
            <img
              class="avatar"
              src="https://i1.wp.com/ggrmlawfirm.com/wp-content/uploads/avatar-placeholder.png?fit=256%2C256&ssl=1"
              alt="jofpin"
            />
          </div>
          <div class="user-profile-data">
            <h1> {userDetails?.name}</h1>
            <p>{userDetails?.designation}</p>
          </div>
          <ul class="data-user">
            <li>
              <div className="para" style={{ marginBottom: "10px" }}>
                <strong>Company: </strong> {userDetails?.company}
              </div>
            </li>
            <li>
              <div className="para" style={{ marginBottom: "10px" }}>
                <strong>Email: </strong> {userDetails?.email}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Profile;