import React from "react";
import { Tab, Tabs } from "react-bootstrap";

const RedactedFields = (props) => {
  return (
    <div className="Redacted-Fields">
      <Tabs
        defaultActiveKey="Redacted fields"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="Redacted fields" title="Redacted fields">
          <div data-testid="Redaction_table_maincontainer" className="Redaction_table_switch_checkfields_maincontainer">
            {props.checkedFields?.map((value, index) => {
              return (
                <div key={index} className="Redaction_table_switch_checkfields">
                  <span>{value}</span>
                </div>
              );
            })}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default RedactedFields;
