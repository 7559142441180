import { useContext } from "react";
import env from "../../InferIq-Solution/env/env";
import ParentContext from "../../InferIq-Solution/context/context";
import axios from "axios";
import sign from "jwt-encode";
import { secretKeyJwt, token, userDetails } from "../../InferIq-Solution/Auth/authConfig";

const useBillingMatrics = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { allChartsBillingDates } = state;

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const ModifiDate = (date) => {
    const parts = date && date.split(" ");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const monthNumber = (
      new Date(Date.parse(month + " 1, 2022")).getMonth() + 1
    )
      .toString()
      .padStart(2, "0");

    const formattedDate = `${year}-${monthNumber}-${day}`;
    return formattedDate;
  };

  const fromDate = ModifiDate(
    allChartsBillingDates?.length > 1
      ? allChartsBillingDates?.[0]?.format()
      : ""
  );

  const toDate = ModifiDate(
    allChartsBillingDates?.length > 1
      ? allChartsBillingDates?.[1]?.format()
      : ""
  );

  const fetchAllChartsBillingData = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    dispatch({ type: "SET_RES_ERROR", payload: false });
    await axios
      .post(
        `${env.API_GATEWAY + "/requestmonitor"}`,
        {
          callType: "billingDetails",
          from_date: fromDate === "undefined-NaN-undefined" ? "" : fromDate,
          to_date: toDate === "undefined-NaN-undefined" ? "" : toDate,
          granularity: "DAILY",
          userId: userDetails?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_BILLING_METRICES_ALL_RES",
          payload: response.data,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
        dispatch({ type: "SET_RES_ERROR", payload: true });
      });
  };

  return { fetchAllChartsBillingData };
};

export default useBillingMatrics;