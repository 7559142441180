import { useCallback, useContext } from "react";
import ParentContext from "../context/context";
import axios from "axios";
import env from "../env/env.js";
import {
  encryptionKey,
  initVector,
  secretKeyJwt,
  token,
  userDetails,
} from "../Auth/authConfig.js";
import sign from "jwt-encode";
import { decryptData } from "../utils/crypto.js";

const useCREApiCall = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { file, serviceType, uploadedFiles } = state;
  const fileUploadDate = new Date();
  let preSignedUrl, fileObject;
  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  let post_url = `${env.API_GATEWAY}/extractData`;

  const convertBlobToBase64 = async (blob) => {
    return await blobToBase64(blob);
  };
  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function putToS3(fileObject, presignedUrl) {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": fileObject.type,
      },
      body: fileObject,
    };
    const response = await fetch(presignedUrl, requestOptions);
    return response;
  }

  const handleSubmit = useCallback(
    async (
      event,
      rentRollNewlyAddedQuestions,
      selectedValue,
      selectedPages
    ) => {
      event?.preventDefault();
      dispatch({ type: "SET_RES_ERROR", payload: false });
      if (file.size / 1024 >= 6000) {
        preSignedUrl = await axios.post(
          `${env.API_GATEWAY + "/extractData"}`,
          {
            service: serviceType,
            formType: selectedValue,
            documentId: null,
            fileSize: file.size / 1024,
            fileContent: "",
            filename: file.name,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        );

        const decodedUrl = decryptData(
          preSignedUrl.data.presignedurl,
          encryptionKey,
          initVector
        );
        const upload_resp = await putToS3(file, decodedUrl);
      }
      if (file.size / 1024 <= 6000) {
        fileObject = await convertBlobToBase64(file);
      }
      if (uploadedFiles.length >= 1) {
        await axios
          .post(
            post_url,
            {
              service: serviceType,
              fileUploadDate: fileUploadDate.toLocaleDateString(),
              fileUploadTime: fileUploadDate.toLocaleTimeString(),
              userId: userDetails?.user_id,
              userQuestions: rentRollNewlyAddedQuestions,
              llmType: "Bedrock Anthropic Claude",
              formType: selectedValue,
              selected_pages: selectedPages,
              documentId:
                file.size / 1024 >= 6000 ? preSignedUrl.data.documentId : null,
              fileSize: file.size / 1024,
              fileContent: file.size / 1024 >= 6000 ? "" : fileObject,
              filename: file.name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
          .then((response) => {
            if (response?.data?.status === "100%") {
              dispatch({
                type: "SET_CRE_RESPONSE",
                payload: response.data?.response,
              });
              dispatch({ type: "SET_RES_ERROR", payload: false });
            }
          })
          .catch((error) => {
            dispatch({ type: "SET_ERR_MSG", payload: error.message });
            dispatch({ type: "SET_RES_ERROR", payload: true });
          });
      }
    },
    [dispatch, serviceType, file, uploadedFiles]
  );

  return { handleSubmit };
};

export default useCREApiCall;
