import React, { useState } from "react";
import "./ReqMSubCardBody.css";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FontAwesomeIcon,
  faCaretDown,
  faClipboard,
  faCopy,
} from "../../../import/FontAwesomeIcons";
import { CustomButton } from "../../../../InferIq-Solution/components";
import useRequestMonitoringGetLogs from "../../../Hooks/useRequestMonitoringGetLogs";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "../../../../InferIq-Solution/Hooks";

const ReqMSubCardBody = (props) => {
  const [buttonState, setButtonState] = useState(
    <FontAwesomeIcon icon={faClipboard} style={{ visibility: "hidden" }} />
  );
  const { getRequestMonitoringLogs } = useRequestMonitoringGetLogs();
  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(props.childId).then(() => {
      setButtonState(<FontAwesomeIcon icon={faCopy} />);
      setTimeout(() => {
        setButtonState(
          <FontAwesomeIcon
            icon={faClipboard}
            style={{ visibility: "hidden" }}
          />
        );
      }, 2000);
    });
  };
  const status = props.fileStatus === "true";

  const { Toast } = useToasts();

  const downloadLogs = () => {
    getRequestMonitoringLogs(props.parentReqId, props.childId);
    Toast("success", "Please wait. File is downloading... ");
  };

  return (
    <div
      className="para rm-sub-card-body"
      onClick={props.substageLength !== 0 && props.clickToOpen}
    >
      <span className="para rm-sub-card-sno1">{props.sNo}</span>
      <span className="para rm-sub-card-service1">{props.phaseName}</span>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip> {props.fileName ? props.fileName : "Not Recived"}</Tooltip>
        }
      >
        <span className="para rm-sub-card-form-type1">
          {props.fileName ? props.fileName.slice(0, 8) : "Not Recived"}...
        </span>
      </OverlayTrigger>
      <span className="para rm-sub-card-file-upload-time1">
        {props.processingStartTime}
      </span>
      <span
        className="para rm-table-header-req-id1"
        onClick={(e) => {
          e.stopPropagation();
          handleCopyButtonClick();
        }}
        onMouseOver={() => {
          setButtonState(<FontAwesomeIcon icon={faClipboard} />);
        }}
        onMouseOut={() => {
          setButtonState(
            <FontAwesomeIcon
              icon={faClipboard}
              style={{ visibility: "hidden" }}
            />
          );
        }}
      >
        {props.childId ? props.childId : "Not Recived"}
        {props.childId && (
          <span
            id="copyButton_childId"
            style={{ marginLeft: "5px", cursor: "pointer" }}
          >
            {buttonState}
          </span>
        )}
      </span>
      <span className="rm-table-header-get-logs1">
        <CustomButton
          size="sm"
          variant="warning"
          onClick={() => downloadLogs()}
        >
          <FontAwesomeIcon icon={faFileLines} />
        </CustomButton>
      </span>
      <span className="para rm-table-header-status1">
        <Badge pill bg={status ? "success" : "danger"}>
          {status ? "success" : "failed"}
        </Badge>
      </span>
      {props.substageLength !== 0 && (
        <span className="rm-table-header-toggle">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      )}
    </div>
  );
};

export default ReqMSubCardBody;
