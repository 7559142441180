"use strict";
const userDashboardlayoutConfig = {
    xxl: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 5, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 5, y: 0, w: 4, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 9, y: 0, w: 5, h: 2 },
        },
        // {
        //     key: 4,
        //     gridValues: { x: 0, y: 3, w: 6, h: 4 },
        // },
        {
            key: 5,
            gridValues: { x: 0, y: 3, w: 5, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 5, y: 6, w: 4, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 9, y: 6, w: 5, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 0, y: 9, w: 5, h: 2 },
        },
        // {
        //     key: 9,
        //     gridValues: { x: 11, y: 9, w: 5, h: 2 },
        // },
    ],
    xl: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 6, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 6, y: 0, w: 6, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 0, w: 6, h: 2 },
        },
        // {
        //     key: 4,
        //     gridValues: { x: 6, y: 3, w: 6, h: 4 },
        // },
        {
            key: 5,
            gridValues: { x: 0, y: 3, w: 6, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 6, y: 6, w: 6, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 6, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 6, y: 9, w: 6, h: 2 },
        },
        // {
        //     key: 9,
        //     gridValues: { x: 0, y: 9, w: 6, h: 2 },
        // },
    ],
    lg: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 5, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 5, y: 0, w: 5, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 0, w: 5, h: 2 },
        },
        // {
        //     key: 4,
        //     gridValues: { x: 5, y: 3, w: 5, h: 4 },
        // },
        {
            key: 5,
            gridValues: { x: 0, y: 3, w: 5, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 5, y: 6, w: 5, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 5, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 5, y: 9, w: 5, h: 2 },
        },
        // {
        //     key: 9,
        //     gridValues: { x: 0, y: 9, w: 5, h: 2 },
        // },
    ],
    md: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 6, h: 2 }

        },
        {
            key: 2,
            gridValues: { x: 0, y: 0, w: 6, h: 2 }

        },
        {
            key: 3,
            gridValues: { x: 0, y: 2, w: 6, h: 2 }

        },
        // {
        //     key: 4,
        //     gridValues: { x: 5, y: 2, w: 5, h: 4 }

        // },
        {
            key: 5,
            gridValues: { x: 0, y: 5, w: 6, h: 2 }

        },
        {
            key: 6,
            gridValues: { x: 0, y: 5, w: 6, h: 2 }

        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 6, h: 2 }

        },
        {
            key: 8,
            gridValues: { x: 0, y: 9, w: 6, h: 2 },
        },
        // {
        //     key: 9,
        //     gridValues: { x: 0, y: 9, w: 5, h: 2 },
        // },
    ],
    sm: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 2, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 0, y: 0, w: 2, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 2, w: 2, h: 2 },
        },
        // {
        //     key: 4,
        //     gridValues: { x: 3, y: 2, w: 3, h: 4 },
        // },
        {
            key: 5,
            gridValues: { x: 0, y: 4, w: 2, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 0, y: 4, w: 2, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 2, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 0, y: 6, w: 2, h: 2 },
        },
        // {
        //     key: 9,
        //     gridValues: { x: 0, y: 8, w: 3, h: 2 },
        // },
    ],
    xs: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 4, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 0, y: 2, w: 4, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 4, w: 4, h: 2 },
        },
        // {
        //     key: 4,
        //     gridValues: { x: 0, y: 6, w: 4, h: 4 },
        // },
        {
            key: 5,
            gridValues: { x: 0, y: 8, w: 4, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 0, y: 10, w: 4, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 12, w: 4, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 0, y: 14, w: 4, h: 2 },
        },
        // {
        //     key: 9,
        //     gridValues: { x: 0, y: 16, w: 4, h: 2 },
        // },
    ],
    xxs: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 3, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 0, y: 2, w: 3, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 4, w: 3, h: 2 },
        },
        // {
        //     key: 4,
        //     gridValues: { x: 0, y: 6, w: 3, h: 4 },
        // },
        {
            key: 5,
            gridValues: { x: 0, y: 8, w: 3, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 0, y: 10, w: 3, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 12, w: 3, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 0, y: 14, w: 3, h: 2 },
        },
        // {
        //     key: 9,
        //     gridValues: { x: 0, y: 16, w: 3, h: 2 },
        // },
    ]
};

const AdminDashboardlayoutConfig = {
    xxl: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 5, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 5, y: 0, w: 6, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 11, y: 0, w: 5, h: 2 },
        },
        {
            key: 4,
            gridValues: { x: 0, y: 3, w: 6, h: 4 },
        },
        {
            key: 5,
            gridValues: { x: 6, y: 3, w: 5, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 11, y: 6, w: 5, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 5, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 6, y: 9, w: 5, h: 2 },
        },
        {
            key: 9,
            gridValues: { x: 11, y: 9, w: 5, h: 2 },
        },
    ],
    xl: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 6, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 6, y: 0, w: 6, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 0, w: 6, h: 2 },
        },
        {
            key: 4,
            gridValues: { x: 6, y: 3, w: 6, h: 4 },
        },
        {
            key: 5,
            gridValues: { x: 0, y: 3, w: 6, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 6, y: 6, w: 6, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 6, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 6, y: 9, w: 6, h: 2 },
        },
        {
            key: 9,
            gridValues: { x: 0, y: 9, w: 6, h: 2 },
        },
    ],
    lg: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 4, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 4, y: 0, w: 4, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 8, y: 0, w: 4, h: 2 },
        },
        {
            key: 4,
            gridValues: { x: 0, y: 3, w: 4, h: 2 },
        },
        {
            key: 5,
            gridValues: { x: 4, y: 3, w: 4, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 8, y: 6, w: 4, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 4, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 4, y: 9, w: 4, h: 2 },
        },
        {
            key: 9,
            gridValues: { x: 8, y: 9, w: 4, h: 2 },
        }, {
            key: 10,
            gridValues: { x: 0, y: 11, w: 4, h: 2 },
        },
    ],
    md: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 5, h: 2 }

        },
        {
            key: 2,
            gridValues: { x: 5, y: 0, w: 5, h: 2 }

        },
        {
            key: 3,
            gridValues: { x: 0, y: 2, w: 5, h: 2 }

        },
        {
            key: 4,
            gridValues: { x: 5, y: 2, w: 5, h: 2 }

        },
        {
            key: 5,
            gridValues: { x: 0, y: 5, w: 5, h: 2 }

        },
        {
            key: 6,
            gridValues: { x: 5, y: 5, w: 5, h: 2 }

        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 5, h: 2 }

        },
        {
            key: 8,
            gridValues: { x: 5, y: 6, w: 5, h: 2 },
        },
        {
            key: 9,
            gridValues: { x: 0, y: 9, w: 5, h: 2 },
        }, {
            key: 10,
            gridValues: { x: 5, y: 9, w: 5, h: 2 },
        },
    ],
    sm: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 3, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 3, y: 0, w: 3, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 2, w: 3, h: 2 },
        },
        {
            key: 4,
            gridValues: { x: 3, y: 2, w: 3, h: 2 },
        },
        {
            key: 5,
            gridValues: { x: 0, y: 4, w: 3, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 3, y: 4, w: 3, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 6, w: 3, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 3, y: 6, w: 3, h: 2 },
        },
        {
            key: 9,
            gridValues: { x: 0, y: 8, w: 3, h: 2 },
        }, {
            key: 10,
            gridValues: { x: 0, y: 10, w: 3, h: 2 },
        },
    ],
    xs: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 4, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 0, y: 2, w: 4, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 4, w: 4, h: 2 },
        },
        {
            key: 4,
            gridValues: { x: 0, y: 6, w: 4, h: 2 },
        },
        {
            key: 5,
            gridValues: { x: 0, y: 8, w: 4, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 0, y: 10, w: 4, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 12, w: 4, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 0, y: 14, w: 4, h: 2 },
        },
        {
            key: 9,
            gridValues: { x: 0, y: 16, w: 4, h: 2 },
        }, {
            key: 9,
            gridValues: { x: 0, y: 18, w: 4, h: 2 },
        },
    ],
    xxs: [
        {
            key: 1,
            gridValues: { x: 0, y: 0, w: 3, h: 2 },
        },
        {
            key: 2,
            gridValues: { x: 0, y: 2, w: 3, h: 2 },
        },
        {
            key: 3,
            gridValues: { x: 0, y: 4, w: 3, h: 2 },
        },
        {
            key: 4,
            gridValues: { x: 0, y: 6, w: 3, h: 2 },
        },
        {
            key: 5,
            gridValues: { x: 0, y: 8, w: 3, h: 2 },
        },
        {
            key: 6,
            gridValues: { x: 0, y: 10, w: 3, h: 2 },
        },
        {
            key: 7,
            gridValues: { x: 0, y: 12, w: 3, h: 2 },
        },
        {
            key: 8,
            gridValues: { x: 0, y: 14, w: 3, h: 2 },
        },
        {
            key: 9,
            gridValues: { x: 0, y: 16, w: 3, h: 2 },
        }, {
            key: 10,
            gridValues: { x: 0, y: 18, w: 3, h: 2 },
        },
    ]
};

export { userDashboardlayoutConfig, AdminDashboardlayoutConfig };
