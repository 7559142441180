import React from "react";
import logo from "../../../../images/logo.png";
import { initialPath } from "../../../../Auth/authConfig";

const SideBarHeader = () => {
  return (
    <div className="sidebar-header">
      <a herf={initialPath} draggable={false}>
        <img src={logo} alt="AIDE-lOGO" draggable={false} />
      </a>
    </div>
  );
};

export default SideBarHeader;
