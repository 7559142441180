import React from "react";
import { Badge } from "react-bootstrap";

const Card = ({ text, value, ...rest }) => {
  const cardStyle = {
    border: "1px solid transparent",
    borderRadius: "5px",
    margin: "5px",
    boxShadow: "rgba(0, 0, 0, .24) 0 0 2px",
    width: "32%",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 5px",
  };
  return (
    <div style={cardStyle} {...rest}>
      <p style={{fontSize:"14px", fontWeight:"500"}}>{text}</p>
      <span
        style={{
          width: "2rem",
          height: "2rem",
          borderRadius: "3px",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        <Badge bg="primary">{value}</Badge>
      </span>
    </div>
  );
};

export default Card;
