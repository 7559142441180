import React, { useContext, useEffect, memo } from "react";
import ParentContext from "../../context/context";
import { FileUploadForm, InferSideImage } from "../../components/index";
import { BankStatementImg } from "../../images/sideContent";
import { userPermission } from "../../Auth/authConfig";

const BankStatements = () => {
  const { dispatch } = useContext(ParentContext);

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "Bank Statement" });
  }, [dispatch]);

  return (
    <FileUploadForm
      RadioOptions={userPermission?.map(
        (item) => item.label === "Bank Statement" && item.RadioOptions
      )}
      sideContent={
        <InferSideImage image={BankStatementImg} title="Bank Statement">
          Official bank statement is typically sent by e bank to the account
          holder every month, summarizing all the transactions of an account
          during the month. Bank statements contain bank account information,
          such as account numbers and a detailed list of deposits and
          withdrawals.
        </InferSideImage>
      }
      ButtonText="Next"
    />
  );
};

export default memo(BankStatements);
