import { useContext } from "react";
import axios from "axios";
import ParentContext from "../context/context";
import { encryptData } from "../utils/crypto";
import env from "../env/env.js";

const useSignUpApi = () => {
  const { state, dispatch } = useContext(ParentContext);

  const getSignUpData = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    await axios
      .post(
        `${env.API_GATEWAY}/extractData`,
        {
          callType: "sign_up",
          formData: encryptData(
            state.signUpFormData,
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_SIGNUP_BACKEND_RES",
          payload: response.data,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
      });
  };

  return { getSignUpData };
};

export default useSignUpApi;
