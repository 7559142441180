import { CustomButton } from "../../../../components/index";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FontAwesomeIcon,
  faAngleLeft,
  faAngleRight,
  faDownload,
  faPencil,
} from "../../../../import/FontAwesomeIcons";

const GenAiHeaderButtons = (props) => {
  return (
    <div className="genai_header_buttons" style={props.HeaderButtonsStyles}>
      <div className="genai_header_title">
        {props.tabs.map((tab) => (
          <span
            key={tab.key}
            style={{
              display: "contents",
            }}
            className={`genai_tab_title heading ${
              tab.key === props.activeTab ? "active" : ""
            }`}
            id={tab.key === props.activeTab ? tab.title : ""}
          >
            {tab.key === props.activeTab ? tab.title : ""}
          </span>
        ))}
      </div>
      <div className="genai_heaber_tools">
        {props.getRedactioOutputEventKey === "raw_text" && (
          <div style={props.RedactionEditButton}>
            <CustomButton
              size="sm"
              variant={props.editButtonActive ? "danger" : "light"}
              onClick={props.genai_Redaction_Output_HandleEdit}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Edit</Tooltip>}
              >
                <FontAwesomeIcon
                  style={{
                    color: props.editButtonActive ? "white" : "black",
                  }}
                  icon={faPencil}
                />
              </OverlayTrigger>
            </CustomButton>
          </div>
        )}
      </div>
      <div style={props.LeftButtonStyles} className="genai_header_backbutton">
        <div style={props.RedactionOutputButtonsStyles}>
          {props.getRedactioOutputEventKey === "raw_text" && (
            <CustomButton
              size="sm"
              variant="light"
              onClick={
                props.activeTab === "redaction_output"
                  ? props.Redacted_Output_export_As_Pdf
                  : props.Ai_Output_export_As_Pdf
              }
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Download</Tooltip>}
              >
                <FontAwesomeIcon className="iq_svg" icon={faDownload} />
              </OverlayTrigger>
            </CustomButton>
          )}
        </div>
        <CustomButton
          size="sm"
          variant="primary"
          onClick={props.goToPreviousStep}
          disabled={props.disablePrevious}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          <span
            style={{
              marginLeft: "5px",
            }}
          >
            Back
          </span>
        </CustomButton>
      </div>
      <div style={props.DontShowButton} className="genai_header_nextbutton">
        <CustomButton
          size="sm"
          variant="primary"
          onClick={props.goToNextStep}
          disabled={props.NextButtonDisable}
        >
          <span
            style={{
              marginRight: "5px",
            }}
          >
            {props.RightButtonText}
          </span>
          <FontAwesomeIcon icon={faAngleRight} />
        </CustomButton>
      </div>
    </div>
  );
};

export default GenAiHeaderButtons;
