import React, { memo, useState } from "react";
import "./PdfViewer.css";
import { Document, Page, pdfjs } from "react-pdf";
// import ParentContext from "../../../../context/context";
// import "../Loader/Loader";

const PdfLoader = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div className="container-loader3">
      <div className="loading">
        <div className="ball one"></div>
        <div className="ball two"></div>
        <div className="ball three"></div>
        <div className="ball four"></div>
      </div>
    </div>
  );
};

const PdfViewer = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [isLoading, setIsLoading] = useState(true);

  const { pdf, pageNumber, onDocumentLoadSuccess, scale, rotate } = props;

  return (
    <div className="page_PdfViewer">
      {/* <PdfLoader isLoading={isLoading} /> */}
      <Document
        file={pdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <div className="pdf_page_container">
          <Page
            size="A4"
            pageNumber={pageNumber}
            scale={scale}
            rotate={rotate}
          />
        </div>
      </Document>
    </div>
  );
};

export default memo(PdfViewer);
