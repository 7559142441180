import React, { Fragment, useContext, useEffect } from "react";
import { Modal, Badge } from "react-bootstrap";
import { CustomButton } from "../../../../InferIq-Solution/components/index";
import "./ReqMModal.css";
import {
  FontAwesomeIcon,
  faArrowRightLong,
  faXmark,
} from "../../../import/FontAwesomeIcons";
import useRequestMonitoringGetErrorLogs from "../../../Hooks/useRequestMonitoringGetErrorLogs";
import ErrorLogs from "../ErrorLogs/ErrorLogs";
import ParentContext from "../../../../InferIq-Solution/context/context";
import Spinner from "../../../Spinner/Spinner";

const ReqMModal = ({
  showInfo,
  handleCloseInfo,
  ParentrequestId,
  stageName,
  phaseName,
  childId,
}) => {
  const { state } = useContext(ParentContext);
  const { requestMonitoringErrLogs } = state;
  const { getRequestMonitoringErrorLogs, loading } =
    useRequestMonitoringGetErrorLogs();

  useEffect(() => {
    getRequestMonitoringErrorLogs(
      ParentrequestId,
      stageName,
      phaseName,
      childId
    );
  }, []);

  return (
    <Modal
      size="xl"
      show={showInfo}
      aria-labelledby="example-modal-sizes-title-lg"
      backdrop="static"
      keyboard={false}
    >
      <div className="RequestMonitoringModal">
        <div className="RequestMonitoringModal-header">
          <div className="RequestMonitoringModal-header-title heading">
            Error Logs:
            <span className="para">
              <FontAwesomeIcon
                style={{ margin: "0px 8px" }}
                icon={faArrowRightLong}
              />
              <Badge bg="danger">{stageName}</Badge>
            </span>
          </div>

          <CustomButton variant="light" size="sm" onClick={handleCloseInfo}>
            <FontAwesomeIcon icon={faXmark} />
          </CustomButton>
        </div>
        <div className="RequestMonitoringModal-body">
          {loading ? (
            <div className="rm-fallback-ui">
              <div>
                <Spinner />
              </div>
              <span className="para">Lodaing Data,Please Wait...</span>
            </div>
          ) : (
            <Fragment>
              {requestMonitoringErrLogs && (
                <ErrorLogs errorLogs={requestMonitoringErrLogs} />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReqMModal;
