import React from "react";
import "./CustomButton.css";

const CustomButton = ({
  variant,
  outline,
  size,
  onClick,
  disabled,
  children,
  type,
  style,
  ...rest
}) => {
  const buttonClasses = `cbtn ${variant ? `cbtn-${variant}` : ""} ${
    outline ? "outline" : ""
  } ${size ? `cbtn-${size}` : ""}`;

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={style}
      {...rest}
    >
      {children}
    </button>
  );
};

export default CustomButton;
