import React from "react";
import "./RangeDatePicker.css";
import { faCalendarDays, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-multi-date-picker";
import { CustomButton } from "../../../InferIq-Solution/components";

const SubmitPlugin = ({ billingDates, handleSubmit }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "5px",
        gap: "5px",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {billingDates.length > 1 && (
        <span className="para">
          <span
            style={{
              border: "1px solid transperent",
              borderRadius: "3px",
              padding: "3px 5px",
              backgroundColor: "#102447",
              color: "#fff",
            }}
          >
            {billingDates[0].format()}
          </span>
          <span> -To- </span>
          <span
            style={{
              border: "1px solid transperent",
              borderRadius: "3px",
              padding: "3px",
              backgroundColor: "#102447",
              color: "#fff",
            }}
          >
            {billingDates[1].format()}
          </span>
        </span>
      )}
      <CustomButton
        variant="primary"
        onClick={() => handleSubmit()}
        size="sm"
        disabled={!DatePicker}
      >
        Download <FontAwesomeIcon icon={faDownload} />
      </CustomButton>
    </div>
  );
};

const RangeDatePicker = ({
  billingDates,
  handleDateChange,
  dateFormat,
  minDate,
  maxDate,
  handleSubmit,
}) => {
  return (
    <div className="range-date-picker para">
      <FontAwesomeIcon icon={faCalendarDays} />
      <DatePicker
        range
        calendarPosition="bottom-left"
        fixMainPosition
        dateSeparator=" -To- "
        value={billingDates}
        onChange={handleDateChange}
        format={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        sort
        rangeHover
        numberOfMonths={1}
        plugins={[
          <SubmitPlugin
            position="bottom"
            billingDates={billingDates}
            handleSubmit={handleSubmit}
          />,
        ]}
      />
    </div>
  );
};

export default RangeDatePicker;
