import { initialPath } from "../../../Auth/authConfig";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const Copy_Right_Year = () => {
    return new Date().getFullYear();
  };
  const footerLinks = [
    { id: 1, label: "Home", link: initialPath },
    { id: 2, label: "FAQ's", link: "/faqs" },
    // { id: 3, label: "Privacy Policy", link: "/privacyPloicy" },
  ];
  return (
    <div className="footer-container">
      <span className="para">
        &#169; Copyright {Copy_Right_Year()} Idexcel, Inc. All Rights Reserved.
      </span>
      <div className="footer-links para">
        {footerLinks.map((item) => (
          <Link key={item.id} to={item.link}>
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Footer;
