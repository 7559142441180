import React, { useContext } from "react";
import Spreadsheet from "react-spreadsheet";
import ParentContext from "../../context/context";
import {
  faAngleLeft,
  faAngleRight,
  FontAwesomeIcon,
} from "../../import/FontAwesomeIcons";
import "./ExcelViewer.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ExcelViewer = ({ excelData }) => {
  const { state, dispatch } = useContext(ParentContext);
  const { excelSheetIndex } = state;

  const goToNextSheet = () => {
    dispatch({
      type: "SET_EXCEL_SHEET_INDEX",
      payload: excelSheetIndex + 1,
    });
  };

  const goToPreviousSheet = () => {
    dispatch({
      type: "SET_EXCEL_SHEET_INDEX",
      payload: excelSheetIndex - 1,
    });
  };

  return (
    <div
      className="excel_viewer_container"
      style={{ width: "100%", height: "100%", overflow: "auto" }}
    >
      <div className="excel_viewer_controls">
        <div className="excel_viewer_controls_btns">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">Go to previous sheet</Tooltip>}
          >
            <button
              onClick={goToPreviousSheet}
              disabled={excelSheetIndex === 0}
              className="left-arrows"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">Go to next sheet</Tooltip>}
          >
            <button
              className="right-arrows"
              onClick={goToNextSheet}
              disabled={excelSheetIndex === excelData.length - 1}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </OverlayTrigger>
        </div>
        <div className="no-of-sheets para">
          Current sheet:{" "}
          <span
            style={{
              border: "1px solid #ccc",
              padding: "1px 9px",
              borderRadius: "4px",
              color: "black",
              backgroundColor: "#fff",
            }}
          >
            {excelSheetIndex + 1}
          </span>
        </div>
        <div className="no-of-sheets para">
          No of sheets:{" "}
          <span
            style={{
              border: "1px solid #ccc",
              padding: "1px 9px",
              borderRadius: "4px",
              color: "black",
              backgroundColor: "#fff",
            }}
          >
            {excelData.length}
          </span>
        </div>
      </div>
      <div className="excel_viewer">
        {excelData[excelSheetIndex] && (
          <Spreadsheet
            data={excelData[excelSheetIndex]}
            hideColumnIndicators={true}
          />
        )}
      </div>
    </div>
  );
};

export default ExcelViewer;
