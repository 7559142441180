import "./Loader.css";
import logo from "../../images/InferIQ-Blue.png";
import { useStatus } from "../../Hooks/index";

const Loader = () => {
  const { parsedStatus, style, statusMsgForServiceTypes } = useStatus();
  return (
    <div className="overlay">
      <div className="progress-container">
        {/* <img src={logo} alt="logo" /> */}
        <div className="progress ">
          <div
            className="progress-done progress-bar-animated progress-bar-striped"
            style={style}
          >
            {parsedStatus}%
          </div>
        </div>
        <div className="progress-message para" style={{ color: "white" }}>
          {statusMsgForServiceTypes}
        </div>
      </div>
    </div>
  );
};

export default Loader;
