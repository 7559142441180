import React, { useContext, memo, useState } from "react";
import {
  ClassifierTable,
  ExtractionTable,
  RecognizerTable,
  RedactionTable,
  TableButtons,
} from "../../../components/index";
import ParentContext from "../../../context/context";

const TableViewer = (props) => {
  const {
    RedactedUrl,
    kvpTdData,
    tableThData,
    tableTrData,
    Allfiles,
    button_Styles,
    all_tabs,
    BankStatementResponse,
    IrsFormsResponse,
    checkedState,
    setCheckedState,
    selectedRow,
    rowRefs,
    isViewTableModal,
    setIsViewTableModal,
    searchRow,
    setSearchRow,
    setSelectedRow,
    showAccReport,
    setShowAccReport,
    tableResponse
  } = props;
  const { state, dispatch } = useContext(ParentContext);
  const { serviceType, selectedOption } = state;
  const [tabAccNumber, setTabAccNumber] = useState(
    tableResponse?.report_analysis?.account_num[0]
  );
  return (
    <React.Fragment>
      <div className="outputscreen-buttons">
        <TableButtons
          RedactedUrl={RedactedUrl}
          kvpTdData={() => kvpTdData()}
          tableThData={tableThData}
          tableTrData={tableTrData}
          Allfiles={Allfiles}
          selectedOption={selectedOption}
          setSelectedOption={(selectedOption) =>
            dispatch({
              type: "SET_SEL_OPTION",
              payload: selectedOption,
            })
          }
          checkedState={checkedState}
          button_Styles={button_Styles}
          isViewTableModal={isViewTableModal}
          setIsViewTableModal={setIsViewTableModal}
          accEditDetails={tableResponse}
          currentActiveAcc={tabAccNumber}
        />
      </div>
      <div className="outputscreen-table">
        {serviceType === "Extraction" && (
          <ExtractionTable
            all_tabs={all_tabs}
            BankStatementResponse={BankStatementResponse}
            kvpTdData={kvpTdData}
            tableThData={tableThData}
            tableTrData={tableTrData}
            serviceType={serviceType}
          />
        )}
        {serviceType === "Bank Statement" && (
          <ExtractionTable
            all_tabs={all_tabs}
            BankStatementResponse={BankStatementResponse}
            kvpTdData={kvpTdData}
            tableThData={tableThData}
            tableTrData={tableTrData}
            selectedRow={selectedRow}
            rowRefs={rowRefs}
            searchRow={searchRow}
            setSearchRow={setSearchRow}
            setSelectedRow={setSelectedRow}
            showAccReport={showAccReport}
            setShowAccReport={setShowAccReport}
            serviceType={serviceType}
            tableResponse={tableResponse}
            tabAccNumber={tabAccNumber}
            setTabAccNumber={setTabAccNumber}
          />
        )}
        {serviceType === "FinStat Extraction" && (
          <ExtractionTable
            all_tabs={all_tabs}
            BankStatementResponse={BankStatementResponse}
            kvpTdData={kvpTdData}
            tableThData={tableThData}
            tableTrData={tableTrData}
          />
        )}
        {serviceType === "Classification" && (
          <ClassifierTable
            all_tabs={all_tabs}
            kvpTdData={kvpTdData}
            tableThData={tableThData}
            tableTrData={tableTrData}
          />
        )}
        {serviceType === "Recognition" && (
          <RecognizerTable
            all_tabs={all_tabs}
            kvpTdData={kvpTdData}
            tableThData={tableThData}
            tableTrData={tableTrData}
          />
        )}
        {serviceType === "Redaction" && (
          <RedactionTable
            url={RedactedUrl}
            checkedState={checkedState}
            setCheckedState={setCheckedState}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(TableViewer);
