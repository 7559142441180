import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import "../../components/Table/EditTransactionModal.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ViewTransactionTable = (props) => {
  const { show, setShow, children } = props;
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="bsEditModal viewTransactionModal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Transaction Table
          <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default memo(ViewTransactionTable);
