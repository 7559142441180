import React, { Fragment, memo, useContext, useEffect, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import PdfViewer from "./components/PdfViewer/PdfViewer";
import { Document, Page, pdfjs } from "react-pdf";
import CustomButton from "../Button/CustomButton";
import ParentContext from "../../context/context";
import Input from "../Input/Input";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import "./PageSelectionModal.css";
import { useToasts } from "../../Hooks";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PageSelectionModal = ({ modalShow, setModalShow, onSave }) => {
  const { state, dispatch } = useContext(ParentContext);
  const { file, selectedPages } = state;

  const [numPages, setNumPages] = useState(null);
  const [isChecked, setIsChecked] = useState([]);
  const [previewPage, setPreviewPage] = useState(1);
  const [pageInput, setPageInput] = useState("");
  const [scale, setScale] = useState(1.0);
  const [rotate, setRotate] = useState(0);
  const [showMessage, setShowMessage] = useState(true);
  const maxSelectedPages = 25;
  const { Toast } = useToasts();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 20000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (pageInput !== "") {
      handlePageInput(pageInput);
    }
  }, [pageInput]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsChecked(new Array(numPages).fill(false));
  };

  const handlePageClick = (pageNumber) => {
    const updatedIsChecked = [...isChecked];
    updatedIsChecked[pageNumber - 1] = !updatedIsChecked[pageNumber - 1];
    setIsChecked(updatedIsChecked);
    if (updatedIsChecked[pageNumber - 1]) {
      if (selectedPages.length < maxSelectedPages) {
        dispatch({
          type: "SET_SELECTED_PAGES",
          payload: [...selectedPages, pageNumber],
        });
      } else {
        Toast("error", `You can only select up to ${maxSelectedPages} pages.`);
        updatedIsChecked[pageNumber - 1] = false;
        setIsChecked(updatedIsChecked);
      }
    } else {
      dispatch({
        type: "SET_SELECTED_PAGES",
        payload: selectedPages.filter((page) => page !== pageNumber),
      });
    }
  };

  const handlePreviewClick = (pageNumber) => {
    setPreviewPage(pageNumber);
  };

  const handlePageInput = (input) => {
    const pages = input.split(",");
    let newSelectedPages = [];

    pages.forEach((page) => {
      if (page.includes("-")) {
        const [start, end] = page.split("-").map((p) => parseInt(p.trim()));

        const validStart = Math.max(1, start);
        const validEnd = Math.min(end, numPages);

        for (let i = validStart; i <= validEnd; i++) {
          if (newSelectedPages.length < maxSelectedPages) {
            newSelectedPages.push(i);
          } else {
            break;
          }
        }
      } else {
        const pageNum = parseInt(page.trim());
        if (pageNum >= 1 && pageNum <= numPages) {
          if (newSelectedPages.length < maxSelectedPages) {
            newSelectedPages.push(pageNum);
          }
        }
      }
    });

    newSelectedPages = Array.from(new Set(newSelectedPages));
    dispatch({ type: "SET_SELECTED_PAGES", payload: newSelectedPages });

    const newIsChecked = new Array(numPages).fill(false);
    newSelectedPages.forEach((page) => {
      newIsChecked[page - 1] = true;
    });

    if (newSelectedPages.length > maxSelectedPages) {
      Toast("error", `You can only select up to ${maxSelectedPages} pages.`);
    }

    setIsChecked(newIsChecked);
  };

  const handleSelectAtleastOnePage = () => {
    if (selectedPages.length <= 0) {
      Toast(
        "warn",
        "Please select at least 1 page from the left hand side page selection panel."
      );
    } else {
      onSave();
      dispatch({
        type: "SET_SELECTED_PAGES",
        payload: [],
      });
      setModalShow(false);
    }
  };

  const handleClose = () => {
    setModalShow(false);
    dispatch({
      type: "SET_SELECTED_PAGES",
      payload: [],
    });
  };

  return (
    <Modal
      size="xl"
      show={modalShow}
      backdrop="static"
      onHide={() => setModalShow(false)}
    >
      <div className="page_selection_header">
        <div>
          <span className="heading">Page Selection</span>
          {showMessage && (
            <span className="note para" style={{ marginLeft: "30px" }}>
              Alert: InferlQ demo version supports upto {maxSelectedPages} pages per file. Please
              reach out to us for accessing the full suite of services.
            </span>
          )}
        </div>
        <CustomButton size="sm" variant="primary" onClick={handleClose}>
          X
        </CustomButton>
      </div>
      <hr />
      <div className="page_selection_body_container">
        <div className="page_selection_body_select_pdf_page ">
          <div className="custom_selection_input_container">
            <div className="para  custom_selection_input_header">
              Custom Selection:
            </div>
            <Input
              className="custom_selection_input"
              type="text"
              placeholder="eg:1, 4-6, 9"
              name={pageInput}
              value={pageInput}
              handleChange={(e) => setPageInput(e.target.value)}
            />
          </div>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <div
                key={`page-${index}`}
                onClick={() => handlePageClick(index + 1)}
                className="select_pdf_page_container"
                style={{
                  border: isChecked[index]
                    ? "2px solid #28a745"
                    : "2px solid rgba(16, 36, 71, 0.329)",
                }}
              >
                <div
                  className="select_pdf_page_no"
                  style={{
                    backgroundColor: isChecked[index]
                      ? "#28a745"
                      : "rgba(16, 36, 71, 0.329)",
                    color: isChecked[index] && "#fff",
                  }}
                >
                  {index + 1}
                </div>
                <Page
                  pageNumber={index + 1}
                  width={100}
                  onClick={() => handlePreviewClick(index + 1)}
                />
              </div>
            ))}
          </Document>
        </div>
        <div className="page_selection_body_preview_page">
          <ControlPanel
            scale={scale}
            setScale={setScale}
            rotate={rotate}
            setRotate={setRotate}
            previewPage={previewPage}
            setPreviewPage={setPreviewPage}
            numPages={numPages}
          />
          <PdfViewer
            pdf={file}
            scale={scale}
            rotate={rotate}
            onLoadSuccess={onDocumentLoadSuccess}
            pageNumber={previewPage}
          />
        </div>
      </div>
      <hr />
      <div className="page_selection_footer">
        <div style={{ display: "flex", width: "70%" }}>
          <span className="heading">selected Pages:</span>
          <span style={{ fontWeight: "500", marginLeft: "2px" }}>&#x5b;</span>
          {selectedPages.map((num) => (
            <div key={num} style={{ padding: "0px 3px" }}>
              <Badge bg="success">{num}</Badge>
            </div>
          ))}
          <span style={{ fontWeight: "500", marginLeft: "2px" }}>&#x5d;</span>
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton size="sm" variant="primary" onClick={handleClose}>
            Cancel
          </CustomButton>
          <span style={{ marginLeft: "10px" }}>
            <CustomButton
              size="sm"
              variant="primary"
              onClick={handleSelectAtleastOnePage}
            >
              Submit
            </CustomButton>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default memo(PageSelectionModal);
