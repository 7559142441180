import { useContext } from "react";
import ParentContext from "../../InferIq-Solution/context/context";
import axios from "axios";
import env from "../../InferIq-Solution/env/env.js";
import sign from "jwt-encode";
import { secretKeyJwt, token, userDetails } from "../../InferIq-Solution/Auth/authConfig.js";

const useRequestMonitoringApiCall = () => {
  const { dispatch } = useContext(ParentContext);

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const getRequestMonitoringData = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    dispatch({ type: "SET_RES_ERROR", payload: false });
    await axios
      .post(
        `${env.API_GATEWAY + "/requestmonitor"}`,
        {
          callType: "fetchRequestMonitoringData",
          pageNo: 1,
          userId: userDetails?.user_id,
          pageLimit: 20,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_REQUEST_MONITORING_DATA",
          payload: response.data,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
        dispatch({ type: "SET_RES_ERROR", payload: true });
      });
  };
  return { getRequestMonitoringData };
};

export default useRequestMonitoringApiCall;
