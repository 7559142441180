import React, { useState, useContext } from "react";
import "./ErrorBoundary.css";
import ParentContext from "../context/context";
import {
  FontAwesomeIcon,
  faExclamationTriangle,
} from "../import/FontAwesomeIcons";
import { ErrorModal } from "../components/index";

class ErrorBoundaryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorBoundaryMediaQuery: {
        width: this.props.isOpen && "100%",
        height: this.props.isOpen && "calc(100vh - 79px)",
      },
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  handleRedirect = () => {
    window.location.href = "/";
    this.props.setShow(false);
  };

  handleShow = () => {
    if (this.state.hasError) {
      this.props.setShow(true);
    }
  };

  handleResize = () => {
    const maxWidth = window.matchMedia("(max-width: 1280px)").matches;
    const minWidth = window.matchMedia("(min-width: 1536px)").matches;
    if (maxWidth) {
      this.setState((prevState) => ({
        errorBoundaryMediaQuery: {
          ...prevState.errorBoundaryMediaQuery,
          width: this.props.isOpen
            ? "calc(100vw - 290px)"
            : "calc(100vw - 125px)",
          height: this.props.isOpen && "calc(100vh - 79px)",
        },
      }));
    } else if (minWidth) {
      this.setState((prevState) => ({
        errorBoundaryMediaQuery: {
          ...prevState.errorBoundaryMediaQuery,
          width: this.props.isOpen && "100%",
          height: this.props.isOpen && "calc(100vh - 125px)",
        },
      }));
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="ErrorBoundaryContainer"
          style={this.state.errorBoundaryMediaQuery}
        >
          <ErrorModal
            show={this.handleShow}
            onHide={this.handleRedirect}
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
            onClick={this.handleRedirect}
            title="Something went wrong."
            descriptionHeading="The application has encountered an unexpected error. We
            apologize for the inconvenience."
            descriptionText=""
            buttonText="Refresh"
            centered={true}
            keyboard={false}
          />
        </div>
      );
    }
    return this.props.children;
  }
}

const ErrorBoundary = (props) => {
  const [show, setShow] = useState(false);
  const { state } = useContext(ParentContext);
  const { isOpen } = state;

  return (
    <ErrorBoundaryModal isOpen={isOpen} show={show} setShow={setShow}>
      {props.children}
    </ErrorBoundaryModal>
  );
};

export default ErrorBoundary;
