import React, { useContext } from "react";
import {
  FontAwesomeIcon,
  faUser,
  faHouse,
  faRightFromBracket,
} from "../../import/FontAwesomeIcons";
import "./DropDown.css";
import ParentContext from "../../context/context";
import { useNavigate } from "react-router";
import { useToasts } from "../../Hooks";
import { Dropdown } from "react-bootstrap";
import { userDetails } from "../../Auth/authConfig";

const DropDown = () => {
  const navigate = useNavigate();
  const { Toast } = useToasts();
  const { state, setLoggedIn } = useContext(ParentContext);

  const logout = () => {
    sessionStorage.removeItem("usd");
    sessionStorage?.removeItem("expirationTime");
    setLoggedIn(false);
    state.signInBackendRes = [];
    navigate("/");
    Toast(
      "info",
      "You have been successfully signed out. Thank you for using our application!"
    );
    window.location.reload();
  };

  const drop_Down_Links = [
    {
      id: 1,
      icon: faUser,
      path: "/profile",
      lable: "Profile",
    },
    {
      id: 2,
      icon: faHouse,
      path: "/faqs",
      lable: "FAQ's",
    },
    {
      id: 3,
      icon: faRightFromBracket,
      lable: "Logout",
      fun: () => logout(),
    },
  ];
  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
        {userDetails.name}
      </Dropdown.Toggle>
      {drop_Down_Links && (
        <Dropdown.Menu>
          {drop_Down_Links.map(({ id, icon, path, lable, fun }) => {
            return (
              <Dropdown.Item href={path} onClick={fun} key={id}>
                <FontAwesomeIcon icon={icon} />
                <span className="c-dropdown-item-links">{lable}</span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default DropDown;
