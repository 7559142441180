import React, { useContext, useEffect, useMemo } from "react";
import {
  BarChart,
  GroupedBarChart,
  HistogramChart,
  LineChart,
  // SunburstChart,
} from "../pages/DashBoard/components/charts/index";
// import { FontAwesomeIcon, faMaximize } from "../import/FontAwesomeIcons";
import {
  groupedBarChartResponse,
  // sunbrustChartResponse,
  histogramChartResponse,
} from "../pages/DashBoard/utils/ChartsResponse";
import ParentContext from "../context/context";
import env from "../env/env";
import axios from "axios";
import sign from "jwt-encode";
import { secretKeyJwt, token, userDetails } from "../Auth/authConfig";

const useCharts = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { dashboardResponse } = state;

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const fetchDashboardData = async () => {
    const apiUrl = `${env.API_GATEWAY}/dblambda`;
    try {
      const response = await axios.post(
        apiUrl,
        { callType: "Dashboard", userId: userDetails?.user_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      );
      dispatch({ type: "SET_DASHBOARD_RESPONSE", payload: response.data });
      dispatch({ type: "SET_RES_ERROR", payload: false });
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
      dispatch({ type: "SET_RES_ERROR", payload: true });
    }
  };

  useEffect(() => {
    fetchDashboardData();
    const fetchDataInterval = setInterval(() => {
      fetchDashboardData();
    }, 300000);
    return () => {
      clearInterval(fetchDataInterval);
    };
  }, []);

  const userGraphs = useMemo(() => {
    return [
      {
        key: 1,
        title: dashboardResponse?.bar_chart1?.title,
        chart: <BarChart barChartResponse={dashboardResponse?.bar_chart1} />,
      },
      {
        key: 2,
        title: dashboardResponse?.line_chart1?.title,
        chart: <LineChart lineChartResponse={dashboardResponse?.line_chart1} />,
      },
      {
        key: 3,
        title: dashboardResponse?.grouped_bar1?.title,
        chart: (
          <GroupedBarChart
            groupedBarChartResponse={dashboardResponse?.grouped_bar1}
          />
        ),
      },
      // {
      //   key: 4,
      //   title: sunbrustChartResponse?.title,
      //   chart: <SunburstChart data={sunbrustChartResponse} height="500px" />,
      //   toggel: <FontAwesomeIcon icon={faMaximize} />,
      // },
      {
        key: 5,
        title: dashboardResponse?.bar_chart2?.title,
        chart: <BarChart barChartResponse={dashboardResponse?.bar_chart3} />,
      },
      {
        key: 6,
        title: dashboardResponse?.bar_chart3?.title,
        chart: <BarChart barChartResponse={dashboardResponse?.bar_chart3} />,
      },
      {
        key: 7,
        title: dashboardResponse?.grouped_bar2?.title,
        chart: (
          <GroupedBarChart
            groupedBarChartResponse={dashboardResponse?.grouped_bar2}
          />
        ),
      },
      {
        key: 8,
        title: dashboardResponse?.bar_chart4?.title,
        chart: <BarChart barChartResponse={dashboardResponse?.bar_chart4} />,
      },
      {
        key: 9,
        title: histogramChartResponse?.title,
        chart: (
          <HistogramChart histogramChartResponse={histogramChartResponse} />
        ),
      },
      // {
      //   key: 10,
      //   title: histogramChartResponse?.title,
      //   chart: (
      //     <HistogramChart histogramChartResponse={histogramChartResponse} />
      //   ),
      // },
    ];
  }, [dashboardResponse]);

  const adminGraphs = useMemo(() => {
    return [
      {
        key: 1,
        title: "Graph 1",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 2,
        title: "Graph 2",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 3,
        title: "Graph 3",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 4,
        title: "Graph 4",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 5,
        title: "Graph 5",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 6,
        title: "Graph 6",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 7,
        title: "Graph 7",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 8,
        title: "Graph 8",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 9,
        title: "Graph 9",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
      {
        key: 10,
        title: "Graph 10",
        chart: (
          <GroupedBarChart groupedBarChartResponse={groupedBarChartResponse} />
        ),
      },
    ];
  }, []);
  return { userGraphs, adminGraphs };
};

export default useCharts;
