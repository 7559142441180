import React, { useContext, useState } from "react";
import { Row, Col, Stack } from "react-bootstrap";
import ParentContext from "../../context/context";
import { Radio, CustomButton, DragAndDrop } from "../index.js";
import "../FileUploadForm/FileUploadForm.css";
import { useHandleApi, useToasts } from "../../Hooks/index";
import { userPermission } from "../../Auth/authConfig.js";
import PageSelectionModal from "../PageSelectionModal/PageSelectionModal.jsx";

const FileUploader = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const { state, dispatch } = useContext(ParentContext);
  const {
    selectedValue,
    serviceType,
    multipleFileCheck,
    resetUploads,
    uploadedFiles,
    fileRemoved,
    file,
    selectedPages,
    displayText,
  } = state;
  const { handleSubmit } = useHandleApi();

  const { Toast } = useToasts();
  const { BatchProcessing, RadioHandler, RadioOptions, ButtonText } = props;

  const isRequiredFieldsFilled =
    uploadedFiles.length <= 0 || !selectedValue || !fileRemoved || !displayText;

  const submitCall = (event) => {
    event?.preventDefault();
    if (isRequiredFieldsFilled) {
      Toast(
        "error",
        "Input variable missing, please check if file type is selected or file is uploaded."
      );
    } else {
      handleSubmit(event, selectedPages);
    }
  };

  const showPageSelection = (event) => {
    event?.preventDefault();
    if (isRequiredFieldsFilled) {
      Toast(
        "error",
        "Input variable missing, please check if file type is selected or file is uploaded."
      );
    } else {
      if (file.type === "application/pdf") {
        setModalShow(true);
      } else {
        submitCall();
      }
    }
  };
  let currentPath = window.location.pathname;
  const renderRadioOptions = (radioOptions) => {
    return radioOptions
      .filter((option) => option.path === currentPath)
      .map(({ id, label, group }) => {
        return (!multipleFileCheck && label !== "Tax Forms") ||
          label === "Tax Forms" ? (
          <div className="radio-wrapper" key={id}>
            <Stack direction="horizontal" gap={2}>
              <Radio
                label={label}
                value={label}
                Checked={selectedValue === label}
                group={group}
                onChange={RadioHandler}
              />
            </Stack>
          </div>
        ) : null;
      });
  };

  const renderSidebarRoutes = () => {
    return userPermission?.map((route) => (
      <span key={route.id} style={{ display: "flex", flexWrap: "wrap" }}>
        {route.path === currentPath && renderRadioOptions(route.RadioOptions)}
      </span>
    ));
  };

  return (
    <form
      onSubmit={submitCall}
      style={{
        marginTop: serviceType === "Conversational AI" ? "-0.8rem" : "",
      }}
    >
      <Row>
        <Col xs={12}>
          {BatchProcessing && (
            <div className="fileupload-component-switch">{BatchProcessing}</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {RadioOptions && (
            <div className="fileupload-component-radio">
              {renderSidebarRoutes()}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div
            style={{
              paddingTop: serviceType === "Rent Roll" ? "2rem" : "0",
            }}
            className="fileupload-component-upload"
          >
            <p>upload files</p>
            <DragAndDrop
              style={{ width: "100%", height: "100%", minHeight: "18rem" }}
              multiple={multipleFileCheck}
              resetUploads={resetUploads}
              setFile={(file) => dispatch({ type: "SET_FILE", payload: file })}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 10, offset: 2 }}>
          {ButtonText && (
            <div className="fileupload-component-btn">
              <CustomButton
                variant="primary"
                size="lg"
                onClick={showPageSelection}
              >
                {ButtonText}
              </CustomButton>
            </div>
          )}
        </Col>
      </Row>
      {modalShow && (
        <PageSelectionModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          onSave={submitCall}
        />
      )}
    </form>
  );
};

export default FileUploader;
