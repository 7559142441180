import Spinner from "../Spinner/Spinner";
import "./ChartsFallBackUi.css";
import Dashboardwarning from "../../../../images/svg/Dashboardwarning.svg";

const ChartsFallBackUi = () => {
  return (
    <div className="chart-fallback-container">
      <Spinner />
      <div className="chart-fallback-container-content">
        <img src={Dashboardwarning} alt="Warning" width="25px" height="25px" />
        <p className="para">Loading data, Please wait...</p>
      </div>
    </div>
  );
};

export default ChartsFallBackUi;
