export default [
    {
        id: Math.random(),
        label: "Verification Code",
        type: "text",
        name: "verificationCode",
        placeholder: "Enter your verification code...",
        required: true
    },
    {
        id: Math.random(),
        label: "New Password",
        type: "password",
        name: "password",
        placeholder: "Enter your new password...",
        required: true
    }, {
        id: Math.random(),
        label: "Confirm Password",
        type: "password",
        name: "confirmPassword",
        placeholder: "Re-enter your new password...",
        required: true
    },
]