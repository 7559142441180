const AdminControlledRedactionOptions = [
  {
    id: 1,
    label: "Mandatory Auto Redaction Even In Manual Redaction",
    value: "Mandatory Auto Redaction Even In Manual Redaction",
  },
  {
    id: 2,
    label: "Apply Mandatory Redaction",
    value: "Apply Mandatory Redaction",
  },
  {
    id: 3,
    label: "Automatic Redaction Only",
    value: "Automatic Redaction Only",
  },
  {
    id: 4,
    label: "Disable Redaction",
    value: "Disable Redaction",
  },
];

const AdminControlledQuestionnaireOptions = [
  {
    id: 1,
    label: "Edit Template Visible Only To Admins",
    value: "Edit Template Visible Only To Admins",
  },
  {
    id: 2,
    label: "Disable Add Question Button",
    value: "Disable Add Question Button",
  },
  {
    id: 3,
    label: "Disable Delete Question Button",
    value: "Disable Delete Question Button",
  },
];
const TemplateOptions = [
  {
    id: 1,
    label: "Form - 1040",
    description:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste esseLorem ipsum dolor sit amet consectetur adipisicing elit. Iste esseLorem ipsum dolor sit amet consectetur adipisicing elit. Iste esseLorem ipsum dolor sit amet consectetur adipisicing elit. Iste esseLorem ipsum dolor sit amet consectetur adipisicing elit. Iste esseLorem ipsum dolor sit amet consectetur adipisicing elit. Iste esse",
    questions: [
      {
        id: 1,
        question: "form - 1040",
      },
      {
        id: 2,
        question: "form - 1040",
      },
    ]
  },
  {
    id: 2,
    label: "Financial Statement",
    description:
      " accusantium amet aliquid commodi, velit, odit quae, culpa nemo error",
    questions: [
      {
        id: 1,
        question: "form - 1065",
      },
      {
        id: 2,
        question: "form - 1065",
      },
    ]
  },
  {
    id: 3,
    label: "Invoice",
    description:
      "a placeat porro atque tempora veritatis eum temporibus! Assumenda,",
    questions: [
      {
        id: 1,
        question: "form - 1120",
      },
      {
        id: 2,
        question: "form - 1120",
      },
    ]
  },
  {
    id: 4,
    label: "Legal Docs",
    description:
      "a placeat porro atque tempora veritatis eum temporibus! Assumenda,",
    questions: [
      {
        id: 1,
        question: "form - 1120s",
      },
      {
        id: 2,
        question: "form - 1120s",
      },
    ]
  },
];



export {
  AdminControlledRedactionOptions,
  AdminControlledQuestionnaireOptions,
  TemplateOptions,
};
