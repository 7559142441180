const useRenderLine = () => {
  const renderTextInNewLines = (text) => {
    const lines = text?.split("\n");
    return lines?.map((line, index) => (
      <span key={index} style={{ display: "block", marginBottom: "6px" ,lineHeight:"30px"}}>
        {line}
      </span>
    ));
  };
  return { renderTextInNewLines };
};

export default useRenderLine;
