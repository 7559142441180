import React, { useEffect, useState, memo } from "react";
import { FontAwesomeIcon } from "../../../../import/FontAwesomeIcons";
import { InputBox, CustomButton } from "../../../../components/index";
import "./Template.css";

const Template = (props) => {
  const {
    TemplateOptions,
    title,
    editIcon,
    deleteIcon,
    saveIcon,
    addIcon,
    addButtonText,
    isEditTemplateDisabled,
    isAddQuestionDisabled,
    isDeleteQuestionDisabled,
  } = props;

  const [taxForms, setTaxForms] = useState(TemplateOptions);
  const [editedLabel, setEditedLabel] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newField, setNewField] = useState("");
  const [showNewFieldInput, setShowNewFieldInput] = useState(false);
  // Load taxForms from localStorage on component mount
  useEffect(() => {
    const storedTaxForms = localStorage.getItem("taxForms");
    if (storedTaxForms) {
      setTaxForms(JSON.parse(storedTaxForms));
    }
  }, []);

  // Save taxForms to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("taxForms", JSON.stringify(taxForms));
  }, [taxForms]);

  const handleEdit = (id) => {
    const taxFormToEdit = taxForms.find((form) => form.id === id);
    setEditedLabel(taxFormToEdit.label);
    setIsEditing(id);
  };

  const handleDelete = (id) => {
    const updatedTaxForms = taxForms.filter((form) => form.id !== id);
    setTaxForms(updatedTaxForms);
  };

  const handleSave = (id) => {
    const updatedTaxForms = taxForms.map((form) => {
      if (form.id === id) {
        return { ...form, label: editedLabel };
      }
      return form;
    });
    setTaxForms(updatedTaxForms);
    setIsEditing(false);
  };

  const handleAddField = () => {
    const newId =
      taxForms.length > 0 ? taxForms[taxForms.length - 1].id + 1 : 1;
    const newTaxForm = { id: newId, label: newField };
    const updatedTaxForms = [...taxForms, newTaxForm];
    setTaxForms(updatedTaxForms);
    setNewField("");
    setShowNewFieldInput(false);
  };

  const handleInputChange = (event) => {
    setNewField(event.target.value);
  };

  const handleEditInputChange = (event) => {
    setEditedLabel(event.target.value);
  };

  const handleShowNewFieldInput = () => {
    setShowNewFieldInput(true);
  };

  return (
    <div className="template-container">
      <h6>{title}</h6>
      <hr />
      <div className="templates">
        {taxForms.map((taxForm) => (
          <div key={taxForm.id} className="template-items">
            {!isEditing || isEditing !== taxForm.id ? (
              <React.Fragment>{taxForm.label}</React.Fragment>
            ) : (
              <InputBox
                type="text"
                size="sm"
                value={editedLabel}
                onChange={handleEditInputChange}
                disabled={false}
              />
            )}
            {!isEditing || isEditing !== taxForm.id ? (
              <div className="template-edit-and-delete">
                <React.Fragment>
                  <div className="template-edit">
                    {editIcon && (
                      <CustomButton
                        size="sm"
                        variant="primary"
                        onClick={() => handleEdit(taxForm.id)}
                        disabled={isAddQuestionDisabled}
                      >
                        <FontAwesomeIcon icon={editIcon} />
                      </CustomButton>
                    )}
                  </div>
                  <div className="template-delete">
                    {deleteIcon && (
                      <CustomButton
                        size="sm"
                        variant="danger"
                        onClick={() => handleDelete(taxForm.id)}
                        disabled={isDeleteQuestionDisabled}
                      >
                        <FontAwesomeIcon icon={deleteIcon} />
                      </CustomButton>
                    )}
                  </div>
                </React.Fragment>
              </div>
            ) : (
              <React.Fragment>
                {saveIcon && (
                  <CustomButton
                    size="sm"
                    variant="success"
                    onClick={() => handleSave(taxForm.id)}
                  >
                    <FontAwesomeIcon icon={saveIcon} />
                    <span className="template-save">Save</span>
                  </CustomButton>
                )}
              </React.Fragment>
            )}
          </div>
        ))}
      </div>
      <div className="add-new-template">
        {!showNewFieldInput && (
          <div className="add-new-template-btn">
            {addIcon && (
              <CustomButton
                size="md"
                variant="info"
                onClick={handleShowNewFieldInput}
                disabled={isEditTemplateDisabled}
              >
                <FontAwesomeIcon icon={addIcon} />
                <span className="create-new-template">{addButtonText}</span>
              </CustomButton>
            )}
          </div>
        )}
      </div>
      {showNewFieldInput && (
        <div className="add-new-template">
          <div className="add-new-template-input">
            <center>
              <InputBox
                type="text"
                size="sm"
                value={newField}
                onChange={handleInputChange}
                placeholder="Add New Template"
                disabled={false}
              />
            </center>
          </div>
          <div className="add-new-template-btn">
            {addIcon && (
              <CustomButton
                size="sm"
                variant="info"
                onClick={handleAddField}
                disabled={newField.length <= 0}
              >
                <FontAwesomeIcon icon={addIcon} />
                <span className="create-new-template">Add</span>
              </CustomButton>
            )}
            <span style={{ marginLeft: "4px" }}>
              <CustomButton
                size="sm"
                variant="primary"
                onClick={() => setShowNewFieldInput(false)}
              >
                Back
              </CustomButton>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Template);
