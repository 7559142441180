import React, { memo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartsFallBackUi from "../ChartsFallBackUi/ChartsFallBackUi";
import colors from "../../../../utils/graphColors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const BarChart = (props) => {
  const { barChartResponse } = props;

  if (!barChartResponse && !barChartResponse?.datasets) {
    return <ChartsFallBackUi />;
  }

  const datasetsWithStyles = barChartResponse?.datasets?.map(
    (dataset, index) => ({
      ...dataset,
      borderColor: colors[index % colors.length],
      backgroundColor: colors[index % colors.length],
    })
  );

  const dataWithStyles = {
    ...barChartResponse,
    datasets: datasetsWithStyles,
  };
  return <Bar options={options} data={dataWithStyles} />;
};

export default memo(BarChart);
