import React, { memo } from "react";
import "./LoginPage.css";
import { Container, Col, Row } from "react-bootstrap";
import { Accelerate, Analyze, Streamline } from "../../images/svg/index";
import { LoginLeftContainer } from "./LoginLeftContainer";
import LoginRightContainer from "./LoginRightContainer";

const LoginPage = () => {
  let Images = [
    {
      id: 1,
      img: Streamline,
      text: "Streamline",
    },
    {
      id: 2,
      img: Analyze,
      text: "Analyze",
    },
    {
      id: 3,
      img: Accelerate,
      text: "Accelerate",
    },
  ];

  return (
    <Container>
      <div className="loginpage">
        <Row xs={12}>
          <Col xs={7}>
            <LoginLeftContainer Images={Images} />
          </Col>
          <Col xs={5}>
            <Row xs={12}>
              <LoginRightContainer />
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default memo(LoginPage);
