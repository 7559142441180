import React, { useCallback, memo } from "react";
import "./UserManagment.css";
import { CustomButton } from "../../../../components/index";
import {
  FontAwesomeIcon,
  faAngleLeft,
} from "../../../../import/FontAwesomeIcons";
import { useNavigate } from "react-router";
import { EditableTable } from "../index";

const UserManagment = () => {
  const headers = ["S.No", "Name", "Role", "Created", "Status", "Edit"];
  const values = [
    {
      id: 1,
      name: "John",
      roll: "Admin",
      created: "23 - Dec - 23",
      Status: "INACTIVE",
      edit: "Edit",
    },
    {
      id: 2,
      name: "Jane",
      roll: "User",
      created: "23 - Dec - 23",
      Status: " ACTIVE",
      edit: "Edit",
    },
    {
      id: 3,
      name: "Mark",
      roll: "User",
      created: "23 - Dec - 23",
      Status: "PENDING",
      edit: "Edit",
    },
  ];

  const navigate = useNavigate();

  const BackToSettings = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  return (
    <div className="user_managment">
      <div className="user_managment_header">
        <CustomButton
          size="md"
          variant="secondary"
          onClick={() => BackToSettings()}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          <span className="user_managment_back_btn">Back</span>
        </CustomButton>
        {/* <CustomButton size="md" variant="info">
          <span className="user_managment_export_btn">Export</span>
          <FontAwesomeIcon icon={faFileExport} />
        </CustomButton> */}
      </div>
      <div>
        <EditableTable headers={headers} values={values} />
      </div>
    </div>
  );
};

export default memo(UserManagment);
