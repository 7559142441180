import React from "react";
import { Tab, Tabs } from "react-bootstrap";

const RedactedPlainText = (props) => {
  return (
    <Tabs
      defaultActiveKey="Redacted PlainText"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="Redacted PlainText" title="Redacted PlainText">
        <div data-testid="Redaction_table_maincontainer" className="Redaction-plaintext">
          {props.responseText?.split?.("\n")?.map((item) => {
            return (
              <span key={item} className="para">
                {props.renderTextInNewLines(item)}
              </span>
            );
          })}
        </div>
      </Tab>
    </Tabs>
  );
};

export default RedactedPlainText;
