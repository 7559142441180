import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ExtractionReport.css";
import "../EditTransactionModal.css";

const ExtractionReport = ({ tableAccNo, setShowAccReport, accReport }) => {
  const ACCOUNT_REPORTS = accReport?.report_analysis?.data;
  const BANK_NAME = accReport?.report_analysis?.bank_name;
  const UNSTRUCTURED_DATA = accReport?.report_analysis?.unstructured_data
  return (
    <div className="extraction_report_wrap">
      <div className="extraction_report_header">
        <FontAwesomeIcon
          className="extraction_report_closeBtn"
          icon={faTimes}
          onClick={() => setShowAccReport(false)}
        />
        <h1 className="extraction_report_header_name">
          Account Report Analysis
        </h1>
      </div>
      <div className="extraction_report_details">
        <table id="BsTable" className="table1">
          <thead className="thead1">
            <tr
              className="tr1"
              style={{ width: "100%", border: "2px solid #dddddd" }}
            >
              <th
                className="th1"
                style={{ border: "unset", fontWeight: "bold" }}
              >
                Bank Name: {BANK_NAME}
              </th>
            </tr>
            <tr className="tr1">
              <th className="th1">Date</th>
              <th className="th1">Balance Mismatch</th>
              <th className="th1">Amount Difference</th>
            </tr>
          </thead>
          <tbody className="tbody1">
            {Object.entries(ACCOUNT_REPORTS[tableAccNo]).map(
              ([date, info], index) => {
                return (
                  <tr className="tr1" key={index}>
                    <td className="td1">{date}</td>
                    <td className="td1">
                      {info?.balace_mismatch ? "Yes" : "No"}
                    </td>
                    <td className="td1">{info?.amount_diff}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
      <div className="unstructured_data">
      <div className="bsEditModalForm">
          <div className="wrap">
            {UNSTRUCTURED_DATA[tableAccNo] && Object.entries(UNSTRUCTURED_DATA[tableAccNo])?.map(([key, value], index) => {
              return (
                <div key={index} className="editField">
                  <label>{key?.replace("_", " ")}</label>
                  <span>{value}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtractionReport;
