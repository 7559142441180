import React, {
  useContext,
  useState,
  useCallback,
  memo,
  useEffect,
  useRef,
  useMemo,
} from "react";
import "./OutputScreen.css";
import ParentContext from "../../context/context";
import { useNavigate } from "react-router-dom";
import TabTitleContext from "../../context/TabTitleContext";
import {
  NerText,
  RedactionPdfViewer,
  ImageViewer,
  ControlPanel,
  PdfViewer,
} from "../../components/index";
import { GenaiTabs } from "../../services/Generative AI/components/index";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { GenAiTabViewer, TableViewer } from "./components/index";
import ErrorHandler from "./components/ErrorHandler";
import ExcelViewer from "../../components/ExcelViewer/ExcelViewer";
import { initialPath } from "../../Auth/authConfig";
import ViewTransactionTableModal from "./ViewTransactionTableModal";

const OutputScreen = () => {
  const { state, dispatch } = useContext(ParentContext);
  const {
    backendResponse,
    serviceType,
    multipleFileCheck,
    selectedOption,
    numPages,
    pageNumber,
    scale,
    rotate,
    responseText,
    redactionDoc,
    redactionOutputFile,
    selectedValue,
    currentPage,
    file,
    excelData,
  } = state;
  let MultipleFiles = backendResponse;
  let response = backendResponse;
  let indexing, Allfiles, url, file_extension;

  const [currentTab, setCurrentTab] = useState(
    multipleFileCheck
      ? response?.[0]?.[0]?.DocType
      : backendResponse?.Data?.[0]?.TabTitle || ""
  );
  const [checkedState, setCheckedState] = useState([]);
  const is_Button_Disabled = checkedState.every((checked) => !checked);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isViewTableModal, setIsViewTableModal] = useState(false);
  const [showAccReport, setShowAccReport] = useState(false);
  const [searchRow, setSearchRow] = useState({});
  const rowRefs = useRef([]);

  const DID_NOT_GET_THE_RESPONSE_FROM_API = "Did not get the response from API";
  const SOMETHING_WENT_WRONG = "Something went wrong";

  const button_Styles = {
    backgroundColor: is_Button_Disabled ? "rgb(79 89 106)" : "rgb(16, 36, 71)",
  };

  if (file) {
    if (file.type === "application/pdf") {
      url = file;
    } else {
      url = URL.createObjectURL(file);
    }
  }
  file_extension = file?.name?.split(".")?.pop();

  const navigate = useNavigate();

  const BackToHome = useCallback(() => {
    navigate(initialPath);
  }, [navigate]);

  if (multipleFileCheck) {
    let j = 0;
    Allfiles = [];
    let files, fileName;
    for (j; j < Object.keys(MultipleFiles[0]).length; j++) {
      files = MultipleFiles[0][j]?.display_file;
      fileName = files?.substring(files?.lastIndexOf("/") + 1);
      Allfiles.push(fileName);
    }
    indexing = Allfiles.indexOf(selectedOption);
    if (indexing === -1) {
      indexing = 0;
    }
    response = MultipleFiles[0][indexing];
    url = `${response?.display_file}`;
    file_extension = url?.split(".").at(-1);
  }

  useEffect(() => {
    dispatch({
      type: "SET_CURRENT_PAGE",
      payload: Object.keys(backendResponse?.Data ?? {})?.[0],
    });
  }, []);

  let BankStatementResponse;
  if (
    selectedValue === "Credit Statement" ||
    selectedValue === "Forms" ||
    selectedValue === "IRS Forms" ||
    selectedValue === "Application Form" &&
  serviceType === "Extraction" && currentPage) {
    BankStatementResponse = currentPage && Object.values(backendResponse?.Data?.[currentPage] ?? {});
  } else if (selectedValue === "Bank Statement" && serviceType == "Bank Statement") {
    BankStatementResponse = backendResponse
  }

  let all_tabs = [];
  if (selectedValue !== "Bank Statement") {
    if (response && Array?.isArray(response?.Data)) {
      response?.Data?.forEach((blocks) => {
        all_tabs?.push(blocks.TabTitle);
      });
    }
    all_tabs = [...new Set(all_tabs)];
  }

  // BS_all_tabs only for Bank Statement
  const BS_all_tabs = backendResponse?.report_analysis?.account_num

  const kvpTdData = () => {
    return response?.Data?.filter((block) => {
      return currentTab === block.TabTitle && block.BlockType === "kvp";
    }).map((block, index) => {
      return (
        <tr key={index} className="tr">
          {selectedValue === "Plain Text" &&
          block.TabTitle === "Text" ? null : (
            <td className="td">{block.Key}</td>
          )}
          <td className="td">
            {selectedValue === "Plain Text" && block.TabTitle === "Text" ? (
              <NerText nerResponse={backendResponse?.Data?.[0]?.Value} />
            ) : (
              block.Value
            )}
          </td>
        </tr>
      );
    });
  };
  let tableTdData;
  if (Array.isArray(backendResponse?.Data)) {
    tableTdData = backendResponse?.Data?.filter((block) => {
      return currentTab === block?.TabTitle && block?.BlockType === "table";
    });
  }
  let tableThData = [];
  let tableTrData = [];

  if (tableTdData) {
    tableTdData?.forEach((block) => {
      if (!tableThData.includes(block.Key)) {
        tableThData.push(block.Key);
      }

      if (block.Value !== "[]") {
        tableTrData.push(block.Value);
      }
    });
  }

  let RedactedUrl;
  if (responseText?.[1] !== undefined) {
    RedactedUrl = url;
    file_extension = RedactedUrl?.name?.split(".")?.pop();
  }

  let viewerComponent;
  const isPdf = file_extension === "pdf";
  const isXls = ["xls", "xlsx", "csv"].includes(file_extension);
  let isImage = ["jpg", "jpeg", "png"].includes(file_extension);

  if (responseText) {
    isImage = true;
  }

  const handleRowSelection = (index) => {
    setSelectedRow(index);
    rowRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    }); // Scroll the table row into view
  };

  const searchRowInPDF = (textractID, pageNum) => {
    setSearchRow(textractID);
    pageNum && dispatch({
      type: "SET_PAGE_NUMBER",
      payload: pageNum,
    })
  }

  if (isPdf) {
    viewerComponent =
      redactionOutputFile && selectedValue === "Forms" ? (
        <div className="Redaction-pdfViewer-component">
          <RedactionPdfViewer
            title="Redacted Document:"
            pdf={redactionDoc?.display_file}
          />
        </div>
      ) : serviceType === "Bank Statement" ? (
        <PdfViewer
          pdf={url}
          onRowClick={handleRowSelection}
          selectedRow={selectedRow}
          searchRow={searchRow}
          setSearchRow={searchRowInPDF}
          serviceType={serviceType}
          backendResponse = {backendResponse}
        />
      ) : (
        <PdfViewer
          pdf={url}
          serviceType={serviceType}
        />
      );
  } else if (isXls) {
    viewerComponent = <ExcelViewer excelData={excelData} />;
  } else if (isImage) {
    viewerComponent = (
      <ImageViewer
        image={
          serviceType === "Recognition"
            ? backendResponse?.display_file?.display_file
            : url
        }
      />
    );
  }

  let TabViewer, outPutLayout;
  if (serviceType === "Conversational AI") {
    TabViewer = (
      <GenAiTabViewer
        file_extension={file_extension}
        viewerComponent={viewerComponent}
      />
    );
  } else {
    TabViewer = (
      <React.Fragment>
        <div className="outputscreen-controllpannel">
          {file_extension === "pdf" && !redactionOutputFile && (
            <ControlPanel
              scale={scale}
              setScale={(scale) =>
                dispatch({ type: "SET_SCALE", payload: scale })
              }
              numPages={numPages}
              pageNumber={pageNumber}
              setPageNumber={(pageNumber) =>
                dispatch({
                  type: "SET_PAGE_NUMBER",
                  payload: pageNumber,
                })
              }
              rotate={rotate}
              setRotate={(rotate) =>
                dispatch({ type: "SET_ROTATE", payload: rotate })
              }
            />
          )}
        </div>
        <div className="outputscreen-pdfviewer">{viewerComponent}</div>
      </React.Fragment>
    );
  }

  if (serviceType === "Conversational AI") {
    outPutLayout = <GenaiTabs />;
  } else if (serviceType === "Bank Statement") {
    outPutLayout = (
      <TableViewer
        RedactedUrl={RedactedUrl}
        kvpTdData={kvpTdData}
        tableThData={tableThData}
        tableTrData={tableTrData}
        Allfiles={Allfiles}
        is_Button_Disabled={is_Button_Disabled}
        button_Styles={button_Styles}
        all_tabs={BS_all_tabs}
        bank_stt_tabs = {BS_all_tabs}
        BankStatementResponse={BankStatementResponse}
        checkedState={is_Button_Disabled}
        setCheckedState={setCheckedState}
        selectedRow={selectedRow}
        rowRefs={rowRefs}
        isViewTableModal={isViewTableModal}
        setIsViewTableModal={() => setIsViewTableModal(!isViewTableModal)}
        searchRow={searchRow}
        setSearchRow={searchRowInPDF}
        setSelectedRow={(textractID) => setSelectedRow(textractID)}
        showAccReport={showAccReport}
        setShowAccReport={setShowAccReport}
        tableResponse={backendResponse}
      />
    );
  } else {
    outPutLayout = (
      <TableViewer
        RedactedUrl={RedactedUrl}
        kvpTdData={kvpTdData}
        tableThData={tableThData}
        tableTrData={tableTrData}
        Allfiles={Allfiles}
        is_Button_Disabled={is_Button_Disabled}
        button_Styles={button_Styles}
        all_tabs={all_tabs}
        BankStatementResponse={BankStatementResponse}
        checkedState={is_Button_Disabled}
        setCheckedState={setCheckedState}
      />
    );
  } 

  return (
    <React.Fragment>
      {(backendResponse === undefined ||
        backendResponse === null ||
        backendResponse === DID_NOT_GET_THE_RESPONSE_FROM_API ||
        backendResponse === SOMETHING_WENT_WRONG ||
        backendResponse?.length === 0) && (
        <ErrorHandler
          backendResponse={backendResponse}
          BackToHome={BackToHome}
        />
      )}
      {backendResponse?.length !== 0 &&
        backendResponse !== null &&
        backendResponse !== DID_NOT_GET_THE_RESPONSE_FROM_API &&
        backendResponse !== SOMETHING_WENT_WRONG &&  (
          <TabTitleContext.Provider
            value={{
              currentTab,
              setCurrentTab,
              response,
            }}
          >
            <div className="outputscreen">
              <PanelGroup direction="horizontal" className="custom-pannelg">
                <Panel className="custom-panel" defaultSize={50} minSize={35}>
                  <div className="outputscreen-section1">{TabViewer}</div>
                </Panel>
                <PanelResizeHandle className="custom-resize-handle" />
                <Panel className="custom-panel" defaultSize={50} minSize={35}>
                  <div className="outputscreen-section2">{outPutLayout}</div>
                </Panel>
              </PanelGroup>
              {isViewTableModal && (
                <ViewTransactionTableModal
                  children={outPutLayout}
                  show={isViewTableModal}
                  setShow={setIsViewTableModal}
                />
              )}
            </div>
          </TabTitleContext.Provider>
        )}
    </React.Fragment>
  );
};

export default memo(OutputScreen);
