import React, { useMemo, useRef } from "react";
import LeaderLine from "leader-line-new";
import colors from "../../utils/nerTextColorSource";
import "./NerText.css";

const NerText = ({ nerResponse }) => {
  let map_Element_By_Type;

  const entity_Types = useMemo(() => {
    const types = new Set();
    nerResponse?.forEach(({ entities }) => {
      entities?.forEach(({ type }) => {
        types?.add(type);
      });
    });
    return Array?.from(types);
  }, [nerResponse]);

  const Random_Color_Map = useMemo(() => {
    map_Element_By_Type = {};
    entity_Types?.forEach((type, index) => {
      map_Element_By_Type[type] = colors[index % colors?.length];
    });
    return map_Element_By_Type;
  }, [entity_Types]);

  const start_Element_Ref = useRef();
  const entity_Refs = {};

  const create_Connection_Between_Entites = (
    start_Element,
    end_Element,
    rel
  ) => {
    const line = new LeaderLine(
      LeaderLine.mouseHoverAnchor(start_Element, "draw", {
        style: { backgroundColor: start_Element.style.backgroundColor },
      }),
      end_Element,
      {
        startSocket: "top",
        endSocket: "top",
        path: "grid",
        startPlug: "disc",
        endPlug: "arrow",
        startPlugColor: "#1a6be0",
        endPlugColor: "#1efdaa",
        size: 3,
        gradient: true,
        backgroundColor: null,
        dash: { animation: true },
      }
    );
    let initial_Background_Color = start_Element.style.backgroundColor;
    start_Element.addEventListener("mouseenter", () => {
      const { top, left, width } = start_Element.getBoundingClientRect();
      const {
        top: endTop,
        left: endLeft,
        width: endWidth,
      } = end_Element.getBoundingClientRect();

      const tooltipWrapper = document.createElement("div");
      tooltipWrapper.style.position = "fixed";
      tooltipWrapper.style.top = `${top - 33}px`;
      tooltipWrapper.style.left = `${left + width / 2}px`;
      tooltipWrapper.style.transform = `translateX(${
        (endLeft - left) / 5
      }px) translateY(-100%)`;
      tooltipWrapper.style.backgroundColor = "#43425D";
      tooltipWrapper.style.color = "#F0F0F7";
      tooltipWrapper.style.fontSize = "10px";
      tooltipWrapper.style.fontWeight = "500";
      tooltipWrapper.style.letterSpacing = "1px";
      tooltipWrapper.style.padding = "0PX 1px";
      tooltipWrapper.style.marginBottom = "5PX";
      tooltipWrapper.style.border = "3px solid rgba(255, 255, 255, 0.8)";
      tooltipWrapper.style.borderRadius = "5px";
      tooltipWrapper.style.boxShadow = "1px 1px 1px 0px rgba(0, 0, 0, 0.75)";
      tooltipWrapper.style.display = "flex";
      tooltipWrapper.style.flexDirection = "column";
      tooltipWrapper.style.justifyContent = "flex-start";
      tooltipWrapper.style.alignItems = "flex-start";
      tooltipWrapper.style.flexWrap = "wrap";
      tooltipWrapper.style.width = "auto";
      tooltipWrapper.style.maxWidth = "200px";
      tooltipWrapper.style.height = "auto";
      tooltipWrapper.style.maxHeight = "auto";
      tooltipWrapper.style.zIndex = 9999;

      const tooltipHeader = document.createElement("p");
      tooltipHeader.style.textAlign = "left";
      tooltipHeader.style.margin = "4px 4px";
      tooltipHeader.style.fontWeight = "600";
      tooltipHeader.style.fontSize = "10px";
      tooltipHeader.style.padding = " 0px 4px";
      tooltipHeader.style.width = "fill-available";
      tooltipHeader.style.border = "1px solid rgba(255, 255, 255, 0.18)";
      tooltipHeader.style.borderRadius = "4px";
      tooltipHeader.innerText = "RelationShip:";
      tooltipWrapper.appendChild(tooltipHeader);

      const tooltipContent = document.createElement("p");
      tooltipContent.style.padding = "4px";
      tooltipContent.style.margin = "4px 4px";
      tooltipContent.style.borderRadius = "4px";
      tooltipContent.innerText = rel;
      tooltipWrapper.appendChild(tooltipContent);

      start_Element.style.backgroundColor = initial_Background_Color;

      document.body.appendChild(tooltipWrapper);
      start_Element.addEventListener("mouseleave", () => {
        document.body.removeChild(tooltipWrapper);
        start_Element.style.backgroundColor = initial_Background_Color;
      });
    });
    return line;
  };

  const highlight_Entities = (doc, entities) => {
    const All_HighLighted_Words = [];
    let last_Index = 0;
    entities?.forEach(({ start_pos, end_pos, type, document_id }) => {
      const substring = doc?.substring(last_Index, start_pos);
      if (substring?.length > 0) {
        All_HighLighted_Words?.push(substring);
      }

      const entity_Span = (
        <span
          key={document_id}
          style={{ backgroundColor: Random_Color_Map[type] }}
          className="ner_higilitedText"
          ref={(element) => (entity_Refs[document_id] = element)}
        >
          {doc?.substring(start_pos, end_pos)}
          <span
            style={{ marginLeft: "5px", marginBottom: "2px" }}
            className="ner_higilitedText-tag"
          >
            {type?.length >= 16 ? type.slice(0, 16) : type}
          </span>
        </span>
      );
      All_HighLighted_Words?.push(entity_Span);
      last_Index = end_pos;
    });

    const substring = doc?.substring(last_Index);
    if (substring.length > 0) {
      All_HighLighted_Words?.push(substring);
    }
    return [All_HighLighted_Words, entity_Refs];
  };

  React.useEffect(() => {
    const lines = [];
    nerResponse?.map(({ relation }) => {
      if (relation && relation?.length > 1) {
        relation?.map(({ start_ele_id, end_ele_id, rel }) => {
          const start_Entity = entity_Refs[start_ele_id];
          const end_Entity = entity_Refs[end_ele_id];
          if (start_Entity && end_Entity) {
            const line = create_Connection_Between_Entites(
              start_Entity,
              end_Entity,
              rel
            );
            lines.push(line);
          }
        });
      }
    });
    return () => {
      lines?.forEach((line) => line?.remove());
    };
  }, [nerResponse, entity_Refs]);

  return (
    <div className="nerText">
      {nerResponse?.map(({ id, doc, entities }) => (
        <span key={id}>
          <div ref={start_Element_Ref}>
            {highlight_Entities(doc, entities)[0]}
          </div>
        </span>
      ))}
    </div>
  );
};

export default NerText;
