import { useContext, useState, useEffect } from "react";
import ParentContext from "../context/context";

const useMediaQuery = () => {
  const { state } = useContext(ParentContext);
  const { isOpen } = state;

  const [layOutAndTopBarmediaQuery, setLayOutAndTopBarMediaQuery] = useState({
    left: isOpen ? "18%" : "5%",
    width: isOpen ? "calc(100% - 18%)" : "calc(100% - 5%)",
  });

  useEffect(() => {
    const layOutAndTopBarHandleResize = () => {
      const maxWidth = window.matchMedia("(min-width: 1280px)").matches;
      const minWidth = window.matchMedia("(min-width: 1536px)").matches;

      if (maxWidth) {
        setLayOutAndTopBarMediaQuery((prev) => ({
          ...prev,
          left: isOpen ? "18%" : "5%",
          width: isOpen ? "calc(100% - 18%)" : "calc(100% - 5%)",
        }));
      } else if (minWidth) {
        setLayOutAndTopBarMediaQuery((prev) => ({
          ...prev,
          left: isOpen ? "18%" : "5%",
          width: isOpen ? "calc(100% - 18%)" : "calc(100% - 5%)",
        }));
      }
    };

    layOutAndTopBarHandleResize();

    window.addEventListener("resize", () => {
      layOutAndTopBarHandleResize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        layOutAndTopBarHandleResize();
      });
    };
  }, [isOpen]);
  return {
    layOutAndTopBarmediaQuery,
  };
};

export default useMediaQuery;
