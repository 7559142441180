import { useContext, useEffect } from "react";
import ParentContext from "../context/context";
import axios from "axios";
import env from "../env/env";
import { useNavigate } from "react-router";
import sign from "jwt-encode";
import { secretKeyJwt, token, userDetails } from "../Auth/authConfig";

const useRedactionApi = () => {
  const { state, dispatch } = useContext(ParentContext);
  const {
    serviceType,
    redactionSelectedFields,
    selectedValue,
    backendResponse,
  } = state;
  let redactionFinalResponseReceived = false;
  let Response;
  let childId = null;
  const navigate = useNavigate();

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };
  
  const jwtToken = sign(userInfo, secretKeyJwt);

  const delayInMilliseconds = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleProgress = async (payload, redactType) => {
    try {
      Response = await axios.post(`${env.API_GATEWAY}/extractData`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      });
      sessionStorage.setItem("childId", Response.data.childId);
      dispatch({
        type: "SET_STATUS",
        payload: Response?.data?.status,
      });
      if (Response.data.status === "100%") {
        redactionFinalResponseReceived = true;
        sessionStorage.setItem("requestId", "");

        if (redactType === "Forms") {
          dispatch({ type: "SET_LOADING", payload: false });
          dispatch({ type: "SET_REDAC_DOC", payload: Response.data?.response });
          dispatch({ type: "SET_REDAC_OUT_FILE", payload: true });
          localStorage.setItem("requestId", "");
        } else if (redactType === "Plain Text") {
          dispatch({ type: "SET_LOADING", payload: false });
          dispatch({
            type: "SET_RESPONSE_TEXT",
            payload: Response.data?.response,
          });
        }
      }
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
      dispatch({ type: "SET_RES_ERROR", payload: true });
    }
  };

  const recursiveGetProgress = async (
    requestId,
    redactType,
    timeLapsed = 0,
    timeout = 120000
  ) => {
    if (!redactionFinalResponseReceived && timeLapsed <= timeout) {
      await delayInMilliseconds(1000);
      localStorage.setItem("firstCall", false);
      childId = sessionStorage.getItem("childId");

      const payload = {
        selectedFields: redactionSelectedFields,
        stage: serviceType,
        service: serviceType,
        documentId: localStorage.getItem("requestId"),
        firstCall: localStorage.getItem("firstCall"),
        redact_identified_pii: backendResponse?.redact_identified_pii,
        redact_doc_text: backendResponse?.redact_doc_text,
        childId,
      };

      await handleProgress(payload, redactType);
      await recursiveGetProgress(
        requestId,
        redactType,
        timeLapsed + 1000,
        timeout
      );
    } else {
      dispatch({ type: "SET_SUBMIT_CLICKED", payload: true });
    }
  };

  const handleForms = async () => {
    const payload = {
      selectedFields: redactionSelectedFields,
      stage: serviceType,
      service: serviceType,
      documentId: localStorage.getItem("requestId"),
      firstCall: localStorage.getItem("firstCall"),
      childId,
    };

    try {
      await handleProgress(payload, "Forms");
      await recursiveGetProgress(localStorage.getItem("requestId"), "Forms");
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
      dispatch({ type: "SET_RES_ERROR", payload: true });
    }
  };

  const handlePlainText = async () => {
    let updatedString = backendResponse?.redact_doc_text?.replace(
      /(\"|\'|"|')/g,
      "`"
    );
    const payload = {
      selectedFields: redactionSelectedFields,
      documentId: localStorage.getItem("requestId"),
      firstCall: localStorage.getItem("firstCall"),
      stage: serviceType,
      service: serviceType,
      redact_identified_pii: backendResponse?.redact_identified_pii,
      redact_doc_text: updatedString,
      childId,
    };

    try {
      await handleProgress(payload, "Plain Text");
      await recursiveGetProgress(
        localStorage.getItem("requestId"),
        "Plain Text"
      );
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
      dispatch({ type: "SET_RES_ERROR", payload: true });
    }
  };

  const handleApiCall = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    localStorage.setItem("firstCall", true);

    if (selectedValue === "Forms") {
      await handleForms();
    } else if (selectedValue === "Plain Text") {
      await handlePlainText();
    }
  };

  useEffect(() => {
    dispatch({ type: "SET_SUBMIT_CLICKED", payload: false });
  }, [dispatch, navigate]);

  return { submit_Redaction_Fields: handleApiCall };
};

export default useRedactionApi;