import { useContext } from "react";
import ParentContext from "../context/context";
import axios from "axios";
import env from "../env/env.js";
import { encryptionKey, initVector, secretKeyJwt, token, userDetails } from "../Auth/authConfig.js";
import CryptoJS from "crypto-js";
import sign from "jwt-encode";

const useGenAiHistoryApiCall = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { serviceType } = state;

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };
  
  const jwtToken = sign(userInfo, secretKeyJwt);

  const fetchGenAiHistoryData = async () => {
    const apiUrl = `${env.API_GATEWAY}/dblambda`;
    await axios
      .post(
        apiUrl,
        {
          service: serviceType,
          callType: "GenAI_History",
          userId: userDetails?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_GEN_AI_HISTORY_RESPONSE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
      });
  };

  const getDocumentFromS3PregignedUrl = async (presignedUrl) => {
    const response = await axios.get(presignedUrl, {
      responseType: "blob",
    });
    return response;
  };

  const decryptData = (encryptedData) => {
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(initVector);

    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      mode: CryptoJS.mode.CFB,
      iv: iv,
      padding: CryptoJS.pad.NoPadding,
    });

    const result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
  };

  const downloadFile = async (presignedUrl, fileName) => {
    const response = await getDocumentFromS3PregignedUrl(presignedUrl);
    const blob = new Blob([response.data]);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  const getGenAiZipFolder = async (id, fileName) => {
    const apiUrl = `${env.API_GATEWAY}/extractData`;
    try {
      const url_resp = await axios.post(
        apiUrl,
        {
          service: serviceType,
          callType: "download",
          documentId: id,
          fileName: fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      );
      const decodedUrl = url_resp.data.presignedurl;
      const file_split = fileName.split(".");
      const file_index = fileName.lastIndexOf(
        file_split[file_split.length - 1]
      );
      const file_initals = fileName.substring(0, file_index);
      downloadFile(decodedUrl, file_initals + url_resp.data.extension);
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
    }
  };

  return {
    fetchGenAiHistoryData,
    getGenAiZipFolder,
  };
};

export default useGenAiHistoryApiCall;
