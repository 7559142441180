import React, { useContext, useEffect, memo } from "react";
import ParentContext from "../../context/context";
import { FileUploadForm, InferSideImage } from "../../components/index";
import { ClassificationImg } from "../../images/sideContent/index";
import { userPermission } from "../../Auth/authConfig";

const Classification = () => {
  const { dispatch } = useContext(ParentContext);

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "Classification" });
  }, [dispatch]);

  return (
    <FileUploadForm
      RadioOptions={userPermission?.map(
        (item) => item.label === "Classification" && item.RadioOptions
      )}
      sideContent={
        <InferSideImage image={ClassificationImg} title="Classification">
          InferIQ uses sophisticated algorithms to analyze content, extract key
          features, and assign documents to predefined categories. This
          efficient and accurate solution streamlines information retrieval and
          improves data organization for businesses and researchers.
        </InferSideImage>
      }
      ButtonText="Next"
    />
  );
};

export default memo(Classification);
