import React,{useContext} from "react";
import { Radio } from "../../../../components/index";
import ParentContext from "../../../../context/context";

const GenAiTemplatesDescription = () => {
  const { state } = useContext(ParentContext);
  const { genAiTemplateRadioOptionsAndCheck } = state;
  return (
    <span className="para">
      {genAiTemplateRadioOptionsAndCheck?.description}
    </span>
  );
};

const GenAiTemplates = ({ options, onSelectOption, setGenAiOptionChecked }) => {
  const { state, dispatch } = useContext(ParentContext);
  const { genAiTemplateRadioOptionsAndCheck } = state;

  const handleOptionChange = (option) => {
    dispatch({
      type: "SET_GENAI_TEMPLATE_RADIO_OPTIONS_AND_CHECK",
      payload: option,
    });
    onSelectOption(option);
    setGenAiOptionChecked(true);
  };

  return (
    <React.Fragment>
      {options?.map((option) => (
        <Radio
          key={option.id}
          type="radio"
          id={option.id}
          label={option.label}
          name="templateOption"
          value={option.id}
          checked={genAiTemplateRadioOptionsAndCheck === option}
          onChange={() => handleOptionChange(option)}
        />
      ))}
    </React.Fragment>
  );
};

export { GenAiTemplates, GenAiTemplatesDescription };
