import React, { useState, useContext } from "react";
import env from "../env/env";
import ParentContext from "../context/context";
import axios from "axios";
import sign from "jwt-encode";
import { secretKeyJwt, token, userDetails } from "../Auth/authConfig";

const useGenAiApi = () => {
  const [previousResponses, setPreviousResponses] = useState([]);
  const { dispatch, state } = useContext(ParentContext);
  let result;

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const {
    serviceType,
    activeTab,
    selectedValue,
    genaiRedactionCheckfields,
    dataRedactionCheck,
    autoPiiRedactionCheck,
    genAiTemplateRadioOptionsAndCheck,
    genaiNewlyAddedQuestions,
    genaiRedactionOutputEditedText,
    getLatestRequestId,
    selectedLlmType,
  } = state;

  const tabs = [
    { key: "redaction_settings", title: "Redaction Settings" },
    { key: "redaction_output", title: "Redacted Output" },
    { key: "statement", title: selectedValue },
    { key: "questions", title: "Questions" },
    { key: "genaioutput", title: "AI Response" },
  ];

  const currentIndex = tabs.findIndex((tab) => tab.key === activeTab);
  const disableNext = currentIndex === tabs.length - 1;
  const disablePrevious = currentIndex === 0;

  const handleButtonClick = (direction) => {
    let nextIndex;
    if (direction === "next" && !disableNext) {
      nextIndex = currentIndex + 1;
    } else if (direction === "previous" && !disablePrevious) {
      nextIndex = currentIndex - 1;
    }
    if (nextIndex !== undefined) {
      const nextTab = tabs[nextIndex].key;
      dispatch({ type: "SET_ACTIVE_TAB", payload: nextTab });
      if (
        direction === "previous" &&
        nextIndex > 1 &&
        nextIndex < previousResponses.length + 1
      ) {
        const prevResponse = previousResponses[nextIndex - 2];
        dispatch({ type: "SET_GENAI_OUTPUT", payload: prevResponse });
      }
    }
  };

  const MAX_RETRY_DURATION = 15 * 60 * 1000;
  const delayInMilliseconds = 400;
  let apiCallCount = 0;

  const handleApiCall = async (
    stage,
    requestData,
    successCallback,
    startTime
  ) => {
    dispatch({ type: "SET_LOADING", payload: true });
    let post_url = `${env.API_GATEWAY + "/extractData"}`;

    try {
      result = await axios.post(post_url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      });
      sessionStorage.setItem("childId", result.data.childId);
      dispatch({
        type: "SET_STATUS",
        payload: result?.data?.status,
      });
      if (result?.data?.status === "100%") {
        dispatch({
          type: "SET_GENAI_OUTPUT",
          payload: result?.data?.response,
        });
        dispatch({ type: "SET_LOADING", payload: false });
        dispatch({ type: "SET_RES_ERROR", payload: false });
        successCallback();
        apiCallCount = apiCallCount + 1;
      } else {
        await new Promise((resolve) =>
          setTimeout(resolve, delayInMilliseconds)
        );
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < MAX_RETRY_DURATION) {
          await new Promise((resolve) =>
            setTimeout(resolve, delayInMilliseconds)
          );
          apiCallCount = apiCallCount + 1;
          requestData.childId =
            apiCallCount === 0 ? null : sessionStorage.getItem("childId");
          await handleApiCall(stage, requestData, successCallback, startTime);
        } else {
          dispatch({ type: "SET_LOADING", payload: false });
        }
      }
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
      dispatch({ type: "SET_RES_ERROR", payload: true });
    }
  };

  const handle_Genai_Redacted_Fileds = () => {
    const requestData = {
      documentId: getLatestRequestId,
      childId: apiCallCount === 0 && null,
      formType: selectedValue,
      service: serviceType,
      stage: "Genai_Redaction",
      ner_Fields: dataRedactionCheck === false ? [] : genaiRedactionCheckfields,
      autoPiiRedactionCheck:
        dataRedactionCheck === false ? false : autoPiiRedactionCheck,
      dataRedactionCheck: dataRedactionCheck,
    };
    const successCallback = () => {
      setPreviousResponses((prevResponses) => [
        ...prevResponses,
        result?.data?.response,
      ]);
      handleButtonClick("next");
    };
    handleApiCall("Genai_Redaction", requestData, successCallback, Date.now());
  };

  const handle_Genai_Query = () => {
    let updatedString = genaiRedactionOutputEditedText.replace(
      /(\"|\'|"|')/g,
      "`"
    );
    const requestData = {
      documentId: getLatestRequestId,
      childId: apiCallCount === 0 && null,
      formType: selectedValue,
      service: serviceType,
      stage: "Genai_Query",
      selectedTemplate: genAiTemplateRadioOptionsAndCheck.label,
      llm: selectedLlmType,
      userQuestions: genaiNewlyAddedQuestions,
      updatedRedactedText: updatedString,
    };

    const successCallback = () => {
      setPreviousResponses((prevResponses) => [
        ...prevResponses,
        result?.data?.response,
      ]);
      handleButtonClick("next");
    };
    handleApiCall("Genai_Query", requestData, successCallback, Date.now());
  };

  const terminate_handleupload = () => {
    let post_url = `${env.API_GATEWAY + "/extractData"}`;
    axios.post(post_url, {
      documentId: getLatestRequestId,
      service: serviceType,
      stage: "Genai_Shutdown",
    });
  };

  return {
    handle_Genai_Redacted_Fileds,
    handle_Genai_Query,
    terminate_handleupload,
    handleButtonClick,
    tabs,
    disablePrevious,
  };
};

export default useGenAiApi;