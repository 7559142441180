import React, { useContext } from "react";
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import "./ExtractionTable.css";
import TabTitleContext from "../../../context/TabTitleContext";
import ParentContext from "../../../context/context";
import BankStatementTable from "../BankStatementTable";
import Table from "../Table";
import { faFileText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExtractionTable = (props) => {
  const {
    all_tabs,
    BankStatementResponse,
    kvpTdData,
    tableThData,
    tableTrData,
    selectedRow,
    rowRefs,
    searchRow,
    setSearchRow,
    setSelectedRow,
    showAccReport,
    setShowAccReport,
    serviceType,
    tableResponse,
    tabAccNumber,
    setTabAccNumber,
  } = props;

  const { currentTab, setCurrentTab } = useContext(TabTitleContext);
  const { state, dispatch } = useContext(ParentContext);
  const { selectedValue, bankStatementActiveTab } = state;

  const isNumber = (value) => {
    return (
      typeof value === "number" || (!isNaN(value) && !isNaN(parseFloat(value)))
    );
  };
  const handleTabSelect = (tabIndex) => {
    const currentAccountTab =
      BankStatementResponse?.report_analysis?.account_num[tabIndex];
    currentAccountTab && setTabAccNumber(currentAccountTab);
    dispatch({ type: "SET_BANKSTATEMENT_ACTIVE_TAB", payload: tabIndex });
  };

  let RenderTable;
  if (
    selectedValue !== "Bank Statement" ||
    selectedValue !== "IRS Forms" ||
    selectedValue !== "Application Form"
  ) {
    RenderTable = (
      <Tabs
        defaultActiveKey={`${currentTab || all_tabs[0]}`}
        id="justify-tab-example"
        className="mb-3"
        justify
        onSelect={(tab) => setCurrentTab(tab)}
      >
        {all_tabs.map((tab, index) => {
          return (
            <Tab key={index} eventKey={`${tab}`} title={tab}>
              <div className="Extraction">
                <div className="Extraction-tables">
                  <Table
                    kvpTdData={kvpTdData}
                    tableThData={tableThData}
                    tableTrData={tableTrData}
                  />
                </div>
              </div>
            </Tab>
          );
        })}
      </Tabs>
    );
  }
  if (
    selectedValue === "Bank Statement" ||
    selectedValue === "Credit Statement" ||
    selectedValue === "Forms" ||
    selectedValue === "IRS Forms" ||
    selectedValue === "Application Form"
  ) {
    RenderTable = (
      <Tabs
        defaultActiveKey={all_tabs[0] || 0}
        activeKey={bankStatementActiveTab}
        onSelect={handleTabSelect}
        className="mb-3"
        justify
      >
        {serviceType !== "Bank Statement"
          ? BankStatementResponse &&
            BankStatementResponse?.map((tableData, tabIndex) => {
              const tableTitle = tableData[0]?.TabTitle || "No Title";
              let title = isNumber(tableTitle)
                ? `Account Number-${tableTitle}`
                : tableTitle;
              return (
                <Tab eventKey={tabIndex} title={title} key={tabIndex}>
                  <div className="Extraction">
                    <div className="Extraction-tables">
                      {}
                      <BankStatementTable
                        tableData={tableData}
                        selectedRow={selectedRow}
                        rowRefs={rowRefs}
                        searchRow={searchRow}
                        setSearchRow={setSearchRow}
                        setSelectedRow={setSelectedRow}
                        serviceType={serviceType}
                      />
                    </div>
                  </div>
                </Tab>
              );
            })
          : BankStatementResponse &&
            all_tabs?.map(
              (tabData, tabIndex) => {
                const tableTitle = tabData || "No Title";
                let title = isNumber(tableTitle)
                  ? `Acc No-${tableTitle}`
                  : tableTitle;
                return (
                  <Tab
                    eventKey={tabIndex}
                    title={
                      <span>
                        {title}
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="tooltip">Report</Tooltip>}
                        >
                          <FontAwesomeIcon
                            icon={faFileText}
                            onClick={() => setShowAccReport(true)}
                            style={{marginLeft: '10px'}}
                          />
                        </OverlayTrigger>
                      </span>
                    }
                    key={tabIndex}
                  >
                    <div className="Extraction">
                      <div className="Extraction-tables">
                        <BankStatementTable
                          tableData={BankStatementResponse}
                          selectedRow={selectedRow}
                          rowRefs={rowRefs}
                          searchRow={searchRow}
                          setSearchRow={setSearchRow}
                          setSelectedRow={setSelectedRow}
                          tableAccNo={tabAccNumber}
                          showAccReport={showAccReport}
                          setShowAccReport={setShowAccReport}
                          serviceType={serviceType}
                        />
                      </div>
                    </div>
                  </Tab>
                );
              }
            )}
      </Tabs>
    );
  }
  return <div className="output-table">{RenderTable}</div>;
};

export default ExtractionTable;
