import React,{memo} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartsFallBackUi from "../ChartsFallBackUi/ChartsFallBackUi";
import colors from "../../../../utils/graphColors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = (props) => {
  const { lineChartResponse } = props;

  if (!lineChartResponse && !lineChartResponse?.datasets) {
    return <ChartsFallBackUi />;
  }

  const datasetsWithStyles = lineChartResponse?.datasets?.map(
    (dataset, index) => ({
      ...dataset,
      borderColor: colors[index % colors.length],
      backgroundColor: colors[index % colors.length],
    })
  );

  const dataWithStyles = {
    ...lineChartResponse,
    datasets: datasetsWithStyles,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  return <Line options={options} data={dataWithStyles} />;
};

export default memo(LineChart);
