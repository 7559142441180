import React, { useContext, useState, useRef, useEffect, memo } from "react";
import {
  FontAwesomeIcon,
  faCircleArrowRight,
  faCircleArrowLeft,
  faGears,
  faBell,
} from "../../../import/FontAwesomeIcons";
import "./TopBar.css";
import ParentContext from "../../../context/context";
import { useMediaQuery } from "../../../Hooks/index";
import { OverlayTrigger, Tooltip, Stack } from "react-bootstrap";
import { BreadCrum, CustomButton, DropDown } from "../../../components/index";
import notificationOff from "../../../images/svg/notification-off.svg";
import Notification from "./Notification";
import { Link } from "react-router-dom";
import options from "../../../utils/options";
import {
  monitoringAccess,
  notificationAccess,
  userDetails,
} from "../../../Auth/authConfig";

const TopBar = () => {
  const { state, dispatch, setCurrentItem } = useContext(ParentContext);
  const { loading, isOpen, notification } = state;
  const [show, setShow] = useState(false);
  const [showToggels, setShowToggels] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [prevNotificationDataLength, setPrevNotificationDataLength] =
    useState(0);
  const [showAlertDot, setShowAlertDot] = useState(false);

  const modalRef = useRef();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setShowAlertDot(false);
  };

  const handleShowToggel = () => {
    setShowToggels(true);
  };
  const handleCloseToggel = () => setShowToggels(false);
  const { layOutAndTopBarmediaQuery } = useMediaQuery();

  const ToggleSidebar = () => {
    isOpen
      ? dispatch({ type: "SET_IS_OPEN", payload: false })
      : dispatch({ type: "SET_IS_OPEN", payload: true });
  };

  const clearNotificationData = () => {
    sessionStorage.removeItem("notificationData");
    setNotificationData(null);
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem("notificationData");
    const storedTimestamp = sessionStorage.getItem("notificationDataTimestamp");
    const currentTime = new Date().getTime();
    const fiveMin = 5 * 60 * 1000;
    if (storedData) {
      setNotificationData(JSON.parse(storedData));
      if (storedTimestamp) {
        const lastUpdateTime = parseInt(storedTimestamp, 10);

        if (currentTime - lastUpdateTime > fiveMin) {
          clearNotificationData();
        }
      }
    }
  }, [notification]);

  useEffect(() => {
    const storedPrevNotificationDataLength = sessionStorage.getItem(
      "prevNotificationDataLength"
    );
    if (storedPrevNotificationDataLength) {
      setPrevNotificationDataLength(
        parseInt(storedPrevNotificationDataLength, 10)
      );
    }
  }, []);

  useEffect(() => {
    if (
      notificationData &&
      notificationData.length > prevNotificationDataLength
    ) {
      setShowAlertDot(true);
      setPrevNotificationDataLength(notificationData.length);
      sessionStorage.setItem(
        "prevNotificationDataLength",
        notificationData.length.toString()
      );
    } else {
      setShowAlertDot(false);
    }
  }, [notificationData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        show &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showToggels &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        handleCloseToggel();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showToggels]);

  const ExcludeRoutes = ["/", "/dashboard", "/requestMonitoring"];
  function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() || "";
  }
  return (
    <div style={{ pointerEvents: loading ? "none" : "auto" }}>
      <div className="topbar" style={layOutAndTopBarmediaQuery}>
        <Stack direction="horizontal">
          <div className="topbar-fabars">
            {!ExcludeRoutes.includes(window.location.pathname) && (
              <button onClick={ToggleSidebar}>
                {isOpen ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip">Collapse Side Bar</Tooltip>}
                  >
                    <FontAwesomeIcon icon={faCircleArrowLeft} />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip">Expand Side Bar</Tooltip>}
                  >
                    <FontAwesomeIcon icon={faCircleArrowRight} />
                  </OverlayTrigger>
                )}
              </button>
            )}
          </div>
          <BreadCrum />
        </Stack>
        <Stack direction="horizontal">
          {monitoringAccess && (
            <CustomButton variant="light" size="sm" onClick={handleShowToggel}>
              <FontAwesomeIcon icon={faGears} />
            </CustomButton>
          )}
          {showToggels && (
            <div className="toggels" ref={modalRef}>
              {options.map((item) => (
                <Link
                  className="toggels-links para"
                  to={item.path}
                  key={item.id}
                  onClick={() => {
                    setCurrentItem(item.path);
                  }}
                >
                  <FontAwesomeIcon icon={item.icon} />
                  {item.label}
                </Link>
              ))}
            </div>
          )}
          {notificationAccess && (
            <CustomButton variant="light" size="sm" onClick={handleShow}>
              <FontAwesomeIcon
                className={showAlertDot ? "bell" : ""}
                icon={faBell}
              />
              {showAlertDot && <span className="notification-alert"></span>}
            </CustomButton>
          )}
          {show && (
            <Notification
              modalRef={modalRef}
              handleClose={handleClose}
              notificationData={notificationData}
              notificationOff={notificationOff}
            />
          )}
          <DropDown />
          <span className="avatar">
            <div className="avatar-circle">
              <center style={{ paddingTop: "3px" }}>
                {capitalizeFirstLetter(userDetails?.name)}
              </center>
            </div>
          </span>
        </Stack>
      </div>
    </div>
  );
};

export default memo(TopBar);
