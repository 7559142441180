import React from "react";
import "./ErrorLogs.css";
import ErrorLogsHeader from "./ErrorLogsHeader";
import ErrorLogsBody from "./ErrorLogsBody";
import fileNotFound from "./file-document-svgrepo-com.svg";

const ErrorLogs = ({ errorLogs }) => {
  let checkForMessageKey = Object.keys(errorLogs);
  let msg = checkForMessageKey.includes("message");
  return (
    <div>
      {msg ? (
        <div
          className="rm-fallback-ui"
          style={{
            gap: "40px",
          }}
        >
          <img
            src={fileNotFound}
            alt="fileNotFound"
            width="80px"
            heiht="80px"
          />
          <p className="para">{errorLogs.message}</p>
        </div>
      ) : (
        <>
          <ErrorLogsHeader />
          <ErrorLogsBody data={errorLogs} />
        </>
      )}
    </div>
  );
};

export default ErrorLogs;
