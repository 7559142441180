import React, { useContext, useEffect, memo } from "react";
import ParentContext from "../../context/context";
import { InferSideImage, FileUploadForm } from "../../components/index";
import { ConversationalImg } from "../../images/sideContent/index";
import { userPermission } from "../../Auth/authConfig";

const GenerativeAI = () => {
  const { dispatch } = useContext(ParentContext);

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "Conversational AI" });
  }, [dispatch]);

  return (
    <FileUploadForm
      RadioOptions={userPermission?.map(
        (item) => item.label === "Conversational AI" && item.RadioOptions
      )}
      sideContent={
        <InferSideImage image={ConversationalImg} title="Conversational AI">
          Experience the power of comprehensive data management with this
          innovative module. Extract data efficiently, redact sensitive content
          seamlessly, and effortlessly connect to AI analysis for in-depth
          understanding and valuable predictions. Safeguard your data privacy
          while gaining valuable insights from its powerful features.
        </InferSideImage>
      }
      ButtonText="Next"
    />
  );
};

export default memo(GenerativeAI);
