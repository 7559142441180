import React, { useContext, useEffect, memo } from "react";
import { useLocation, Link } from "react-router-dom";
import "./BreadCrum.css";
import ParentContext from "../../context/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const pathNameMap = {
  OutputScreen: "Output",
  faqs: "FAQ's",
  generativeai: "Conversational AI",
  commercialRealEstate: "Commercial Real Estate",
  serviceDashboard: "Dashboard",
  requestMonitoring: "Request Monitoring",
  customerSettings: "Customer Settings",
  processMetrics: "Process Metrics",
  systemLogs: "System Logs",
  billingMetrics: "Billing Metrics",
  billingInfo: "Billing Info",
  predictiveAnalytics: "Forecasting",
  PredictiveAnalyticsOutputScreen: "OutputScreen",
  clientOnboarding: "Client Onboarding",
  bankstatements: "Bank Statement",
  finStatExtraction: "Financial Statement Extraction",
};

const BreadCrum = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { breadcrumbs } = state;
  const location = useLocation();

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);

    const updated_Breadcrumbs = pathnames.map((path, index) => {
      const route = `/${pathnames.slice(0, index + 1).join("/")}`;
      const isLast = index === pathnames.length - 1;
      const displayName = pathNameMap[path] || path;

      return (
        <span key={route}>
          {isLast ? (
            <span>{displayName}</span>
          ) : (
            <Link to={route} className="breadcrum_link" draggable={false}>
              {displayName}
            </Link>
          )}
          {index < pathnames.length - 1 && (
            <span>
              <FontAwesomeIcon
                className="breadcrum_link_svg"
                icon={faAngleRight}
              />
            </span>
          )}
        </span>
      );
    });

    dispatch({ type: "SET_BREADCRUMBS", payload: updated_Breadcrumbs });
  }, [location]);

  return (
    <div className="breadcrum">
      <span className="heading">{breadcrumbs}</span>
    </div>
  );
};

export default memo(BreadCrum);
