import { useContext } from "react";
import ParentContext from "../../InferIq-Solution/context/context";
import axios from "axios";
import env from "../../InferIq-Solution/env/env.js";
import sign from "jwt-encode";
import {
  secretKeyJwt,
  token,
  userDetails,
} from "../../InferIq-Solution/Auth/authConfig.js";

const useRequestMonitoringGetLogs = () => {
  const { dispatch } = useContext(ParentContext);

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const getDocumentFromS3PregignedUrl = async (presignedUrl) => {
    const response = await axios.get(presignedUrl, {
      responseType: "blob",
    });
    return response;
  };

  const downloadFile = async (presignedUrl, ParentrequestId) => {
    try {
      const response = await getDocumentFromS3PregignedUrl(presignedUrl);
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${ParentrequestId}.zip`;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const getRequestMonitoringLogs = async (ParentrequestId, childId) => {
    dispatch({ type: "SET_RES_ERROR", payload: false });
    await axios
      .post(
        `${env.API_GATEWAY + "/requestmonitor"}`,
        {
          callType: "cloudwatchLogs",
          documentId: ParentrequestId,
          childId: childId,
          userId: userDetails?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        downloadFile(response.data.presignedUrl, ParentrequestId);
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
        dispatch({ type: "SET_RES_ERROR", payload: true });
      });
  };
  return { getRequestMonitoringLogs };
};

export default useRequestMonitoringGetLogs;
