import React, { useContext, useState, useEffect } from "react";
import {
  InputBox,
  CustomButton,
  ConfirmationDialog,
} from "../../../../components/index";
import ParentContext from "../../../../context/context";
import {
  FontAwesomeIcon,
  faTriangleExclamation,
} from "../../../../import/FontAwesomeIcons";
import { useToasts } from "../../../../Hooks";

const GenAiQuestions = (props) => {
  const MAX_QUESTIONS = 10;

  const {
    deleteIcon,
    saveIcon,
    addIcon,
    addButtonText,
    setAddQuestionButtonActive,
  } = props;
  const { state, dispatch } = useContext(ParentContext);
  const { genAiTemplateRadioOptionsAndCheck } = state;
  const { Toast } = useToasts();
  const [templateQuestions, setTemplateQuestions] = useState(
    JSON.parse(localStorage.getItem("templateQuestions")) ||
      genAiTemplateRadioOptionsAndCheck?.questions ||
      []
  );
  const [editedLabel, setEditedLabel] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newField, setNewField] = useState("");
  const [showNewFieldInput, setShowNewFieldInput] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [pendingDeleteId, setPendingDeleteId] = useState(null);

  useEffect(() => {
    dispatch({
      type: "SET_GENAI_NEWLY_ADDED_QUESTIONS",
      payload: templateQuestions,
    });
    localStorage.setItem(
      "templateQuestions",
      JSON.stringify(templateQuestions)
    );
  }, [templateQuestions]);

  const handleDelete = (id) => {
    setPendingDeleteId(id);
    setConfirmationMessage("Are you sure you want to delete this question?");
    setShowConfirmation(true);
  };

  useEffect(() => {
    dispatch({
      type: "SET_GET_TEMP_QUES_LENGTH",
      payload: templateQuestions.length,
    });
  }, [templateQuestions]);

  const handleSave = (id) => {
    const updatedQuestions = templateQuestions.map((form) => {
      if (form.id === id) {
        return { ...form, question: editedLabel };
      }
      return form;
    });
    setTemplateQuestions(updatedQuestions);
    setIsEditing(false);
  };

  const handleAddField = () => {
    if (templateQuestions.length >= MAX_QUESTIONS) {
      Toast(
        "info",
        "In the Sandbox environment, you can only add up to 10 questions. Please subscribe to our service to add unlimited questions."
      );
      return;
    }

    const newId =
      templateQuestions.length > 0
        ? templateQuestions[templateQuestions.length - 1].id + 1
        : 1;
    const newTaxForm = { id: newId, question: newField };
    const updatedQuestions = [...templateQuestions, newTaxForm];
    setTemplateQuestions(updatedQuestions);
    setNewField("");
    setShowNewFieldInput(false);
    dispatch({
      type: "SET_GENAI_NEWLY_ADDED_QUESTIONS",
      payload: updatedQuestions,
    });
    setAddQuestionButtonActive(false);
  };

  const handleInputChange = (event) => {
    setNewField(event.target.value);
  };

  const handleEditInputChange = (event) => {
    setEditedLabel(event.target.value);
  };

  const handleShowNewFieldInput = () => {
    if (templateQuestions.length >= MAX_QUESTIONS) {
      Toast(
        "info",
        "In the Sandbox environment, you can only add up to 10 questions. Please subscribe to our service to add unlimited questions."
      );
      return;
    }
    setShowNewFieldInput(true);
    setAddQuestionButtonActive(true);
  };

  const handleConfirmationYes = () => {
    if (pendingDeleteId !== null) {
      const updatedQuestions = templateQuestions.filter(
        (form) => form.id !== pendingDeleteId
      );
      setTemplateQuestions(updatedQuestions);
      dispatch({
        type: "SET_GENAI_NEWLY_ADDED_QUESTIONS",
        payload: updatedQuestions,
      });
      setPendingDeleteId(null);
    }
    setShowConfirmation(false);
  };

  const handleConfirmationNo = () => {
    setPendingDeleteId(null);
    setShowConfirmation(false);
  };

  const handleBack = () => {
    setShowNewFieldInput(false);
    setAddQuestionButtonActive(false);
  };

  return (
    <div className="genai_questions_container">
      {templateQuestions?.map((Question, index) => (
        <div key={Question.id} className="template-items">
          {!isEditing || isEditing !== Question.id ? (
            <span className="para">
              {index + 1}. {Question.question}
            </span>
          ) : (
            <InputBox
              type="text"
              size="sm"
              value={editedLabel}
              onChange={handleEditInputChange}
              disabled={false}
            />
          )}
          {!isEditing || isEditing !== Question.id ? (
            <div className="template-edit-and-delete">
              <div className="template-delete">
                {deleteIcon && (
                  <CustomButton
                    size="sm"
                    variant="light"
                    onClick={() => handleDelete(Question.id)}
                  >
                    <FontAwesomeIcon className="iq_svg" icon={deleteIcon} />
                  </CustomButton>
                )}
              </div>
            </div>
          ) : (
            <React.Fragment>
              {saveIcon && (
                <CustomButton
                  size="sm"
                  variant="success"
                  onClick={() => handleSave(Question.id)}
                >
                  <FontAwesomeIcon icon={saveIcon} />
                  <span className="template-save">Save</span>
                </CustomButton>
              )}
            </React.Fragment>
          )}
        </div>
      ))}
      <div className="add-new-template">
        {!showNewFieldInput && (
          <div className="add-new-template-btn">
            {addIcon && (
              <CustomButton
                size="md"
                variant="primary"
                onClick={handleShowNewFieldInput}
              >
                <FontAwesomeIcon icon={addIcon} />
                <span className="create-new-template">{addButtonText}</span>
              </CustomButton>
            )}
          </div>
        )}
      </div>
      {showNewFieldInput && (
        <div className="add-new-template">
          <div className="add-new-template-input">
            <center>
              <InputBox
                type="text"
                size="sm"
                value={newField}
                onChange={handleInputChange}
                placeholder="Add New Template"
                disabled={false}
              />
            </center>
          </div>
          <div className="add-new-template-btn">
            {addIcon && (
              <CustomButton
                size="sm"
                variant="primary"
                onClick={handleAddField}
                disabled={newField.length <= 0}
              >
                <span className="create-new-template">Add</span>
              </CustomButton>
            )}
            <span style={{ marginLeft: "4px" }}>
              <CustomButton size="sm" variant="primary" onClick={handleBack}>
                Discard
              </CustomButton>
            </span>
          </div>
        </div>
      )}
      {showConfirmation && (
        <ConfirmationDialog
          icon={faTriangleExclamation}
          confirmationMessage={confirmationMessage}
          handleConfirmationNo={handleConfirmationNo}
          handleConfirmationYes={handleConfirmationYes}
          cancel="No"
          confirm="yes"
        />
      )}
    </div>
  );
};

export default GenAiQuestions;
