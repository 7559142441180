import {
  faFolderTree,
  faChartLine,
  faFolderOpen,
  faFilePen,
  faFileInvoice,
  faCommentDots,
  faTarpDroplet,
  faMoneyBillWave,
  faComputer,
  faSliders,
  faMicrochip,
  faFileInvoiceDollar,
  faGaugeHigh,
  faFileContract,
  faNetworkWired,
  faChartPie,
  faBuildingColumns,
} from "@fortawesome/free-solid-svg-icons";

const SideBarRoutesIcons = [
  {
    label: "Dashboard",
    icon: faChartLine,
  },
  {
    label: "Extraction",
    icon: faFolderTree,
  },
  {
    label: "FinStat Extraction",
    icon: faFileInvoiceDollar,
  },
  {
    label: "Bank Statement",
    icon: faBuildingColumns,
  },
  {
    label: "Conversational AI",
    icon: faCommentDots,
  },
  {
    label: "Rent Roll",
    icon: faTarpDroplet,
  },
  {
    label: "Forecasting",
    icon: faChartPie,
  },
  {
    label: "Redaction",
    icon: faFilePen,
  },
  {
    label: "Recognition",
    icon: faFileInvoice,
  },
  {
    label: "Classification",
    icon: faFolderOpen,
  },
];

const serviceDashboardRoutesIcons = [
  {
    label: "Dashboard",
    icon: faGaugeHigh,
  },
  {
    label: "Request Monitoring",
    icon: faNetworkWired,
  },
  {
    label: "Product Info",
    icon: faFileContract,
  },
  {
    label: "Customer Settings",
    icon: faSliders,
  },
  {
    label: "Process Metrics",
    icon: faMicrochip,
  },
  {
    label: "System Logs",
    icon: faComputer,
  },
];

const billingDashboardRoutesIcons = [
  {
    label: "Processed Files Info",
    icon: faFileInvoiceDollar,
  },
  {
    label: "Billing Metrics",
    icon: faMoneyBillWave,
  },
  {
    label: "Billing Info",
    icon: faFileInvoiceDollar,
  },
  {
    label: "Client Onboarding",
    icon: faFileInvoiceDollar,
  },
];

export {
  SideBarRoutesIcons,
  serviceDashboardRoutesIcons,
  billingDashboardRoutesIcons,
};
