export const userPermissionLocal = {
  SideBarRoutes: [
    {
      id: 1,
      label: "Extraction",
      path: "/extraction",
      RadioOptions: [
        {
          id: 1,
          label: "Rent Roll Extraction",
          value: "Rent Roll Extraction",
          group: "fileType",
          path: "/extraction",
        },
        {
          id: 2,
          label: "Tax Forms",
          value: "Tax Forms",
          group: "fileType",
          path: "/extraction",
        },
        {
          id: 3,
          label: "Property Cash Flow",
          value: "Property Cash Flow",
          group: "fileType",
          path: "/extraction",
        },
        {
          id: 5,
          label: "Legal docs & Contracts",
          value: "Legal docs & Contracts",
          group: "fileType",
          path: "/extraction",
        },
        {
          id: 6,
          label: "IRS Forms",
          value: "IRS Forms",
          group: "fileType",
          path: "/extraction",
        },
        {
          id: 7,
          label: "Plain Text",
          value: "Plain Text",
          group: "fileType",
          path: "/extraction",
        },
        {
          id: 8,
          label: "Invoice & Receipts",
          value: "Invoice & Receipts",
          group: "fileType",
          path: "/extraction",
        },
        {
          id: 9,
          label: "Bank Forms",
          value: "Bank Forms",
          group: "fileType",
          path: "/extraction",
        },
      ],
    },
    {
      id: 2,
      label: "Bank Statements",
      path: "/bankstatements",
      RadioOptions: [
        {
          id: 1,
          label: "Bank Statement",
          value: "Bank Statement",
          group: "fileType",
          path: "/bankstatements",
        },
        {
          id: 2,
          label: "Forms",
          value: "Forms",
          group: "fileType",
          path: "/bankstatements",
        },
        {
          id: 3,
          label: "Credit Statement",
          value: "Credit Statement",
          group: "fileType",
          path: "/bankstatements",
        },
      ],
    },
    {
      id: 3,
      label: "FinStat Extraction",
      path: "/finStatExtraction",
      RadioOptions: [
        {
          id: 1,
          label: "Financial Statement",
          value: "Financial Statement",
          group: "fileType",
          path: "/finStatExtraction",
        },
      ],
    },
    {
      id: 4,
      label: "Conversational AI",
      path: "/generativeai",
      RadioOptions: [
        {
          id: 4,
          label: "Financial Statement",
          value: "Financial Statement",
          group: "fileType",
          path: "/generativeai",
        },
        {
          id: 5,
          label: "Bank Statement",
          value: "Bank Statement",
          group: "fileType",
          path: "/generativeai",
        },

        {
          id: 7,
          label: "Legal docs & Contracts",
          value: "Legal docs & Contracts",
          group: "fileType",
          path: "/generativeai",
        },

        {
          id: 3,
          label: "Bank Forms",
          value: "Bank Forms",
          group: "fileType",
          path: "/generativeai",
        },
      ],
    },
    {
      id: 5,
      label: "Rent Roll",
      path: "/commercialRealEstate",
      RadioOptions: [
        {
          id: 1,
          label: "Rent Roll",
          value: "Rent Roll",
          group: "fileType",
          path: "/commercialRealEstate",
        },
      ],
    },
    {
      id: 6,
      label: "Forecasting",
      path: "/predictiveAnalytics",
      RadioOptions: [
        {
          id: 1,
          label: "Loan Default",
          value: "Loan Default",
          group: "fileType",
        },
      ],
    },
    {
      id: 7,
      label: "Redaction",
      path: "/redaction",
      RadioOptions: [
        {
          id: 1,
          label: "Forms",
          value: "Forms",
          group: "fileType",
          path: "/redaction",
        },
        {
          id: 2,
          label: "Plain Text",
          value: "Plain Text",
          group: "fileType",
          path: "/redaction",
        },
      ],
    },
    {
      id: 8,
      label: "Recognition",
      path: "/recognition",
      RadioOptions: [
        {
          id: 1,
          label: "Document Understanding",
          value: "Table from documents",
          group: "fileType",
          path: "/recognition",
        },
      ],
    },
    {
      id: 9,
      label: "Classification",
      path: "/classification",
      RadioOptions: [
        {
          id: 1,
          label: "Generic Document Classifier",
          value: "Generic Document Classifier",
          group: "fileType",
          path: "/classification",
        },
        {
          id: 2,
          label: "Taxreturn / Finstat Classifier",
          value: "Taxreturn / Finstat Classifier",
          group: "fileType",
          path: "/classification",
        },
      ],
    },
  ],
  serviceDashboardRoutes: [
    {
      id: Math.random(),
      label: "Dashboard",
      path: "/serviceDashboard",
    },
    {
      id: Math.random(),
      label: "Request Monitoring",
      path: "/requestMonitoring",
    },
    {
      id: Math.random(),
      label: "Product Info",
      path: "/productInfo",
    },
    {
      id: Math.random(),
      label: "Customer Settings",
      path: "/customerSettings",
    },
    {
      id: Math.random(),
      label: "Process Metrics",
      path: "/processMetrics",
    },
    {
      id: Math.random(),
      label: "System Logs",
      path: "/systemLogs",
    },
  ],

  billingDashboardRoutes: [
    {
      id: Math.random(),
      label: "Billing Metrics",
      path: "/billingMetrics",
    },
    {
      id: Math.random(),
      label: "Processed Files Info",
      path: "/processedFileInfo",
    },
    {
      id: Math.random(),
      label: "Billing Info",
      path: "/billingInfo",
    },
    {
      id: Math.random(),
      label: "Client Onboarding",
      path: "/clientOnboarding",
    },
  ],
  // llmTypes: {
  //     rentrollLllmTypes: [
  //         {
  //             id: 1,
  //             label: "Built-in-LLM",
  //             value: "Built-in-LLM",
  //             group: "fileType",
  //             description:
  //                 "a state-of-the-art, pre trained and fine-tuned large language model (LLM) which runs within the solution infrastructure",
  //         },
  //         {
  //             id: 3,
  //             label: "Bedrock Anthropic Claude",
  //             value: "Bedrock Anthropic Claude",
  //             group: "fileType",
  //             description:
  //                 "Claude is based on Anthropic's research into creating reliable, interpretable, and steerable AI systems.",
  //         },
  //     ],
  //     conversationalAiLllmTypes: [
  //         {
  //             id: 3,
  //             label: "Bedrock Anthropic Claude",
  //             value: "Bedrock Anthropic Claude",
  //             group: "fileType",
  //             description:
  //                 "Claude is based on Anthropic's research into creating reliable, interpretable, and steerable AI systems.",
  //         },
  //         {
  //             id: 1,
  //             label: "PrivateGPT",
  //             value: "PrivateGPT",
  //             group: "fileType",
  //             description:
  //                 "LLMs are built on neural network architectures, particularly the transformer architecture, which allows them to capture complex language patterns and relationships between words or phrases in large-scale text datasets",
  //         },
  //         {
  //             id: 2,
  //             label: "ChatGPT",
  //             value: "ChatGPT",
  //             group: "fileType",
  //             description: `The information will be shared with ChatGPT. If you have chosen to
  //             redact information, only the redacted information will be shared
  //             with ChatGPT. Please review the data before proceeding. By
  //             proceeding you agree to share the data with ChatGPT`,
  //         },
  //     ]
  // }
};
