import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "../../../../import/FontAwesomeIcons";

const SidebarBody = (props) => {
  return (
    <div className="sidebar-body">
      {props?.SideBarRoutes?.map(({ id, label, path, icon }) => {
        return (
          <div key={id} className={props.activeIndex === id ? "active" : ""}>
              <Link
                to={path}
                onClick={() => props.handle_Click(id)}
                draggable={false}
                className="sidebar-link"
              >
                <span className="icon">
                  <FontAwesomeIcon style={{width:"17px",height:"17px"}} icon={icon} />
                </span>
                <span className="items">{label}</span>
              </Link>
          </div>
        );
      })}
    </div>
  );
};

export default SidebarBody;
