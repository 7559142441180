import React, { useContext, memo } from "react";
import ParentContext from "../../../context/context";
import { ControlPanel, PdfViewer } from "../../../components/index";

const CreHistoryPdfViewer = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { numPages, pageNumber, scale, rotate, rentRollPreviewFile } = state;
  console.log(rentRollPreviewFile);
  return (
    <div className="cre-history-pdf-viewer">
      <div className="cre-history-pdf-viewer-controll">
        <ControlPanel
          scale={scale}
          setScale={(scale) => dispatch({ type: "SET_SCALE", payload: scale })}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={(pageNumber) =>
            dispatch({
              type: "SET_PAGE_NUMBER",
              payload: pageNumber,
            })
          }
          rotate={rotate}
          setRotate={(rotate) =>
            dispatch({ type: "SET_ROTATE", payload: rotate })
          }
        />
      </div>
      <div>
        {rentRollPreviewFile ? (
          <PdfViewer pdf={rentRollPreviewFile} />
        ) : (
          <div className="container-loader2">
            <div className="loading">
              <div className="ball one"></div>
              <div className="ball two"></div>
              <div className="ball three"></div>
              <div className="ball four"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CreHistoryPdfViewer);
