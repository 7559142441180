import React, { useState, Fragment, memo } from "react";
import {
  FontAwesomeIcon,
  faEye,
  faFileArrowDown,
} from "../../../import/FontAwesomeIcons";
import { CustomButton } from "../../../components/index";
import { OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { CreLoader, CreHistoryPdfViewerModal } from "./index";
import { useCreHistoryApiCall, useToasts } from "../../../Hooks/index";

const ProcessCard = ({
  sNo,
  fileName,
  llmType,
  dateAndTime,
  request_Id,
  status,
}) => {
  const [creHistoryPdfViewerModalShow, setCreHistoryPdfViewerModalShow] =
    useState(false);
  const { getRentrollFile, getRentrollPreviewFile } = useCreHistoryApiCall();
  const { Toast } = useToasts();

  const slicedFileName = fileName.slice(0, 18);
  const slicedLlm = llmType.slice(0, 10);
  const statusStyle = { marginRight: "4px", fontSize: ".7rem" };

  const handlePreviewButton = () => {
    getRentrollPreviewFile(request_Id);
    setCreHistoryPdfViewerModalShow(true);
  };

  const handleDownloadButton = () => {
    getRentrollFile(request_Id, fileName);
    Toast("success", "Please wait. File is downloading... ");
  };

  const renderButton = (text, icon, onClickHandler) => (
    <CustomButton
      variant="primary"
      size="sm"
      onClick={onClickHandler}
      disabled={status === "processing" || status === "error"}
    >
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{text}</Tooltip>}
      >
        <FontAwesomeIcon icon={icon} />
      </OverlayTrigger>
    </CustomButton>
  );

  return (
    <Fragment>
      <div className="cre-processing-card">
        <span className="cre-processing-card-sno">{sNo}</span>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip">{fileName}</Tooltip>}
        >
          <span className="cre-processing-card-filename" data-testid={fileName}>
            {slicedFileName}...
          </span>
        </OverlayTrigger>
        <span className="cre-processing-card-date">{dateAndTime}</span>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip">{llmType}</Tooltip>}
        >
          <span className="cre-processing-card-llm-type" data-testid={llmType}>
            {slicedLlm}...
          </span>
        </OverlayTrigger>
        <span className="cre-processing-card-status-msg">
          {status === "processing" ? (
            <Badge bg="warning">
              <span className="cre-processing-card-status">
                <div style={statusStyle}>{status}</div>
                <CreLoader />
              </span>
            </Badge>
          ) : status === "error" ? (
            <Badge bg="danger">
              <span className="cre-processing-card-status">
                <div style={statusStyle}>{status}</div>
              </span>
            </Badge>
          ) : (
            <Badge bg="success">
              <span className="cre-processing-card-status">
                <div style={statusStyle}>{status}</div>
              </span>
            </Badge>
          )}
        </span>
        <span className="cre-processing-card-download-btn">
          {renderButton("Download", faFileArrowDown, handleDownloadButton)}
        </span>
        <span className="cre-processing-card-preview-btn">
          {renderButton("View", faEye, handlePreviewButton)}
        </span>
      </div>
      {creHistoryPdfViewerModalShow && (
        <CreHistoryPdfViewerModal
          creHistoryPdfViewerModalShow={creHistoryPdfViewerModalShow}
          setCreHistoryPdfViewerModalShow={() =>
            setCreHistoryPdfViewerModalShow()
          }
          fileName={fileName}
          dateAndTime={dateAndTime}
          llmType={llmType}
          fileDownload={handleDownloadButton}
        />
      )}
    </Fragment>
  );
};

export default memo(ProcessCard);
