let userData = JSON?.parse(sessionStorage?.getItem("usd")) || [];
let parsedUserData = userData.flat();

const token = parsedUserData[1]?.AuthenticationResult?.AccessToken || "";
const login_status = parsedUserData[1]?.login_status || "";
const errorMsg = parsedUserData[1]?.message || "";
const userDetails = parsedUserData[1]?.userDetails || [];
const monitoringAccess = parsedUserData[1]?.monitoring_access || "";
const notificationAccess = parsedUserData[1]?.notification_access || "";
const secretKeyJwt = parsedUserData[0]?.jk;
const userPermission = parsedUserData[1]?.permissions || [];
const encryptionKey = parsedUserData[0]?.eyk;
const initVector = parsedUserData[0]?.iv;
const initialPath = userPermission?.[0]?.path;

export {
  token,
  login_status,
  userDetails,
  monitoringAccess,
  notificationAccess,
  errorMsg,
  secretKeyJwt,
  userPermission,
  encryptionKey,
  initVector,
  initialPath,
};