import React, { Fragment, useState } from "react";
import ReqMTabelHeader from "./ReqMTableHeader";
import usePagination from "../../../Hooks/usePagination";
import ReqMTablePagination from "./ReqMTablePagination";
import ReqMTableBody from "./ReqMTableBody";
import "./ReqMTable.css";

const ReqMTabel = ({ stages_data, containerNo }) => {
  const [toggelCard, setToggelCard] = useState(
    new Array(stages_data?.length).fill(false)
  );

  // const openCard = (index) => {
  //   const newState = [...toggelCard];
  //   newState[index] = !newState[index];
  //   setToggelCard(newState);
  // };

  const openCard = (index) => {
    const newState = toggelCard.map((state, i) =>
      i === index ? !state : false
    );
    setToggelCard(newState);
  };

  const ITEMS_PER_PAGE = 10;
  const { handlePageChange, currentItems, currentPage, totalPages } =
    usePagination(ITEMS_PER_PAGE, stages_data);

  return (
    <Fragment>
      <ReqMTabelHeader />
      <ReqMTableBody
        containerNo={containerNo}
        currentItems={currentItems}
        toggelCard={toggelCard}
        itemPerPage={ITEMS_PER_PAGE}
        currentPage={currentPage}
        openCard={openCard}
        stages_data={stages_data}
      />
      {/* <ReqMTablePagination
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        totalPages={totalPages}
      />  */}
    </Fragment>
  );
};

export default ReqMTabel;
