import React, { useContext } from "react";
import "./GenAiHistory.css";
import ParentContext from "../../../../context/context";
import GenAiHistoryTabelHeader from "./GenAiHistoryTabelHeader";
import GenAiHistoryTabelBody from "./GenAiHistoryTabelBody";

const GenAiHistory = () => {
  const { state } = useContext(ParentContext);
  const { genAiHistoryResponse} = state;
  return (
    <div className="gen-ai-history">
      <GenAiHistoryTabelHeader />
      {genAiHistoryResponse &&
        genAiHistoryResponse?.map((data, index) => {
          return (
            <span key={index}>
              <GenAiHistoryTabelBody
                sNo={index + 1}
                fileName={data.file_name}
                dateAndTime={data.document_upload_datetime}
                status={data.file_status}
                llmType={data.llmtype}
                request_Id={data.request_Id}
              />
            </span>
          );
        })}
    </div>
  );
};

export default GenAiHistory;
