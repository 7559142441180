import React, { memo } from "react";
import Chart from "react-apexcharts";
import colors from "../../InferIq-Solution/utils/graphColors";

const BillingPieChart = ({ response, showLegend }) => {
  const state = {
    series: response?.datasets,
    options: {
      chart: {
        width: 350,
        type: "pie",
      },
      colors: [...colors],
      labels: response?.labels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
      legend: {
        show: showLegend,
        position: "right",
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return val?.toFixed(3) + "$";
          },
        },
      },
    },
  };

  return (
    <Chart
      options={state?.options}
      series={state?.series}
      type="pie"
      height={350}
    />
  );
};

export default memo(BillingPieChart);
