import React, { useEffect, useRef } from "react";
import LeaderLine from "leader-line-new";
import ReqMCard from "../ReqMCard/ReqMCard";
import "./ReqMFlowChart.css";

const ReqMFlowChart = ({
  flowChartData,
  ParentrequestId,
  phaseName,
  childId,
}) => {
  const containerRef = useRef(null);
  const createDivRefs = useRef(
    Array.from({ length: flowChartData?.length }, () => React.createRef())
  );

  useEffect(() => {
    const lines = [];
    for (let i = 0; i < createDivRefs.current.length - 1; i++) {
      let start_Element = createDivRefs.current[i].current;
      let end_Element = createDivRefs.current[i + 1].current;

      let lineStyles = {
        color: "#102447",
        outlineColor: "rgba(30, 130, 250, 0.5)",
        size: 1,
        startSocket: "right",
        endSocket: "left",
        path: "grid",
        startPlug: "square",
        endPlug: "arrow",
        startPlugColor: "#102447",
        endPlugColor: "#102447",
        startPlugSize: 1.5,
        endPlugSize: 1.5,
        gradient: false,
        outline: false,
        dropShadow: false,
        backgroundColor: null,
        dash: { animation: true },
      };
      const line = new LeaderLine(start_Element, end_Element, lineStyles);
      lines.push(line);
    }
    const updateLines = () => {
      lines.forEach((line) => line.position());
    };
    const handleScroll = () => {
      updateLines();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      lines.forEach((line) => line.remove());
    };
  }, [flowChartData?.length]);

  return (
    <div className="RequestMonitoring-flowchart" ref={containerRef}>
      {createDivRefs?.current?.map((divRef, index) => {
        return (
          <div
            key={index}
            ref={divRef}
            className="RequestMonitoring-card-wrapper"
          >
            <ReqMCard
              data={flowChartData[index]}
              ParentrequestId={ParentrequestId}
              phaseName={phaseName}
              childId={childId}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ReqMFlowChart;
