export const restrictedDomains = [
    "gmail.com",
    "outlook.com",
    "hotmail.com",
    "yahoo.com",
    "icloud.com",
    "AOL.com",
    "maildrop.cc",
    'devmail.com',
    'testmail.net',
    'debugmail.org',
    'fakeinbox.biz',
    'devboxmail.com',
    'dummyemail.co',
    'sandboxmail.info',
    'codeboxmail.net',
    'mockinbox.org',
    'devmailer.biz',
    'testinbox.net',
    'debugmailer.com',
    'fakeboxmail.co',
    'devtestmail.org',
    'sandboxinbox.net',
    'dummymailer.info',
    'codeinbox.biz',
    'mockmail.co',
    'devdummyemail.net',
    'testmailer.info',
    'debugboxmail.biz',
    'fakemailbox.org',
    'devsandboxmail.net',
    'testboxmailer.info',
    'debugdummyemail.biz',
    'codeboxinbox.org',
    'mocktestmail.net',
    'devtestmailer.co',
    'sandboxdummyemail.org',
    'fakemailinbox.biz',
    'addy.io',
    'guerrillamail.net',
    "mail.com",
    "email.com",
    "usa.com",
    "myself.com",
    "consultant.com",
    "post.com",
    "dr.com",
    "execs.com",
    "europe.com",
    "engineer.com",
    "asia.com",
    "writeme.com",
    "iname.com",
    "techie.com",
    "contractor.com",
    "accountant.com",
    "workmail.com",
    "artlover.com",
    "cheerful.com",
    "musician.org",
    "gmx.com",
    "gmx.us",
    "rediffmail.com",
];