import React, { useState, useContext } from "react";
import ParentContext from "../../../context/context";
import "./RedactionTable.css";
import { useRenderLine } from "../../../Hooks/index";
import {
  RedactedFields,
  RedactedPlainText,
  RedactionSelectFields,
} from "../../../services/Redaction/components";

const RedactionTable = (props) => {
  const [checkedFields, setCheckedFields] = useState([]);
  const { state, dispatch } = useContext(ParentContext);

  const {
    responseText,
    selectedValue,
    backendResponse,
    loading,
    redactionOutputFile,
  } = state;

  const { checkedState, setCheckedState } = props;
  const { renderTextInNewLines } = useRenderLine();

  const fields = React.useMemo(() => {
    let fields = [];
    if (selectedValue === "Forms") {
      fields = backendResponse?.form_keys?.Org;
    }
    if (selectedValue === "Plain Text") {
      backendResponse?.redact_identified_pii?.map((identified_pii) => {
        fields?.push(identified_pii?.Label);
      });
      fields = [...new Set(fields)];
    }
    return fields;
  }, [selectedValue, backendResponse, dispatch]);

  const CheckboxChangeHandler = React.useCallback((position) => {
    setCheckedState((prev) => {
      const updatedCheckedState = [...prev];
      updatedCheckedState[position] = !prev[position];
      fetchFields(updatedCheckedState);
      return updatedCheckedState;
    });
  }, []);

  const fetchFields = React.useCallback(
    (checked) => {
      const selectedFields = fields?.filter((_, index) => checked[index]);
      dispatch({ type: "SET_REDAC_SEL_FIELDS", payload: selectedFields });
      setCheckedFields(selectedFields);
    },
    [checkedState, dispatch, fields]
  );

  return (
    <React.Fragment>
      {loading ? (
        ""
      ) : (
        <div className="Extraction">
          <div className="Extraction-tables">
            {!redactionOutputFile &&
              (selectedValue === "Forms" || selectedValue === "Plain Text") &&
              responseText === "" && (
                <RedactionSelectFields
                  fields={fields}
                  checkedState={checkedState}
                  CheckboxChangeHandler={CheckboxChangeHandler}
                />
              )}
            {redactionOutputFile && selectedValue === "Forms" && (
              <RedactedFields checkedFields={checkedFields} />
            )}
            {responseText !== "" && (
              <RedactedPlainText
                responseText={responseText}
                renderTextInNewLines={renderTextInNewLines}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RedactionTable;
