import React, { useContext, useState, useEffect, memo } from "react";
import "./Setting.css";
import ParentContext from "../../context/context";
import {
  AdminControlledRedaction,
  AdminControlledQuestionnaire,
  Template,
  UserManagment,
} from "./components/index";
import {
  AdminControlledRedactionOptions,
  TemplateOptions,
} from "../../utils/settings";
import { Tab, Tabs } from "react-bootstrap";
import {
  FontAwesomeIcon,
  faBuildingUser,
  faGears,
  faPencil,
  faPlus,
  faSave,
  faTrashCan,
} from "../../import/FontAwesomeIcons";

const Setting = () => {
  const { dispatch } = useContext(ParentContext);
  const [isEditTemplateDisabled, setIsEditTemplateDisabled] = useState(false);
  const [isAddQuestionDisabled, setIsAddQuestionDisabled] = useState(false);
  const [isDeleteQuestionDisabled, setIsDeleteQuestionDisabled] =
    useState(false);

  useEffect(() => {
    const editTemplateState = localStorage.getItem("TemplateDisabled");
    const addQuestionState = localStorage.getItem("AddQuestionDisabled");
    const deleteQuestionState = localStorage.getItem("DeleteQuestionDisabled");
    if (editTemplateState)
      setIsEditTemplateDisabled(JSON.parse(editTemplateState));

    if (addQuestionState)
      setIsAddQuestionDisabled(JSON.parse(addQuestionState));

    if (deleteQuestionState)
      setIsDeleteQuestionDisabled(JSON.parse(deleteQuestionState));
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "TemplateDisabled",
      JSON.stringify(isEditTemplateDisabled)
    );
    localStorage.setItem(
      "AddQuestionDisabled",
      JSON.stringify(isAddQuestionDisabled)
    );
    localStorage.setItem(
      "DeleteQuestionDisabled",
      JSON.stringify(isDeleteQuestionDisabled)
    );
  }, [isEditTemplateDisabled, isAddQuestionDisabled, isDeleteQuestionDisabled]);

  const handleEditTemplateSwitchChange = () => {
    setIsEditTemplateDisabled(!isEditTemplateDisabled);
  };

  const handleAddQuestionSwitchChange = () => {
    setIsAddQuestionDisabled(!isAddQuestionDisabled);
  };

  const handleDeleteQuestionSwitchChange = () => {
    setIsDeleteQuestionDisabled(!isDeleteQuestionDisabled);
  };

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "AdminSetting" });
  }, [dispatch]);

  return (
    <Tabs
      defaultActiveKey="Admin Settings"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab
        eventKey="Admin Settings"
        title={
          <span className="tab-title">
            <FontAwesomeIcon icon={faGears} />
            Admin Settings
          </span>
        }
      >
        <div className="admin_settings">
          <div className="admin_setting_container1">
            <div className="admin_setting1">
              <AdminControlledRedaction
                AdminControlledRedactionOptions={
                  AdminControlledRedactionOptions
                }
              />
            </div>
            <div className="admin_setting2">
              <AdminControlledQuestionnaire
                handleDeleteQuestionSwitchChange={() =>
                  handleDeleteQuestionSwitchChange()
                }
                handleAddQuestionSwitchChange={() =>
                  handleAddQuestionSwitchChange()
                }
                handleEditTemplateSwitchChange={() =>
                  handleEditTemplateSwitchChange()
                }
                isEditTemplateDisabled={isEditTemplateDisabled}
                isAddQuestionDisabled={isAddQuestionDisabled}
                isDeleteQuestionDisabled={isDeleteQuestionDisabled}
              />
            </div>
          </div>

          <div className="admin_setting_container2">
            <Template
              TemplateOptions={TemplateOptions}
              title="Template"
              editIcon={faPencil}
              deleteIcon={faTrashCan}
              saveIcon={faSave}
              addIcon={faPlus}
              addButtonText="Create custom questionnaire template"
              isEditTemplateDisabled={isEditTemplateDisabled}
              isAddQuestionDisabled={isAddQuestionDisabled}
              isDeleteQuestionDisabled={isDeleteQuestionDisabled}
            />
            {/* <Sample/> */}
          </div>
        </div>
      </Tab>
      <Tab
        eventKey="User Managment"
        title={
          <span className="tab-title">
            <FontAwesomeIcon icon={faBuildingUser} />
            User Managment
          </span>
        }
      >
        <UserManagment />
      </Tab>
    </Tabs>
  );
};

export default memo(Setting);
