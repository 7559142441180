import React from "react";

const ErrorLogsHeader = () => {
  return (
    <div className="para error-logs-header">
      <span className="para error-logs-header_service">Service</span>
      <span className="para error-logs-header_form_type">Form Type</span>
      <span className="para error-logs-header_filename">File Name</span>
      <span className="para error-logs-header_llm_type">llm Type</span>
      <span className="para error-logs-header_file_upload">
        File upload Time
      </span>
      <span className="para error-logs-header_request_id">Request Id</span>
      <span className="para error-logs-header_phase_name">Phase Name</span>
    </div>
  );
};

export default ErrorLogsHeader;
