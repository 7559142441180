import React, { memo } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  FontAwesomeIcon,
  faClockRotateLeft,
  faUpload,
} from "../../../import/FontAwesomeIcons";
import { CreHistory, CreForm } from "../components/index";

const CreTabViewer = (props) => {
  return (
    <Tabs
      defaultActiveKey="Upload file"
      id="justify-tab-example"
      className="mb-3"
      justify
      onSelect={(eventKey) => {
        eventKey === "History" && props.handleHistoryTabClick();
        eventKey === "Upload file" && props.setHistoryTabActive(false);
      }}
    >
      <Tab
        eventKey="Upload file"
        title={
          <span className="tab-title">
            <FontAwesomeIcon icon={faUpload} />
            Upload file
          </span>
        }
      >
        <CreForm
          dispatch={props.dispatch}
          selectedValue={props.selectedValue}
          resetUploads={props.resetUploads}
          handleChange={props.handleChange}
          handleRadioLlm={props.handleRadioLlm}
          RadioOptions={props.RadioOptions}
          submitCre={props.submitCre}
        />
      </Tab>
      <Tab
        eventKey="History"
        title={
          <span className="tab-title">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            History
          </span>
        }
      >
        <div className="rent-roll-history">
          <CreHistory />
        </div>
      </Tab>
    </Tabs>
  );
};

export default memo(CreTabViewer);
