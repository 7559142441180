import React, { useContext, useEffect, memo } from "react";
import ParentContext from "../../context/context";
import { FileUploadForm, InferSideImage } from "../../components/index";
import { RedactionImg } from "../../images/sideContent/index";
import { userPermission } from "../../Auth/authConfig";

const Redaction = () => {
  const { dispatch } = useContext(ParentContext);
  
  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "Redaction" });
  }, [dispatch]);

  return (
    <FileUploadForm
      RadioOptions={userPermission?.map(
        (item) => item.label === "Redaction" && item.RadioOptions
      )}
      sideContent={
        <InferSideImage image={RedactionImg} title="Redaction">
          Digitally protect sensitive or confidential information from being
          disclosed in documents. InferIQ helps maintain confidentiality by
          identifying and concealing sensitive information, such as personal
          identification numbers, financial data, medical records, or classified
          information.
        </InferSideImage>
      }
      ButtonText="Next"
    />
  );
};

export default memo(Redaction);
