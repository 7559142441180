import { useContext } from "react";
import axios from "axios";
import { encryptData } from "../utils/crypto";
import ParentContext from "../context/context";
import env from "../env/env.js";

const useSignUpVerificationApi = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { signUpOtp } = state;
  let confirmSignUpData = {
    code: signUpOtp,
    email: sessionStorage.getItem("email"),
  };

  const getSignUpVerifiData = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    await axios
      .post(
        `${env.API_GATEWAY}/extractData`,
        {
          callType: "confirm_sign_up",
          formData: encryptData(confirmSignUpData),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_SIGNUP_VERIFI_BACKEND_RES",
          payload: response.data,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
      });
  };

  return { getSignUpVerifiData };
};

export default useSignUpVerificationApi;
