const inputList = [
    {
        id: Math.random(),
        toolTip: "",
        label: "Company Name",
        name: "clientCompanyName",
        placeholder: "Enter Company Name...",
        title: "Company Name",
        type: "text",
        pattern: "^[a-zA-Z ._-]*$",
        required: true,
        min: "",
        max: "",
        minLength: "",
        maxLength: "",
    }]

const dropDownList = [
    {
        id: Math.random(),
        toolTip: "",
        label: "Clients",
        name: "clients",
        required: true,
        value: [{
            id: Math.random(),
            label: "Select Client...",
            value: "",
            selected: true,
            hidden: true,
            disabled: true
        },
        {
            id: Math.random(),
            label: "client1",
            value: "client1",
            selected: false,
            hidden: false,
            disabled: false
        }, {
            id: Math.random(),
            label: "client2",
            value: "client2",
            selected: false,
            hidden: false,
            disabled: false
        }, {
            id: Math.random(),
            label: "client3",
            value: "client3",
            selected: false,
            hidden: false,
            disabled: false
        }, {
            id: Math.random(),
            label: "client4",
            value: "client4",
            selected: false,
            hidden: false,
            disabled: false
        }]
    },
]
const envdropDownList = [
    {
        id: Math.random(),
        toolTip: "",
        label: "Environment",
        name: "environment",
        required: true,
        value: [{
            id: Math.random(),
            label: "Select Environment...",
            value: "",
            selected: true,
            hidden: true,
            disabled: true
        },
        {
            id: Math.random(),
            label: "Development",
            value: "dev",
            selected: false,
            hidden: false,
            disabled: false
        }, {
            id: Math.random(),
            label: "Test",
            value: "test",
            selected: false,
            hidden: false,
            disabled: false
        }, {
            id: Math.random(),
            label: "UAT",
            value: "uat",
            selected: false,
            hidden: false,
            disabled: false
        }, {
            id: Math.random(),
            label: "production",
            value: "production",
            selected: false,
            hidden: false,
            disabled: false
        }]
    },
]
export { inputList, dropDownList, envdropDownList }