import { useContext } from "react";
import env from "../../InferIq-Solution/env/env";
import ParentContext from "../../InferIq-Solution/context/context";
import axios from "axios";
import sign from "jwt-encode";
import { secretKeyJwt, token, userDetails } from "../../InferIq-Solution/Auth/authConfig";

const useClientOnboarding = () => {
  const { state, dispatch } = useContext(ParentContext);
  const {
    checkBoxServicesValues,
    checkBoxllmsValues,
    checkBoxEnvValues,
    clientCompanyName,
  } = state;

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const fetchClientOnboardingData = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    dispatch({ type: "SET_RES_ERROR", payload: false });
    await axios
      .post(
        `${env.API_GATEWAY + "/requestmonitor"}`,
        {
          callType: "ClientOnboarding",
          companyName: clientCompanyName,
          services: checkBoxServicesValues,
          llms: checkBoxllmsValues,
          env: checkBoxEnvValues,
          userId: userDetails?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_BILLING_METRICES_ALL_RES",
          payload: response.data,
        });
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
        dispatch({ type: "SET_RES_ERROR", payload: true });
      });
  };

  return { fetchClientOnboardingData };
};

export default useClientOnboarding;
