const events = [
    // "focus",
    // "focusin",
    // "focusout",
    // "blur",
    // "wheel",
    // "contextmenu",
    // "dblclick",
    // "mouseenter",
    // "mouseleave",
    // "pointerdown",
    // "pointerup",
    // "pointercancel",
    // "pointermove",
    // "pointerover",
    // "pointerout",
    // "load",
    // "mousemove",
    // "mousedown",
    // "click",
    // "scroll",
    // "keypress",
    // "keydown",
    // "touchstart",
    // "touchmove",
    // "touchend",
    // "resize",
    ""
];

export default events;