import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { LabelSwitch, RedactionCheckBox } from "../../../../components/index";
import "./GenAiSwitches.css";
import AutoPiiModal from "./AutoPiiModal";
import ParentContext from "../../../../context/context";
import {
  FontAwesomeIcon,
  faCircleInfo,
} from "../../../../import/FontAwesomeIcons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const GenAiSwitches = (props) => {
  const { state, dispatch } = useContext(ParentContext);
  const { backendResponse, dataRedactionCheck, autoPiiRedactionCheck } = state;
  const { genaicheckedState, setGenAiCheckedState } = props;
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [tooltip, setTooltip] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleShow = () => {
    setFullscreen();
    setShow(true);
  };

  const fields = useMemo(() => {
    let getGenAiFields = [];
    getGenAiFields = backendResponse?.ner_list?.map(({ ner, desc }) => ({
      ner,
      desc,
    }));
    return getGenAiFields;
  }, []);

  const dataRedactionHandler = () => {
    const updatedDataRedactionCheck = !dataRedactionCheck;
    dispatch({
      type: "SET_DATA_REDACTION_CHECK",
      payload: updatedDataRedactionCheck,
    });
    localStorage.setItem(
      "dataRedactionCheck",
      JSON.stringify(updatedDataRedactionCheck)
    );
  };
  const autoPiiRedactionHandler = () => {
    dispatch({
      type: "SET_AUTO_PII_REDACTION_CHECK",
      payload: !autoPiiRedactionCheck,
    });
  };
  const CheckboxChangeHandler = useCallback((position) => {
    setGenAiCheckedState((prev) => {
      const updatedCheckedState = [...prev];
      updatedCheckedState[position] = !prev[position];
      fetchFields(updatedCheckedState);
      const tooltipDescription = fields?.[position]?.desc;
      setTooltip(tooltipDescription);
      return updatedCheckedState;
    });
  }, []);

  const fetchFields = useCallback(
    (checked) => {
      const selectedFields = fields?.filter((_, index) => checked[index]);
      let nerSelectedFields = selectedFields.map(({ ner }) => ner);
      dispatch({
        type: "SET_GENAI_REDACTION_CHECKFIELDS",
        payload: nerSelectedFields,
      });
    },
    [fields]
  );

  useEffect(() => {
    const storedDataRedactionCheck = localStorage.getItem("dataRedactionCheck");
    if (storedDataRedactionCheck) {
      dispatch({
        type: "SET_DATA_REDACTION_CHECK",
        payload: JSON.parse(storedDataRedactionCheck),
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "dataRedactionCheck",
      JSON.stringify(dataRedactionCheck)
    );
  }, [dataRedactionCheck]);

  useEffect(() => {
    return () => setTooltip("");
  }, [fields]);

  const handleCheckboxHover = (index, desc) => {
    setHoveredIndex(index);
    setTooltip(desc);
  };

  let AutoPiiLabel = (
    <span>
      <span className="genai_switch_label">Auto PII Redaction</span>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Learn more</Tooltip>}
      >
        <FontAwesomeIcon
          className="info_svg"
          onClick={() => handleShow()}
          icon={faCircleInfo}
        />
      </OverlayTrigger>
    </span>
  );

  return (
    <div className="genai_switches_main_container">
      <div className="redaction-switches">
        <div className="switch-container">
          <div>
            <LabelSwitch
              label="Data Redaction"
              value="Data Redaction"
              Checked={dataRedactionCheck}
              onChange={dataRedactionHandler}
            />
          </div>
          <div>
            {dataRedactionCheck && (
              <LabelSwitch
                label={AutoPiiLabel}
                value="Auto PII Redaction"
                Checked={autoPiiRedactionCheck}
                onChange={autoPiiRedactionHandler}
                disabled={false}
              />
            )}
            {show && (
              <AutoPiiModal
                show={show}
                fullscreen={fullscreen}
                onHide={() => setShow(false)}
              />
            )}
          </div>
        </div>
      </div>
      {dataRedactionCheck && (
        <div className="genai_switch_checkfields_maincontainer">
          <span className="heading">Manual Redaction</span>
          <hr />
          {fields?.map((value, index) => {
            return (
              <div
                className="genai_switch_checkfields"
                key={value.ner}
                onMouseEnter={() => handleCheckboxHover(index, value.desc)}
                onMouseLeave={() => handleCheckboxHover(-1, "")}
                style={{ position: "relative" }}
              >
                <RedactionCheckBox
                  name={value.ner}
                  label={value.ner}
                  checked={genaicheckedState?.[index]}
                  onChange={() => CheckboxChangeHandler(index)}
                />
                {value.desc && index === hoveredIndex && tooltip && (
                  <div className="genai_switch_checkfields_tooltip">
                    <span className="para">{value.desc}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GenAiSwitches;
