const apiEndpoints = {
  test: "https://testapi.inferiq.ai/inferiq",
  dev: "https://devapi.inferiq.ai/inferiq",
  demo: "https://demoapi.inferiq.ai/inferiq",
  sandbox: "https://sandboxapi.inferiq.ai/inferiq",
  localhost: "https://devapi.inferiq.ai/inferiq",
};

const hostname = window.location.hostname;
const getMatchingEndpoint = Object.keys(apiEndpoints).find((key) =>
  hostname.includes(key)
);

const API_GATEWAY = () => apiEndpoints[getMatchingEndpoint];

const getEnvironment = () => {
  const env = process.env.NODE_ENV;
  switch (env) {
    case 'production':
      return 'dev';
    case 'dev':
      return 'dev';
    default:
      return 'dev';
  }
};

export default {
  API_GATEWAY: API_GATEWAY(),
  apiEndpoints: getEnvironment()
};