import React, { useContext, useState } from "react";
import "./GenAiSummary.css";
import ParentContext from "../../../../context/context";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useRenderLine } from "../../../../Hooks/index";

const GenAiSummary = () => {
  const { state } = useContext(ParentContext);
  const { backendResponse } = state;
  const [selectedPage, setSelectedPage] = useState({
    category: "combined",
    page: null,
  });

  const { renderTextInNewLines } = useRenderLine();

  const handlePageSelect = (category, page) => {
    setSelectedPage({ category, page });
  };

  const removeUnderscoresFromWord = (word) => {
    return word.replace(/_+/g, " ");
  };

  return (
    <div className="genai_summary_conatiner">
      <div className="genai_summary_header">
        <span className="heading">
          {selectedPage && selectedPage?.category === "combined"
            ? "Document"
            : `${removeUnderscoresFromWord(selectedPage?.page)}`}
        </span>
        <div>
          <DropdownButton id="page-dropdown" size="sm" title="Select Page">
            <div className="genai_summary_dropdown">
              <Dropdown.Header>
                <span
                  className="para"
                  style={{ color: "rgb(16, 36, 71)", fontWeight: "500" }}
                >
                  Document Summary
                </span>
              </Dropdown.Header>
              <Dropdown.Divider />
              {backendResponse?.summary_response?.combined?.map((item) => (
                <Dropdown.Item
                  key={item}
                  onClick={() => handlePageSelect("combined", "1")}
                >
                  <span className="para">Document</span>
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
              <Dropdown.Header>
                <span
                  className="para"
                  style={{ color: "rgb(16, 36, 71)", fontWeight: "500" }}
                >
                  Page Wise Summary
                </span>
              </Dropdown.Header>
              <Dropdown.Divider />
              <div className="genai_summary_dropdown_pagewise">
                {backendResponse?.summary_response?.individual?.map((item) => (
                  <Dropdown.Item
                    key={item.page}
                    onClick={() => handlePageSelect("individual", item.page)}
                  >
                    <span className="para">
                      {removeUnderscoresFromWord(item.page)}
                    </span>
                  </Dropdown.Item>
                ))}
              </div>
            </div>
          </DropdownButton>
        </div>
      </div>
      <div className="genai_summary_preview">
        <span className="para">
          {renderTextInNewLines(
            selectedPage?.category === "individual"
              ? backendResponse?.summary_response?.individual.find(
                  (item) => item?.page === selectedPage?.page
                )?.text
              : backendResponse?.summary_response?.combined?.[0]?.text
          )}
        </span>
      </div>
    </div>
  );
};

export default GenAiSummary;
