import React from "react";
import { useStatus } from "../../../Hooks/index";

const RedactionLoader = () => {
  const { parsedStatus, style, statusMsgForGenaiTabs } = useStatus();
  return (
    <div className="container-loader2">
      <div className="progress-container">
        <div className="progress" style={{ width: "230px" }}>
          <div
            className="progress-done progress-bar-animated progress-bar-striped"
            style={style}
          >
            {parsedStatus}%
          </div>
        </div>
        <div className="progress-message para">{statusMsgForGenaiTabs}</div>
      </div>
    </div>
  );
};
export default RedactionLoader;
