import React, { useState } from "react";

const usePagination = (perPageItems, stages_data) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = perPageItems;
  const totalItems = stages_data?.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = stages_data?.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return { handlePageChange, currentItems, currentPage, totalPages };
};

export default usePagination;
