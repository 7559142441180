import React, { useMemo, memo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import GridItem from "./GridItem";

const UserDashboard = (props) => {
  const {
    breakpoints,
    cols,
    onBreakpointChange,
    currentLayout,
    data,
    selectedLayouts,
  } = props;

  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

  return (
    <ResponsiveGridLayout
      isDraggable={true}
      isRearrangeable={true}
      isResizable={true}
      breakpoints={breakpoints}
      cols={cols}
      layouts={currentLayout}
      onBreakpointChange={onBreakpointChange}
    >
      {currentLayout
        ?.filter((item) => selectedLayouts?.includes(item?.key))
        ?.map((item) => (
          <div
            className="userDashboard-grid-cell"
            key={item?.key}
            data-grid={{
              x: item?.gridValues?.x,
              y: item?.gridValues?.y,
              w: item?.gridValues?.w,
              h: item?.gridValues?.h,
              // static: item?.gridValues?.static,
            }}
          >
            {data
              ?.filter((graph) => graph?.key === item?.key)
              ?.map(({ key, title, chart, toggel }) => (
                <React.Fragment key={key}>
                  <GridItem title={title} chart={chart} toggel={toggel} />
                </React.Fragment>
              ))}
          </div>
        ))}
    </ResponsiveGridLayout>
  );
};
export default memo(UserDashboard);
