import React, { memo, useContext, useEffect, useRef, useState } from "react";
import logo from "../../images/InferIQ-Blue.png";
import SignInForm from "./components/SignInForm";
import SignUpForm from "./components/SignUpForm";
import ParentContext from "../../context/context";
import ForgotPassword from "./components/ForgotPassword";
import {
  useForgetPasswordApi,
  useSignInApi,
  useSignUpApi,
  useSignUpVerificationApi,
  useToasts,
  useUpdatePassword,
  useValidater,
  useSignUpResendVerificationCodeApi,
} from "../../Hooks";
import { SiginUpVerificationForm } from "./components/SiginUpVerificationForm";
import { token, login_status, errorMsg } from "../../Auth/authConfig";
console.log(memo);
const LoginRightContainer = () => {
  const { state, dispatch } = useContext(ParentContext);
  const {
    validatePassword,
    validateOTP,
    validateEmail,
    comparePassword,
    validateName,
    validateJobTitle,
    validateCompany,
  } = useValidater();

  const {
    signInFormData,
    signUpFormData,
    formError,
    forgetPasswordFormData,
    showPassword,
    termsAndConditions,
    signUpOtp,
    getUpdatePasswordStatus,
  } = state;
  const alreadyRenderedRef = useRef(false);
  const { getSignInData } = useSignInApi();
  const { getSignUpData } = useSignUpApi();
  const { getForgetPassVeriFiCode } = useForgetPasswordApi();
  const { getSignUpVerifiData } = useSignUpVerificationApi();
  const { getSignUpResendVerifiCode } = useSignUpResendVerificationCodeApi();
  const { updatePassword } = useUpdatePassword();

  const { Toast } = useToasts();
  const [showVerifiSignUpForm, setShowVerfiSignUpForm] = useState(false);
  const [showVerificationForm, setShowVerficationForm] = useState(false);
  const [showVerificationEmail, setShowVerficationEmail] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [toastShown, setToastShown] = useState(false);

  const showSignIn = () => {
    dispatch({
      type: "SET_IS_SIGN_IN",
      payload: true,
    });
    dispatch({
      type: "SET_IS_SIGN_UP",
      payload: false,
    });
    dispatch({
      type: "SET_FORGOT_PASSWORD",
      payload: false,
    });
  };

  const showSignUp = () => {
    dispatch({
      type: "SET_IS_SIGN_IN",
      payload: false,
    });
    dispatch({
      type: "SET_IS_SIGN_UP",
      payload: true,
    });
    dispatch({
      type: "SET_FORGOT_PASSWORD",
      payload: false,
    });
    setShowVerfiSignUpForm(false);
  };

  const showForgotPassword = () => {
    dispatch({
      type: "SET_IS_SIGN_IN",
      payload: false,
    });
    dispatch({
      type: "SET_IS_SIGN_UP",
      payload: false,
    });
    dispatch({
      type: "SET_FORGOT_PASSWORD",
      payload: true,
    });
    showForgotPasswordEmail();
  };

  const showSignUpVerificationForm = () => {
    dispatch({
      type: "SET_IS_SIGN_IN",
      payload: false,
    });
    setShowVerfiSignUpForm(true);
  };

  const showForgotPasswordEmail = () => {
    setShowVerficationEmail(true);
    setShowVerficationForm(false);
  };

  const showForgotPasswordVerificationForm = () => {
    dispatch({
      type: "SET_FORGET_PASS_FORM_DATA",
      payload: "",
    });
    setShowVerficationForm(true);
    setShowVerficationEmail(false);
  };

  const handleSignInInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "SET_SIGN_IN_FORM_DATA",
      payload: {
        ...signInFormData,
        [name]: value,
      },
    });
    dispatch({
      type: "SET_FORM_ERROR",
      payload: "",
    });
  };

  const handleSignUpInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "SET_SIGN_UP_FORM_DATA",
      payload: {
        ...signUpFormData,
        [name]: value,
      },
    });
    dispatch({
      type: "SET_FORM_ERROR",
      payload: "",
    });
  };

  const handleForgotPassInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "SET_FORGET_PASS_FORM_DATA",
      payload: {
        ...forgetPasswordFormData,
        [name]: value,
      },
    });
    dispatch({
      type: "SET_FORM_ERROR",
      payload: "",
    });
  };

  const handleShowPassword = () => {
    dispatch({ type: "SET_SHOW_PASSWORD", payload: !showPassword });
  };

  const handlesignUpOtp = (e) => {
    dispatch({
      type: "SET_SIGNUP_OTP",
      payload: e.target.value,
    });
    dispatch({
      type: "SET_FORM_ERROR",
      payload: "",
    });
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    if (termsAndConditions) {
      getSignInData();
    } else {
      Toast(
        "error",
        "Please agree to the terms and conditions before signing up."
      );
    }
    sessionStorage.removeItem("frgtEmail");
  };

  useEffect(() => {
    if (!alreadyRenderedRef.current) {
      if (token && login_status === true && !toastShown) {
        setToastShown(true);
      } else if (!token && errorMsg) {
        Toast(
          "error",
          "The username or password you entered is incorrect. Please check your credentials and try again."
        );
      }
      alreadyRenderedRef.current = true;
    }
  }, [login_status, toastShown, Toast]);

  const handleSignUp = (e) => {
    e.preventDefault();
    if (termsAndConditions) {
      const checkForValidName = validateName(signUpFormData?.sname);
      const checkForValidJobTitle = validateJobTitle(signUpFormData?.jobTitle);
      const checkForValidCompany = validateCompany(signUpFormData?.company);
      const checkForValidEmail = validateEmail(signUpFormData?.email);
      const checkForValidPassword = validatePassword(signUpFormData?.password);
      const checkComparePassword = comparePassword(
        signUpFormData?.password,
        signUpFormData?.confirmPassword
      );
      if (
        checkForValidName &&
        checkForValidCompany &&
        checkForValidEmail &&
        checkForValidJobTitle &&
        checkForValidPassword &&
        checkComparePassword
      ) {
        getSignUpData();
        sessionStorage.setItem("email", signUpFormData?.email);
        setShowVerfiSignUpForm(true);
      }
    } else {
      Toast(
        "error",
        "Please agree to the terms and conditions before signing up."
      );
    }
  };

  useEffect(() => {
    const storedCount = localStorage.getItem("clickCount");
    const storedDisabledTime = localStorage.getItem("disabledTime");

    if (storedCount && storedDisabledTime) {
      const parsedCount = parseInt(storedCount);
      const parsedDisabledTime = parseInt(storedDisabledTime);
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parsedDisabledTime;

      if (parsedCount >= 5 && elapsedTime < 120000) {
        setClickCount(parsedCount);
        setDisabled(true);
        const timeout = setTimeout(() => {
          setDisabled(false);
          setClickCount(0);
          localStorage.removeItem("clickCount");
          localStorage.removeItem("disabledTime");
        }, 120000 - elapsedTime);
        return () => clearTimeout(timeout);
      } else {
        localStorage.removeItem("clickCount");
        localStorage.removeItem("disabledTime");
      }
    }
  }, []);

  const handleClick = () => {
    if (!disabled) {
      const newCount = clickCount + 1;
      setClickCount(newCount);
      localStorage.setItem("clickCount", newCount);
      if (newCount >= 5) {
        setDisabled(true);
        localStorage.setItem("disabledTime", new Date().getTime());
        setTimeout(() => {
          setDisabled(false);
          setClickCount(0);
          localStorage.removeItem("clickCount");
          localStorage.removeItem("disabledTime");
        }, 120000);
      }
    }
  };

  const handleSignUpVerifiCode = (e) => {
    e.preventDefault();
    const checkForValidOTP = validateOTP(signUpOtp);
    if (checkForValidOTP) {
      getSignUpVerifiData();
      setShowVerfiSignUpForm(false);
      showSignIn();
      Toast(
        "success",
        "Thank you for registering with InferlQ lite. Your account has been successfully created. Please login to continue"
      );
    }
  };

  const handleResendSignUpVerifiCode = (e) => {
    e.preventDefault();
    handleClick();
    getSignUpResendVerifiCode();
    Toast("success", "Please check you email for latest code");
  };

  const handleGetVerifiCodeForNewPassword = (e) => {
    e.preventDefault();
    const checkForValidEmail = validateEmail(forgetPasswordFormData?.email);
    sessionStorage.setItem("frgtEmail", forgetPasswordFormData?.email);
    if (checkForValidEmail) {
      getForgetPassVeriFiCode();
      Toast("info", "OTP has been sent to your email. Please check your inbox");
      showForgotPasswordVerificationForm();
    }
  };

  const handeleChangeNewPassword = async (e) => {
    e.preventDefault();
    const checkForValidOTP = validateOTP(
      forgetPasswordFormData?.verificationCode
    );
    const checkForValidPassword = validatePassword(
      forgetPasswordFormData?.password
    );
    const checkComparePassword = comparePassword(
      forgetPasswordFormData?.password,
      forgetPasswordFormData?.confirmPassword
    );

    if (checkForValidOTP && checkForValidPassword && checkComparePassword) {
      await updatePassword();
    }
  };

  useEffect(() => {
    if (getUpdatePasswordStatus) {
      if (getUpdatePasswordStatus.status === true) {
        showSignIn();
        Toast(
          "success",
          "Password changed successfully! Please login again with your email and new password."
        );
      } else {
        Toast("error", "Invalid OTP. Please try again");
      }
    }
  }, [getUpdatePasswordStatus]);

  const handleTermsAndConditions = () => {
    dispatch({
      type: "SET_TERMS_AND_CONDITIONS",
      payload: !termsAndConditions,
    });
  };

  return (
    <div className="loginpage-right-container">
      <div className="loginpage-right-container-form ">
        <div className="loginpage-form-header">
          <img src={logo} alt="logo" />
          <p>Welcome to InferIQ</p>
        </div>
        <div className="loginpage-signin">
          {state.isSignIn && (
            <SignInForm
              handleSubmit={handleSignIn}
              signInFormData={signInFormData}
              handleInputChange={handleSignInInputChange}
              formError={formError}
              showSignUp={showSignUp}
              showForgotPassword={showForgotPassword}
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
              showSignUpVerificationForm={showSignUpVerificationForm}
              handleTermsAndConditions={handleTermsAndConditions}
            />
          )}
          {state.isSignUp && (
            <SignUpForm
              signUpOtp={signUpOtp}
              handleSubmit={handleSignUp}
              signUpFormData={signUpFormData}
              formError={formError}
              handleInputChange={handleSignUpInputChange}
              showVerifiSignUpForm={showVerifiSignUpForm}
              showSignIn={showSignIn}
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
              termsAndConditions={termsAndConditions}
              handleSignUpVerifiCode={handleSignUpVerifiCode}
              handlesignUpOtp={handlesignUpOtp}
              handleResendSignUpVerifiCode={handleResendSignUpVerifiCode}
              disabled={disabled}
            />
          )}
          {state.isForgotPassword && (
            <ForgotPassword
              forgetPasswordFormData={forgetPasswordFormData}
              handleGetVerifiCodeForNewPassword={
                handleGetVerifiCodeForNewPassword
              }
              handleForgotPassInputChange={handleForgotPassInputChange}
              formError={formError}
              showVerificationForm={showVerificationForm}
              showVerificationEmail={showVerificationEmail}
              showSignIn={showSignIn}
              handeleChangeNewPassword={handeleChangeNewPassword}
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
            />
          )}
          {showVerifiSignUpForm && (
            <SiginUpVerificationForm
              formError={formError}
              handleSignUpVerifiCode={handleSignUpVerifiCode}
              signUpOtp={signUpOtp}
              handlesignUpOtp={handlesignUpOtp}
              handleResendSignUpVerifiCode={handleResendSignUpVerifiCode}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(LoginRightContainer);
