import React, { Fragment, useCallback, useContext } from "react";
import "./PredictiveAnalyticsOutputScreen.css";
import PaHorizontalBarChart from "./Charts/PaHorizontalBarChart";
import PaDonutChart from "./Charts/PaDonutChart";
import ParentContext from "../../context/context";
import ErrorHandler from "../OutputScreen/components/ErrorHandler";
import { useNavigate } from "react-router";

const PredictiveAnalyticsOutputScreen = () => {
  const { state } = useContext(ParentContext);
  const { backendResponse } = state;

  const basicsStyles = {
    container: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      width: "100%",
    },
    value: {
      flex: 1,
    },
  };
  const navigate = useNavigate();

  const BackToHome = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  return (
    <Fragment>
      {(backendResponse === undefined ||
        backendResponse === null ||
        backendResponse === "Did not get the response from API" ||
        backendResponse === "something went wrong" ||
        backendResponse?.length === 0) && (
        <ErrorHandler
          backendResponse={backendResponse}
          BackToHome={BackToHome}
        />
      )}
      {backendResponse?.length !== 0 &&
        backendResponse !== null &&
        backendResponse !== "Did not get the response from API" &&
        backendResponse !== "something went wrong" && (
          <div className="pa_output_container">
            <div className="pa_left_container">
              <div className="pa_donut_chart">
                <header
                  className="para"
                  style={{ fontWeight: "500", marginBottom: "20px" }}
                >
                  {backendResponse?.graphChart?.title}
                </header>
                <div
                  data-testid={`${backendResponse?.graphChart?.labels?.[0]}:${backendResponse?.graphChart?.datasets?.[0]}<-->${backendResponse?.graphChart?.labels?.[1]}:${backendResponse?.graphChart?.datasets?.[1]}`}
                >
                  <PaDonutChart response={backendResponse?.graphChart} />
                </div>
              </div>
              <div className="pa_desc para">
                <div className="pa_accordion">
                  <div className="heading">
                    <span>Points</span>
                  </div>
                  <div>
                    <hr />
                    {backendResponse?.points?.map((item, index) => (
                      <span key={item?.key} style={basicsStyles?.container}>
                        <div style={basicsStyles?.value}>{item?.value}</div>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="pa_accordion">
                  <div className="heading">Conculsion</div>
                  <div className="para">
                    <hr />
                    <span>{backendResponse?.conclusion}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pa_right_container">
              <header
                className="para"
                style={{ fontWeight: "500", marginBottom: "20px" }}
              >
                {backendResponse?.horizontalBarChart?.title}
              </header>
              <PaHorizontalBarChart
                response={backendResponse?.horizontalBarChart}
              />
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default PredictiveAnalyticsOutputScreen;
