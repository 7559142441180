export default [
    {
        id: Math.random(),
        label: "Name",
        type: "text",
        name: "sname",
        placeholder: "Enter your name...",
        required: true
    },
    {
        id: Math.random(),
        label: "Company Name",
        type: "text",
        name: "company",
        placeholder: "Enter your company name...",
        required: true
    },
    {
        id: Math.random(),
        label: "Corporate Email",
        type: "email",
        name: "email",
        placeholder: "Enter your Email id...",
        required: true
    }
    , {
        id: Math.random(),
        label: "Job Title",
        type: "text",
        name: "jobTitle",
        placeholder: "Enter your job title...",
        required: true
    }, {
        id: Math.random(),
        label: "Password",
        type: "password",
        name: "password",
        placeholder: "Enter Password...",
        required: true
    }, {
        id: Math.random(),
        label: "Confirm Password",
        type: "password",
        name: "confirmPassword",
        placeholder: "Confirm Password...",
        required: true
    }
]