export default [
    {
        id: 3,
        label: "Bedrock Anthropic Claude",
        value: "Bedrock Anthropic Claude",
        group: "fileType",
        description:
            "Claude is based on Anthropic's research into creating reliable, interpretable, and steerable AI systems.",
    },
    // {
    //     id: 1,
    //     label: "PrivateGPT",
    //     value: "PrivateGPT",
    //     group: "fileType",
    //     description:
    //         "LLMs are built on neural network architectures, particularly the transformer architecture, which allows them to capture complex language patterns and relationships between words or phrases in large-scale text datasets",
    // },
    // {
    //     id: 2,
    //     label: "ChatGPT",
    //     value: "ChatGPT",
    //     group: "fileType",
    //     description: `The information will be shared with ChatGPT. If you have chosen to
    //     redact information, only the redacted information will be shared
    //     with ChatGPT. Please review the data before proceeding. By
    //     proceeding you agree to share the data with ChatGPT`,
    // },
];