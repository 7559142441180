import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomButton } from "../../../../components";
import { useToasts, useGenAiHistoryApiCall } from "../../../../Hooks/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

const GenAiHistoryTabelBody = ({
  sNo,
  fileName,
  dateAndTime,
  status,
  llmType,
  request_Id,
}) => {
  const { getGenAiZipFolder } = useGenAiHistoryApiCall();

  const statusStyle = { marginRight: "4px", fontSize: ".7rem" };
  const { Toast } = useToasts();

  const slicedFileName = fileName.slice(0, 18);

  const handleDownloadButton = () => {
    getGenAiZipFolder(request_Id, fileName);
    Toast("success", "Please wait. File is downloading... ");
  };
  return (
    <div className="genai-history-table-body">
      <span className="genai-history-table-header-sno">{sNo}</span>
      <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip">{fileName}</Tooltip>}
        >
      <span className="genai-history-table-header-filename" data-testid={fileName}>{slicedFileName}...</span>
      </OverlayTrigger>
      <span className="genai-history-table-header-dateAndTime">
        {dateAndTime}
      </span>
      <span className="genai-history-table-header-llm_type">{llmType}</span>
      <span className="genai-history-table-header-status">
        <Badge pill bg="success">
          <span className="cre-processing-card-status">
            <div style={statusStyle}>{status}</div>
          </span>
        </Badge>
      </span>
      <span className="genai-history-table-header-download">
        <CustomButton
          variant="primary"
          size="sm"
          onClick={handleDownloadButton}
          disabled={status === "failed"}
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">Click To Download</Tooltip>}
          >
            <FontAwesomeIcon icon={faFileArrowDown} />
          </OverlayTrigger>
        </CustomButton>
      </span>
    </div>
  );
};

export default GenAiHistoryTabelBody;
