import React, { useEffect } from "react";
import {
  FontAwesomeIcon,
  faAnglesLeft,
  faAnglesRight,
  faAngleLeft,
  faAngleRight,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faRotate,
} from "../../../../import/FontAwesomeIcons.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./ControlPanel.css";

const PageControl = (props) => {
  return (
    <div className="page-control">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">GoToFirstPage</Tooltip>}
      >
        <FontAwesomeIcon
          className={` aleft ${props.firstPageClass}`}
          icon={faAnglesLeft}
          onClick={props.goToFirstPage}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">GoToPreviousPage</Tooltip>}
      >
        <FontAwesomeIcon
          className={` aleft ${props.firstPageClass}`}
          icon={faAngleLeft}
          onClick={props.goToPreviousPage}
        />
      </OverlayTrigger>
      <span>
        Page
        <input
          name="pageNumber"
          type="number"
          min={1}
          max={props.numPages || 1}
          className="pageinput"
          value={props.pageNumber}
          onChange={props.onPageChange}
          readOnly
        />
        of {props.numPages}
      </span>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">GoToNextPage</Tooltip>}
      >
        <FontAwesomeIcon
          className={` aright ${props.lastPageClass}`}
          icon={faAngleRight}
          onClick={props.goToNextPage}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">GoToLastPage</Tooltip>}
      >
        <FontAwesomeIcon
          className={` aright ${props.lastPageClass}`}
          icon={faAnglesRight}
          onClick={props.goToLastPage}
        />
      </OverlayTrigger>
    </div>
  );
};
const ZoomControl = (props) => {
  return (
    <div className="zoom-control">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">ZoomOut</Tooltip>}
      >
        <FontAwesomeIcon
          className={`zoomminus  ${props.zoomOutClass}`}
          icon={faMagnifyingGlassMinus}
          onClick={props.zoomOut}
        />
      </OverlayTrigger>
      <span>{props.toFixed}%</span>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">ZoomIn</Tooltip>}
      >
        <FontAwesomeIcon
          className={`zoomplus ${props.zoomInClass}`}
          icon={faMagnifyingGlassPlus}
          onClick={props.zoomIn}
        />
      </OverlayTrigger>
    </div>
  );
};

const PageRotate = (props) => {
  return (
    <div className="page-rotate">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Rotate</Tooltip>}
      >
        <FontAwesomeIcon icon={faRotate} onClick={props.rotatePage} />
      </OverlayTrigger>
    </div>
  );
};

const ControlPanel = ({
  numPages,
  scale,
  setScale,
  rotate,
  setRotate,
  previewPage,
  setPreviewPage,
}) => {
  const isMinZoom = scale < 0.5;
  const isMaxZoom = scale >= 5.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  const rotatePage = () => {
    setRotate(rotate + 90);
  };

  const isFirstPage = previewPage === 1;
  const isLastPage = previewPage === numPages;

  const firstPageClass = isFirstPage ? "disabled" : "clickable";
  const lastPageClass = isLastPage ? "disabled" : "clickable";

  const goToFirstPage = () => {
    if (!isFirstPage) setPreviewPage(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPreviewPage(previewPage - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPreviewPage(previewPage + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPreviewPage(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPreviewPage(Number(value));
  };

  useEffect(() => {
    setPreviewPage(1);
    return () => {
      setScale(scale);
    };
  }, []);

  return (
    <div className="control-panel">
      <PageControl
        numPages={numPages}
        pageNumber={previewPage}
        firstPageClass={firstPageClass}
        lastPageClass={lastPageClass}
        goToFirstPage={goToFirstPage}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
        onPageChange={onPageChange}
      />
      <PageRotate rotatePage={rotatePage} />
      <ZoomControl
        toFixed={(scale * 100).toFixed()}
        zoomOutClass={zoomOutClass}
        zoomInClass={zoomInClass}
        zoomOut={zoomOut}
        zoomIn={zoomIn}
      />
    </div>
  );
};

export default ControlPanel;
