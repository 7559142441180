import React, { Fragment, useEffect } from "react";
import ReactJson from "react-json-view";

const ErrorLogsBody = ({ data }) => {
  useEffect(() => {
    const elements = document.querySelectorAll(".string-value");
    elements?.forEach(function (element) {
      const innerHTML = element?.innerHTML;
      if (innerHTML?.includes("Traceback")) {
        element?.classList?.add("traceback-styles");
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="para error-logs-body">
        <span className="para error-logs-body_service">{data?.service}</span>
        <span className="para error-logs-body_form_type">
          {data?.form_type}
        </span>
        <span className="para error-logs-body_filename">
          {data?.file_name?.slice(0, 10)}...
        </span>
        <span className="para error-logs-body_llm_type">
          {data?.llmtype?.slice(0, 10)}...
        </span>
        <span className="para error-logs-body_file_upload">
          {data?.start_time}
        </span>
        <span className="para error-logs-body_request_id">
          {data?.request_id?.slice(0, 18)}...
        </span>
        <span className="para error-logs-body_phase_name">
          {data?.phase_name}
        </span>
      </div>
      <div className="err-logs-json-viewer">
        <ReactJson
          src={data?.stages}
          name="stages"
          iconStyle="circle"
          collapsed={false}
          onDelete={false}
          onEdit={false}
          onAdd={false}
        />
      </div>
    </Fragment>
  );
};

export default ErrorLogsBody;
