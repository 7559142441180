import React from "react";

const TermsAndConditions = () => {
  return (
    <div style={{ padding: "15px" }}>
      <div style={{ textAlign: "center" }}>
        <h5>Idexcel-InferIQ Terms And Conditions</h5>
      </div>
      <hr />
      <div>
        <h6>Introduction</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          Idexcel offers a web-based IDP platform – InferIQ, as a service and
          related products to banks, financial institutions, factors, and other
          commercial financial companies. In this connection, each Party may
          obtain access to certain confidential information of the other Party.
          In order to safeguard the Parties’ rights with respect to confidential
          information, the Parties agree as follows:
        </p>
      </div>
      <div>
        <h6>1. Confidential Information</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          “Confidential Information” shall mean information provided by one
          Party or its agents to the other Party or its agents, including in any
          case any and all technical and non-technical information relating to
          existing, future and/or proposed products and services of each of the
          Parties, including but not limited to expertise, copyright, trade
          secret, proprietary information, client data, techniques, sketches,
          drawings, models, inventions, know-how, processes, equipment,
          algorithms, software programs, software source documents and formulae.
          Without limiting the generality of the foregoing, the protection of
          Confidential Information shall extend to all information concerning
          Idexcel research, experimental work, developments, design details and
          specifications, engineering, financial information, business models,
          business plans, procurement requirements, purchasing, manufacturing,
          customer lists, business forecasts, sales and merchandising and
          marketing plans and other information which may be exchanged by the
          Parties. Confidential Information may be communicated in writing,
          orally or electronically.
        </p>
      </div>
      <div>
        <h6>2. Non-Disclosure of Confidential Information</h6>
        <hr />
        <div style={{ marginLeft: "20px" }}>
          <p style={{ fontSize: "12px" }}>
            2.1 Each Party (the “Receiving Party”) to which Confidential
            Information is disclosed by the other Party (the “Disclosing Party”)
            shall keep such Confidential Information strictly secret and shall
            not disclose it to any person or third party without the prior
            written consent of the Disclosing Party. In particular, the
            Receiving Party agrees:
          </p>
          <div style={{ marginLeft: "20px" }}>
            <p style={{ marginLeft: "20px", fontSize: "12px" }}>
              a). to use such Confidential Information only for purposes of
              assessing and performing the Business Relationship and to provide
              such Information only to directors, officers, employees and
              advisors of the Receiving Party that need to know such
              information;
            </p>
            <p style={{ marginLeft: "20px", fontSize: "12px" }}>
              b). to ensure that a standard of strict confidentiality is applied
              by the Receiving Party’s employees, agents and sub-contractors so
              as to prevent disclosure to third parties, including but not
              limited to taking all steps necessary to assure that its
              employees, agents and sub-contractors adhere to the terms of this
              Agreement (it being understood that any disclosure by any such
              persons or parties in contravention of this Agreement shall be
              deemed a breach of this Agreement by the Party employing or
              retaining such person);
            </p>
            <p style={{ marginLeft: "20px", fontSize: "12px" }}>
              c). to return all Confidential Information to the Disclosing
              Party, within 30 days of the written request of the Disclosing
              Party to the effect and to retain no copies or reproductions
              thereof; and
            </p>
            <p style={{ marginLeft: "20px", fontSize: "12px" }}>
              d). to certify in writing to the Disclosing Party at its request
              that the terms of the Agreement have been complied with.
            </p>
          </div>
          <p style={{ fontSize: "12px" }}>
            2.2 The Receiving Party shall not directly or indirectly disclose to
            any person or entity the fact that the Confidential Information has
            been made available, that discussions or negotiations are taking
            place or have taken place concerning the Business Relationship or
            any of the terms, conditions or other facts with respect to the
            Business Relationship, including the status thereof, nor make any
            announcement of any of the matters referred to above.
          </p>
          <p style={{ fontSize: "12px" }}>
            2.3 Each Party agrees not to initiate, solicit, enter into or engage
            in any discussions, correspondence, negotiations, agreements or
            understanding or otherwise have any contact with, any officers or
            employees of the other Party other than designated directors,
            officers employees and advisors of such Party.
          </p>
        </div>
      </div>
      <div>
        <h6>3. Limitations</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          Notwithstanding the foregoing, the Receiving Party may disclose
          Confidential Information, provided such information:
        </p>
        <div style={{ marginLeft: "20px" }}>
          <p style={{ marginLeft: "20px", fontSize: "12px" }}>
            a). was legitimately in the Receiving Party’s possession or was
            legitimately known to the Receiving Party prior to receipt from the
            Disclosing Party; or
          </p>
          <p style={{ marginLeft: "20px", fontSize: "12px" }}>
            b). is or becomes public knowledge without the fault of the
            Receiving Party; or
          </p>
          <p style={{ marginLeft: "20px", fontSize: "12px" }}>
            c). is or becomes rightfully available to the Receiving Party from a
            party that is not bound by any confidentiality undertaking and which
            is not directly or indirectly controlled by the Disclosing Party; or
          </p>
          <p style={{ marginLeft: "20px", fontSize: "12px" }}>
            d). is developed independently by employees, consultants or
            contractors of the Receiving Party without use of Confidential
            Information disclosed hereunder;
          </p>
          <p style={{ marginLeft: "20px", fontSize: "12px" }}>
            e). is required to be disclosed by an order of a court or government
            agency or in connection with a litigation or adjudication by oral
            questions, interrogatories, requests for information or documents,
            subpoena, civil investigative demand or similar process, provided
            that in such case the Receiving Party will provide the Disclosing
            Party with prompt notice of such request(s) and the documents
            requested thereby so that the Disclosing Party may seek an
            appropriate protective order or the receipt of a waiver hereunder,
            the Receiving Party is nonetheless, in the written opinion of its
            counsel (who shall not be an employee of the Receiving Party),
            compelled to disclose information concerning the Disclosing Party to
            any tribunal or else stand liable for contempt or suffer other
            censure or penalty, the Receiving Party may disclose such
            information to such tribunal without liability hereunder; provided,
            however, that the Receiving Party shall give the Disclosing Party
            written notice of the information to be so disclosed as far in
            advance of its disclosure as is practicable and shall use its best
            efforts to provide the Disclosing Party the opportunity to obtain an
            order or other reliable assurance that confidential treatment will
            be accorded to such portion of the information required to be
            disclosed as the Disclosing Party designates.
          </p>
        </div>
      </div>
      <div>
        <h6>4. Restrictions Use</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          The end user / Company may not, and shall not permit others to: (1)
          reverse engineer, decompile, decode, decrypt, disassemble, or in any
          way derive source code from, the software or Services; (2) modify,
          translate, adapt, alter, or create derivative works from the Services;
          (3) copy, distribute, publicly display, transmit, sell, rent, lease or
          otherwise exploit the Services; (4) distribute, sublicense, rent, or
          lease the Services to any third party or (5) edit, alter, abridge or
          otherwise change in any manner the content of the Services, including,
          without limitation, all copyright and proprietary rights notices.
          Subject to the limited rights expressly granted hereunder, all
          intellectual property rights in the Services not expressly granted
          hereunder are reserved to Idexcel. Any and all modifications or
          enhancements to the Services shall be the intellectual property of
          Idexcel.
        </p>
      </div>
      <div>
        <h6>5. No Express or Implied Warranty</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          The Receiving Party acknowledges that neither the Disclosing Party,
          nor any of such Disclosing Party’s subsidiaries, affiliate companies
          or representatives makes any express or implies representation or
          warranty as to the accuracy or completeness of the Confidential
          Information, errors therein or omissions there from. In evaluating the
          Business Relationship, neither Party shall rely on the accuracy or
          completeness of the Confidential Information.
        </p>
      </div>
      <div>
        <h6>6. Term; Termination; Survival</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          This Agreement shall be effective as of the date first written above
          and shall remain in full force and effect for the duration of the
          Business Relationship. Upon thirty (30) days prior written notice from
          one Party to the other, this Agreement may be terminated solely with
          respect to then undisclosed Confidential Information. The Parties’
          respective rights and obligations hereunder shall survive termination
          and remain in full force and effect with respect to each termination
          for a period of five (5) years after termination.
        </p>
      </div>
      <div>
        <h6>7. No License to Use Proprietary Rights</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          The Parties explicitly acknowledge that nothing contained in this
          Agreement shall be construed as giving to any of the Parties any
          license or other right to use or otherwise exploit in any manner
          whatsoever any proprietary rights of the other Party, including but
          not limited to any copyrights, patents, and trademarks.
          Notwithstanding the foregoing provision, neither Party shall prevent,
          or seek to prevent, the other Party from continuing to use its own
          proprietary rights in the manner in which they were used prior to the
          date of this Agreement.
        </p>
      </div>
      <div>
        <h6>8. Costs and Expenses</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          Each Party shall bear its own costs and expenses (including legal
          fees) incurred in connection with the Business Relationship
        </p>
      </div>
      <div>
        <h6>9. Remedies</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          The Parties acknowledge and agree that the unauthorized disclosure or
          use of Confidential Information is likely to give rise to irreparable
          injury to the Disclosing Party for which the Disclosing Party will
          have no adequate remedy at law. Accordingly, in the event of an actual
          or threatened unauthorized disclosure or use of Confidential
          Information in violation hereof, the Disclosing Party shall be
          entitled to obtain injunctive relief against the Receiving Party in
          addition to all other remedies available to it at law or in equity.
        </p>
      </div>
      <div>
        <h6>10. Entire Agreement</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          This Agreement represents the entire Agreement between the Parties
          with respect to the subject matter covered by this Agreement.
        </p>
      </div>
      <div>
        <h6>11. Governing Law and Jurisdiction</h6>
        <hr />
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          This Agreement shall be governed in its construction, interpretation,
          and performance by the laws of the State of Virginia, without
          reference to law pertaining to choice of laws or conflict of laws.
        </p>
        <p style={{ marginLeft: "20px", fontSize: "12px" }}>
          IN WITNESS WHEREOF, by agreeing to the terms and conditions on the
          login page of InferIQ, each of the Parties have caused this Mutual
          Nondisclosure and Confidentiality Agreement to be executed
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
