import React from "react";
import Chart from "react-apexcharts";

const PaHorizontalBarChart = ({ response }) => {
  const state = {
    series: response?.datasets?.map((dataset, index) => ({
      name: dataset?.name,
      data: dataset?.data,
    })),
    options: {
      chart: {
        type: "bar",
        height: "auto",
        stacked: true,
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      colors: ["#e23e3d", "#1290eba3"],
      plotOptions: {
        bar: {
          borderRadius: 5,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "all",
          horizontal: true,
          barHeight: "80%",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"],
          fontSize: "10px",
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      yaxis: {
        min: response?.xaxis?.minValue,
        max: response?.xaxis?.maxValue,
        title: {
          text: response?.xaxis?.title,
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return Math?.abs(val) + "%";
          },
        },
      },
      xaxis: {
        categories: response?.yaxis?.categories,
        title: {
          text: response?.yaxis?.title,
        },
        labels: {
          formatter: function (val) {
            return Math.abs(Math.round(val)) + "%";
          },
        },
      },
    },
  };

  return (
    <Chart
      options={state?.options}
      series={state?.series}
      type="bar"
      height={500}
    />
  );
};

export default PaHorizontalBarChart;
