import { useContext } from "react";
import axios from "axios";
import ParentContext from "../context/context";
import env from "../env/env.js";

const useForgetPasswordApi = () => {
  const { state, dispatch } = useContext(ParentContext);
  let keys;
  const getForgetPassVeriFiCode = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    await axios
      .post(
        `${env.API_GATEWAY}/authenticate`,
        {
          callType: "forgot_password",
          formData: { email: state?.forgetPasswordFormData?.email },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          const encryptionKey = response.data.key;
          const initVector = response.data.iv;
          const status = response.data.statusCode;
          keys = {
            eyk: encryptionKey,
            iv: initVector,
            st: status,
          };
          sessionStorage.setItem("qes", JSON.stringify(keys));
          dispatch({
            type: "SET_SIGNIN_BACKEND_RES",
            payload: response.data,
          });
        }
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
      });
  };

  return { getForgetPassVeriFiCode };
};

export default useForgetPasswordApi;
