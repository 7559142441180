import React from "react";
import Input from "../../../components/Input/Input";
import CustomButton from "../../../components/Button/CustomButton";
import { ForgetPassVerificationForm } from "./ForgetPassVerificationForm";

const ForgotPassword = ({
  handleGetVerifiCodeForNewPassword,
  formError,
  handleForgotPassInputChange,
  showVerificationForm,
  showVerificationEmail,
  showSignIn,
  handeleChangeNewPassword,
  showPassword,
  handleShowPassword,
  forgetPasswordFormData,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <p className="heading">Forgot Password</p>
      {showVerificationEmail && (
        <form onSubmit={handleGetVerifiCodeForNewPassword}>
          <Input
            label="Corporate Email"
            type="email"
            name="email"
            value={forgetPasswordFormData.userName}
            placeholder="Enter your email address..."
            handleChange={handleForgotPassInputChange}
            required={true}
          />
          <div
            className="signin_form_btn_redirect"
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <CustomButton
              variant="primary"
              onClick={showSignIn}
              size="md"
              type="submit"
            >
              Back
            </CustomButton>
            <CustomButton variant="primary" size="md" type="submit">
              Get verification code
            </CustomButton>
          </div>
          {formError && <p className="signin_form_error">{formError}</p>}
        </form>
      )}
      {showVerificationForm && (
        <ForgetPassVerificationForm
          formError={formError}
          handleForgotPassInputChange={handleForgotPassInputChange}
          showSignIn={showSignIn}
          handeleChangeNewPassword={handeleChangeNewPassword}
          showPassword={showPassword}
          handleShowPassword={handleShowPassword}
          name={forgetPasswordFormData.name}
        />
      )}
    </div>
  );
};

export default ForgotPassword;
