import React, { useContext, useEffect, Fragment } from "react";
import { InferSideImage, Loader } from "../../components";
import { predictiveAnalyticsImg } from "../../images/sideContent";
import { Col, Container, Row } from "react-bootstrap";
import "./PredictiveAnalytics.css";
import PaTabViewer from "./components/PaTabViewer";
import PaOptions from "./components/PaOptions";
import { usePredictiveAnalyticsApiCall } from "../../Hooks/index";
import ParentContext from "../../context/context";
import { useToasts } from "../../Hooks/index";
import { userPermission } from "../../Auth/authConfig";

const PredictiveAnalytics = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { handleSubmit } = usePredictiveAnalyticsApiCall();
  const { formData, paActiveTab, loading, selectedValue } = state;
  const { Toast } = useToasts();

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "Forecasting" });
  }, [dispatch]);

  const submitPredictiveAnalytics = (event) => {
    event?.preventDefault();
    if (selectedValue === "Loan Default") {
      handleSubmit(event, formData, paActiveTab);
    } else {
      Toast(
        "error",
        "Input variable missing, please check if Radio option selected or file the form."
      );
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Container fluid>
          <Row>
            <div className="pa-container">
              <Col xs={8}>
                <form className="pa-form" onSubmit={submitPredictiveAnalytics}>
                  <Row>
                    <Col xs={12}>
                      <PaOptions
                        PredictiveAnalyticsRadioOptions={userPermission?.map(
                          (item) =>
                            item.path === "/predictiveAnalytics" &&
                            item.RadioOptions
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <PaTabViewer />
                  </Row>
                </form>
              </Col>
              <Col xs={4}>
                <div className="rent-roll-content">
                  <InferSideImage
                    image={predictiveAnalyticsImg}
                    title="Predictive Analytics"
                  >
                    Predictive analytics is the process of using data to
                    forecast future outcomes. The process uses data analysis,
                    machine learning, artificial intelligence, and statistical
                    models to find patterns that might predict future behavior.
                    Organizations can use historic and current data to forecast
                    trends and behaviors seconds, days, or years into the future
                    with a great deal of precision.
                  </InferSideImage>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};

export default PredictiveAnalytics;
