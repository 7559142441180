import React, { memo, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { RentRollQuestions } from "../components/index";
import { DragAndDrop, Radio, CustomButton } from "../../../components/index";
import PageSelectionModal from "../../../components/PageSelectionModal/PageSelectionModal";
import ParentContext from "../../../context/context";
import { useToasts } from "../../../Hooks";

const CreForm = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const { state } = useContext(ParentContext);
  const { file, uploadedFiles } = state;

  const { Toast } = useToasts();

  const showPageSelection = (event) => {
    event?.preventDefault();
    if (uploadedFiles.length > 0) {
    if (file.size > 6291456) {
      Toast(
        "warn",
        "Please select at least 1 page from the left hand side page selection pannel."
      );
    } else {
      if (file.type === "application/pdf") {
          setModalShow(true);
        } else {
          props.submitCre();
        }
      }
    } else {
      Toast(
        "error",
        "Input variable missing, please check if file type is selected or file is uploaded."
      );
    }
  };

  const findObjectInArray = (array) => {
    return array.filter((item) => typeof item === "object" && item !== null);
  };

  let RadioOptions = findObjectInArray(props.RadioOptions.flat());
  return (
    <form className="rent-roll-form" onSubmit={props.submitCre}>
      <Row>
        <Col xs={12}>
          <div className="rent-roll-options">
            {RadioOptions?.map(({ id, label, value, group }) => (
              <span className="radio-wrapper" key={id}>
                <Radio
                  label={label}
                  value={value}
                  group={group}
                  Checked={props.selectedValue === `${value}`}
                  onChange={props.handleChange}
                />
              </span>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={{
            span: 10,
            offset: 2,
          }}
        >
          <DragAndDrop
            style={{
              width: "75%",
              height: "11rem",
              minHeight: "11rem",
            }}
            resetUploads={props.resetUploads}
            setFile={(file) =>
              props.dispatch({
                type: "SET_FILE",
                payload: file,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <RentRollQuestions />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col
          xs={12}
          md={{
            span: 8,
            offset: 10,
          }}
        >
          <CustomButton variant="primary" size="md" onClick={showPageSelection}>
            Next
          </CustomButton>
        </Col>
      </Row>
      {modalShow && !props.resetUploads && (
        <PageSelectionModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          onSave={props.submitCre}
        />
      )}
    </form>
  );
};

export default memo(CreForm);
