import CryptoJS from 'crypto-js';

export const encryptData = (data, encryptionKey, initVector) => {
  const key = CryptoJS.enc.Utf8.parse(encryptionKey);
  const iv = CryptoJS.enc.Utf8.parse(initVector);

  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    mode: CryptoJS.mode.CFB,
    iv: iv,
    padding: CryptoJS.pad.NoPadding,
  });

  return encrypted?.toString();
};

export const decryptData = (data, encryptionKey, initVector) => {
  const key = CryptoJS.enc.Utf8.parse(encryptionKey);
  const iv = CryptoJS.enc.Utf8.parse(initVector);

  const decrypted = CryptoJS.AES.decrypt(data, key, {
    mode: CryptoJS.mode.CFB,
    iv: iv,
    padding: CryptoJS.pad.NoPadding,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};